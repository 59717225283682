import styled from 'styled-components';

export const CreateJobS = styled.div`
	display: flex;
	align-items: flex-start;
	min-height: 80vh;
	width: 100%;

	#err .ant-typography {
		margin: 6px 10px 0 0;
	}
	
	@media (max-width: 600px) {
		margin: 20px 0;
	}
`;

export const StepsWrapperS = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 100%;
`;

export const StepContentS = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70vh;
	border-radius: 4px;
	border: 1px solid rgba(140, 140, 140, 0.35);
`;
