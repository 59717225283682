/* eslint-disable */
// source: scheduler/models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var scheduler_enums_pb = require('../scheduler/enums_pb.js');
goog.object.extend(proto, scheduler_enums_pb);
var cloud_models_pb = require('../cloud/models_pb.js');
goog.object.extend(proto, cloud_models_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.scheduler.api.v1.FileOutput', null, global);
goog.exportSymbol('proto.scheduler.api.v1.InputInfo', null, global);
goog.exportSymbol('proto.scheduler.api.v1.InputInfo.ConfigCase', null, global);
goog.exportSymbol('proto.scheduler.api.v1.JobInfo', null, global);
goog.exportSymbol('proto.scheduler.api.v1.JobPartInfo', null, global);
goog.exportSymbol('proto.scheduler.api.v1.JobResultInfo', null, global);
goog.exportSymbol('proto.scheduler.api.v1.OutputInfo', null, global);
goog.exportSymbol('proto.scheduler.api.v1.OutputInfo.ConfigCase', null, global);
goog.exportSymbol('proto.scheduler.api.v1.Preset', null, global);
goog.exportSymbol('proto.scheduler.api.v1.PresetAudioOutput', null, global);
goog.exportSymbol('proto.scheduler.api.v1.PresetVideoOutput', null, global);
goog.exportSymbol('proto.scheduler.api.v1.Rational', null, global);
goog.exportSymbol('proto.scheduler.api.v1.Resources', null, global);
goog.exportSymbol('proto.scheduler.api.v1.ResultInfo', null, global);
goog.exportSymbol('proto.scheduler.api.v1.VideoAudioStreamMapping', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.FileOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scheduler.api.v1.FileOutput.repeatedFields_, null);
};
goog.inherits(proto.scheduler.api.v1.FileOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.FileOutput.displayName = 'proto.scheduler.api.v1.FileOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.VideoAudioStreamMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.VideoAudioStreamMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.VideoAudioStreamMapping.displayName = 'proto.scheduler.api.v1.VideoAudioStreamMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.Rational = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.Rational, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.Rational.displayName = 'proto.scheduler.api.v1.Rational';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.Preset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scheduler.api.v1.Preset.repeatedFields_, null);
};
goog.inherits(proto.scheduler.api.v1.Preset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.Preset.displayName = 'proto.scheduler.api.v1.Preset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.Resources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.Resources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.Resources.displayName = 'proto.scheduler.api.v1.Resources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.PresetVideoOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.PresetVideoOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.PresetVideoOutput.displayName = 'proto.scheduler.api.v1.PresetVideoOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.PresetAudioOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.PresetAudioOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.PresetAudioOutput.displayName = 'proto.scheduler.api.v1.PresetAudioOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.JobInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.JobInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.JobInfo.displayName = 'proto.scheduler.api.v1.JobInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.JobPartInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.JobPartInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.JobPartInfo.displayName = 'proto.scheduler.api.v1.JobPartInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.OutputInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.scheduler.api.v1.OutputInfo.oneofGroups_);
};
goog.inherits(proto.scheduler.api.v1.OutputInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.OutputInfo.displayName = 'proto.scheduler.api.v1.OutputInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.InputInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.scheduler.api.v1.InputInfo.oneofGroups_);
};
goog.inherits(proto.scheduler.api.v1.InputInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.InputInfo.displayName = 'proto.scheduler.api.v1.InputInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.JobResultInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scheduler.api.v1.JobResultInfo.repeatedFields_, null);
};
goog.inherits(proto.scheduler.api.v1.JobResultInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.JobResultInfo.displayName = 'proto.scheduler.api.v1.JobResultInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.ResultInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scheduler.api.v1.ResultInfo.repeatedFields_, null);
};
goog.inherits(proto.scheduler.api.v1.ResultInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.ResultInfo.displayName = 'proto.scheduler.api.v1.ResultInfo';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scheduler.api.v1.FileOutput.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.FileOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.FileOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.FileOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.FileOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    settingsMap: (f = msg.getSettingsMap()) ? f.toObject(includeInstance, undefined) : [],
    videoAudioMapList: jspb.Message.toObjectList(msg.getVideoAudioMapList(),
    proto.scheduler.api.v1.VideoAudioStreamMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.FileOutput}
 */
proto.scheduler.api.v1.FileOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.FileOutput;
  return proto.scheduler.api.v1.FileOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.FileOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.FileOutput}
 */
proto.scheduler.api.v1.FileOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = msg.getSettingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = new proto.scheduler.api.v1.VideoAudioStreamMapping;
      reader.readMessage(value,proto.scheduler.api.v1.VideoAudioStreamMapping.deserializeBinaryFromReader);
      msg.addVideoAudioMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.FileOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.FileOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.FileOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.FileOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSettingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getVideoAudioMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.scheduler.api.v1.VideoAudioStreamMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.scheduler.api.v1.FileOutput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.FileOutput} returns this
 */
proto.scheduler.api.v1.FileOutput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.scheduler.api.v1.FileOutput.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.FileOutput} returns this
 */
proto.scheduler.api.v1.FileOutput.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> settings = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.scheduler.api.v1.FileOutput.prototype.getSettingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.scheduler.api.v1.FileOutput} returns this
 */
proto.scheduler.api.v1.FileOutput.prototype.clearSettingsMap = function() {
  this.getSettingsMap().clear();
  return this;};


/**
 * repeated VideoAudioStreamMapping video_audio_map = 4;
 * @return {!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>}
 */
proto.scheduler.api.v1.FileOutput.prototype.getVideoAudioMapList = function() {
  return /** @type{!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scheduler.api.v1.VideoAudioStreamMapping, 4));
};


/**
 * @param {!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>} value
 * @return {!proto.scheduler.api.v1.FileOutput} returns this
*/
proto.scheduler.api.v1.FileOutput.prototype.setVideoAudioMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.scheduler.api.v1.VideoAudioStreamMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scheduler.api.v1.VideoAudioStreamMapping}
 */
proto.scheduler.api.v1.FileOutput.prototype.addVideoAudioMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.scheduler.api.v1.VideoAudioStreamMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scheduler.api.v1.FileOutput} returns this
 */
proto.scheduler.api.v1.FileOutput.prototype.clearVideoAudioMapList = function() {
  return this.setVideoAudioMapList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.VideoAudioStreamMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.VideoAudioStreamMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    video: jspb.Message.getFieldWithDefault(msg, 1, ""),
    audio: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.VideoAudioStreamMapping}
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.VideoAudioStreamMapping;
  return proto.scheduler.api.v1.VideoAudioStreamMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.VideoAudioStreamMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.VideoAudioStreamMapping}
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.VideoAudioStreamMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.VideoAudioStreamMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAudio();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string video = 1;
 * @return {string}
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.prototype.getVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.VideoAudioStreamMapping} returns this
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.prototype.setVideo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audio = 2;
 * @return {string}
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.prototype.getAudio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.VideoAudioStreamMapping} returns this
 */
proto.scheduler.api.v1.VideoAudioStreamMapping.prototype.setAudio = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.Rational.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.Rational.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.Rational} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.Rational.toObject = function(includeInstance, msg) {
  var f, obj = {
    num: jspb.Message.getFieldWithDefault(msg, 1, 0),
    den: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.Rational}
 */
proto.scheduler.api.v1.Rational.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.Rational;
  return proto.scheduler.api.v1.Rational.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.Rational} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.Rational}
 */
proto.scheduler.api.v1.Rational.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.Rational.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.Rational.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.Rational} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.Rational.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDen();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 num = 1;
 * @return {number}
 */
proto.scheduler.api.v1.Rational.prototype.getNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.Rational} returns this
 */
proto.scheduler.api.v1.Rational.prototype.setNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 den = 2;
 * @return {number}
 */
proto.scheduler.api.v1.Rational.prototype.getDen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.Rational} returns this
 */
proto.scheduler.api.v1.Rational.prototype.setDen = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scheduler.api.v1.Preset.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.Preset.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.Preset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.Preset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.Preset.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    videoOutputsList: jspb.Message.toObjectList(msg.getVideoOutputsList(),
    proto.scheduler.api.v1.PresetVideoOutput.toObject, includeInstance),
    audioOutputsList: jspb.Message.toObjectList(msg.getAudioOutputsList(),
    proto.scheduler.api.v1.PresetAudioOutput.toObject, includeInstance),
    fileOutputsList: jspb.Message.toObjectList(msg.getFileOutputsList(),
    proto.scheduler.api.v1.FileOutput.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.Preset}
 */
proto.scheduler.api.v1.Preset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.Preset;
  return proto.scheduler.api.v1.Preset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.Preset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.Preset}
 */
proto.scheduler.api.v1.Preset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.scheduler.api.v1.PresetVideoOutput;
      reader.readMessage(value,proto.scheduler.api.v1.PresetVideoOutput.deserializeBinaryFromReader);
      msg.addVideoOutputs(value);
      break;
    case 3:
      var value = new proto.scheduler.api.v1.PresetAudioOutput;
      reader.readMessage(value,proto.scheduler.api.v1.PresetAudioOutput.deserializeBinaryFromReader);
      msg.addAudioOutputs(value);
      break;
    case 4:
      var value = new proto.scheduler.api.v1.FileOutput;
      reader.readMessage(value,proto.scheduler.api.v1.FileOutput.deserializeBinaryFromReader);
      msg.addFileOutputs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.Preset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.Preset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.Preset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.Preset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVideoOutputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.scheduler.api.v1.PresetVideoOutput.serializeBinaryToWriter
    );
  }
  f = message.getAudioOutputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.scheduler.api.v1.PresetAudioOutput.serializeBinaryToWriter
    );
  }
  f = message.getFileOutputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.scheduler.api.v1.FileOutput.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.scheduler.api.v1.Preset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.Preset} returns this
 */
proto.scheduler.api.v1.Preset.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PresetVideoOutput video_outputs = 2;
 * @return {!Array<!proto.scheduler.api.v1.PresetVideoOutput>}
 */
proto.scheduler.api.v1.Preset.prototype.getVideoOutputsList = function() {
  return /** @type{!Array<!proto.scheduler.api.v1.PresetVideoOutput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scheduler.api.v1.PresetVideoOutput, 2));
};


/**
 * @param {!Array<!proto.scheduler.api.v1.PresetVideoOutput>} value
 * @return {!proto.scheduler.api.v1.Preset} returns this
*/
proto.scheduler.api.v1.Preset.prototype.setVideoOutputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.scheduler.api.v1.PresetVideoOutput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scheduler.api.v1.PresetVideoOutput}
 */
proto.scheduler.api.v1.Preset.prototype.addVideoOutputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.scheduler.api.v1.PresetVideoOutput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scheduler.api.v1.Preset} returns this
 */
proto.scheduler.api.v1.Preset.prototype.clearVideoOutputsList = function() {
  return this.setVideoOutputsList([]);
};


/**
 * repeated PresetAudioOutput audio_outputs = 3;
 * @return {!Array<!proto.scheduler.api.v1.PresetAudioOutput>}
 */
proto.scheduler.api.v1.Preset.prototype.getAudioOutputsList = function() {
  return /** @type{!Array<!proto.scheduler.api.v1.PresetAudioOutput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scheduler.api.v1.PresetAudioOutput, 3));
};


/**
 * @param {!Array<!proto.scheduler.api.v1.PresetAudioOutput>} value
 * @return {!proto.scheduler.api.v1.Preset} returns this
*/
proto.scheduler.api.v1.Preset.prototype.setAudioOutputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.scheduler.api.v1.PresetAudioOutput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scheduler.api.v1.PresetAudioOutput}
 */
proto.scheduler.api.v1.Preset.prototype.addAudioOutputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.scheduler.api.v1.PresetAudioOutput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scheduler.api.v1.Preset} returns this
 */
proto.scheduler.api.v1.Preset.prototype.clearAudioOutputsList = function() {
  return this.setAudioOutputsList([]);
};


/**
 * repeated FileOutput file_outputs = 4;
 * @return {!Array<!proto.scheduler.api.v1.FileOutput>}
 */
proto.scheduler.api.v1.Preset.prototype.getFileOutputsList = function() {
  return /** @type{!Array<!proto.scheduler.api.v1.FileOutput>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scheduler.api.v1.FileOutput, 4));
};


/**
 * @param {!Array<!proto.scheduler.api.v1.FileOutput>} value
 * @return {!proto.scheduler.api.v1.Preset} returns this
*/
proto.scheduler.api.v1.Preset.prototype.setFileOutputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.scheduler.api.v1.FileOutput=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scheduler.api.v1.FileOutput}
 */
proto.scheduler.api.v1.Preset.prototype.addFileOutputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.scheduler.api.v1.FileOutput, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scheduler.api.v1.Preset} returns this
 */
proto.scheduler.api.v1.Preset.prototype.clearFileOutputsList = function() {
  return this.setFileOutputsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.Resources.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.Resources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.Resources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.Resources.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsCpu: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    requestsGpu: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.Resources}
 */
proto.scheduler.api.v1.Resources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.Resources;
  return proto.scheduler.api.v1.Resources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.Resources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.Resources}
 */
proto.scheduler.api.v1.Resources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRequestsCpu(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestsGpu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.Resources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.Resources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.Resources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.Resources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsCpu();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getRequestsGpu();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional float requests_cpu = 1;
 * @return {number}
 */
proto.scheduler.api.v1.Resources.prototype.getRequestsCpu = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.Resources} returns this
 */
proto.scheduler.api.v1.Resources.prototype.setRequestsCpu = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 requests_gpu = 2;
 * @return {number}
 */
proto.scheduler.api.v1.Resources.prototype.getRequestsGpu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.Resources} returns this
 */
proto.scheduler.api.v1.Resources.prototype.setRequestsGpu = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.PresetVideoOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.PresetVideoOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.PresetVideoOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    encoderName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxGop: jspb.Message.getFieldWithDefault(msg, 3, 0),
    width: jspb.Message.getFieldWithDefault(msg, 4, 0),
    height: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bitrate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    refs: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pixelFormat: jspb.Message.getFieldWithDefault(msg, 8, 0),
    frameRate: (f = msg.getFrameRate()) && proto.scheduler.api.v1.Rational.toObject(includeInstance, f),
    timeBase: (f = msg.getTimeBase()) && proto.scheduler.api.v1.Rational.toObject(includeInstance, f),
    filter: jspb.Message.getFieldWithDefault(msg, 11, ""),
    level: jspb.Message.getFieldWithDefault(msg, 12, 0),
    colorSpace: jspb.Message.getFieldWithDefault(msg, 13, 0),
    passMode: jspb.Message.getFieldWithDefault(msg, 14, 0),
    rcMaxBitrate: jspb.Message.getFieldWithDefault(msg, 15, 0),
    rcMinBitrate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    resources: (f = msg.getResources()) && proto.scheduler.api.v1.Resources.toObject(includeInstance, f),
    bufSize: jspb.Message.getFieldWithDefault(msg, 18, 0),
    maxBFrames: jspb.Message.getFieldWithDefault(msg, 19, 0),
    keyIntMin: jspb.Message.getFieldWithDefault(msg, 20, 0),
    privDataMap: (f = msg.getPrivDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.PresetVideoOutput}
 */
proto.scheduler.api.v1.PresetVideoOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.PresetVideoOutput;
  return proto.scheduler.api.v1.PresetVideoOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.PresetVideoOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.PresetVideoOutput}
 */
proto.scheduler.api.v1.PresetVideoOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncoderName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxGop(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeight(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBitrate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRefs(value);
      break;
    case 8:
      var value = /** @type {!proto.scheduler.api.v1.PixelFormat} */ (reader.readEnum());
      msg.setPixelFormat(value);
      break;
    case 9:
      var value = new proto.scheduler.api.v1.Rational;
      reader.readMessage(value,proto.scheduler.api.v1.Rational.deserializeBinaryFromReader);
      msg.setFrameRate(value);
      break;
    case 10:
      var value = new proto.scheduler.api.v1.Rational;
      reader.readMessage(value,proto.scheduler.api.v1.Rational.deserializeBinaryFromReader);
      msg.setTimeBase(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLevel(value);
      break;
    case 13:
      var value = /** @type {!proto.scheduler.api.v1.ColorSpace} */ (reader.readEnum());
      msg.setColorSpace(value);
      break;
    case 14:
      var value = /** @type {!proto.scheduler.api.v1.PassMode} */ (reader.readEnum());
      msg.setPassMode(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRcMaxBitrate(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRcMinBitrate(value);
      break;
    case 17:
      var value = new proto.scheduler.api.v1.Resources;
      reader.readMessage(value,proto.scheduler.api.v1.Resources.deserializeBinaryFromReader);
      msg.setResources(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBufSize(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxBFrames(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKeyIntMin(value);
      break;
    case 99:
      var value = msg.getPrivDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.PresetVideoOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.PresetVideoOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.PresetVideoOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEncoderName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxGop();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getBitrate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getRefs();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPixelFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getFrameRate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.scheduler.api.v1.Rational.serializeBinaryToWriter
    );
  }
  f = message.getTimeBase();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.scheduler.api.v1.Rational.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getColorSpace();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getPassMode();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getRcMaxBitrate();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getRcMinBitrate();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getResources();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.scheduler.api.v1.Resources.serializeBinaryToWriter
    );
  }
  f = message.getBufSize();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getMaxBFrames();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getKeyIntMin();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getPrivDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string encoder_name = 2;
 * @return {string}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getEncoderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setEncoderName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 max_gop = 3;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getMaxGop = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setMaxGop = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 width = 4;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 height = 5;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 bitrate = 6;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 refs = 7;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getRefs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setRefs = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional PixelFormat pixel_format = 8;
 * @return {!proto.scheduler.api.v1.PixelFormat}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getPixelFormat = function() {
  return /** @type {!proto.scheduler.api.v1.PixelFormat} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.scheduler.api.v1.PixelFormat} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setPixelFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional Rational frame_rate = 9;
 * @return {?proto.scheduler.api.v1.Rational}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getFrameRate = function() {
  return /** @type{?proto.scheduler.api.v1.Rational} */ (
    jspb.Message.getWrapperField(this, proto.scheduler.api.v1.Rational, 9));
};


/**
 * @param {?proto.scheduler.api.v1.Rational|undefined} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
*/
proto.scheduler.api.v1.PresetVideoOutput.prototype.setFrameRate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.clearFrameRate = function() {
  return this.setFrameRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.hasFrameRate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Rational time_base = 10;
 * @return {?proto.scheduler.api.v1.Rational}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getTimeBase = function() {
  return /** @type{?proto.scheduler.api.v1.Rational} */ (
    jspb.Message.getWrapperField(this, proto.scheduler.api.v1.Rational, 10));
};


/**
 * @param {?proto.scheduler.api.v1.Rational|undefined} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
*/
proto.scheduler.api.v1.PresetVideoOutput.prototype.setTimeBase = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.clearTimeBase = function() {
  return this.setTimeBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.hasTimeBase = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string filter = 11;
 * @return {string}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 level = 12;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional ColorSpace color_space = 13;
 * @return {!proto.scheduler.api.v1.ColorSpace}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getColorSpace = function() {
  return /** @type {!proto.scheduler.api.v1.ColorSpace} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.scheduler.api.v1.ColorSpace} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setColorSpace = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional PassMode pass_mode = 14;
 * @return {!proto.scheduler.api.v1.PassMode}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getPassMode = function() {
  return /** @type {!proto.scheduler.api.v1.PassMode} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.scheduler.api.v1.PassMode} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setPassMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int64 rc_max_bitrate = 15;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getRcMaxBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setRcMaxBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 rc_min_bitrate = 16;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getRcMinBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setRcMinBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional Resources resources = 17;
 * @return {?proto.scheduler.api.v1.Resources}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getResources = function() {
  return /** @type{?proto.scheduler.api.v1.Resources} */ (
    jspb.Message.getWrapperField(this, proto.scheduler.api.v1.Resources, 17));
};


/**
 * @param {?proto.scheduler.api.v1.Resources|undefined} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
*/
proto.scheduler.api.v1.PresetVideoOutput.prototype.setResources = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.clearResources = function() {
  return this.setResources(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.hasResources = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int64 buf_size = 18;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getBufSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setBufSize = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 max_b_frames = 19;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getMaxBFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setMaxBFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 key_int_min = 20;
 * @return {number}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getKeyIntMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.setKeyIntMin = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * map<string, string> priv_data = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.getPrivDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.scheduler.api.v1.PresetVideoOutput} returns this
 */
proto.scheduler.api.v1.PresetVideoOutput.prototype.clearPrivDataMap = function() {
  this.getPrivDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.PresetAudioOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.PresetAudioOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.PresetAudioOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    encoderName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bitrate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    channelLayout: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sampleFormat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    channels: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sampleRate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    timeBase: (f = msg.getTimeBase()) && proto.scheduler.api.v1.Rational.toObject(includeInstance, f),
    privDataMap: (f = msg.getPrivDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.PresetAudioOutput}
 */
proto.scheduler.api.v1.PresetAudioOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.PresetAudioOutput;
  return proto.scheduler.api.v1.PresetAudioOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.PresetAudioOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.PresetAudioOutput}
 */
proto.scheduler.api.v1.PresetAudioOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncoderName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBitrate(value);
      break;
    case 4:
      var value = /** @type {!proto.scheduler.api.v1.ChannelLayout} */ (reader.readEnum());
      msg.setChannelLayout(value);
      break;
    case 5:
      var value = /** @type {!proto.scheduler.api.v1.SampleFormat} */ (reader.readEnum());
      msg.setSampleFormat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannels(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSampleRate(value);
      break;
    case 8:
      var value = new proto.scheduler.api.v1.Rational;
      reader.readMessage(value,proto.scheduler.api.v1.Rational.deserializeBinaryFromReader);
      msg.setTimeBase(value);
      break;
    case 99:
      var value = msg.getPrivDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.PresetAudioOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.PresetAudioOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.PresetAudioOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEncoderName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBitrate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getChannelLayout();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSampleFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getChannels();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getSampleRate();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTimeBase();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.scheduler.api.v1.Rational.serializeBinaryToWriter
    );
  }
  f = message.getPrivDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string encoder_name = 2;
 * @return {string}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getEncoderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.setEncoderName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 bitrate = 3;
 * @return {number}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.setBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ChannelLayout channel_layout = 4;
 * @return {!proto.scheduler.api.v1.ChannelLayout}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getChannelLayout = function() {
  return /** @type {!proto.scheduler.api.v1.ChannelLayout} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.scheduler.api.v1.ChannelLayout} value
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.setChannelLayout = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional SampleFormat sample_format = 5;
 * @return {!proto.scheduler.api.v1.SampleFormat}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getSampleFormat = function() {
  return /** @type {!proto.scheduler.api.v1.SampleFormat} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.scheduler.api.v1.SampleFormat} value
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.setSampleFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional uint32 channels = 6;
 * @return {number}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.setChannels = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 sample_rate = 7;
 * @return {number}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getSampleRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.setSampleRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Rational time_base = 8;
 * @return {?proto.scheduler.api.v1.Rational}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getTimeBase = function() {
  return /** @type{?proto.scheduler.api.v1.Rational} */ (
    jspb.Message.getWrapperField(this, proto.scheduler.api.v1.Rational, 8));
};


/**
 * @param {?proto.scheduler.api.v1.Rational|undefined} value
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
*/
proto.scheduler.api.v1.PresetAudioOutput.prototype.setTimeBase = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.clearTimeBase = function() {
  return this.setTimeBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.hasTimeBase = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * map<string, string> priv_data = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.getPrivDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.scheduler.api.v1.PresetAudioOutput} returns this
 */
proto.scheduler.api.v1.PresetAudioOutput.prototype.clearPrivDataMap = function() {
  this.getPrivDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.JobInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.JobInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.JobInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inputInfo: (f = msg.getInputInfo()) && proto.scheduler.api.v1.InputInfo.toObject(includeInstance, f),
    preset: (f = msg.getPreset()) && proto.scheduler.api.v1.Preset.toObject(includeInstance, f),
    outputInfo: (f = msg.getOutputInfo()) && proto.scheduler.api.v1.OutputInfo.toObject(includeInstance, f),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxPartDurationInSeconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 99, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.JobInfo}
 */
proto.scheduler.api.v1.JobInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.JobInfo;
  return proto.scheduler.api.v1.JobInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.JobInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.JobInfo}
 */
proto.scheduler.api.v1.JobInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.scheduler.api.v1.JobStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto.scheduler.api.v1.InputInfo;
      reader.readMessage(value,proto.scheduler.api.v1.InputInfo.deserializeBinaryFromReader);
      msg.setInputInfo(value);
      break;
    case 4:
      var value = new proto.scheduler.api.v1.Preset;
      reader.readMessage(value,proto.scheduler.api.v1.Preset.deserializeBinaryFromReader);
      msg.setPreset(value);
      break;
    case 5:
      var value = new proto.scheduler.api.v1.OutputInfo;
      reader.readMessage(value,proto.scheduler.api.v1.OutputInfo.deserializeBinaryFromReader);
      msg.setOutputInfo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgress(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxPartDurationInSeconds(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.JobInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.JobInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.JobInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getInputInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.scheduler.api.v1.InputInfo.serializeBinaryToWriter
    );
  }
  f = message.getPreset();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.scheduler.api.v1.Preset.serializeBinaryToWriter
    );
  }
  f = message.getOutputInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.scheduler.api.v1.OutputInfo.serializeBinaryToWriter
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMaxPartDurationInSeconds();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      99,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.scheduler.api.v1.JobInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional JobStatus status = 2;
 * @return {!proto.scheduler.api.v1.JobStatus}
 */
proto.scheduler.api.v1.JobInfo.prototype.getStatus = function() {
  return /** @type {!proto.scheduler.api.v1.JobStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.scheduler.api.v1.JobStatus} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional InputInfo input_info = 3;
 * @return {?proto.scheduler.api.v1.InputInfo}
 */
proto.scheduler.api.v1.JobInfo.prototype.getInputInfo = function() {
  return /** @type{?proto.scheduler.api.v1.InputInfo} */ (
    jspb.Message.getWrapperField(this, proto.scheduler.api.v1.InputInfo, 3));
};


/**
 * @param {?proto.scheduler.api.v1.InputInfo|undefined} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
*/
proto.scheduler.api.v1.JobInfo.prototype.setInputInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.clearInputInfo = function() {
  return this.setInputInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.JobInfo.prototype.hasInputInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Preset preset = 4;
 * @return {?proto.scheduler.api.v1.Preset}
 */
proto.scheduler.api.v1.JobInfo.prototype.getPreset = function() {
  return /** @type{?proto.scheduler.api.v1.Preset} */ (
    jspb.Message.getWrapperField(this, proto.scheduler.api.v1.Preset, 4));
};


/**
 * @param {?proto.scheduler.api.v1.Preset|undefined} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
*/
proto.scheduler.api.v1.JobInfo.prototype.setPreset = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.clearPreset = function() {
  return this.setPreset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.JobInfo.prototype.hasPreset = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OutputInfo output_info = 5;
 * @return {?proto.scheduler.api.v1.OutputInfo}
 */
proto.scheduler.api.v1.JobInfo.prototype.getOutputInfo = function() {
  return /** @type{?proto.scheduler.api.v1.OutputInfo} */ (
    jspb.Message.getWrapperField(this, proto.scheduler.api.v1.OutputInfo, 5));
};


/**
 * @param {?proto.scheduler.api.v1.OutputInfo|undefined} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
*/
proto.scheduler.api.v1.JobInfo.prototype.setOutputInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.clearOutputInfo = function() {
  return this.setOutputInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.JobInfo.prototype.hasOutputInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float progress = 6;
 * @return {number}
 */
proto.scheduler.api.v1.JobInfo.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 max_part_duration_in_seconds = 7;
 * @return {number}
 */
proto.scheduler.api.v1.JobInfo.prototype.getMaxPartDurationInSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.setMaxPartDurationInSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 created_at = 99;
 * @return {number}
 */
proto.scheduler.api.v1.JobInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};


/**
 * optional int64 updated_at = 100;
 * @return {number}
 */
proto.scheduler.api.v1.JobInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobInfo} returns this
 */
proto.scheduler.api.v1.JobInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.JobPartInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.JobPartInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobPartInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    jobId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    startPts: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endPts: jspb.Message.getFieldWithDefault(msg, 6, 0),
    endDts: jspb.Message.getFieldWithDefault(msg, 9, 0),
    presetOutputName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 99, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.JobPartInfo}
 */
proto.scheduler.api.v1.JobPartInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.JobPartInfo;
  return proto.scheduler.api.v1.JobPartInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.JobPartInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.JobPartInfo}
 */
proto.scheduler.api.v1.JobPartInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 3:
      var value = /** @type {!proto.scheduler.api.v1.JobPartStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgress(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartPts(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndPts(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndDts(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresetOutputName(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.JobPartInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.JobPartInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobPartInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getStartPts();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getEndPts();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEndDts();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPresetOutputName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      99,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string job_id = 2;
 * @return {string}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional JobPartStatus status = 3;
 * @return {!proto.scheduler.api.v1.JobPartStatus}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getStatus = function() {
  return /** @type {!proto.scheduler.api.v1.JobPartStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.scheduler.api.v1.JobPartStatus} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float progress = 4;
 * @return {number}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 start_pts = 5;
 * @return {number}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getStartPts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setStartPts = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 end_pts = 6;
 * @return {number}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getEndPts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setEndPts = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 end_dts = 9;
 * @return {number}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getEndDts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setEndDts = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string preset_output_name = 7;
 * @return {string}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getPresetOutputName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setPresetOutputName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 created_at = 99;
 * @return {number}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};


/**
 * optional int64 updated_at = 100;
 * @return {number}
 */
proto.scheduler.api.v1.JobPartInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobPartInfo} returns this
 */
proto.scheduler.api.v1.JobPartInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.scheduler.api.v1.OutputInfo.oneofGroups_ = [[20,21,22]];

/**
 * @enum {number}
 */
proto.scheduler.api.v1.OutputInfo.ConfigCase = {
  CONFIG_NOT_SET: 0,
  GCS: 20,
  AZURE: 21,
  S3: 22
};

/**
 * @return {proto.scheduler.api.v1.OutputInfo.ConfigCase}
 */
proto.scheduler.api.v1.OutputInfo.prototype.getConfigCase = function() {
  return /** @type {proto.scheduler.api.v1.OutputInfo.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.scheduler.api.v1.OutputInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.OutputInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.OutputInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.OutputInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.OutputInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gcs: (f = msg.getGcs()) && cloud_models_pb.GoogleStorageConfig.toObject(includeInstance, f),
    azure: (f = msg.getAzure()) && cloud_models_pb.AzureStorageConfig.toObject(includeInstance, f),
    s3: (f = msg.getS3()) && cloud_models_pb.S3StorageConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.OutputInfo}
 */
proto.scheduler.api.v1.OutputInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.OutputInfo;
  return proto.scheduler.api.v1.OutputInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.OutputInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.OutputInfo}
 */
proto.scheduler.api.v1.OutputInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 20:
      var value = new cloud_models_pb.GoogleStorageConfig;
      reader.readMessage(value,cloud_models_pb.GoogleStorageConfig.deserializeBinaryFromReader);
      msg.setGcs(value);
      break;
    case 21:
      var value = new cloud_models_pb.AzureStorageConfig;
      reader.readMessage(value,cloud_models_pb.AzureStorageConfig.deserializeBinaryFromReader);
      msg.setAzure(value);
      break;
    case 22:
      var value = new cloud_models_pb.S3StorageConfig;
      reader.readMessage(value,cloud_models_pb.S3StorageConfig.deserializeBinaryFromReader);
      msg.setS3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.OutputInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.OutputInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.OutputInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.OutputInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGcs();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      cloud_models_pb.GoogleStorageConfig.serializeBinaryToWriter
    );
  }
  f = message.getAzure();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      cloud_models_pb.AzureStorageConfig.serializeBinaryToWriter
    );
  }
  f = message.getS3();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      cloud_models_pb.S3StorageConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.scheduler.api.v1.OutputInfo.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.OutputInfo} returns this
 */
proto.scheduler.api.v1.OutputInfo.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional cloud.api.v1.GoogleStorageConfig gcs = 20;
 * @return {?proto.cloud.api.v1.GoogleStorageConfig}
 */
proto.scheduler.api.v1.OutputInfo.prototype.getGcs = function() {
  return /** @type{?proto.cloud.api.v1.GoogleStorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.GoogleStorageConfig, 20));
};


/**
 * @param {?proto.cloud.api.v1.GoogleStorageConfig|undefined} value
 * @return {!proto.scheduler.api.v1.OutputInfo} returns this
*/
proto.scheduler.api.v1.OutputInfo.prototype.setGcs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.scheduler.api.v1.OutputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.OutputInfo} returns this
 */
proto.scheduler.api.v1.OutputInfo.prototype.clearGcs = function() {
  return this.setGcs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.OutputInfo.prototype.hasGcs = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional cloud.api.v1.AzureStorageConfig azure = 21;
 * @return {?proto.cloud.api.v1.AzureStorageConfig}
 */
proto.scheduler.api.v1.OutputInfo.prototype.getAzure = function() {
  return /** @type{?proto.cloud.api.v1.AzureStorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.AzureStorageConfig, 21));
};


/**
 * @param {?proto.cloud.api.v1.AzureStorageConfig|undefined} value
 * @return {!proto.scheduler.api.v1.OutputInfo} returns this
*/
proto.scheduler.api.v1.OutputInfo.prototype.setAzure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.scheduler.api.v1.OutputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.OutputInfo} returns this
 */
proto.scheduler.api.v1.OutputInfo.prototype.clearAzure = function() {
  return this.setAzure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.OutputInfo.prototype.hasAzure = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional cloud.api.v1.S3StorageConfig s3 = 22;
 * @return {?proto.cloud.api.v1.S3StorageConfig}
 */
proto.scheduler.api.v1.OutputInfo.prototype.getS3 = function() {
  return /** @type{?proto.cloud.api.v1.S3StorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.S3StorageConfig, 22));
};


/**
 * @param {?proto.cloud.api.v1.S3StorageConfig|undefined} value
 * @return {!proto.scheduler.api.v1.OutputInfo} returns this
*/
proto.scheduler.api.v1.OutputInfo.prototype.setS3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.scheduler.api.v1.OutputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.OutputInfo} returns this
 */
proto.scheduler.api.v1.OutputInfo.prototype.clearS3 = function() {
  return this.setS3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.OutputInfo.prototype.hasS3 = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.scheduler.api.v1.InputInfo.oneofGroups_ = [[20,21,22,23]];

/**
 * @enum {number}
 */
proto.scheduler.api.v1.InputInfo.ConfigCase = {
  CONFIG_NOT_SET: 0,
  GCS: 20,
  AZURE: 21,
  S3: 22,
  HTTP: 23
};

/**
 * @return {proto.scheduler.api.v1.InputInfo.ConfigCase}
 */
proto.scheduler.api.v1.InputInfo.prototype.getConfigCase = function() {
  return /** @type {proto.scheduler.api.v1.InputInfo.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.scheduler.api.v1.InputInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.InputInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.InputInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.InputInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.InputInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    videoStream: (f = msg.getVideoStream()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    audioStream: (f = msg.getAudioStream()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    gcs: (f = msg.getGcs()) && cloud_models_pb.GoogleStorageConfig.toObject(includeInstance, f),
    azure: (f = msg.getAzure()) && cloud_models_pb.AzureStorageConfig.toObject(includeInstance, f),
    s3: (f = msg.getS3()) && cloud_models_pb.S3StorageConfig.toObject(includeInstance, f),
    http: (f = msg.getHttp()) && cloud_models_pb.HttpStorageConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.InputInfo}
 */
proto.scheduler.api.v1.InputInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.InputInfo;
  return proto.scheduler.api.v1.InputInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.InputInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.InputInfo}
 */
proto.scheduler.api.v1.InputInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setVideoStream(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setAudioStream(value);
      break;
    case 20:
      var value = new cloud_models_pb.GoogleStorageConfig;
      reader.readMessage(value,cloud_models_pb.GoogleStorageConfig.deserializeBinaryFromReader);
      msg.setGcs(value);
      break;
    case 21:
      var value = new cloud_models_pb.AzureStorageConfig;
      reader.readMessage(value,cloud_models_pb.AzureStorageConfig.deserializeBinaryFromReader);
      msg.setAzure(value);
      break;
    case 22:
      var value = new cloud_models_pb.S3StorageConfig;
      reader.readMessage(value,cloud_models_pb.S3StorageConfig.deserializeBinaryFromReader);
      msg.setS3(value);
      break;
    case 23:
      var value = new cloud_models_pb.HttpStorageConfig;
      reader.readMessage(value,cloud_models_pb.HttpStorageConfig.deserializeBinaryFromReader);
      msg.setHttp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.InputInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.InputInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.InputInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.InputInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVideoStream();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getAudioStream();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getGcs();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      cloud_models_pb.GoogleStorageConfig.serializeBinaryToWriter
    );
  }
  f = message.getAzure();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      cloud_models_pb.AzureStorageConfig.serializeBinaryToWriter
    );
  }
  f = message.getS3();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      cloud_models_pb.S3StorageConfig.serializeBinaryToWriter
    );
  }
  f = message.getHttp();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      cloud_models_pb.HttpStorageConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.scheduler.api.v1.InputInfo.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
 */
proto.scheduler.api.v1.InputInfo.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int64Value video_stream = 2;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.scheduler.api.v1.InputInfo.prototype.getVideoStream = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
*/
proto.scheduler.api.v1.InputInfo.prototype.setVideoStream = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
 */
proto.scheduler.api.v1.InputInfo.prototype.clearVideoStream = function() {
  return this.setVideoStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.InputInfo.prototype.hasVideoStream = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int64Value audio_stream = 3;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.scheduler.api.v1.InputInfo.prototype.getAudioStream = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
*/
proto.scheduler.api.v1.InputInfo.prototype.setAudioStream = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
 */
proto.scheduler.api.v1.InputInfo.prototype.clearAudioStream = function() {
  return this.setAudioStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.InputInfo.prototype.hasAudioStream = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional cloud.api.v1.GoogleStorageConfig gcs = 20;
 * @return {?proto.cloud.api.v1.GoogleStorageConfig}
 */
proto.scheduler.api.v1.InputInfo.prototype.getGcs = function() {
  return /** @type{?proto.cloud.api.v1.GoogleStorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.GoogleStorageConfig, 20));
};


/**
 * @param {?proto.cloud.api.v1.GoogleStorageConfig|undefined} value
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
*/
proto.scheduler.api.v1.InputInfo.prototype.setGcs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.scheduler.api.v1.InputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
 */
proto.scheduler.api.v1.InputInfo.prototype.clearGcs = function() {
  return this.setGcs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.InputInfo.prototype.hasGcs = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional cloud.api.v1.AzureStorageConfig azure = 21;
 * @return {?proto.cloud.api.v1.AzureStorageConfig}
 */
proto.scheduler.api.v1.InputInfo.prototype.getAzure = function() {
  return /** @type{?proto.cloud.api.v1.AzureStorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.AzureStorageConfig, 21));
};


/**
 * @param {?proto.cloud.api.v1.AzureStorageConfig|undefined} value
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
*/
proto.scheduler.api.v1.InputInfo.prototype.setAzure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.scheduler.api.v1.InputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
 */
proto.scheduler.api.v1.InputInfo.prototype.clearAzure = function() {
  return this.setAzure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.InputInfo.prototype.hasAzure = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional cloud.api.v1.S3StorageConfig s3 = 22;
 * @return {?proto.cloud.api.v1.S3StorageConfig}
 */
proto.scheduler.api.v1.InputInfo.prototype.getS3 = function() {
  return /** @type{?proto.cloud.api.v1.S3StorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.S3StorageConfig, 22));
};


/**
 * @param {?proto.cloud.api.v1.S3StorageConfig|undefined} value
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
*/
proto.scheduler.api.v1.InputInfo.prototype.setS3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.scheduler.api.v1.InputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
 */
proto.scheduler.api.v1.InputInfo.prototype.clearS3 = function() {
  return this.setS3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.InputInfo.prototype.hasS3 = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional cloud.api.v1.HttpStorageConfig http = 23;
 * @return {?proto.cloud.api.v1.HttpStorageConfig}
 */
proto.scheduler.api.v1.InputInfo.prototype.getHttp = function() {
  return /** @type{?proto.cloud.api.v1.HttpStorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.HttpStorageConfig, 23));
};


/**
 * @param {?proto.cloud.api.v1.HttpStorageConfig|undefined} value
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
*/
proto.scheduler.api.v1.InputInfo.prototype.setHttp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.scheduler.api.v1.InputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scheduler.api.v1.InputInfo} returns this
 */
proto.scheduler.api.v1.InputInfo.prototype.clearHttp = function() {
  return this.setHttp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scheduler.api.v1.InputInfo.prototype.hasHttp = function() {
  return jspb.Message.getField(this, 23) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scheduler.api.v1.JobResultInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.JobResultInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.JobResultInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.JobResultInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobResultInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.scheduler.api.v1.ResultInfo.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 99, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.JobResultInfo}
 */
proto.scheduler.api.v1.JobResultInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.JobResultInfo;
  return proto.scheduler.api.v1.JobResultInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.JobResultInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.JobResultInfo}
 */
proto.scheduler.api.v1.JobResultInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var value = new proto.scheduler.api.v1.ResultInfo;
      reader.readMessage(value,proto.scheduler.api.v1.ResultInfo.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.JobResultInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.JobResultInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.JobResultInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobResultInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.scheduler.api.v1.ResultInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      99,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.scheduler.api.v1.JobResultInfo.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.JobResultInfo} returns this
 */
proto.scheduler.api.v1.JobResultInfo.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ResultInfo results = 2;
 * @return {!Array<!proto.scheduler.api.v1.ResultInfo>}
 */
proto.scheduler.api.v1.JobResultInfo.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.scheduler.api.v1.ResultInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scheduler.api.v1.ResultInfo, 2));
};


/**
 * @param {!Array<!proto.scheduler.api.v1.ResultInfo>} value
 * @return {!proto.scheduler.api.v1.JobResultInfo} returns this
*/
proto.scheduler.api.v1.JobResultInfo.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.scheduler.api.v1.ResultInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scheduler.api.v1.ResultInfo}
 */
proto.scheduler.api.v1.JobResultInfo.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.scheduler.api.v1.ResultInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scheduler.api.v1.JobResultInfo} returns this
 */
proto.scheduler.api.v1.JobResultInfo.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional int64 created_at = 99;
 * @return {number}
 */
proto.scheduler.api.v1.JobResultInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobResultInfo} returns this
 */
proto.scheduler.api.v1.JobResultInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};


/**
 * optional int64 updated_at = 100;
 * @return {number}
 */
proto.scheduler.api.v1.JobResultInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.scheduler.api.v1.JobResultInfo} returns this
 */
proto.scheduler.api.v1.JobResultInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scheduler.api.v1.ResultInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.ResultInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.ResultInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.ResultInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.ResultInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    output: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outputPathsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.ResultInfo}
 */
proto.scheduler.api.v1.ResultInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.ResultInfo;
  return proto.scheduler.api.v1.ResultInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.ResultInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.ResultInfo}
 */
proto.scheduler.api.v1.ResultInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutput(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutputPaths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.ResultInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.ResultInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.ResultInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.ResultInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutput();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutputPathsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string output = 1;
 * @return {string}
 */
proto.scheduler.api.v1.ResultInfo.prototype.getOutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scheduler.api.v1.ResultInfo} returns this
 */
proto.scheduler.api.v1.ResultInfo.prototype.setOutput = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string output_paths = 2;
 * @return {!Array<string>}
 */
proto.scheduler.api.v1.ResultInfo.prototype.getOutputPathsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.scheduler.api.v1.ResultInfo} returns this
 */
proto.scheduler.api.v1.ResultInfo.prototype.setOutputPathsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.scheduler.api.v1.ResultInfo} returns this
 */
proto.scheduler.api.v1.ResultInfo.prototype.addOutputPaths = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scheduler.api.v1.ResultInfo} returns this
 */
proto.scheduler.api.v1.ResultInfo.prototype.clearOutputPathsList = function() {
  return this.setOutputPathsList([]);
};


goog.object.extend(exports, proto.scheduler.api.v1);
