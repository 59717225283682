import React, {FC, useCallback, useEffect, useState} from 'react';
import {SelectSourceS, SelectSourceS_Buttons, SelectSourceS_Dropzone} from './SelectSourceS';
import {Button, Col, Empty, Result, Row, Space, Table, Typography} from 'antd';
import {FileInput} from '../../../../../components/FileInput/FileInput';
import {AudioOutlined, CloudUploadOutlined, FileOutlined, GlobalOutlined, UploadOutlined, VideoCameraOutlined} from '@ant-design/icons';
import {useAppDispatch, useAppSelector} from '../../../../../stores/hooks';
import {FileEntry, filesActions, selectFile, selectFiles} from '../../../../../stores/slices/files';
import {handleFiles, onFileSelect, setupFilesManagement} from '../../../../files/utils/files';
import {ListSelect, ListSelectEntry} from '../../../../../components/ListSelect/ListSelect';
import {store} from '../../../../../stores/store';
import Dropzone from 'react-dropzone';
import {FromUrlModal} from './FromUrlModal/FromUrlModal';
import {InputInfo as InputInfoProber, StreamInfo} from '../../../../../autogen/gRPC/prober/models_pb';
import {InputInfo} from '../../../../../autogen/gRPC/scheduler/models_pb';
import {callProbeFile} from '../../../../files/api/requests';
import {GoogleStorageConfig} from '../../../../../autogen/gRPC/cloud/models_pb';
import {FromCloudModal} from './FromCloudModal/FromCloudModal';
import {TableRowSelection} from 'antd/lib/table/interface';
import {ListSelectS_Container} from '../../../../../components/ListSelect/ListSelectS';
import {ApiSample} from '../../../../../components/ApiSample/ApiSample';
import {ListFilesInfo} from '../../../../../components/ApiSample/infos';

export interface Streams {
	audio?: number;
	video?: number;
}

export interface SelectSourceProps {
	selectedSource?: FileEntry;
	setSelectedSource: (arg: FileEntry) => void;
	setInputInfo: (arg: InputInfo) => void;
	setSelectedVideoIdx: (arg: number) => void;
	setSelectedAudioIdx: (arg: number) => void;
}

export const SelectSource: FC<SelectSourceProps> = (props) => {
	const {selectedSource, setSelectedSource, setInputInfo, setSelectedVideoIdx, setSelectedAudioIdx} = props;

	const files = useAppSelector(selectFiles);
	const dispatch = useAppDispatch();
	const [dragging, setDragging] = useState(false);

	const [selectedFileRow, setSelectedFileRow] = useState<ListSelectEntry | undefined>(undefined);

	const [selectedAudioStreamRowKeys, setSelectedAudioStreamRowKeys] = useState<React.Key[]>([]);
	const [selectedVideoStreamRowKeys, setSelectedVideoStreamRowKeys] = useState<React.Key[]>([]);

	const [videoStreams, setVideoStreams] = useState<StreamInfo.AsObject[]>([]);
	const [audioStreams, setAudioStreams] = useState<StreamInfo.AsObject[]>([]);

	const [httpModalVisible, setHttModalVisible] = useState(false);
	const [cloudModalVisible, setCloudModalVisible] = useState(false);

	useEffect(() => {
		(async function () {
			await setupFilesManagement();
		})();
	}, []);

	useEffect(() => {
		if (selectedAudioStreamRowKeys.length) {
			setSelectedAudioIdx(parseInt(selectedAudioStreamRowKeys[0].toString()));
		}
	}, [selectedAudioStreamRowKeys]);

	useEffect(() => {
		if (selectedVideoStreamRowKeys.length) {
			setSelectedVideoIdx(parseInt(selectedVideoStreamRowKeys[0].toString()));
		}
	}, [selectedVideoStreamRowKeys]);

	useEffect(() => {
		if (audioStreams.length) {
			setSelectedAudioStreamRowKeys([audioStreams[0].index.toString()]);
		}
	}, [audioStreams]);

	useEffect(() => {
		if (videoStreams.length) {
			setSelectedVideoStreamRowKeys([videoStreams[0].index.toString()]);
		}
	}, [videoStreams]);

	useEffect(() => {
		const currentFile = selectedFile();

		(async function () {
			if (currentFile && !currentFile.details) {
				const infoProber = new InputInfoProber();
				infoProber.setPath(currentFile.name);
				infoProber.setGcs(new GoogleStorageConfig());
				const result = await callProbeFile(infoProber);
				const file = {...currentFile, details: result.fileInfo};
				result.fileInfo && setSelectedSource(file);
				dispatch(filesActions.setFile(file));
			} else if (currentFile) {
				currentFile.details && setSelectedSource(currentFile);
			}
		})();

	}, [selectedFileRow]);

	useEffect(() => {
		if (selectedSource) {
			const videoStreams: StreamInfo.AsObject[] = [];
			const audioStreams: StreamInfo.AsObject[] = [];
			selectedSource.details?.streamsList.forEach((stream) => {
				if (stream.codecType === 'video') {
					videoStreams.push(stream);
				} else if (stream.codecType === 'audio') {
					audioStreams.push(stream);
				}
			});
			setVideoStreams(videoStreams);
			setAudioStreams(audioStreams);
		}
	}, [selectedSource]);

	const selectedFile = () => {
		let result: FileEntry | undefined;
		if (selectedFileRow) {
			result = selectFile(store.getState(), selectedFileRow?.key);
		}
		return result;
	};

	const prepareData = () => {
		return files.map((file) => {
			const result: ListSelectEntry = {
				key: file.name,
				name: <><FileOutlined style={{marginRight: '10px'}}/>{file.name}</>
			};
			return result;
		});
	};

	const onDragEnter = () => {
		setDragging(true);
	};

	const onDragLeave = () => {
		setDragging(false);
	};

	const onDrop = useCallback(acceptedFiles => {
		handleFiles(acceptedFiles);
		setDragging(false);
	}, []);

	const audioStreamRowSelection: TableRowSelection<ListSelectEntry> = {
		onChange: (selectedRowKeys, _) => {
			setSelectedAudioStreamRowKeys(selectedRowKeys);
		}
	};

	const videoStreamRowSelection: TableRowSelection<ListSelectEntry> = {
		onChange: (selectedRowKeys, _) => {
			setSelectedVideoStreamRowKeys(selectedRowKeys);
		}
	};

	const prepareColumns = (title: string) => {
		return [
			{
				title: <Typography.Text style={{fontWeight: 600}}>{title}</Typography.Text>,
				dataIndex: 'name',
				render: (text: string) => <Typography.Text style={{cursor: 'pointer'}}>{text}</Typography.Text>
			}
		];
	};

	const prepareStreams = (streams: StreamInfo.AsObject[]) => {
		return streams.map((stream) => {
			const result: ListSelectEntry = {
				key: stream.index.toString(),
				name: <div>
					<Row align="middle">
						{stream.codecType === 'video' ?
							<VideoCameraOutlined style={{fontSize: '70px'}}/>
							:
							<AudioOutlined style={{fontSize: '70px'}}/>
						}
						<Col style={{marginLeft: '20px'}}>
							<Row style={{width: '260px'}}>
								<Typography.Text style={{width: '130px'}}>Index:</Typography.Text>
								<Typography.Text style={{width: '130px'}}>{stream.index}</Typography.Text>
							</Row>
							<Row style={{width: '260px'}}>
								<Typography.Text style={{width: '130px'}}>Codec:</Typography.Text>
								<Typography.Text style={{width: '130px'}}>{stream.codecName}</Typography.Text>
							</Row>
							<Row style={{width: '260px'}}>
								<Typography.Text style={{width: '130px'}}>Bitrate:</Typography.Text>
								<Typography.Text style={{width: '130px'}}>
									{stream.bitRate ? `${(stream.bitRate / 1000).toFixed(2)} kbps` : 'no data'}
								</Typography.Text>
							</Row>
							{stream.codecType === 'video' ?
								<>
									<Row style={{minWidth: '260px'}}>
										<Typography.Text style={{minWidth: '130px'}}>Width:</Typography.Text>
										<Typography.Text style={{minWidth: '130px'}}>{stream.width}</Typography.Text>
									</Row>
									<Row style={{minWidth: '260px'}}>
										<Typography.Text style={{minWidth: '130px'}}>Height:</Typography.Text>
										<Typography.Text style={{minWidth: '130px'}}>{stream.height}</Typography.Text>
									</Row>
									<Row style={{minWidth: '260px'}}>
										<Typography.Text style={{minWidth: '130px'}}>Frame Rate:</Typography.Text>
										<Typography.Text style={{minWidth: '130px'}}>{stream.rFrameRate}</Typography.Text>
									</Row>
								</>
								:
								<>
									<Row style={{minWidth: '260px'}}>
										<Typography.Text style={{minWidth: '130px'}}>Channels:</Typography.Text>
										<Typography.Text style={{minWidth: '130px'}}>{stream.channels}</Typography.Text>
									</Row>
									<Row style={{minWidth: '260px'}}>
										<Typography.Text style={{minWidth: '130px'}}>Sample Rate:</Typography.Text>
										<Typography.Text style={{minWidth: '130px'}}>{stream.sampleRate}</Typography.Text>
									</Row>
									<Row style={{minWidth: '260px'}}>
										<Typography.Text style={{minWidth: '130px'}}>Sample Format:</Typography.Text>
										<Typography.Text style={{minWidth: '130px'}}>{stream.sampleFmt}</Typography.Text>
									</Row>
								</>
							}
						</Col>
					</Row>
				</div>
			};

			return result;
		});
	};

	const prepareSourceInfo = () => {
		let fileName = '';

		if (selectedSource) {
			fileName = selectedSource?.name;
			const parts = fileName?.split('/');
			if (parts && parts.length) {
				fileName = parts[parts.length - 1];
			}
		}

		return (
			<>
				{selectedSource ?
					<Col style={{padding: '15px 50px'}}>
						<Row style={{marginBottom: '20px'}}>
							<Typography.Text>Your file has been selected properly. You can go to the next step or select different file.</Typography.Text>
						</Row>
						<Row>
							<FileOutlined style={{fontSize: '82px', marginTop: '12px'}}/>
							<div style={{marginLeft: '10px'}}>
								<Row>
									<Typography.Text>Selected File:</Typography.Text>
								</Row>
								<Row style={{minWidth: '260px'}}>
									<Typography.Text style={{minWidth: '130px'}}>Name:</Typography.Text>
									<Typography.Text style={{minWidth: '130px'}}>{fileName}</Typography.Text>
								</Row>
								<Row style={{minWidth: '260px'}}>
									<Typography.Text style={{minWidth: '130px'}}>Type:</Typography.Text>
									<Typography.Text style={{minWidth: '130px'}}>{selectedSource.details?.format?.formatName}</Typography.Text>
								</Row>
								<Row style={{minWidth: '260px'}}>
									<Typography.Text style={{minWidth: '130px'}}>Size:</Typography.Text>
									<Typography.Text style={{minWidth: '130px'}}>
										{selectedSource.details?.format?.size && (selectedSource.details.format?.size / 1000000).toFixed(2)} MB
									</Typography.Text>
								</Row>
								<Row style={{minWidth: '260px'}}>
									<Typography.Text style={{minWidth: '130px'}}>Streams:</Typography.Text>
									<Typography.Text style={{minWidth: '130px'}}>{selectedSource.details?.format?.nbStreams}</Typography.Text>
								</Row>
							</div>
						</Row>
					</Col>
					:
					<Col>
						<Row style={{padding: '15px 50px'}}>
							<Typography.Text>Please select file from the table at the left or upload it from cloud or url.</Typography.Text>
						</Row>
						<Row justify="center" align="middle" style={{height: '60%'}}>
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
						</Row>
					</Col>
				}
			</>
		);
	};

	const selected = videoStreams.length + audioStreams.length > 0;

	const leftTitle = (
		<Row justify='space-between' align='middle'>
				<Typography.Text>Uploaded Files</Typography.Text>
				<ApiSample info={ListFilesInfo}/>
		</Row>
	)

	return (
		<SelectSourceS selected={selected}>
			<Col>
				<Row justify="center">
					<Typography.Title>Step 1: select file source</Typography.Title>
				</Row>
				<Space direction="vertical" size={30}>
					<Typography.Text>
						At the beginning, you need to specify source file to transcode. You can upload new file, select one of
						previously uploaded or use file from cloud (S3 / Azure/ GCP). You can also provide a url to the file.
						To choose uploaded file(s) please select it on the list below and click arrow to move them to selected files.
					</Typography.Text>
					<SelectSourceS_Buttons>
						<FileInput onChange={onFileSelect}>Upload File</FileInput>
						<Button icon={<CloudUploadOutlined/>} type="primary" onClick={() => setCloudModalVisible(true)}>From Cloud</Button>
						<Button icon={<GlobalOutlined/>} type="primary" onClick={() => setHttModalVisible(true)}>From URL</Button>
					</SelectSourceS_Buttons>
					<Dropzone onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
						{({getRootProps}) => (
							<div {...getRootProps({
								onClick: event => event.stopPropagation()
							})}>
								{
									dragging ?
										<SelectSourceS_Dropzone>
											<Result
												icon={<UploadOutlined/>}
												title="Drop files here to upload them"
											/>
										</SelectSourceS_Dropzone>
										:
										<>
											<ListSelect leftTitle={leftTitle} rightTitle="Selected File" selectedContent={prepareSourceInfo()}
											            entries={prepareData()} setSelectedRow={setSelectedFileRow}
											/>
											{
												selected &&
												<ListSelectS_Container style={{margin: '40px 0'}}>
													<Table
														rowSelection={{
															type: 'radio',
															selectedRowKeys: selectedAudioStreamRowKeys,
															...audioStreamRowSelection
														}}
														columns={prepareColumns('Audio Streams')}
														pagination={false}
														dataSource={prepareStreams(audioStreams)}
														onRow={(r) => ({
															onClick: () => {
																setSelectedAudioStreamRowKeys([r.key]);
															}
														})}
													/>
													<Table
														rowSelection={{
															type: 'radio',
															selectedRowKeys: selectedVideoStreamRowKeys,
															...videoStreamRowSelection
														}}
														columns={prepareColumns('Video Streams')}
														pagination={false}
														dataSource={prepareStreams(videoStreams)}
														onRow={(r) => ({
															onClick: () => {
																setSelectedVideoStreamRowKeys([r.key]);
															}
														})}
													/>
												</ListSelectS_Container>
											}
										</>
								}
							</div>
						)}
					</Dropzone>
				</Space>
			</Col>
			<FromUrlModal visible={httpModalVisible} setVisible={setHttModalVisible}
			              setFileInfo={setSelectedSource} setInputInfo={setInputInfo}/>
			<FromCloudModal visible={cloudModalVisible} setVisible={setCloudModalVisible}
			                setFileInfo={setSelectedSource} setInputInfo={setInputInfo}/>
		</SelectSourceS>
	);
};
