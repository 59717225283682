import {ChannelLayoutName} from './channelLayoutConverter';
import {SampleFormatName} from './sampleFormatConverter';
import {PixelFormatName} from './pixelFormatConverter';
import {PassModeName} from './passModeConverter';
import {ColorSpaceName} from './colorSpaceConverter';

export interface Blueprint {
	fileOutputs: Map<string, FileOutput>;
	audioOutputs: Map<string, AudioOutput>;
	videoOutputs: Map<string, VideoOutput>;
}

export interface Property<T> {
	value: T;
	details: PropertyDetails;
}

export interface PropertyDetails {
	required?: boolean;
	editable?: boolean;
	hidden?: boolean;
}

export interface VideoAudioMapper {
	idx: number;
	video: string;
	audio: string;
}

export interface FileOutput {
	formatName: string;
	videoAudioMap: VideoAudioMapper[];
	privateData: Map<string, string>;
}

export const newFileOutput = () => {
	const result: FileOutput = {
		formatName: '',
		videoAudioMap: [],
		privateData: new Map<string, string>()
	};
	return result;
};

export interface AudioOutput {
	encoderName: string;
	bitrate: Property<number>;
	channelLayout: Property<ChannelLayoutName>;
	sampleFormat: Property<SampleFormatName>;
	channels: Property<number>;
	sampleRate: Property<number>;
	timeBase: Property<Rational>;
	privateData: Map<string, Property<string>>;
}

export const newAudioOutput = () => {
	const result: AudioOutput = {
		encoderName: '',
		bitrate: {
			value: 0,
			details: {}
		},
		channelLayout: {
			value: 'UNKNOWN',
			details: {}
		},
		sampleFormat: {
			value: 'UNKNOWN',
			details: {}
		},
		channels: {
			value: 0,
			details: {}
		},
		sampleRate: {
			value: 0,
			details: {}
		},
		timeBase: {
			value: {
				nom: 0,
				den: 0
			},
			details: {}
		},
		privateData: new Map<string, Property<string>>()
	};
	return result;
};

export interface VideoOutput {
	encoderName: string;
	maxGop: Property<number>;
	width: Property<number>;
	height: Property<number>;
	bitrate: Property<number>;
	refs: Property<number>;
	pixelFormat: Property<PixelFormatName>;
	frameRate: Property<Rational>;
	timeBase: Property<Rational>;
	filter: Property<string>;
	level: Property<number>;
	colorSpace: Property<ColorSpaceName>;
	passMode: Property<PassModeName>;
	rcMaxBitrate: Property<number>;
	rcMinBitrate: Property<number>;
	resources: Property<Resources>;
	bufSize: Property<number>;
	maxBFrames: Property<number>;
	keyIntMin: Property<number>;
	privateData: Map<string, Property<string>>;
}

export const newVideoOutput = () => {
	const result: VideoOutput = {
		encoderName: '',
		maxGop: {
			value: 0,
			details: {}
		},
		width: {
			value: 0,
			details: {}
		},
		height: {
			value: 0,
			details: {}
		},
		bitrate: {
			value: 0,
			details: {}
		},
		refs: {
			value: 0,
			details: {}
		},
		pixelFormat: {
			value: 'UNKNOWN',
			details: {}
		},
		frameRate: {
			value: {
				nom: 0,
				den: 0
			},
			details: {}
		},
		timeBase: {
			value: {
				nom: 0,
				den: 0
			},
			details: {}
		},
		filter: {
			value: '',
			details: {}
		},
		level: {
			value: 0,
			details: {}
		},
		colorSpace: {
			value: 'UNKNOWN',
			details: {}
		},
		passMode: {
			value: 'UNKNOWN',
			details: {}
		},
		rcMaxBitrate: {
			value: 0,
			details: {}
		},
		rcMinBitrate: {
			value: 0,
			details: {}
		},
		resources: {
			value: {
				cpu: 0,
				gpu: 0
			},
			details: {}
		},
		bufSize: {
			value: 0,
			details: {}
		},
		maxBFrames: {
			value: 0,
			details: {}
		},
		keyIntMin: {
			value: 0,
			details: {}
		},
		privateData: new Map<string, Property<string>>()
	};
	return result;
};

export interface Rational {
	nom: number;
	den: number;
}

export interface Resources {
	cpu: number;
	gpu: number;
}
