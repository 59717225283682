import {AnyAction, combineReducers, configureStore} from '@reduxjs/toolkit';
import {createEpicMiddleware, Epic} from 'redux-observable';
import {enableBatching} from 'redux-batched-actions';
import {userSlice} from './slices/user';
import {uploadsSlice} from './slices/uploads';
import {filesSlice} from './slices/files';
import {basePresetsSlice} from './slices/basePresets';
import {customPresetsSlice} from './slices/customPresets';
import {jobsSlice} from './slices/jobs';

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();

export const reducer = enableBatching(combineReducers({
	user: userSlice.reducer,
	uploads: uploadsSlice.reducer,
	basePresets: basePresetsSlice.reducer,
	customPresets: customPresetsSlice.reducer,
	files: filesSlice.reducer,
	jobs: jobsSlice.reducer
}));

export const store = configureStore({
	reducer,
	middleware:
		(getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware)
});

// epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducer>;
export type AppEpic = Epic<AnyAction, AnyAction, RootState>;
