const STORAGE_KEY_UPLOAD = 'transcoderMultipartUpload';

interface ChunkInfo {
	etag: string;
	checksum: string;
}

interface Meta {
	chunks: ChunkInfo[],
	chunkSize: number,
	started: boolean,
	fileSize: number
	uploadId?: string
}

export default class FileMeta {
	private readonly id: string;
	private readonly fileSize: number;
	private readonly chunkSize: number;
	private readonly uploadId?: string;
	private storage: Storage;

	constructor(id: string, fileSize: number, chunkSize: number, storage: Storage, uploadId: string) {
		this.id = id;
		this.fileSize = fileSize;
		this.chunkSize = chunkSize;
		this.storage = storage;
		this.uploadId = uploadId;
	}

	getMeta() {
		const meta = this.storage.getItem(`${STORAGE_KEY_UPLOAD}.${this.id}`);
		if (meta) {
			return JSON.parse(meta) as Meta;
		} else {
			const meta: Meta = {
				chunks: [],
				chunkSize: this.chunkSize,
				started: false,
				fileSize: this.fileSize,
				uploadId: this.uploadId
			};
			return meta;
		}
	}

	setMeta(meta: Meta | null) {
		const key = `${STORAGE_KEY_UPLOAD}.${this.id}`;
		if (meta) {
			this.storage.setItem(key, JSON.stringify(meta));
		} else {
			this.storage.removeItem(key);
		}
	}

	isResumable() {
		let meta = this.getMeta();
		return meta.started && this.chunkSize === meta.chunkSize;
	}

	getResumeIndex() {
		return this.getMeta().chunks.length;
	}

	getFileSize() {
		return this.getMeta().fileSize;
	}

	addChunkInfo(index: number, checksum: string, etag: string) {
		let meta = this.getMeta();
		meta.chunks[index] = {checksum, etag};
		meta.started = true;
		this.setMeta(meta);
	}

	getChunkInfos() {
		return this.getMeta().chunks;
	}

	getChecksum(index: number) {
		return this.getMeta().chunks[index];
	}

	reset() {
		const key = `${STORAGE_KEY_UPLOAD}.${this.id}`;
		this.storage.removeItem(key);
		this.setMeta(null);
	}
}
