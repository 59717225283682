import React, {FC, useState} from 'react';
import {Button, Checkbox, Form, Select, Typography} from 'antd';
import {ArrowRightOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {OutputFormat} from '../../../settings/components/CreatePreset/CreatePreset';
import {VideoAudioMappingS, VideoAudioMappingS_LongRow} from './VideoAudioMappingS';

const {Option} = Select;

export interface VideoAudioMappingProps {
	idx: number,
	formatName: string,
	audioOutputs: string[],
	videoOutputs: string[],
	checkMode?: boolean,
	initialIndexes?: number[],
}

export const VideoAudioMapping: FC<VideoAudioMappingProps> = ({
	idx,
	formatName,
	audioOutputs,
	videoOutputs,
	checkMode,
	initialIndexes
}) => {
	const [videoAudioMapIndexes, setVideoAudioMapIndexes] = useState(initialIndexes ? initialIndexes : [0]);

	const hls = (
		<div>
			{videoAudioMapIndexes.map((index) => (
				<VideoAudioMappingS_LongRow>
					{
						checkMode &&
						<Form.Item style={{margin: 0}}
						           name={`selectedOutput|${idx}|${index}`}
						>
							<Checkbox/>
						</Form.Item>
					}
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Video Audio Mapping</Typography.Text>}
					           name={`audioOutput|${idx}|${index}`}
					           tooltip="Set at least one video audio mapping"

					>
						<Select placeholder="select audio output">
							{audioOutputs.map(audioOutput => (
									<Option key={audioOutput}>{audioOutput}</Option>
								)
							)}
						</Select>
					</Form.Item>
					<ArrowRightOutlined/>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text></Typography.Text>}
					           name={`videoOutput|${idx}|${index}`}

					>
						<Select placeholder="select video output">
							{videoOutputs.map(videoOutput => (
								<Option key={videoOutput}>{videoOutput}</Option>
							))}
						</Select>
					</Form.Item>
					{index === 0 &&
						<Button icon={<PlusCircleOutlined/>} shape="circle" type="text" onClick={() => {
							setVideoAudioMapIndexes([...videoAudioMapIndexes, videoAudioMapIndexes.length]);
						}}/>
					}
				</VideoAudioMappingS_LongRow>
			))}
		</div>
	);

	const dash = (
		<div>
			{videoAudioMapIndexes.map((index) => (
				<VideoAudioMappingS_LongRow style={{gap: 30, marginLeft: 40}}>
					{
						checkMode &&
						<Form.Item style={{margin: 0}}
						           name={`selectedOutput|${idx}|${index}`}
						>
							<Checkbox/>
						</Form.Item>
					}
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Audio Outputs</Typography.Text>}
					           name={`audioOutput|${idx}|${index}`}
					           tooltip="Set at least one video audio mapping"

					>
						<Select placeholder="select audio output">
							{audioOutputs.map(audioOutput => (
									<Option key={audioOutput}>{audioOutput}</Option>
								)
							)}
						</Select>
					</Form.Item>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Video Outputs</Typography.Text>}
					           name={`videoOutput|${idx}|${index}`}

					>
						<Select placeholder="select video output">
							{videoOutputs.map(videoOutput => (
								<Option key={videoOutput}>{videoOutput}</Option>
							))}
						</Select>
					</Form.Item>
					{index === 0 &&
						<div style={{marginLeft: '-30px'}}>
							<Button icon={<PlusCircleOutlined/>} shape="circle" type="text" onClick={() => {
								setVideoAudioMapIndexes([...videoAudioMapIndexes, videoAudioMapIndexes.length]);
							}}/>
						</div>
					}
				</VideoAudioMappingS_LongRow>
			))}
		</div>
	);

	if (formatName === OutputFormat.HLS.toLowerCase()) {
		return hls;
	} else if (formatName === OutputFormat.DASH.toLowerCase()) {
		return dash;
	} else {
		return (
			<VideoAudioMappingS>
				<VideoAudioMappingS_LongRow style={{gap: 30, marginLeft: 0}}>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Audio Output</Typography.Text>}
					           name={`audioOutput|${idx}|${0}`}
					           tooltip="select audio output"

					>
						<Select placeholder="select audio output">
							{audioOutputs.map(audioOutput => (
									<Option key={audioOutput}>{audioOutput}</Option>
								)
							)}
						</Select>
					</Form.Item>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Video Output</Typography.Text>}
					           name={`videoOutput|${idx}|${0}`}
					           tooltip="select video output"

					>
						<Select placeholder="select video output">
							{videoOutputs.map(videoOutput => (
								<Option key={videoOutput}>{videoOutput}</Option>
							))}
						</Select>
					</Form.Item>
				</VideoAudioMappingS_LongRow>
			</VideoAudioMappingS>
		);
	}
};
