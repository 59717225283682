import styled from 'styled-components';

export const FileInputS = styled.div`
	
	input[type="file"] {
		display: none;
	}

	button {
		width: 100%;
		cursor: pointer;
	}

	svg {
		color: white;
	}
`;
