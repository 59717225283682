import SparkMD5 from 'spark-md5';

export class FileProcessor {
	private file: File;
	private chunkSize: number;

	constructor(file: File, chunkSize: number) {
		this.file = file;
		this.chunkSize = chunkSize;
	}

	async run(fn: (checksum: string, index: number, chunk: ArrayBuffer) => any, startIndex?: number, endIndex?: number) {

		if (!startIndex) {
			startIndex = 0;
		}

		const {file, chunkSize} = this;
		const totalChunks = Math.ceil(file.size / chunkSize);

		console.log('Starting run on file:');
		console.log(` - Total chunks: ${totalChunks}`);
		console.log(` - Start index: ${startIndex}`);
		console.log(` - End index: ${endIndex || totalChunks}`);

		if (startIndex === totalChunks || startIndex === endIndex) {
			console.log('File process complete');
			return;
		}

		const start = startIndex * chunkSize;
		const section = file.slice(start, start + chunkSize);
		const chunk = await FileProcessor.getData(section);
		const checksum = FileProcessor.getChecksum(chunk);

		fn(checksum, startIndex, chunk);
		await this.run(fn, startIndex + 1, endIndex);
	}

	/// private methods

	private static async getData(blob: Blob) {
		return new Promise<ArrayBuffer>((resolve, reject) => {
			let reader = new window.FileReader();
			reader.onload = () => resolve(reader.result as ArrayBuffer);
			reader.onerror = reject;
			reader.readAsArrayBuffer(blob);
		});
	}

	private static getChecksum(chunk: ArrayBuffer) {
		let spark = new SparkMD5.ArrayBuffer();
		spark.append(chunk);
		const checksum = btoa(spark.end(true));
		btoa(spark.end(true));
		return checksum;
	}
}
