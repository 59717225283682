import styled from 'styled-components';
import {LIGHT_3} from '../../../../utils/colors';

export const ApiS = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const ApiS_Main = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1000px) {
		margin-top: 10vh;
		flex-direction: column;
	}
`;

export const ApiS_LeftPart = styled.div`
	color: ${LIGHT_3};
	width: 34vw;
	padding-right: 2vw;

	h1 {
		color: inherit;
		font-weight: 700;
		font-size: 42px;
	}

	h2 {
		margin-top: 15px;
		color: inherit;
		font-weight: 600;
		font-size: 26px;
	}
	
	.ant-input {
		border-radius: 4px 0 0 4px;
	}
	
	.ant-btn {
		height: 40px;
		width: 40px;
		border-radius: 0 4px 4px 0;
	}

	.ant-btn:not(.ant-btn-circle) {
		min-width: unset;
	}

	@media (max-width: 1000px) {
		width: 70vw;
	}
`;

export const ApiS_RightPart = styled.div<{ image: string }>`
	height: 26vw;
	width: 36vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-image: ${({image}) => `url(${image})`};
	background-repeat: no-repeat;

	@media (max-width: 1000px) {
		margin-top: 10px;
		background-position: center;
		width: 70vw;
		height: 70vw;
	}
`;
