import {
	AudioOutput,
	Blueprint,
	ChannelLayoutProperty,
	ColorSpaceProperty,
	FileOutput,
	IntProperty,
	PassModeProperty,
	PixelFormatProperty,
	PropertyDetails,
	RationalProperty,
	ResourcesProperty,
	SampleFormatProperty,
	StringProperty,
	VideoOutput
} from '../../../autogen/gRPC/arranger/presets/models_pb';
import {
	Blueprint as FormBlueprint,
	Property,
	PropertyDetails as FormPropertyDetails,
	Rational as FormRational,
	Resources as FormResources
} from './blueprint';
import {Rational, Resources, VideoAudioStreamMapping} from '../../../autogen/gRPC/scheduler/models_pb';
import {ChannelLayoutName, channelLayoutStringToEnum} from './channelLayoutConverter';
import {SampleFormatName, sampleFormatStringToEnum} from './sampleFormatConverter';
import {PixelFormatName, pixelFormatStringToEnum} from './pixelFormatConverter';
import {ColorSpaceName, colorSpaceStringToEnum} from './colorSpaceConverter';
import {PassModeName, passModeStringToEnum} from './passModeConverter';

export const convertBlueprintFormValuesToProto = (formBlueprint: FormBlueprint) => {

	const blueprint = new Blueprint();

	/// file outputs
	const fileOutputs = blueprint.getFileOutputsMap();

	formBlueprint.fileOutputs.forEach((fileOutput, name) => {
		const fOutput = new FileOutput();
		fOutput.setFormatName(fileOutput.formatName);

		const mapping = fileOutput.videoAudioMap.map((entry) => {
			const videoAudio = new VideoAudioStreamMapping();
			videoAudio.setAudio(entry.audio);
			videoAudio.setVideo(entry.video);
			return videoAudio;
		});
		fOutput.setVideoAudioMapList(mapping);

		const privateData = fOutput.getPrivateDataMap();
		fileOutput.privateData.forEach((data, prop) => {
			privateData.set(prop, data);
		});

		fileOutputs.set(name, fOutput);
	});

	/// audio outputs
	const audioOutputs = blueprint.getAudioOutputsMap();

	formBlueprint.audioOutputs.forEach((audioOutput, name) => {
		const aOutput = new AudioOutput();
		aOutput.setEncoderName(audioOutput.encoderName);
		aOutput.setBitRate(convertToIntProperty(audioOutput.bitrate));
		aOutput.setChannelLayout(convertToChannelLayoutProperty(audioOutput.channelLayout));
		aOutput.setSampleFormat(convertToSampleFormatProperty(audioOutput.sampleFormat));
		audioOutput.channels && aOutput.setChannels(convertToIntProperty(audioOutput.channels));
		audioOutput.sampleRate && aOutput.setSampleRate(convertToIntProperty(audioOutput.sampleRate));
		audioOutput.timeBase && aOutput.setTimeBase(convertToRationalProperty(audioOutput.timeBase));

		const privateData = aOutput.getPrivateDataMap();
		audioOutput.privateData.forEach((data, prop) => {
			const details = new PropertyDetails();
			data.details.required && details.setRequired(data.details.required);
			data.details.editable && details.setEditable(data.details.editable);
			data.details.hidden && details.setHidden(data.details.hidden);
			privateData.set(prop, new StringProperty().setValue(data.value).setDetails(details));
		});

		audioOutputs.set(name, aOutput);
	});

	/// video outputs
	const videoOutputs = blueprint.getVideoOutputsMap();

	formBlueprint.videoOutputs.forEach((videoOutput, name) => {
		const vOutput = new VideoOutput();
		vOutput.setEncoderName(videoOutput.encoderName);
		vOutput.setMaxGop(convertToIntProperty(videoOutput.maxGop));
		vOutput.setWidth(convertToIntProperty(videoOutput.width));
		vOutput.setHeight(convertToIntProperty(videoOutput.height));
		vOutput.setBitRate(convertToIntProperty(videoOutput.bitrate));
		videoOutput.refs && vOutput.setRefs(convertToIntProperty(videoOutput.refs));
		vOutput.setPixelFormat(convertToPixelFormatProperty(videoOutput.pixelFormat));
		videoOutput.frameRate && vOutput.setFrameRate(convertToRationalProperty(videoOutput.frameRate));
		videoOutput.timeBase && vOutput.setTimeBase(convertToRationalProperty(videoOutput.timeBase));
		vOutput.setFilter(convertToStringProperty(videoOutput.filter));
		videoOutput.level && vOutput.setLevel(convertToIntProperty(videoOutput.level));
		videoOutput.colorSpace && vOutput.setColorSpace(convertToColorSpaceProperty(videoOutput.colorSpace));
		videoOutput.passMode && vOutput.setPassMode(convertToPassModeProperty(videoOutput.passMode));
		videoOutput.rcMaxBitrate && vOutput.setRcMaxBitrate(convertToIntProperty(videoOutput.rcMaxBitrate));
		videoOutput.rcMaxBitrate && vOutput.setRcMinBitrate(convertToIntProperty(videoOutput.rcMaxBitrate));
		videoOutput.resources && vOutput.setResources(convertToResourcesProperty(videoOutput.resources));
		videoOutput.bufSize && vOutput.setBufSize(convertToIntProperty(videoOutput.bufSize));
		videoOutput.maxBFrames && vOutput.setMaxBFrames(convertToIntProperty(videoOutput.maxBFrames));
		videoOutput.keyIntMin && vOutput.setKeyIntMin(convertToIntProperty(videoOutput.keyIntMin));

		const privateData = vOutput.getPrivateDataMap();
		videoOutput.privateData.forEach((data, prop) => {
			const details = new PropertyDetails();
			data.details.required && details.setRequired(data.details.required);
			data.details.editable && details.setEditable(data.details.editable);
			data.details.hidden && details.setHidden(data.details.hidden);
			privateData.set(prop, new StringProperty().setValue(data.value).setDetails(details));
		});

		videoOutputs.set(name, vOutput);
	});

	return blueprint;
};

export const convertToIntProperty = (prop: Property<number>) => {
	const result = new IntProperty();
	result.setValue(prop.value);
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertToStringProperty = (prop: Property<string>) => {
	const result = new StringProperty();
	result.setValue(prop.value);
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertToChannelLayoutProperty = (prop: Property<ChannelLayoutName>) => {
	const result = new ChannelLayoutProperty();
	prop.value && prop.value !== 'UNKNOWN' && result.setValue(channelLayoutStringToEnum(prop.value));
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertToSampleFormatProperty = (prop: Property<SampleFormatName>) => {
	const result = new SampleFormatProperty();
	prop.value && prop.value !== 'UNKNOWN' && result.setValue(sampleFormatStringToEnum(prop.value));
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertToPixelFormatProperty = (prop: Property<PixelFormatName>) => {
	const result = new PixelFormatProperty();
	prop.value && prop.value !== 'UNKNOWN' && result.setValue(pixelFormatStringToEnum(prop.value));
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertToColorSpaceProperty = (prop: Property<ColorSpaceName>) => {
	const result = new ColorSpaceProperty();
	prop.value && prop.value !== 'UNKNOWN' && result.setValue(colorSpaceStringToEnum(prop.value));
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertToPassModeProperty = (prop: Property<PassModeName>) => {
	const result = new PassModeProperty();
	prop.value && prop.value !== 'UNKNOWN' && result.setValue(passModeStringToEnum(prop.value));
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertToRationalProperty = (prop: Property<FormRational>) => {
	const result = new RationalProperty();

	const rational = new Rational();
	rational.setNum(prop.value.nom);
	rational.setDen(prop.value.den);

	if (rational.getNum() > 0 || rational.getDen() > 0) {
		result.setValue(rational);
	}
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertToResourcesProperty = (prop: Property<FormResources>) => {
	const result = new ResourcesProperty();

	const resources = new Resources();
	resources.setRequestsCpu(prop.value.cpu);
	resources.setRequestsGpu(prop.value.gpu);

	if (resources.getRequestsCpu() > 0 || resources.getRequestsGpu() > 0) {
		result.setValue(resources);
	}
	result.setDetails(convertPropertyDetails(prop.details));

	return result;
};

export const convertPropertyDetails = (details: FormPropertyDetails) => {
	const propertyDetails = new PropertyDetails();
	details.editable && propertyDetails.setEditable(details.editable);
	details.required && propertyDetails.setRequired(details.required);
	details.hidden && propertyDetails.setHidden(details.hidden);
	return propertyDetails;
};
