import styled from 'styled-components';
import {ListSelectS_Header, ListSelectS_Info} from '../../../../../components/ListSelect/ListSelectS';

export const SelectPresetS = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 40px 4vw;
	overflow: auto;

	svg {
		color: white;
	}

	.ant-table-thead > tr > th {
		padding: 9px 16px;
	}

	.ant-table-thead {
		height: 52px;
	}

`;

export const SelectPresetS_Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 40px;

	.ant-table-wrapper {
		width: 100%;
		min-height: 23.3vh;
	}

	${ListSelectS_Header} {
		width: 45vw;
	}

	${ListSelectS_Info} {
		min-height: 49vh;
	}
`;
