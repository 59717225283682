import {SettingsFormValues} from '../components/Settings/Settings';
import {diff} from '../../../utils/diff';
import {EditAccountFormValues} from '../components/EditAccount/EditAccount';
import {RoleName, roleStringToEnum} from './roleConverter';
import {UpdateUserSrvArgs} from '../api/users_requests';
import {EditPresetFormValues} from '../components/EditPreset/EditPreset';
import {UpdatePresetSrvArgs} from '../api/presets_requests';
import {CategoryName, categoryStringToEnum} from './categoryConverter';
import {convertBlueprintFormValuesToProto} from './blueptintConverter';
import {Blueprint} from '../../../autogen/gRPC/arranger/presets/models_pb';

export const convertUpdateSettingsDataToServiceArgs = (original: SettingsFormValues, current: SettingsFormValues) => {

	const originalData = {...original};
	const currentData = {...current};

	const difference: Partial<SettingsFormValues> = diff(originalData, currentData);

	let serviceArgs: UpdateUserSrvArgs = {};
	if (Object.keys(difference).length > 0) {
		serviceArgs = {
			email: difference.email,
			firstname: difference.firstname,
			lastname: difference.lastname,
			company: difference.company,
			password: difference.password
		};
	}
	return serviceArgs;
};

export const convertEditAccountDataToServiceArgs = (original: EditAccountFormValues, current: EditAccountFormValues) => {

	const originalData = {...original};
	const currentData = {...current};

	const difference: Partial<EditAccountFormValues> = diff(originalData, currentData);

	let role: number | undefined;
	if (difference.role) {
		role = roleStringToEnum(difference.role as RoleName);
	}

	let serviceArgs: UpdateUserSrvArgs = {};
	if (Object.keys(difference).length > 0) {
		serviceArgs = {
			firstname: difference.firstname,
			lastname: difference.lastname,
			company: difference.company,
			role: role
		};
	}
	return serviceArgs;
};

export const convertEditPresetDataToServiceArgs = (original: EditPresetFormValues, current: EditPresetFormValues) => {

	const originalData = {...original};
	const currentData = {...current};

	const difference: Partial<EditPresetFormValues> = diff(originalData, currentData);

	let category: number | undefined;
	if (difference.category) {
		category = categoryStringToEnum(difference.category as CategoryName);
	}

	let blueprint: Blueprint | undefined;
	if (difference.blueprint) {
		blueprint = convertBlueprintFormValuesToProto(difference.blueprint);
	}

	let serviceArgs: UpdatePresetSrvArgs = {};
	if (Object.keys(difference).length > 0) {
		serviceArgs = {
			name: difference.name,
			nodePoolName: difference.nodePoolName,
			category: category,
			blueprint: blueprint
		};
	}
	return serviceArgs;
};
