import styled from 'styled-components';

export const CreatePresetS = styled.div`
	width: 100%;

	border: 1px solid rgba(140, 140, 140, 0.35);
	padding: 30px;
`;

export const CreatePresetS_Container = styled.div`
	padding-top: 30px;
	max-width: 1200px;
	margin: 0 auto;
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

export const CreatePresetS_Divider = styled.div`
	height: 40px;
`;
