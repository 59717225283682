import {ColorSpace} from '../../../autogen/gRPC/scheduler/enums_pb';

///
/// ColorSpaces List
///

export const colorSpaces: ColorSpace[] = [
	ColorSpace.COLORSPACE_COPY,
	ColorSpace.COLORSPACE_RGB,
	ColorSpace.COLORSPACE_BT709,
	ColorSpace.COLORSPACE_BT2020_NCL,
	ColorSpace.COLORSPACE_BT2020_CL,
	ColorSpace.COLORSPACE_ICTCPL
];

///
/// ColorSpace to String
///

export const colorSpaceToString = (colorSpace: ColorSpace) => {
	switch (colorSpace) {
		case ColorSpace.COLORSPACE_COPY: {
			return 'COPY';
		}
		case ColorSpace.COLORSPACE_RGB: {
			return 'RGB';
		}
		case ColorSpace.COLORSPACE_BT709: {
			return 'BT709';
		}
		case ColorSpace.COLORSPACE_BT2020_NCL: {
			return 'BT2020 NCL';
		}
		case ColorSpace.COLORSPACE_BT2020_CL: {
			return 'BT2020 CL';
		}
		case ColorSpace.COLORSPACE_ICTCPL: {
			return 'ICTCPL';
		}
		case ColorSpace.COLORSPACE_UNKNOWN: {
			return 'UNKNOWN';
		}
		default: {
			return 'UNKNOWN';
		}
	}
};

///
/// Categories String List Type
///

export type ColorSpaceName = ReturnType<typeof colorSpaceToString>

///
/// ColorSpace string to eventAccess
///

export const colorSpaceStringToEnum = (colorSpace: ColorSpaceName) => {
	switch (colorSpace.toUpperCase()) {
		case 'COPY': {
			return ColorSpace.COLORSPACE_COPY;
		}
		case 'RGB': {
			return ColorSpace.COLORSPACE_RGB;
		}
		case 'BT709': {
			return ColorSpace.COLORSPACE_BT709;
		}
		case 'BT2020 NCL': {
			return ColorSpace.COLORSPACE_BT2020_NCL;
		}
		case 'BT2020 CL': {
			return ColorSpace.COLORSPACE_BT2020_CL;
		}
		case 'ICTCPL': {
			return ColorSpace.COLORSPACE_ICTCPL;
		}
		case 'UNKNOWN': {
			return ColorSpace.COLORSPACE_UNKNOWN;
		}
		default: {
			return ColorSpace.COLORSPACE_UNKNOWN;
		}
	}
};
