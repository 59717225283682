import {OutputFormat} from '../../settings/components/CreatePreset/CreatePreset';

export interface FormatInfo {
	url: string;
	summary: string;
}

export const resolveFormatInfo = (format: OutputFormat) => {
	let result: FormatInfo;
	switch (format.toUpperCase()) {
		case 'MP4':
			result = {
				url: 'https://en.wikipedia.org/wiki/MPEG-4_Part_14',
				summary: 'MPEG-4 Part 14 or MP4 is a digital multimedia container format most commonly used to store video and audio, but it can ' +
					'also be used to store other data such as subtitles and still images. Like most modern container formats, it allows streaming ' +
					'over the Internet.'
			};
			return result;
		case 'MATROSKA':
			result = {
				url: 'https://en.wikipedia.org/wiki/Matroska',
				summary: 'The Matroska Multimedia Container is a free and open container format, a file format that can hold an unlimited number of ' +
					'video, audio, picture, or subtitle tracks in one file. It is a universal format for storing common multimedia content, like ' +
					'movies or TV shows.'
			};
			return result;
		case 'WEBM':
			result = {
				url: 'https://en.wikipedia.org/wiki/WebM',
				summary: 'WebM is an audiovisual media file format. It is primarily intended to offer a royalty-free alternative to use in the ' +
					'HTML5 video and the HTML5 audio elements. It has a sister project, WebP, for images. The development of the format is sponsored ' +
					'by Google.'
			};
			return result;
		case 'HLS':
			result = {
				url: 'https://en.wikipedia.org/wiki/HTTP_Live_Streaming',
				summary: 'HTTP Live Streaming (also known as HLS) is an HTTP-based adaptive bitrate streaming communications protocol developed by ' +
					'Apple Inc. and released in 2009. Support for the protocol is widespread in media players, web browsers, mobile devices, and ' +
					'streaming media servers.'
			};
			return result;
		case 'DASH':
			result = {
				url: 'https://en.wikipedia.org/wiki/Dynamic_Adaptive_Streaming_over_HTTP',
				summary: 'Dynamic Adaptive Streaming over HTTP (DASH), also known as MPEG-DASH, is an adaptive bitrate streaming technique that ' +
					'enables high quality streaming of media content over the Internet delivered from conventional HTTP web servers.'
			};
			return result;
		default:
			result = {
				url: '',
				summary: ''
			};
	}
};
