/* eslint-disable */
// source: scheduler/enums.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.scheduler.api.v1.ChannelLayout', null, global);
goog.exportSymbol('proto.scheduler.api.v1.ColorSpace', null, global);
goog.exportSymbol('proto.scheduler.api.v1.InputLocationType', null, global);
goog.exportSymbol('proto.scheduler.api.v1.JobPartStatus', null, global);
goog.exportSymbol('proto.scheduler.api.v1.JobPartStatusValue', null, global);
goog.exportSymbol('proto.scheduler.api.v1.JobStatus', null, global);
goog.exportSymbol('proto.scheduler.api.v1.JobStatusValue', null, global);
goog.exportSymbol('proto.scheduler.api.v1.PassMode', null, global);
goog.exportSymbol('proto.scheduler.api.v1.PixelFormat', null, global);
goog.exportSymbol('proto.scheduler.api.v1.SampleFormat', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.JobStatusValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.JobStatusValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.JobStatusValue.displayName = 'proto.scheduler.api.v1.JobStatusValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scheduler.api.v1.JobPartStatusValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scheduler.api.v1.JobPartStatusValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scheduler.api.v1.JobPartStatusValue.displayName = 'proto.scheduler.api.v1.JobPartStatusValue';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.JobStatusValue.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.JobStatusValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.JobStatusValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobStatusValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.JobStatusValue}
 */
proto.scheduler.api.v1.JobStatusValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.JobStatusValue;
  return proto.scheduler.api.v1.JobStatusValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.JobStatusValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.JobStatusValue}
 */
proto.scheduler.api.v1.JobStatusValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.scheduler.api.v1.JobStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.JobStatusValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.JobStatusValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.JobStatusValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobStatusValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional JobStatus status = 1;
 * @return {!proto.scheduler.api.v1.JobStatus}
 */
proto.scheduler.api.v1.JobStatusValue.prototype.getStatus = function() {
  return /** @type {!proto.scheduler.api.v1.JobStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.scheduler.api.v1.JobStatus} value
 * @return {!proto.scheduler.api.v1.JobStatusValue} returns this
 */
proto.scheduler.api.v1.JobStatusValue.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scheduler.api.v1.JobPartStatusValue.prototype.toObject = function(opt_includeInstance) {
  return proto.scheduler.api.v1.JobPartStatusValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scheduler.api.v1.JobPartStatusValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobPartStatusValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scheduler.api.v1.JobPartStatusValue}
 */
proto.scheduler.api.v1.JobPartStatusValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scheduler.api.v1.JobPartStatusValue;
  return proto.scheduler.api.v1.JobPartStatusValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scheduler.api.v1.JobPartStatusValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scheduler.api.v1.JobPartStatusValue}
 */
proto.scheduler.api.v1.JobPartStatusValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.scheduler.api.v1.JobPartStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scheduler.api.v1.JobPartStatusValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scheduler.api.v1.JobPartStatusValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scheduler.api.v1.JobPartStatusValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scheduler.api.v1.JobPartStatusValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional JobPartStatus status = 1;
 * @return {!proto.scheduler.api.v1.JobPartStatus}
 */
proto.scheduler.api.v1.JobPartStatusValue.prototype.getStatus = function() {
  return /** @type {!proto.scheduler.api.v1.JobPartStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.scheduler.api.v1.JobPartStatus} value
 * @return {!proto.scheduler.api.v1.JobPartStatusValue} returns this
 */
proto.scheduler.api.v1.JobPartStatusValue.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.scheduler.api.v1.SampleFormat = {
  SAMPLEFORMAT_UNKNOWN: 0,
  SAMPLEFORMAT_U8: 1,
  SAMPLEFORMAT_S16: 2,
  SAMPLEFORMAT_S32: 3,
  SAMPLEFORMAT_FLT: 4,
  SAMPLEFORMAT_U8P: 5,
  SAMPLEFORMAT_S16P: 6,
  SAMPLEFORMAT_S32P: 7,
  SAMPLEFORMAT_FLTP: 8,
  SAMPLEFORMAT_NONE: 9
};

/**
 * @enum {number}
 */
proto.scheduler.api.v1.ChannelLayout = {
  CHANNELLAYOUT_UNKNOWN: 0,
  CHANNELLAYOUT_STEREO: 1,
  CHANNELLAYOUT_MONO: 2,
  CHANNELLAYOUT_2_1: 3,
  CHANNELLAYOUT_3_1: 4,
  CHANNELLAYOUT_5_1: 5,
  CHANNELLAYOUT_7_1: 6
};

/**
 * @enum {number}
 */
proto.scheduler.api.v1.PassMode = {
  PASSMODE_SINGLE: 0,
  PASSMODE_MULTI: 1
};

/**
 * @enum {number}
 */
proto.scheduler.api.v1.JobStatus = {
  JOBSTATUS_UNKNOWN: 0,
  JOBSTATUS_PENDING: 1,
  JOBSTATUS_DOWNLOADING: 2,
  JOBSTATUS_ANALYSING: 3,
  JOBSTATUS_ANALYZED: 4,
  JOBSTATUS_TRANSCODING: 5,
  JOBSTATUS_TRANSCODED: 6,
  JOBSTATUS_MUXING: 7,
  JOBSTATUS_FINISHED: 8,
  JOBSTATUS_CANCELLED: 9,
  JOBSTATUS_FAILED: 10
};

/**
 * @enum {number}
 */
proto.scheduler.api.v1.JobPartStatus = {
  JOBPARTSTATUS_UNKNOWN: 0,
  JOBPARTSTATUS_PENDING: 1,
  JOBPARTSTATUS_RUNNING: 2,
  JOBPARTSTATUS_RESCHEDULING: 3,
  JOBPARTSTATUS_FINISHED: 4,
  JOBPARTSTATUS_CANCELLED: 5,
  JOBPARTSTATUS_FAILED: 6
};

/**
 * @enum {number}
 */
proto.scheduler.api.v1.InputLocationType = {
  INPUTLOCATIONTYPE_UNKNOWN: 0,
  INPUTLOCATIONTYPE_HTTP: 1
};

/**
 * @enum {number}
 */
proto.scheduler.api.v1.PixelFormat = {
  PIXELFORMAT_UNKNOWN: 0,
  PIXELFORMAT_COPY: 1,
  PIXELFORMAT_BGR24: 2,
  PIXELFORMAT_RGB24: 4,
  PIXELFORMAT_RGBA: 5,
  PIXELFORMAT_YUV420P: 6,
  PIXELFORMAT_YUVJ420P: 7,
  PIXELFORMAT_YUYV422: 8,
  PIXELFORMAT_YUV422P: 9,
  PIXELFORMAT_YUV444P: 10
};

/**
 * @enum {number}
 */
proto.scheduler.api.v1.ColorSpace = {
  COLORSPACE_UNKNOWN: 0,
  COLORSPACE_COPY: 1,
  COLORSPACE_RGB: 2,
  COLORSPACE_BT709: 3,
  COLORSPACE_BT2020_NCL: 4,
  COLORSPACE_BT2020_CL: 5,
  COLORSPACE_ICTCPL: 6
};

goog.object.extend(exports, proto.scheduler.api.v1);
