import {envVars} from '../../../config/envs/env';
import {AuthService} from '../../auth/api/service';
import {CreateRequest, DeleteRequest, ListRequest, UpdateRequest} from '../../../autogen/gRPC/arranger/presets/base/service_presets_pb';
import {BasePresetsServiceClient} from '../../../autogen/gRPC/arranger/presets/base/Service_presetsServiceClientPb';
import {Category} from '../../../autogen/gRPC/arranger/presets/enums_pb';
import {Blueprint, BlueprintValue, CategoryValue, NameValue, NodePoolNameValue} from '../../../autogen/gRPC/arranger/presets/models_pb';

const service = new BasePresetsServiceClient(envVars.apiArrangerUrl);

const prepareHeaders = async () => {
	const token = await AuthService.getInstance().currentToken();
	return {'Authorization': 'Bearer ' + token.token};
};

export const callListBasePresets = async (offset?: number, limit?: number) => {
	const request = new ListRequest();

	limit && request.setLimit(limit);
	offset && request.setOffset(offset);

	const response = await service.list(request, await prepareHeaders());
	return response.toObject();
};

export const callCreatePreset = async (name: string, nodePoolName: string, category: Category, blueprint: Blueprint) => {
	const request = new CreateRequest();

	request.setName(name);
	request.setNodePoolName(nodePoolName);
	request.setCategory(category);
	request.setBlueprint(blueprint);

	const response = await service.create(request, await prepareHeaders());
	return response.toObject();
};

export interface UpdatePresetSrvArgs {
	override?: boolean,
	name?: string,
	nodePoolName?: string,
	category?: Category,
	blueprint?: Blueprint,
}

export const callUpdatePreset = async (id: string, values: UpdatePresetSrvArgs) => {
	const {override, name, nodePoolName, category, blueprint} = values;
	const request = new UpdateRequest();

	request.setId(id);
	override && request.setOverride(override);
	name && request.setName(new NameValue().setValue(name));
	nodePoolName && request.setNodePoolName(new NodePoolNameValue().setValue(nodePoolName));
	category && request.setCategory(new CategoryValue().setValue(category));
	blueprint && request.setBlueprint(new BlueprintValue().setValue(blueprint));

	const response = await service.update(request, await prepareHeaders());
	return response.toObject();
};

export const callDeletePreset = async (id: string) => {
	const request = new DeleteRequest();

	request.setId(id);

	const response = await service.delete(request, await prepareHeaders());
	return response.toObject();
};
