import {
	AudioOutputOverride,
	BlueprintOverride,
	DisabledMappings,
	FileOutputOverride,
	StringProperty,
	VideoOutputOverride
} from '../../../autogen/gRPC/arranger/presets/models_pb';
import {
	convertToChannelLayoutProperty,
	convertToColorSpaceProperty,
	convertToIntProperty,
	convertToPassModeProperty,
	convertToPixelFormatProperty,
	convertToRationalProperty,
	convertToSampleFormatProperty,
	convertToStringProperty
} from '../../settings/domain/blueptintConverter';
import {BlueprintOverride as FormBlueprintOverride} from './blueprintOverride';
import {VideoAudioStreamMapping} from '../../../autogen/gRPC/scheduler/models_pb';

export const convertBlueprintOverrideFormValuesToProto = (formOverride: FormBlueprintOverride) => {
	const override = new BlueprintOverride();

	/// file outputs
	const fileOutputs = override.getFileOutputsMap();

	formOverride.fileOutputs.forEach((fileOutput, name) => {
		const fOutput = new FileOutputOverride();
		fileOutput.disabled && fOutput.setDisabled(fileOutput.disabled);
		fileOutput.fileName && fOutput.setFileName(fileOutput.fileName);
		fileOutputs.set(name, fOutput);
	});

	/// audio outputs
	const audioOutputs = override.getAudioOutputsMap();

	formOverride.audioOutputs.forEach((audioOutput, name) => {
		const aOutput = new AudioOutputOverride();
		audioOutput.disabled && aOutput.setDisabled(audioOutput.disabled);
		audioOutput.bitrate?.value && aOutput.setBitRate(convertToIntProperty(audioOutput.bitrate));
		audioOutput.channelLayout?.value && aOutput.setChannelLayout(convertToChannelLayoutProperty(audioOutput.channelLayout));
		audioOutput.sampleFormat?.value && aOutput.setSampleFormat(convertToSampleFormatProperty(audioOutput.sampleFormat));
		audioOutput.channels?.value && aOutput.setChannels(convertToIntProperty(audioOutput.channels));
		audioOutput.sampleRate?.value && aOutput.setSampleRate(convertToIntProperty(audioOutput.sampleRate));
		audioOutput.timeBase?.value && aOutput.setTimeBase(convertToRationalProperty(audioOutput.timeBase));

		const privateData = aOutput.getPrivateDataMap();
		audioOutput.privateData?.forEach((data, prop) => {
			privateData.set(prop, new StringProperty().setValue(data.value));
		});

		audioOutputs.set(name, aOutput);
	});

	/// video outputs
	const videoOutputs = override.getVideoOutputsMap();

	formOverride.videoOutputs.forEach((videoOutput, name) => {
		const vOutput = new VideoOutputOverride();
		videoOutput.disabled && vOutput.setDisabled(videoOutput.disabled);
		videoOutput.maxGop?.value && vOutput.setMaxGop(convertToIntProperty(videoOutput.maxGop));
		videoOutput.width?.value && vOutput.setWidth(convertToIntProperty(videoOutput.width));
		videoOutput.height?.value && vOutput.setHeight(convertToIntProperty(videoOutput.height));
		videoOutput.bitrate?.value && vOutput.setBitRate(convertToIntProperty(videoOutput.bitrate));
		videoOutput.refs?.value && vOutput.setRefs(convertToIntProperty(videoOutput.refs));
		videoOutput.pixelFormat?.value && vOutput.setPixelFormat(convertToPixelFormatProperty(videoOutput.pixelFormat));
		videoOutput.frameRate?.value && vOutput.setFrameRate(convertToRationalProperty(videoOutput.frameRate));
		videoOutput.timeBase?.value && vOutput.setTimeBase(convertToRationalProperty(videoOutput.timeBase));
		videoOutput.filter?.value && vOutput.setFilter(convertToStringProperty(videoOutput.filter));
		videoOutput.level?.value && vOutput.setLevel(convertToIntProperty(videoOutput.level));
		videoOutput.colorSpace?.value && vOutput.setColorSpace(convertToColorSpaceProperty(videoOutput.colorSpace));
		videoOutput.passMode?.value && vOutput.setPassMode(convertToPassModeProperty(videoOutput.passMode));
		videoOutput.rcMaxBitrate?.value && vOutput.setRcMaxBitrate(convertToIntProperty(videoOutput.rcMaxBitrate));
		videoOutput.rcMaxBitrate?.value && vOutput.setRcMinBitrate(convertToIntProperty(videoOutput.rcMaxBitrate));
		videoOutput.bufSize?.value && vOutput.setBufSize(convertToIntProperty(videoOutput.bufSize));
		videoOutput.maxBFrames?.value && vOutput.setMaxBFrames(convertToIntProperty(videoOutput.maxBFrames));
		videoOutput.keyIntMin?.value && vOutput.setKeyIntMin(convertToIntProperty(videoOutput.keyIntMin));

		const privateData = vOutput.getPrivateDataMap();
		videoOutput.privateData?.forEach((data, prop) => {
			privateData.set(prop, new StringProperty().setValue(data.value));
		});

		videoOutputs.set(name, vOutput);
	});

	/// disabled
	const disabled = override.getDisabledMap();

	formOverride.disabled.forEach((videoAudios, fName) => {
		const converted: VideoAudioStreamMapping[] = [];
		videoAudios.forEach((videoAudio) => {
			converted.push(new VideoAudioStreamMapping().setAudio(videoAudio.audio).setVideo(videoAudio.video));
		});
		disabled.set(fName, new DisabledMappings().setValuesList(converted));
	});

	return override;
};

