import styled from 'styled-components';

export const SelectCodecS = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 40px 4vw;
	overflow: auto;
	
	svg {
		color: white;
	}
`;
