import styled from 'styled-components';
import {GREY_3} from '../../../../utils/colors';

export const LoadingS = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background: ${GREY_3};
`;
