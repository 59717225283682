import {Form, Input, Modal, Typography} from 'antd';
import React, {FC, useState} from 'react';
import {validateRequired} from '../../../../../settings/domain/validators';
import {HttpStorageConfig} from '../../../../../../autogen/gRPC/cloud/models_pb';
import {callProbeFile} from '../../../../../files/api/requests';
import {InputInfo as InputInfoProber} from '../../../../../../autogen/gRPC/prober/models_pb';
import {InputInfo as InputInfoJob} from '../../../../../../autogen/gRPC/scheduler/models_pb';
import {Headers} from './Headers/Headers';
import {ErrorLabel} from '../../../../../../components/ErrorLabel/ErrorLabel';
import {FileEntry} from '../../../../../../stores/slices/files';

export interface FromUrlModalProps {
	visible: boolean;
	setVisible: (arg: boolean) => void;
	setInputInfo: (arg: InputInfoJob) => void;
	setFileInfo: (arg: FileEntry) => void;
}

export interface FromUrlModalFormValues {
	url: string;
	method?: string;
	headers?: Map<string, string>;
}

export const FromUrlModal: FC<FromUrlModalProps> = (props) => {
	const {visible, setVisible, setInputInfo, setFileInfo} = props;

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState<FromUrlModalFormValues | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);

	const handleOk = async () => {
		setLoading(true);
		const infoProber = new InputInfoProber();
		values?.url && infoProber.setPath(values.url);
		const config = new HttpStorageConfig();
		values?.method && config.setMethod(values.method);
		if (values?.headers) {
			const headers = config.getHeadersMap();
			values.headers.forEach((value, header) => {
				headers.set(header, value);
			});
		}

		infoProber.setHttp(config);

		try {
			const result = await callProbeFile(infoProber);
			if (result.fileInfo?.format) {
				result.fileInfo && setFileInfo({name: result.fileInfo.format?.fileName, details: result.fileInfo});
			}
			console.log(result);

			const jobInfo = new InputInfoJob();
			values?.url && jobInfo.setPath(values.url);
			jobInfo.setHttp(config);
			setInputInfo(jobInfo);

			setVisible(false);
		} catch (error: any) {
			setError(`Unable to check file. Please verify your data. ${error.message}`);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<Modal width="490px" title="File from url" centered visible={visible} onOk={handleOk} onCancel={handleCancel}
		       okButtonProps={{htmlType: 'submit'}} confirmLoading={loading}>
			<Form
				form={form}
				layout="vertical"
				autoComplete="Off"
				onValuesChange={() => {
					const formState = form.getFieldsValue();

					const keys = new Map<number, string>();
					const values = new Map<number, string>();

					for (const [field, value] of Object.entries(formState)) {
						if (field.startsWith('customHeader')) {
							const parts = field.split('|');
							if (parts.length == 2) {
								const idx = parseInt(parts[1]);
								keys.set(idx, value as string);
							}
						}
						if (field.startsWith('customData')) {
							const parts = field.split('|');
							if (parts.length == 2) {
								const idx = parseInt(parts[1]);
								values.set(idx, value as string);
							}
						}
					}

					const headers = new Map<string, string>();
					keys.forEach((val, idx) => {
						const value = values.get(idx);
						value && headers.set(val, value);
					});

					setValues({
						url: formState.fileUrl,
						method: formState.fileMethod,
						headers: headers
					});
				}}
			>
				<Form.Item label={<Typography.Text>File Url</Typography.Text>}
				           name={`fileUrl`}
				           tooltip="Input file location url"
				           rules={validateRequired()}
				>
					<Input placeholder="input file url"/>
				</Form.Item>
				<Form.Item label={<Typography.Text>HTTP Method</Typography.Text>}
				           name={`fileMethod`}
				           tooltip="Input file location fetch method (GET, POST, etc.)"
				           initialValue={'GET'}
				>
					<Input placeholder="input fetch method"/>
				</Form.Item>
				<Headers/>
				<ErrorLabel error={error}/>
			</Form>
		</Modal>
	);
};
