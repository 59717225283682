import {PassMode} from '../../../autogen/gRPC/scheduler/enums_pb';

///
/// PassModes List
///

export const passModes: PassMode[] = [
	PassMode.PASSMODE_SINGLE,
	PassMode.PASSMODE_MULTI
];

///
/// PassMode to String
///

export const passModeToString = (passMode: PassMode) => {
	switch (passMode) {
		case PassMode.PASSMODE_SINGLE: {
			return 'SINGLE';
		}
		case PassMode.PASSMODE_MULTI: {
			return 'MULTI';
		}
		default: {
			return 'UNKNOWN';
		}
	}
};

///
/// Categories String List Type
///

export type PassModeName = ReturnType<typeof passModeToString>

///
/// PassMode string to eventAccess
///

export const passModeStringToEnum = (passMode: PassModeName) => {
	switch (passMode.toUpperCase()) {
		case 'SINGLE': {
			return PassMode.PASSMODE_SINGLE;
		}
		case 'MULTI': {
			return PassMode.PASSMODE_MULTI;
		}
		default: {
			return PassMode.PASSMODE_SINGLE;
		}
	}
};
