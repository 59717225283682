import {CustomPresetsServiceClient} from '../../../autogen/gRPC/arranger/presets/custom/Service_presetsServiceClientPb';
import {envVars} from '../../../config/envs/env';
import {AuthService} from '../../auth/api/service';
import {CreateRequest, DeleteRequest, ListRequest, UpdateRequest} from '../../../autogen/gRPC/arranger/presets/custom/service_presets_pb';
import {BlueprintOverride, BlueprintOverrideValue} from '../../../autogen/gRPC/arranger/presets/models_pb';

const service = new CustomPresetsServiceClient(envVars.apiArrangerUrl);

const prepareHeaders = async () => {
	const token = await AuthService.getInstance().currentToken();
	return {'Authorization': 'Bearer ' + token.token};
};

export const callListCustomPresets = async (offset?: number, limit?: number) => {
	const request = new ListRequest();

	limit && request.setLimit(limit);
	offset && request.setOffset(offset);

	const response = await service.list(request, await prepareHeaders());
	return response.toObject();
};

export const callCreateCustomPreset = async (name: string, baseId: string, override: BlueprintOverride) => {
	const request = new CreateRequest();

	request.setName(name);
	request.setBaseId(baseId);
	request.setOverride(override);

	const response = await service.create(request, await prepareHeaders());
	return response.toObject();
};

export const callUpdateCustomPreset = async (id: string, override: BlueprintOverride) => {
	const request = new UpdateRequest();

	request.setId(id);
	request.setOverride(new BlueprintOverrideValue().setOverride(override));

	const response = await service.update(request, await prepareHeaders());
	return response.toObject();
};

export const callDeleteCustomPreset = async (id: string) => {
	const request = new DeleteRequest();

	request.setId(id);

	const response = await service.delete(request, await prepareHeaders());
	return response.toObject();
};
