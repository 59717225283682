import React, {FC, useEffect, useRef} from 'react';
import {HomeS, HomeS_CardsWrapper, HomeS_Details, HomeS_LeftPart, HomeS_Main, HomeS_RightPart, HomeS_Space} from './HomeS';
import {Page} from '../../../../components/Page/Page';
import cloudImage from '../../../../assets/cloud.svg';
import presetsImage from '../../../../assets/presets.svg';
import filesImage from '../../../../assets/files.svg';
import homeImage from '../../../../assets/home.svg';
import {Button, Row, Space} from 'antd';
import {Card} from '../../../../components/Card/Card';
import {animateScroll as scroll, Link} from 'react-scroll';
import * as CheckType from 'check-types';
import {useLocation} from 'react-router-dom';

export const Home: FC = () => {
	const about = useRef<HTMLDivElement>(null);
	const location = useLocation();

	useEffect(() => {
		if (CheckType.assigned(location.state && (location.state as any).scrolled)) {
			const state = {...(location.state as any)};
			state.scrolled && scroll.scrollToBottom();
			delete location.state;
		}
	}, []);

	return (
		<Page>
			<HomeS>
				<HomeS_Main>
					<HomeS_LeftPart>
						<h1>Highly Optimized Video / Audio Enterprise Transcoder</h1>
						<Space direction="vertical">
							<h2>
								Our product offers distributed cloud transcoding of video and audio. We have a bunch of predefined presets
								which you can use to prepare high quality video with reduced size. You can also adjust video parameters
								by yourself at your discretion.
							</h2>
							<Row justify="center">
								<Link
									to="about"
									smooth={true}
								>
									<Button type="primary">Read more</Button>
								</Link>
							</Row>
						</Space>
					</HomeS_LeftPart>
					<HomeS_RightPart image={homeImage}/>
				</HomeS_Main>
				<HomeS_Details id="about" ref={about}>
					<HomeS_CardsWrapper>
						<Card bordered={false} image={cloudImage} header="Distributed Computing"
						      description="We are splitting video processing to multiple nodes. It significantly increases operation performance,
								      so you will get your result faster. You can schedule multiple files processing and save time on waiting to each file.
								      We are using powerful hardware resources optimized to video processing, so you can be sure that you will get your
								      result as fast as possible."
						/>
						<HomeS_Space/>
						<Card bordered={false} image={presetsImage} header="Predefined Presets"
						      description="We have a bunch of predefined video parameter presets developed by our video experts. It will allow you
								      to get highest quality video with reduced file size. Presets are prepared for multiple video codecs, so you can choose
								      what fits your needs. Additionally you are able to customize selected preset to match your needs. Your custom presets
								      will be persisted, so you will be able to reuse them."
						/>
						<HomeS_Space/>
						<Card bordered={false} image={filesImage} header="Files Management"
						      description="You have full control over your video files. We are storing them in protected storage, so only you have
								      access to them. Once you upload a file, you can run multiple operations on it, without uploading it again each time.
								      We support multipart upload, so you do not need to worry about network failures - your upload will be resumed from
								      last uploaded chunk."
						/>
					</HomeS_CardsWrapper>
				</HomeS_Details>
			</HomeS>
		</Page>
	);
};
