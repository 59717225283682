import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {UploadEntry} from '../../features/files/components/UploadPanel/UploadPanel';

const uploadsAdapter = createEntityAdapter<UploadEntry>({
	selectId: (upload) => upload.name
});

export const uploadsSlice = createSlice({
	name: 'uploads',
	initialState: uploadsAdapter.getInitialState(),
	reducers: {
		addUploads: uploadsAdapter.addMany,
		addUpload: uploadsAdapter.addOne,
		setUploads: uploadsAdapter.setMany,
		removeUpload: uploadsAdapter.removeOne,
		removeUploads: uploadsAdapter.removeMany,
		updateUpload: uploadsAdapter.updateOne
	}
});

export const uploadsActions = uploadsSlice.actions;
export const selectUploads = uploadsAdapter.getSelectors<RootState>((state) => state.uploads).selectAll;
export const selectUpload = uploadsAdapter.getSelectors<RootState>((state) => state.uploads).selectById;
