import styled, {css} from 'styled-components';
import {ListSelectS_Header, ListSelectS_Info} from '../../../../../components/ListSelect/ListSelectS';

export const OutputFormatS = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 40px 4vw;
	overflow: auto;

	svg {
		color: white;
	}

	.ant-input {
		max-width: 300px;
	}
`;

export const OutputFormatS_Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 40px;

	.ant-table-wrapper {
		width: 100%;
		min-height: 22.5vh;
	}

	.ant-collapse-header {
		height: 54px;

		div {
			margin-top: 5px;
		}
	}

	${ListSelectS_Header} {
		width: 45vw;
	}

	${ListSelectS_Info} {
		min-height: 48.5vh;
	}
`;

export const OutputFormatS_Wrapper = styled.div<{ wrapper?: boolean }>`
	${({wrapper}) => wrapper && css`
		border: 1px solid rgba(140, 140, 140, 0.35);
		border-radius: 4px;
		padding: 30px;
	`}
`;
