/// Main

export const PRIMARY = '#454ADE';
export const SECONDARY = '#606169';
export const DANGER = '#ff4d4f';

/// Backgrounds

export const GREY_1 = '#404A4F';
export const GREY_2 = '#373F43';
export const GREY_3 = '#2A3033';
export const GREY_4 = '#252A2D';
export const GREY_5 = '#1C2022';

/// Texts

export const LIGHT_1 = '#EAEAEB';
export const LIGHT_2 = '#EAEAEB';
export const LIGHT_3 = '#E0E0E2';
