import React, {FC} from 'react';
import {callProbeFile} from '../../../../../files/api/requests';
import {InputInfo as InputInfoProber} from '../../../../../../autogen/gRPC/prober/models_pb';
import {InputInfo as InputInfoJob} from '../../../../../../autogen/gRPC/scheduler/models_pb';
import {FileEntry} from '../../../../../../stores/slices/files';
import {AzureStorageConfig, GoogleStorageConfig, S3StorageConfig} from '../../../../../../autogen/gRPC/cloud/models_pb';
import {
	azureConfigGuard,
	AzureDetails,
	CloudModal,
	CloudModalFormValues,
	gcsConfigGuard,
	GcsDetails,
	s3ConfigGuard,
	S3Details
} from '../../common/CloudModal/CloudModal';

export interface FromCloudModalProps {
	visible: boolean;
	setVisible: (arg: boolean) => void;
	setInputInfo: (arg: InputInfoJob) => void;
	setFileInfo: (arg: FileEntry) => void;
}

export const FromCloudModal: FC<FromCloudModalProps> = (props) => {
	const {visible, setVisible, setInputInfo, setFileInfo} = props;

	const onSubmit = async (values: CloudModalFormValues) => {
		const infoProber = new InputInfoProber();
		values?.path && infoProber.setPath(values.path);

		const gcsConfig = new GoogleStorageConfig();
		if (values?.details && gcsConfigGuard(values?.details)) {
			const details = values.details as GcsDetails;
			gcsConfig.setBucketPolicyOnly(details.bucketPolicyOnly);
			const map = gcsConfig.getServiceAccountCredentialsMap();
			details.serviceAccount.forEach((value, key) => {
				map.set(key, value);
			});
			infoProber.setGcs(gcsConfig);
		}

		const azureConfig = new AzureStorageConfig();
		if (values?.details && azureConfigGuard(values?.details)) {
			const details = values.details as AzureDetails;
			azureConfig.setAccount(details.account);
			azureConfig.setKey(details.key);
			infoProber.setAzure(azureConfig);
		}

		const s3Config = new S3StorageConfig();
		if (values?.details && s3ConfigGuard(values?.details)) {
			const details = values.details as S3Details;
			s3Config.setAccessKeyId(details.accessKeyId);
			s3Config.setSecretAccessKey(details.secretAccessKey);
			s3Config.setEndpoint(details.endpoint);
			s3Config.setProvider(details.provider);
			s3Config.setRegion(details.region);
			infoProber.setS3(s3Config);
		}

		const result = await callProbeFile(infoProber);
		console.log('prober result:', result);
		if (result.fileInfo?.format) {
			result.fileInfo && setFileInfo({name: result.fileInfo.format?.fileName, details: result.fileInfo});
		}

		const jobInfo = new InputInfoJob();
		values?.path && jobInfo.setPath(values.path);

		if (values?.details && gcsConfigGuard(values?.details)) {
			jobInfo.setGcs(gcsConfig);
		} else if (values?.details && azureConfigGuard(values?.details)) {
			jobInfo.setAzure(azureConfig);
		} else if (values?.details && s3ConfigGuard(values?.details)) {
			jobInfo.setS3(s3Config);
		}

		setInputInfo(jobInfo);
	};

	return (
		<CloudModal visible={visible} setVisible={setVisible} onSubmit={onSubmit}/>
	);
};
