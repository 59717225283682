import React, {FC, useState} from 'react';
import {Page} from '../../../../components/Page/Page';
import {Button, Divider, Form, Input, Row, Select, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {Blueprint} from '../../domain/blueprint';
import {CreatePresetS, CreatePresetS_Container, CreatePresetS_Divider} from './CreatePresetS';
import {convertFormStateToFormValues} from '../../domain/formConverter';
import {categories, CategoryName, categoryStringToEnum, categoryToString} from '../../domain/categoryConverter';
import {validateRequired} from '../../domain/validators';
import {Notification, notify} from '../../../../utils/notify';
import {callCreatePreset} from '../../api/presets_requests';
import {convertBlueprintFormValuesToProto} from '../../domain/blueptintConverter';
import {VideoOutput} from '../../../presets/components/VideoOutput/VideoOutput';
import {AudioOutput} from '../../../presets/components/AudioOutput/AudioOutput';
import {FileOutput} from '../../../presets/components/FileOutput/FileOutput';

const {Option} = Select;

export enum OutputFormat {
	MP4 = 'MP4',
	MKV = 'MATROSKA',
	WEBM = 'WEBM',
	HLS = 'HLS',
	DASH = 'DASH',
}

export const outputFormats = () => {
	return [OutputFormat.MP4, OutputFormat.MKV, OutputFormat.WEBM, OutputFormat.HLS, OutputFormat.DASH];
};

export interface CreatePresetFormValues {
	name: string;
	category: string;
	nodePoolName: string;
	blueprint: Blueprint;
}

export const CreatePreset: FC = () => {
	const navigate = useNavigate();

	const [form] = Form.useForm();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const [audioNameIdxMap, setAudioNameIdxMap] = useState<Map<number, string>>(new Map<number, string>());
	const [videoNameIdxMap, setVideoNameIdxMap] = useState<Map<number, string>>(new Map<number, string>());

	const onSubmit = async (values: any) => {
		const result = convertFormStateToFormValues(values);
		setLoading(true);

		try {
			const category = categoryStringToEnum(result.category as CategoryName);
			const blueprint = convertBlueprintFormValuesToProto(result.blueprint);
			await callCreatePreset(result.name, result.nodePoolName, category, blueprint);
			navigate('/admin-tools');
			notify(Notification.SUCCESS, 'Operation Completed', 'Preset has been created successfully');
		} catch (error: any) {
			setError(error.message);
			console.log(error);
			notify(Notification.ERROR, 'Operation Failed', `Failed to create a preset. Please try again`);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Page tab="none">
			<CreatePresetS>
				<Form
					form={form}
					layout="vertical"
					autoComplete="Off"
					onFinish={onSubmit}
				>
					<Typography.Title>Creating New Preset</Typography.Title>
					<Divider style={{marginBottom: '50px'}}/>

					<CreatePresetS_Container>
						<Form.Item
							label={<Typography.Text>Preset Name</Typography.Text>}
							name="presetName"
							tooltip="Enter preset name"
							rules={validateRequired()}
						>
							<Input placeholder="input preset name"/>
						</Form.Item>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Preset Category</Typography.Text>}
						           name="presetCategory"
						           tooltip="Select category"
						           rules={validateRequired()}
						>
							<Select placeholder="select preset category">
								{categories.map(categoryToString).map((option) =>
									<Option value={option.toLowerCase()}>{option}</Option>
								)}
							</Select>
						</Form.Item>
						<Form.Item
							label={<Typography.Text>Node Pool Name</Typography.Text>}
							name="nodePoolName"
							tooltip="Enter node pool name"
							rules={validateRequired()}
							initialValue={'test-encoder-cpu-4-16'}
						>
							<Input placeholder="input node pool name"/>
						</Form.Item>
					</CreatePresetS_Container>

					<AudioOutput deletable={true} withHeader={true} nameIdxMap={audioNameIdxMap} setNameIdxMap={setAudioNameIdxMap}/>
					<CreatePresetS_Divider/>
					<VideoOutput deletable={true} withHeader={true} nameIdxMap={videoNameIdxMap} setNameIdxMap={setVideoNameIdxMap}/>
					<CreatePresetS_Divider/>
					<FileOutput deletable={true} videoNameIdxMap={videoNameIdxMap} audioNameIdxMap={audioNameIdxMap}/>

					<Row justify="space-between" style={{marginTop: '20px'}}>
						<Form.Item>
							<Button type="default" onClick={() => navigate(-1)}>Cancel</Button>
						</Form.Item>
						<Form.Item>
							<Button loading={loading} type="primary" htmlType="submit">Submit</Button>
						</Form.Item>
					</Row>
				</Form>
			</CreatePresetS>
		</Page>
	);
};
