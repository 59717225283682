import FileMeta from './fileMeta';
import {FileProcessor} from './fileProcessor';
import {Pool, spawn, Worker} from 'threads';
import {callArrangeUploadPart, callCancelUpload, callFinalizeUpload, callInitUpload, callListUploadParts} from '../api/requests';
import {v4 as uuidv4} from 'uuid';

const MIN_CHUNK_SIZE = 262144 * 100;

interface ChunkResult {
	totalBytes: number,
	uploadedBytes: number,
	chunkIndex: number,
	chunkLength: number
}

export interface Progress {
	percent: number;
	speed: number;
}

interface ProgressDetails {
	currentProgress: number;
	finishedProgress: number;
}

export default class Uploader {
	private static instance?: Uploader;

	private currentFile: File | undefined = undefined;
	private readonly chunkSize: number;

	onChunkUpload?: (c: ChunkResult) => void;
	onUploadResumed?: (name: string, bytesNum: number) => void;
	onUploadCompleted?: (name: string) => Promise<void>;
	onUploadProgress?: (name: string, progress: Progress) => void;
	onUploadFailed?: (name: string) => void;

	private meta?: FileMeta;
	private processor?: FileProcessor;
	private partEtagMap?: Map<number, string>;

	private filesQueue: File[];
	private threadPool: Pool<any>;

	private uploadId: string;
	private running: boolean;

	private readonly progressMap: Map<string, ProgressDetails>;
	private progressUpdater?: NodeJS.Timeout;
	private lastNow: number;
	private lastBytes: number;

	private constructor(chunkSize?: number) {
		if ((chunkSize && chunkSize % MIN_CHUNK_SIZE !== 0) || chunkSize === 0) {
			throw new Error(`Invalid chunk size ${chunkSize}, must be a multiple of 262144`);
		}

		this.chunkSize = chunkSize ?? MIN_CHUNK_SIZE;

		this.uploadId = '';
		this.running = false;

		this.filesQueue = [];
		this.threadPool = this.prepareThreadPool();
		this.progressMap = new Map<string, ProgressDetails>();
		this.lastNow = 0;
		this.lastBytes = 0;
	}

	/// public methods

	static getInstance() {
		if (!this.instance) {
			this.instance = new Uploader();
		}
		return this.instance;
	}

	async enqueue(files: File[]) {
		files.forEach((file) => {
			if (!this.isQueued(file.name)) {
				this.filesQueue.push(file);
			}
		});
		if (!this.running) {
			try {
				await this.start();
			} catch (e) {
				console.log('failed to start upload');
				this.currentFile && this.onUploadFailed && this.onUploadFailed(this.currentFile.name);
				this.resetState();
				await this.start();
			}
		}
	}

	async start() {
		if (!this.currentFile) {
			this.currentFile = this.filesQueue.shift();
		}

		if (!this.currentFile) {
			return;
		}
		const response = await callInitUpload({name: this.currentFile.name, type: this.currentFile.type});

		this.uploadId = response.uploadId;
		this.meta = new FileMeta(this.currentFile.name, this.currentFile.size, this.chunkSize, window.localStorage, this.uploadId);
		this.processor = new FileProcessor(this.currentFile, this.chunkSize);
		this.partEtagMap = new Map<number, string>();
		this.running = true;

		console.log('Creating new upload instance:');
		console.log(` - Name: ${this.currentFile.name}`);
		console.log(` - File size: ${this.currentFile.size}`);
		console.log(` - Chunk size: ${this.chunkSize}`);
		console.log(` - Total chunks: ${Math.ceil(this.currentFile.size / this.chunkSize)}`);

		this.progressUpdater = setInterval(() => {
			const progress = this.getProgress();
			if (this.currentFile && progress && this.running) {
				this.onUploadProgress && this.onUploadProgress(this.currentFile.name, progress);
			}
		}, 500);

		const {meta, currentFile} = this;

		if (meta.isResumable() && meta.getFileSize() === currentFile.size) {
			console.log('Upload might be resumable');
			await this.resumeUpload();
		} else {
			console.log('Upload not resumable, starting from scratch');
			await this.processor.run(this.uploadChunk.bind(this));
		}

		try {
			await this.onCompleted();
		} catch (e) {
			console.log('failed to complete upload');
			this.resetState();
			this.currentFile && this.onUploadFailed && this.onUploadFailed(this.currentFile.name);
		}
	}

	async pause() {
		this.running = false;
		await this.threadPool.terminate(true);
		this.threadPool = this.prepareThreadPool();
		this.progressUpdater && clearInterval(this.progressUpdater);
		this.progressMap.forEach((details, id) => {
			details.currentProgress = details.finishedProgress;
			this.progressMap.set(id, details);
		});
		console.log('Upload paused');
	}

	async cancel(name: string) {
		if (name === this.currentFile?.name) {
			await this.threadPool.terminate(true);
			await callCancelUpload({name: name, uploadId: this.uploadId});
			this.resetState();
			await this.start();
		} else {
			this.filesQueue = this.filesQueue.filter((file) => file.name !== name);
		}
		console.log('Upload cancelled');
	}

	currentUpload() {
		return this.currentFile;
	}

	/// private methods

	private isQueued(name: string) {
		return this.filesQueue.find((file) => file.name === name) !== undefined;
	}

	private async onCompleted() {
		if (!this.currentFile || !this.meta || !this.processor || !this.partEtagMap)
			return;

		console.log('start waiting');
		await this.threadPool.completed();
		console.log('end waiting');

		await this.retryMissingParts();

		await callFinalizeUpload({name: this.currentFile.name, uploadId: this.uploadId, parts: this.partEtagMap});
		await this.threadPool.terminate(true);
		this.onUploadCompleted && await this.onUploadCompleted(this.currentFile.name);
		this.resetState();

		if (this.filesQueue.length) {
			this.start().then();
		}

		console.log('Upload complete', this.getProgress(), this.progressMap);
	}

	async resumeUpload() {
		if (!this.currentFile || !this.meta || !this.processor || !this.partEtagMap)
			return;

		const meta = this.meta.getMeta();

		let chunksSize = 0;
		meta.chunks.forEach((info, idx) => {
			this.partEtagMap!.set(idx + 1, info.etag);
			chunksSize += meta.chunkSize;
		});
		this.onUploadResumed && this.onUploadResumed(this.currentFile.name, chunksSize);

		const uploadId = this.meta.getMeta().uploadId;
		if (uploadId) {
			this.uploadId = uploadId;
		}

		const resumeIndex = this.meta.getResumeIndex();
		console.log(`Validating chunks up to index ${resumeIndex}`);
		console.log(` - Local index: ${resumeIndex}`);

		try {
			await this.processor.run(this.validateChunk.bind(this), 0, resumeIndex);
		} catch (e: any) {
			console.log('Validation failed, starting from scratch');
			console.log(` - Failed chunk index: ${e.chunkIndex}`);
			console.log(` - Old checksum: ${e.originalChecksum}`);
			console.log(` - New checksum: ${e.newChecksum}`);

			await this.processor.run(this.uploadChunk.bind(this));
			return;
		}

		console.log('Validation passed, resuming upload');
		await this.processor.run(this.uploadChunk.bind(this), resumeIndex);
	}

	private async uploadChunk(checksum: string, index: number, chunk: ArrayBuffer) {
		if (!this.currentFile || !this.meta || !this.processor || !this.partEtagMap)
			return;

		const {currentFile, chunkSize, uploadId} = this;

		const total = currentFile.size;
		const start = index * chunkSize;
		const end = index * chunkSize + chunk.byteLength - 1;

		this.threadPool.queue(async (send: (args: { method: string; endpoint: string; body: ArrayBuffer; headers: HeadersInit }) => string | PromiseLike<string>) => {

			console.log(`Uploading chunk ${index}:`);
			console.log(` - Chunk length: ${chunk.byteLength}`);
			console.log(` - Start: ${start}`);
			console.log(` - End: ${end}`);

			try {
				const url = await callArrangeUploadPart({name: currentFile.name, uploadId: uploadId, partNumber: index + 1, hash: checksum});

				const etag = await send({method: 'PUT', endpoint: url, body: chunk, headers: {'Content-MD5': checksum}}) as string;
				this.partEtagMap!.set(index + 1, etag);

				console.log(`Chunk upload succeeded, adding checksum ${checksum}`);
				this.meta!.addChunkInfo(index, checksum, etag);

				this.onChunkUpload && this.onChunkUpload({
					totalBytes: total,
					uploadedBytes: end + 1,
					chunkIndex: index,
					chunkLength: chunk.byteLength
				});
			} catch (e) {
				console.log('failed with uploading chunk', index);
			}
		});
	}

	private async retryMissingParts() {
		if (!this.currentFile || !this.meta || !this.processor || !this.partEtagMap)
			return;

		try {
			const parts = await callListUploadParts({name: this.currentFile?.name, uploadId: this.uploadId});
			const partNumbers = parts.partsList.map((part) => part.partNumber);
			const expectedChunksNum = Math.ceil(this.currentFile.size / this.chunkSize);
			if (partNumbers.length < expectedChunksNum) {
				console.log('retry process');
				const indexes = Array.from({length: expectedChunksNum}, (val, idx) => idx);

				const missing: number[] = [];
				indexes.forEach((idx) => {
					if (partNumbers.indexOf(idx + 1) === -1) {
						missing.push(idx);
					}
				});
				console.log('missing indexes', missing);
				missing.forEach((idx) => {
					console.log('retrying idx', idx);
					this.processor?.run(this.uploadChunk.bind(this), idx, idx + 1);
				});

				await this.threadPool.completed();
				await this.retryMissingParts();
			}
		} catch (e) {
			console.log('failed to retry missing parts, retrying again');
			const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
			/// wait 5s before next try
			await delay(5000);
			await this.retryMissingParts();
		}
	}

	private async validateChunk(newChecksum: string, index: number) {
		if (!this.currentFile || !this.meta || !this.processor || !this.partEtagMap)
			return;

		const originalChecksum = this.meta.getChecksum(index).checksum;
		const isChunkValid = originalChecksum === newChecksum;
		if (!isChunkValid) {
			this.meta.reset();
			throw new Error(`Chunk at index '${index}' is different to original`);
		}
		console.log('valid - skipping chunk');
	}

	private resetState() {
		this.threadPool = this.prepareThreadPool();
		this.meta?.reset();
		this.running = false;
		this.currentFile = undefined;
		this.progressUpdater && clearInterval(this.progressUpdater);
		this.progressMap.clear();
		this.lastNow = 0;
		this.lastBytes = 0;
	}

	private prepareThreadPool() {
		return Pool(() => {
			const w = new Worker('./workers/fetch');
			const id = uuidv4();
			w.addEventListener('message', (e) => {
				const event = e as MessageEvent;
				if ('currentProgress' in event.data) {
					this.progressMap.set(id, event.data);
				}
			});
			return spawn(w);
		}, 2);
	}

	private getProgress() {
		if (!this.currentFile) {
			return undefined;
		}

		let bytes = 0;
		this.progressMap.forEach((v) => {
			bytes += v.currentProgress;
		});

		const now = new Date().getTime();

		const KBytes = (bytes - this.lastBytes) / 1024;
		const MBytes = KBytes / 1024;
		const elapsed = (now - this.lastNow) / 1000;
		const MBps = elapsed ? MBytes / elapsed : 0;

		this.lastNow = now;
		this.lastBytes = bytes;

		const progress: Progress = {
			percent: (bytes / this.currentFile?.size) * 100,
			speed: MBps
		};

		return progress;
	}
}
