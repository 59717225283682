import {Checkbox, Form, Space} from 'antd';
import React from 'react';

export const preparePropertyDetails = (idx: number, name: string) => (
	<Space>
		<Form.Item
			name={`required${name}${idx}`}
			valuePropName="checked"
		>
			<Checkbox>Required</Checkbox>
		</Form.Item>
		<Form.Item
			name={`editable${name}${idx}`}
			valuePropName="checked"
			initialValue={true}
		>
			<Checkbox defaultChecked>Editable</Checkbox>
		</Form.Item>
		<Form.Item
			name={`hidden${name}${idx}`}
			valuePropName="checked"
		>
			<Checkbox>Hidden</Checkbox>
		</Form.Item>
	</Space>
);
