import React, {FC, useEffect, useState} from 'react';
import {Button, Col, Empty, Form, Input, InputNumber, Modal, Row, Select, Space, Table, Typography} from 'antd';
import {BorderlessTableOutlined, DeleteOutlined, SaveOutlined} from '@ant-design/icons';
import {useAppDispatch, useAppSelector} from '../../../../../stores/hooks';
import {ListSelectEntry} from '../../../../../components/ListSelect/ListSelect';
import {store} from '../../../../../stores/store';
import {SelectPresetS, SelectPresetS_Container} from './SelectPresetS';
import {TableRowSelection} from 'antd/lib/table/interface';
import {callListBasePresets} from '../../../../settings/api/presets_requests';
import {BlueprintOverrideValue, Preset, VideoOutput} from '../../../../../autogen/gRPC/arranger/presets/models_pb';
import {GenericOutput} from '../../../../presets/components/GenericOutput/GenericOutput';
import {validateRequired} from '../../../../settings/domain/validators';
import {GenericOutputS_MultiField} from '../../../../presets/components/GenericOutput/GenericOutputS';
import {pixelFormats, pixelFormatToString} from '../../../../settings/domain/pixelFormatConverter';
import {colorSpaces, colorSpaceToString} from '../../../../settings/domain/colorSpaceConverter';
import {passModes, passModeToString} from '../../../../settings/domain/passModeConverter';
import {
	basePresetsActions,
	selectBasePreset,
	selectBasePresetByCategory,
	selectBasePresetById,
	selectBasePresets
} from '../../../../../stores/slices/basePresets';
import {
	callCreateCustomPreset,
	callDeleteCustomPreset,
	callListCustomPresets,
	callUpdateCustomPreset
} from '../../../../presets/api/requests';
import {
	customPresetsActions,
	selectCustomPreset,
	selectCustomPresetByCategory,
	selectCustomPresets
} from '../../../../../stores/slices/customPresets';
import {ListSelectS_Header, ListSelectS_Info} from '../../../../../components/ListSelect/ListSelectS';
import {Category} from '../../../../../autogen/gRPC/arranger/presets/enums_pb';
import {Notification, notify} from '../../../../../utils/notify';
import {convertBlueprintOverrideFormValuesToProto} from '../../../domain/blueptintOverrideConverter';
import {BlueprintOverride} from '../../../domain/blueprintOverride';
import {convertFormStateToFormValues} from '../../../domain/formConverter';
import {ApiSample} from '../../../../../components/ApiSample/ApiSample';
import {categoryToString} from '../../../../settings/domain/categoryConverter';
import {
	CreateCustomPresetInfo,
	ListBasePresetsInfo,
	ListCustomPresetsInfo,
	UpdateCustomPresetInfo
} from '../../../../../components/ApiSample/infos';
import {CreateRequest, UpdateRequest} from '../../../../../autogen/gRPC/arranger/presets/custom/service_presets_pb';
import {v4 as uuidv4} from 'uuid';

const {Option} = Select;

export interface CreateCustomPresetFormValues {
	blueprint: BlueprintOverride;
}

export interface SelectPresetProps {
	codec: Category;
	selectedOverride?: CreateCustomPresetFormValues;
	setSelectedOverride: (arg: CreateCustomPresetFormValues) => void;
	setSelectedPreset: (arg: Preset.AsObject) => void;
}

export const SelectPreset: FC<SelectPresetProps> = (props) => {
	const {codec, selectedOverride, setSelectedOverride, setSelectedPreset} = props;

	const basePresets = useAppSelector(selectBasePresets);
	const currentBasePresets = useAppSelector(selectBasePresetByCategory(codec));
	const customPresets = useAppSelector(selectCustomPresets);
	const currentCustomPresets = useAppSelector(selectCustomPresetByCategory(codec));
	const dispatch = useAppDispatch();

	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedBasePresetKeys, setSelectedBasePresetKeys] = useState<React.Key[]>([]);
	const [selectedCustomPresetKeys, setSelectedCustomPresetKeys] = useState<React.Key[]>([]);
	const [presetName, setPresetName] = useState('');
	const [loading, setLoading] = useState(false);

	const [disabledVideoOutputs, setDisabledVideoOutputs] = useState<string[]>([]);

	useEffect(() => {
		(async function () {
			if (!basePresets.length) {
				const presets = await callListBasePresets();
				dispatch(basePresetsActions.setBasePresets(presets.presetsList));
			}
		})();
	}, []);

	useEffect(() => {
		(async function () {
			if (!customPresets.length) {
				const presets = await callListCustomPresets();
				dispatch(customPresetsActions.setCustomPresets(presets.presetsList));
			}
		})();
	}, []);

	useEffect(() => {
		if (selectedBasePresetKeys.length) {
			setSelectedCustomPresetKeys([]);
		}
	}, [selectedBasePresetKeys]);

	useEffect(() => {
		if (selectedCustomPresetKeys.length) {
			setSelectedBasePresetKeys([]);
		}
	}, [selectedCustomPresetKeys]);

	useEffect(() => {
		const basePreset = currentPreset();
		if (!basePreset) return;

		const result = convertFormStateToFormValues(form.getFieldsValue(), basePreset);
		setSelectedOverride(result);

		form.resetFields();
		setDisabledVideoOutputs([]);
	}, [selectedBasePresetKeys, selectedCustomPresetKeys]);


	const onCustomPresetCreate = async () => {
		const basePreset = currentPreset();
		if (!basePreset) return;

		if (!selectedOverride) return;

		const blueprint = convertBlueprintOverrideFormValuesToProto(selectedOverride.blueprint);
		setLoading(true);

		try {
			const existingPreset = selectCustomPreset(store.getState(), presetName);
			if (existingPreset) {
				const response = await callUpdateCustomPreset(existingPreset.id, blueprint);
				response.preset && dispatch(customPresetsActions.updateCustomPreset(response.preset));
				notify(Notification.SUCCESS, 'Operation Completed', 'Custom Preset has been updated successfully');
			} else {
				const response = await callCreateCustomPreset(presetName, basePreset.id, blueprint);
				response.preset && dispatch(customPresetsActions.addCustomPreset(response.preset));
				notify(Notification.SUCCESS, 'Operation Completed', 'Custom Preset has been saved successfully');
			}
		} catch (error: any) {
			// setError(error.message);
			console.log(error);
			notify(Notification.ERROR, 'Operation Failed', `Failed to create a preset. Please try again`);
		} finally {
			setLoading(false);
		}
	};

	const currentPreset = () => {
		let result: Preset.AsObject | undefined;
		if (selectedBasePresetKeys.length) {
			result = selectBasePreset(store.getState(), selectedBasePresetKeys[0]);
		}
		if (selectedCustomPresetKeys.length && !result) {
			result = selectCustomPreset(store.getState(), selectedCustomPresetKeys[0]);
		}
		result && setSelectedPreset(result);
		return result;
	};

	const prepareData = (source: Preset.AsObject[], deletable?: boolean) => {
		return source.map((preset) => {
			const result: ListSelectEntry = {
				key: preset.name,
				name: <Row justify="space-between" align="middle">
					<div>
						<BorderlessTableOutlined style={{marginRight: '10px'}}/>{preset.name}
					</div>
					{deletable &&
						<Button size="small" type="text" shape="circle" icon={<DeleteOutlined/>} onClick={async (e) => {
							try {
								await callDeleteCustomPreset(preset.id);
								dispatch(customPresetsActions.removeCustomPreset(preset.name));
								notify(Notification.SUCCESS, 'Operation Completed', 'Preset has been deleted successfully');
							} catch (e) {
								console.log(e);
								notify(Notification.ERROR, 'Operation Failed', `Failed to create a preset. Please try again`);
							}
						}}/>
					}
				</Row>
			};
			return result;
		});
	};

	const prepareColumns = (title: string, custom?: boolean) => [
		{
			title: <Row justify="space-between" align="middle">
				<Typography.Text style={{fontWeight: 600}}>{title}</Typography.Text>
				{custom ?
					<ApiSample info={ListCustomPresetsInfo} body={JSON.stringify({category: categoryToString(codec)})}/>
					:
					<ApiSample info={ListBasePresetsInfo} body={JSON.stringify({category: categoryToString(codec)})}/>
				}
			</Row>,
			dataIndex: 'name',
			render: (text: string) => <Typography.Text style={{cursor: 'pointer'}}>{text}</Typography.Text>
		}
	];

	const rowSelection: TableRowSelection<ListSelectEntry> = {
		onChange: (selectedRowKeys, _) => {
			setSelectedBasePresetKeys(selectedRowKeys);
		}
	};

	const onDelete = (idx: number, name: string) => {
		if (selectedOverride) {
			const current = currentPreset();
			const copy = {...selectedOverride};
			if (current) {
				current.blueprint?.videoOutputsMap.forEach(([vName, vOutput]) => {

					if (vName === name) {
						const videoOutput = copy.blueprint.videoOutputs.get(name);
						if (videoOutput) {
							videoOutput.disabled = true;
							copy.blueprint.videoOutputs.set(name, videoOutput);
						} else {
							copy.blueprint.videoOutputs.set(name, {disabled: true});
						}
					}

					setSelectedOverride(copy);
					setDisabledVideoOutputs([...disabledVideoOutputs, name]);
				});
			}
		}
	};

	const shouldAllowDeletion = () => {
		const current = currentPreset();
		if (current) {
			const activeOutputs = current.blueprint?.videoOutputsMap.filter(([name]) => !disabledVideoOutputs.includes(name));
			return activeOutputs && activeOutputs.length > 1;
		}
		return false;
	};

	const prepareVideoOutput = (idx: number, name: string, vOutput: VideoOutput.AsObject) => {

		const privateDataFields = vOutput.privateDataMap.map(([prop, val]) => {
			return (
				<>
					{!val?.details?.hidden &&
						<div>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text>{prop}</Typography.Text>}
							           name={`videoCustomProperty${name}|${idx}|${prop}`}
							           tooltip={`Enter ${prop} for this video output`}
							           rules={val?.details?.required ? validateRequired() : []}
							           initialValue={val?.value && val?.value != '' ? val?.value : undefined}
							>
								<Input disabled={!val?.details?.editable} min={0} placeholder={`input ${prop}`}/>
							</Form.Item>
						</div>
					}
				</>
			);
		});

		return (
			<GenericOutput idx={idx} title={name}>
				{!vOutput.maxGop?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Max GOP</Typography.Text>}
						           name={`videoMaxGop${name}|${idx}`}
						           tooltip="Enter Max GOP for this video output"
						           rules={vOutput.maxGop?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.maxGop?.value && vOutput.maxGop?.value > 0 ? vOutput.maxGop?.value : undefined}
						>
							<InputNumber disabled={!vOutput.maxGop?.details?.editable} min={0} placeholder="input Max GOP"/>
						</Form.Item>
					</div>
				}
				{!vOutput.width?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Width</Typography.Text>}
						           name={`videoWidth${name}|${idx}`}
						           tooltip="Enter width for this video output"
						           rules={validateRequired()}
						           initialValue={vOutput.width?.value && vOutput.width?.value > 0 ? vOutput.width?.value : undefined}
						>
							<InputNumber disabled={!vOutput.width?.details?.editable} min={0} placeholder="input width"/>
						</Form.Item>
					</div>
				}
				{!vOutput.height?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Height</Typography.Text>}
						           name={`videoHeight${name}|${idx}`}
						           tooltip="Enter height for this video output"
						           rules={validateRequired()}
						           initialValue={vOutput.height?.value && vOutput.height?.value > 0 ? vOutput.height?.value : undefined}
						>
							<InputNumber disabled={!vOutput.height?.details?.editable} min={0} placeholder="input height"/>
						</Form.Item>
					</div>
				}
				{!vOutput.bitRate?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Bitrate</Typography.Text>}
						           name={`videoBitrate${name}|${idx}`}
						           tooltip="Enter bitrate for this video output"
						           rules={validateRequired()}
						           initialValue={vOutput.bitRate?.value && vOutput.bitRate?.value > 0 ? vOutput.bitRate?.value : undefined}
						>
							<InputNumber disabled={!vOutput.bitRate?.details?.editable} min={0} placeholder="input bitrate"/>
						</Form.Item>
					</div>
				}
				{!vOutput.refs?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Refs</Typography.Text>}
						           name={`videoRefs${name}|${idx}`}
						           tooltip="Enter refs for this video output"
						           rules={vOutput.refs?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.refs?.value && vOutput.refs?.value > 0 ? vOutput.refs?.value : undefined}
						>
							<InputNumber disabled={!vOutput.refs?.details?.editable} min={0} placeholder="input refs"/>
						</Form.Item>
					</div>
				}
				{!vOutput.pixelFormat?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Pixel Format</Typography.Text>}
						           name={`videoPixelFormat${name}|${idx}`}
						           tooltip="Select pixel format for this video output"
						           rules={vOutput.pixelFormat?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.pixelFormat?.value ? pixelFormatToString(vOutput.pixelFormat?.value) : undefined}
						>
							<Select disabled={!vOutput.pixelFormat?.details?.editable} placeholder="select pixel format">
								{pixelFormats.map(pixelFormatToString).map((option) =>
									<Option value={option.toLowerCase()}>{option}</Option>
								)}
							</Select>
						</Form.Item>
					</div>
				}
				{!vOutput.frameRate?.details?.hidden &&
					<div>
						<GenericOutputS_MultiField>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text>Frame Rate</Typography.Text>}
							           name={`videoFrameRateNom${name}|${idx}`}
							           tooltip="Enter frame rate for this video output"
							           rules={vOutput.frameRate?.details?.required ? validateRequired() : []}
							           initialValue={vOutput.frameRate?.value?.num}
							>
								<InputNumber disabled={!vOutput.frameRate?.details?.editable} min={0} placeholder="input nominator"/>
							</Form.Item>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text></Typography.Text>}
							           name={`videoFrameRateDen${name}|${idx}`}
							           rules={vOutput.frameRate?.details?.required ? validateRequired() : []}
							           initialValue={vOutput.frameRate?.value?.den}
							>
								<InputNumber disabled={!vOutput.frameRate?.details?.editable} min={0} placeholder="input denominator"/>
							</Form.Item>
						</GenericOutputS_MultiField>
					</div>
				}
				{!vOutput.timeBase?.details?.hidden &&
					<div>
						<GenericOutputS_MultiField>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text>Time Base</Typography.Text>}
							           name={`videoTimeBaseNom${name}|${idx}`}
							           tooltip="Enter time base for this video output"
							           rules={vOutput.timeBase?.details?.required ? validateRequired() : []}
							           initialValue={vOutput.timeBase?.value?.num}
							>
								<InputNumber disabled={!vOutput.timeBase?.details?.editable} min={0} placeholder="input nominator"/>
							</Form.Item>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text></Typography.Text>}
							           name={`videoTimeBaseDen${name}|${idx}`}
							           rules={vOutput.timeBase?.details?.required ? validateRequired() : []}
							           initialValue={vOutput.timeBase?.value?.den}
							>
								<InputNumber disabled={!vOutput.timeBase?.details?.editable} min={0} placeholder="input denominator"/>
							</Form.Item>
						</GenericOutputS_MultiField>
					</div>
				}
				{!vOutput.filter?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Filter</Typography.Text>}
						           name={`videoFilter${name}|${idx}`}
						           tooltip="Input filter for this video output"
						           rules={validateRequired()}
						           initialValue={vOutput.filter?.value}
						>
							<Input disabled={!vOutput.filter?.details?.editable} placeholder="input filter"/>
						</Form.Item>
					</div>
				}
				{!vOutput.level?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Level</Typography.Text>}
						           name={`videoLevel${name}|${idx}`}
						           tooltip="Input level for this video output"
						           rules={vOutput.level?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.level?.value && vOutput.level?.value > 0 ? vOutput.level?.value : undefined}
						>
							<InputNumber disabled={!vOutput.level?.details?.editable} min={0} placeholder="input level"/>
						</Form.Item>
					</div>
				}
				{!vOutput.colorSpace?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Color Space</Typography.Text>}
						           name={`videoColorSpace${name}|${idx}`}
						           tooltip="Select color space for this video output"
						           rules={vOutput.colorSpace?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.colorSpace?.value ? colorSpaceToString(vOutput.colorSpace?.value) : undefined}
						>
							<Select placeholder="select color space">
								{colorSpaces.map(colorSpaceToString).map((option) =>
									<Option disabled={!vOutput.colorSpace?.details?.editable} value={option.toLowerCase()}>{option}</Option>
								)}
							</Select>
						</Form.Item>
					</div>
				}
				{!vOutput.passMode?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Pass Mode</Typography.Text>}
						           name={`videoPassMode${name}|${idx}`}
						           tooltip="Select pass mode for this video output"
						           rules={vOutput.passMode?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.passMode?.value !== undefined ? passModeToString(vOutput.passMode?.value) : undefined}
						>
							<Select disabled={!vOutput.passMode?.details?.editable} placeholder="select pass mode">
								{passModes.map(passModeToString).map((option) =>
									<Option value={option.toLowerCase()}>{option}</Option>
								)}
							</Select>
						</Form.Item>
					</div>
				}
				{!vOutput.rcMaxBitrate?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>RC Max Bitrate</Typography.Text>}
						           name={`videoRcMaxBitrate${name}|${idx}`}
						           tooltip="Input rc max bitrate for this video output"
						           rules={vOutput.rcMaxBitrate?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.rcMaxBitrate?.value && vOutput.rcMaxBitrate?.value > 0 ? vOutput.rcMaxBitrate?.value : undefined}
						>
							<InputNumber disabled={!vOutput.rcMaxBitrate?.details?.editable} min={0} placeholder="input rc max bitrate"/>
						</Form.Item>
					</div>
				}
				{!vOutput.rcMinBitrate?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Rc Min Bitrate</Typography.Text>}
						           name={`videoRcMinBitrate${name}|${idx}`}
						           tooltip="Input rc min bitrate for this video output"
						           rules={vOutput.rcMinBitrate?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.rcMinBitrate?.value && vOutput.rcMinBitrate?.value > 0 ? vOutput.rcMinBitrate?.value : undefined}
						>
							<InputNumber disabled={!vOutput.rcMinBitrate?.details?.editable} min={0} placeholder="input RC min. bitrate"/>
						</Form.Item>
					</div>
				}
				{!vOutput.bufSize?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Buf Size</Typography.Text>}
						           name={`videoBufSize${name}|${idx}`}
						           tooltip="Input buf size for this video output"
						           rules={vOutput.bufSize?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.bufSize?.value && vOutput.bufSize?.value > 0 ? vOutput.bufSize?.value : undefined}
						>
							<InputNumber disabled={!vOutput.bufSize?.details?.editable} min={0} placeholder="input buf size"/>
						</Form.Item>
					</div>
				}
				{!vOutput.maxBFrames?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Max B Frames</Typography.Text>}
						           name={`videoMaxBFrames${name}|${idx}`}
						           tooltip="Input max b frames for this video output"
						           rules={vOutput.maxBFrames?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.maxBFrames?.value && vOutput.maxBFrames?.value > 0 ? vOutput.maxBFrames?.value : undefined}
						>
							<InputNumber disabled={!vOutput.maxBFrames?.details?.editable} min={0} placeholder="input max b frames"/>
						</Form.Item>
					</div>
				}
				{!vOutput.keyIntMin?.details?.hidden &&
					<div>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Key Int Min</Typography.Text>}
						           name={`videoKeyIntMin${name}|${idx}`}
						           tooltip="Input key int min for this video output"
						           rules={vOutput.keyIntMin?.details?.required ? validateRequired() : []}
						           initialValue={vOutput.keyIntMin?.value && vOutput.keyIntMin?.value > 0 ? vOutput.keyIntMin?.value : undefined}
						>
							<InputNumber disabled={!vOutput.keyIntMin?.details?.editable} min={0} placeholder="input key int min"/>
						</Form.Item>
					</div>
				}
				{privateDataFields}
			</GenericOutput>
		);
	};

	const prepareVideoOutputs = () => {
		const current = currentPreset();
		if (current) {
			return current.blueprint?.videoOutputsMap.filter(([name]) => !disabledVideoOutputs.includes(name)).map(([name, vOutput], idx) => {
				return prepareVideoOutput(idx, name, vOutput);
			});
		} else {
			return null;
		}
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		setIsModalVisible(false);
		await onCustomPresetCreate();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const preparePresetPreview = () => {
		return (
			<div style={{minWidth: '45vw'}}>
				{selectedBasePresetKeys.length || selectedCustomPresetKeys.length ?
					<Col>
						{prepareVideoOutputs()}
					</Col>
					:
					<ListSelectS_Info>
						<Col>
							<ListSelectS_Header>
								<Typography.Text>Selected Preset</Typography.Text>
							</ListSelectS_Header>
							<Col>
								<Row style={{padding: '15px 50px'}}>
									<Typography.Text>Please select preset from the table at the left.</Typography.Text>
								</Row>
								<Row justify="center" align="middle" style={{height: '60%'}}>
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
								</Row>
							</Col>
						</Col>
					</ListSelectS_Info>
				}
			</div>
		);
	};

	const prepareModalTitle = () => {

		const basePreset = currentPreset();
		if (!basePreset) return;
		if (!selectedOverride) return;

		const blueprint = convertBlueprintOverrideFormValuesToProto(selectedOverride.blueprint);
		const existingPreset = selectCustomPreset(store.getState(), presetName);

		let body = ''
		let update = false;

		if (existingPreset) {
			update = true
			const request = new UpdateRequest();
			request.setId(existingPreset.id);
			request.setOverride(new BlueprintOverrideValue().setOverride(blueprint));
			body = JSON.stringify(request.toObject())
		} else {
			const request = new CreateRequest();
			request.setName(presetName === '' ? `Custom Preset ${uuidv4().toString().substring(0, 6)}` : presetName);
			request.setBaseId(basePreset.id);
			request.setOverride(blueprint);
			body = JSON.stringify(request.toObject())
		}

		return (
			<Row>
				<Space>
					<Typography>Saving Custom Preset</Typography>
					<ApiSample info={update ? UpdateCustomPresetInfo : CreateCustomPresetInfo} body={body}/>
				</Space>
			</Row>
		);
	};

	return (
		<SelectPresetS>
			<Col>
				<Row justify="center">
					<Typography.Title>Step 3: select target preset</Typography.Title>
				</Row>
				<Space direction="vertical" size={10}>
					<Typography.Text>
						At this stage you can select preset for your video. Preset is set of predefined video parameters that can be applied to your
						video. You can either select one of predefined preset from the list below or adjust it to your needs. Your custom preset can
						be saved for later use.
					</Typography.Text>
					<SelectPresetS_Container>
						<Col style={{width: '100%', marginTop: '50px'}}>
							<Space style={{width: '100%'}} direction="vertical" size={30}>
								<Table
									rowSelection={{
										type: 'radio',
										selectedRowKeys: selectedBasePresetKeys,
										...rowSelection
									}}
									style={{minWidth: '15vw'}}
									columns={prepareColumns('Predefined Presets')}
									pagination={false}
									dataSource={prepareData(currentBasePresets)}
									onRow={(r) => ({
										onClick: () => {
											setSelectedBasePresetKeys([r.key]);
										}
									})}
								/>
								<Table
									rowSelection={{
										type: 'radio',
										selectedRowKeys: selectedCustomPresetKeys,
										...rowSelection
									}}
									columns={prepareColumns('Saved Custom Presets', true)}
									pagination={false}
									dataSource={prepareData(currentCustomPresets, true)}
									onRow={(r) => ({
										onClick: () => {
											setSelectedCustomPresetKeys([r.key]);
										}
									})}
								/>
							</Space>
						</Col>
						<Form
							form={form}
							layout="vertical"
							autoComplete="Off"
							onFinish={() => showModal()}
							onValuesChange={() => {
								let basePreset = currentPreset();
								if (!basePreset) return;

								if (basePreset.baseId != '') {
									basePreset = selectBasePresetById(basePreset.baseId)(store.getState());
								}
								if (!basePreset) return;

								const result = convertFormStateToFormValues(form.getFieldsValue(), basePreset);
								setSelectedOverride(result);
							}}
						>
							<Row justify="end" style={{marginBottom: '30px'}}>
								<Button disabled={!currentPreset()} icon={<SaveOutlined/>} htmlType="submit">Save Custom Preset</Button>
							</Row>
							{preparePresetPreview()}
						</Form>
					</SelectPresetS_Container>
				</Space>
			</Col>
			<Modal title={prepareModalTitle()} centered visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
			       okButtonProps={{htmlType: 'submit'}}>
				<Form
					layout="vertical"
					autoComplete="Off"
				>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Preset Name</Typography.Text>}
					           name={`presetName`}
					           tooltip="Input preset name"
					>
						<Input value={presetName} onChange={(val) => setPresetName(val.target.value)} placeholder="input preset name"/>
					</Form.Item>
				</Form>
			</Modal>
		</SelectPresetS>
	);
};
