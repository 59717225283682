// source: arranger/presets/models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var arranger_presets_enums_pb = require('../../arranger/presets/enums_pb.js');
goog.object.extend(proto, arranger_presets_enums_pb);
var scheduler_models_pb = require('../../scheduler/models_pb.js');
goog.object.extend(proto, scheduler_models_pb);
var scheduler_enums_pb = require('../../scheduler/enums_pb.js');
goog.object.extend(proto, scheduler_enums_pb);
goog.exportSymbol('proto.presets.api.v1.AudioOutput', null, global);
goog.exportSymbol('proto.presets.api.v1.AudioOutputOverride', null, global);
goog.exportSymbol('proto.presets.api.v1.AudioOutputsValue', null, global);
goog.exportSymbol('proto.presets.api.v1.Blueprint', null, global);
goog.exportSymbol('proto.presets.api.v1.BlueprintOverride', null, global);
goog.exportSymbol('proto.presets.api.v1.BlueprintOverrideValue', null, global);
goog.exportSymbol('proto.presets.api.v1.BlueprintValue', null, global);
goog.exportSymbol('proto.presets.api.v1.CategoryValue', null, global);
goog.exportSymbol('proto.presets.api.v1.ChannelLayoutProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.ColorSpaceProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.DisabledMappings', null, global);
goog.exportSymbol('proto.presets.api.v1.FileOutput', null, global);
goog.exportSymbol('proto.presets.api.v1.FileOutputOverride', null, global);
goog.exportSymbol('proto.presets.api.v1.FileOutputsValue', null, global);
goog.exportSymbol('proto.presets.api.v1.IntProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.NameValue', null, global);
goog.exportSymbol('proto.presets.api.v1.NodePoolNameValue', null, global);
goog.exportSymbol('proto.presets.api.v1.PassModeProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.PixelFormatProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.Preset', null, global);
goog.exportSymbol('proto.presets.api.v1.PropertyDetails', null, global);
goog.exportSymbol('proto.presets.api.v1.RationalProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.ResourcesProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.SampleFormatProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.StringProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.UintProperty', null, global);
goog.exportSymbol('proto.presets.api.v1.VideoOutput', null, global);
goog.exportSymbol('proto.presets.api.v1.VideoOutputOverride', null, global);
goog.exportSymbol('proto.presets.api.v1.VideoOutputsValue', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.Preset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.Preset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.Preset.displayName = 'proto.presets.api.v1.Preset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.NameValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.NameValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.NameValue.displayName = 'proto.presets.api.v1.NameValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.NodePoolNameValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.NodePoolNameValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.NodePoolNameValue.displayName = 'proto.presets.api.v1.NodePoolNameValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.CategoryValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.CategoryValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.CategoryValue.displayName = 'proto.presets.api.v1.CategoryValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.Blueprint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.Blueprint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.Blueprint.displayName = 'proto.presets.api.v1.Blueprint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.BlueprintValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.BlueprintValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.BlueprintValue.displayName = 'proto.presets.api.v1.BlueprintValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.FileOutputsValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.FileOutputsValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.FileOutputsValue.displayName = 'proto.presets.api.v1.FileOutputsValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.AudioOutputsValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.AudioOutputsValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.AudioOutputsValue.displayName = 'proto.presets.api.v1.AudioOutputsValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.VideoOutputsValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.VideoOutputsValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.VideoOutputsValue.displayName = 'proto.presets.api.v1.VideoOutputsValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.FileOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.presets.api.v1.FileOutput.repeatedFields_, null);
};
goog.inherits(proto.presets.api.v1.FileOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.FileOutput.displayName = 'proto.presets.api.v1.FileOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.AudioOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.AudioOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.AudioOutput.displayName = 'proto.presets.api.v1.AudioOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.VideoOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.VideoOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.VideoOutput.displayName = 'proto.presets.api.v1.VideoOutput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.PropertyDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.PropertyDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.PropertyDetails.displayName = 'proto.presets.api.v1.PropertyDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.IntProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.IntProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.IntProperty.displayName = 'proto.presets.api.v1.IntProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.UintProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.UintProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.UintProperty.displayName = 'proto.presets.api.v1.UintProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.StringProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.StringProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.StringProperty.displayName = 'proto.presets.api.v1.StringProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.PixelFormatProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.PixelFormatProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.PixelFormatProperty.displayName = 'proto.presets.api.v1.PixelFormatProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.RationalProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.RationalProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.RationalProperty.displayName = 'proto.presets.api.v1.RationalProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.ColorSpaceProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.ColorSpaceProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.ColorSpaceProperty.displayName = 'proto.presets.api.v1.ColorSpaceProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.PassModeProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.PassModeProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.PassModeProperty.displayName = 'proto.presets.api.v1.PassModeProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.ResourcesProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.ResourcesProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.ResourcesProperty.displayName = 'proto.presets.api.v1.ResourcesProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.ChannelLayoutProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.ChannelLayoutProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.ChannelLayoutProperty.displayName = 'proto.presets.api.v1.ChannelLayoutProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.SampleFormatProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.SampleFormatProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.SampleFormatProperty.displayName = 'proto.presets.api.v1.SampleFormatProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.BlueprintOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.BlueprintOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.BlueprintOverride.displayName = 'proto.presets.api.v1.BlueprintOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.DisabledMappings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.presets.api.v1.DisabledMappings.repeatedFields_, null);
};
goog.inherits(proto.presets.api.v1.DisabledMappings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.DisabledMappings.displayName = 'proto.presets.api.v1.DisabledMappings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.BlueprintOverrideValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.BlueprintOverrideValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.BlueprintOverrideValue.displayName = 'proto.presets.api.v1.BlueprintOverrideValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.FileOutputOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.FileOutputOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.FileOutputOverride.displayName = 'proto.presets.api.v1.FileOutputOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.AudioOutputOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.AudioOutputOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.AudioOutputOverride.displayName = 'proto.presets.api.v1.AudioOutputOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.api.v1.VideoOutputOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.api.v1.VideoOutputOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.api.v1.VideoOutputOverride.displayName = 'proto.presets.api.v1.VideoOutputOverride';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.Preset.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.Preset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.Preset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.Preset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baseId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nodePoolName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    category: jspb.Message.getFieldWithDefault(msg, 6, 0),
    blueprint: (f = msg.getBlueprint()) && proto.presets.api.v1.Blueprint.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.Preset}
 */
proto.presets.api.v1.Preset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.Preset;
  return proto.presets.api.v1.Preset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.Preset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.Preset}
 */
proto.presets.api.v1.Preset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodePoolName(value);
      break;
    case 6:
      var value = /** @type {!proto.presets.api.v1.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 7:
      var value = new proto.presets.api.v1.Blueprint;
      reader.readMessage(value,proto.presets.api.v1.Blueprint.deserializeBinaryFromReader);
      msg.setBlueprint(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.Preset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.Preset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.Preset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.Preset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaseId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNodePoolName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getBlueprint();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.presets.api.v1.Blueprint.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.presets.api.v1.Preset.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string base_id = 2;
 * @return {string}
 */
proto.presets.api.v1.Preset.prototype.getBaseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.setBaseId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner_id = 3;
 * @return {string}
 */
proto.presets.api.v1.Preset.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.presets.api.v1.Preset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string node_pool_name = 5;
 * @return {string}
 */
proto.presets.api.v1.Preset.prototype.getNodePoolName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.setNodePoolName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Category category = 6;
 * @return {!proto.presets.api.v1.Category}
 */
proto.presets.api.v1.Preset.prototype.getCategory = function() {
  return /** @type {!proto.presets.api.v1.Category} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.presets.api.v1.Category} value
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Blueprint blueprint = 7;
 * @return {?proto.presets.api.v1.Blueprint}
 */
proto.presets.api.v1.Preset.prototype.getBlueprint = function() {
  return /** @type{?proto.presets.api.v1.Blueprint} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.Blueprint, 7));
};


/**
 * @param {?proto.presets.api.v1.Blueprint|undefined} value
 * @return {!proto.presets.api.v1.Preset} returns this
*/
proto.presets.api.v1.Preset.prototype.setBlueprint = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.clearBlueprint = function() {
  return this.setBlueprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.Preset.prototype.hasBlueprint = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.presets.api.v1.Preset.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.presets.api.v1.Preset.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.presets.api.v1.Preset} returns this
 */
proto.presets.api.v1.Preset.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.NameValue.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.NameValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.NameValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.NameValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.NameValue}
 */
proto.presets.api.v1.NameValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.NameValue;
  return proto.presets.api.v1.NameValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.NameValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.NameValue}
 */
proto.presets.api.v1.NameValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.NameValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.NameValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.NameValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.NameValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.presets.api.v1.NameValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.NameValue} returns this
 */
proto.presets.api.v1.NameValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.NodePoolNameValue.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.NodePoolNameValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.NodePoolNameValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.NodePoolNameValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.NodePoolNameValue}
 */
proto.presets.api.v1.NodePoolNameValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.NodePoolNameValue;
  return proto.presets.api.v1.NodePoolNameValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.NodePoolNameValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.NodePoolNameValue}
 */
proto.presets.api.v1.NodePoolNameValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.NodePoolNameValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.NodePoolNameValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.NodePoolNameValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.NodePoolNameValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.presets.api.v1.NodePoolNameValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.NodePoolNameValue} returns this
 */
proto.presets.api.v1.NodePoolNameValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.CategoryValue.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.CategoryValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.CategoryValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.CategoryValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.CategoryValue}
 */
proto.presets.api.v1.CategoryValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.CategoryValue;
  return proto.presets.api.v1.CategoryValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.CategoryValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.CategoryValue}
 */
proto.presets.api.v1.CategoryValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.presets.api.v1.Category} */ (reader.readEnum());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.CategoryValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.CategoryValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.CategoryValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.CategoryValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Category value = 1;
 * @return {!proto.presets.api.v1.Category}
 */
proto.presets.api.v1.CategoryValue.prototype.getValue = function() {
  return /** @type {!proto.presets.api.v1.Category} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.presets.api.v1.Category} value
 * @return {!proto.presets.api.v1.CategoryValue} returns this
 */
proto.presets.api.v1.CategoryValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.Blueprint.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.Blueprint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.Blueprint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.Blueprint.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileOutputsMap: (f = msg.getFileOutputsMap()) ? f.toObject(includeInstance, proto.presets.api.v1.FileOutput.toObject) : [],
    audioOutputsMap: (f = msg.getAudioOutputsMap()) ? f.toObject(includeInstance, proto.presets.api.v1.AudioOutput.toObject) : [],
    videoOutputsMap: (f = msg.getVideoOutputsMap()) ? f.toObject(includeInstance, proto.presets.api.v1.VideoOutput.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.Blueprint}
 */
proto.presets.api.v1.Blueprint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.Blueprint;
  return proto.presets.api.v1.Blueprint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.Blueprint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.Blueprint}
 */
proto.presets.api.v1.Blueprint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getFileOutputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.FileOutput.deserializeBinaryFromReader, "", new proto.presets.api.v1.FileOutput());
         });
      break;
    case 2:
      var value = msg.getAudioOutputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.AudioOutput.deserializeBinaryFromReader, "", new proto.presets.api.v1.AudioOutput());
         });
      break;
    case 3:
      var value = msg.getVideoOutputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.VideoOutput.deserializeBinaryFromReader, "", new proto.presets.api.v1.VideoOutput());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.Blueprint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.Blueprint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.Blueprint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.Blueprint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileOutputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.FileOutput.serializeBinaryToWriter);
  }
  f = message.getAudioOutputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.AudioOutput.serializeBinaryToWriter);
  }
  f = message.getVideoOutputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.VideoOutput.serializeBinaryToWriter);
  }
};


/**
 * map<string, FileOutput> file_outputs = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.FileOutput>}
 */
proto.presets.api.v1.Blueprint.prototype.getFileOutputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.FileOutput>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.presets.api.v1.FileOutput));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.Blueprint} returns this
 */
proto.presets.api.v1.Blueprint.prototype.clearFileOutputsMap = function() {
  this.getFileOutputsMap().clear();
  return this;};


/**
 * map<string, AudioOutput> audio_outputs = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.AudioOutput>}
 */
proto.presets.api.v1.Blueprint.prototype.getAudioOutputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.AudioOutput>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.presets.api.v1.AudioOutput));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.Blueprint} returns this
 */
proto.presets.api.v1.Blueprint.prototype.clearAudioOutputsMap = function() {
  this.getAudioOutputsMap().clear();
  return this;};


/**
 * map<string, VideoOutput> video_outputs = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.VideoOutput>}
 */
proto.presets.api.v1.Blueprint.prototype.getVideoOutputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.VideoOutput>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.presets.api.v1.VideoOutput));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.Blueprint} returns this
 */
proto.presets.api.v1.Blueprint.prototype.clearVideoOutputsMap = function() {
  this.getVideoOutputsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.BlueprintValue.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.BlueprintValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.BlueprintValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.BlueprintValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.presets.api.v1.Blueprint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.BlueprintValue}
 */
proto.presets.api.v1.BlueprintValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.BlueprintValue;
  return proto.presets.api.v1.BlueprintValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.BlueprintValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.BlueprintValue}
 */
proto.presets.api.v1.BlueprintValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.presets.api.v1.Blueprint;
      reader.readMessage(value,proto.presets.api.v1.Blueprint.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.BlueprintValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.BlueprintValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.BlueprintValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.BlueprintValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.presets.api.v1.Blueprint.serializeBinaryToWriter
    );
  }
};


/**
 * optional Blueprint value = 1;
 * @return {?proto.presets.api.v1.Blueprint}
 */
proto.presets.api.v1.BlueprintValue.prototype.getValue = function() {
  return /** @type{?proto.presets.api.v1.Blueprint} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.Blueprint, 1));
};


/**
 * @param {?proto.presets.api.v1.Blueprint|undefined} value
 * @return {!proto.presets.api.v1.BlueprintValue} returns this
*/
proto.presets.api.v1.BlueprintValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.BlueprintValue} returns this
 */
proto.presets.api.v1.BlueprintValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.BlueprintValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.FileOutputsValue.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.FileOutputsValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.FileOutputsValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.FileOutputsValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueMap: (f = msg.getValueMap()) ? f.toObject(includeInstance, proto.presets.api.v1.FileOutput.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.FileOutputsValue}
 */
proto.presets.api.v1.FileOutputsValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.FileOutputsValue;
  return proto.presets.api.v1.FileOutputsValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.FileOutputsValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.FileOutputsValue}
 */
proto.presets.api.v1.FileOutputsValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getValueMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.FileOutput.deserializeBinaryFromReader, "", new proto.presets.api.v1.FileOutput());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.FileOutputsValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.FileOutputsValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.FileOutputsValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.FileOutputsValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.FileOutput.serializeBinaryToWriter);
  }
};


/**
 * map<string, FileOutput> value = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.FileOutput>}
 */
proto.presets.api.v1.FileOutputsValue.prototype.getValueMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.FileOutput>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.presets.api.v1.FileOutput));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.FileOutputsValue} returns this
 */
proto.presets.api.v1.FileOutputsValue.prototype.clearValueMap = function() {
  this.getValueMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.AudioOutputsValue.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.AudioOutputsValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.AudioOutputsValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.AudioOutputsValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueMap: (f = msg.getValueMap()) ? f.toObject(includeInstance, proto.presets.api.v1.AudioOutput.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.AudioOutputsValue}
 */
proto.presets.api.v1.AudioOutputsValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.AudioOutputsValue;
  return proto.presets.api.v1.AudioOutputsValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.AudioOutputsValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.AudioOutputsValue}
 */
proto.presets.api.v1.AudioOutputsValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getValueMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.AudioOutput.deserializeBinaryFromReader, "", new proto.presets.api.v1.AudioOutput());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.AudioOutputsValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.AudioOutputsValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.AudioOutputsValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.AudioOutputsValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.AudioOutput.serializeBinaryToWriter);
  }
};


/**
 * map<string, AudioOutput> value = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.AudioOutput>}
 */
proto.presets.api.v1.AudioOutputsValue.prototype.getValueMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.AudioOutput>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.presets.api.v1.AudioOutput));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.AudioOutputsValue} returns this
 */
proto.presets.api.v1.AudioOutputsValue.prototype.clearValueMap = function() {
  this.getValueMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.VideoOutputsValue.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.VideoOutputsValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.VideoOutputsValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.VideoOutputsValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueMap: (f = msg.getValueMap()) ? f.toObject(includeInstance, proto.presets.api.v1.VideoOutput.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.VideoOutputsValue}
 */
proto.presets.api.v1.VideoOutputsValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.VideoOutputsValue;
  return proto.presets.api.v1.VideoOutputsValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.VideoOutputsValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.VideoOutputsValue}
 */
proto.presets.api.v1.VideoOutputsValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getValueMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.VideoOutput.deserializeBinaryFromReader, "", new proto.presets.api.v1.VideoOutput());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.VideoOutputsValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.VideoOutputsValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.VideoOutputsValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.VideoOutputsValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.VideoOutput.serializeBinaryToWriter);
  }
};


/**
 * map<string, VideoOutput> value = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.VideoOutput>}
 */
proto.presets.api.v1.VideoOutputsValue.prototype.getValueMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.VideoOutput>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.presets.api.v1.VideoOutput));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.VideoOutputsValue} returns this
 */
proto.presets.api.v1.VideoOutputsValue.prototype.clearValueMap = function() {
  this.getValueMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.presets.api.v1.FileOutput.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.FileOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.FileOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.FileOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.FileOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    formatName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    videoAudioMapList: jspb.Message.toObjectList(msg.getVideoAudioMapList(),
    scheduler_models_pb.VideoAudioStreamMapping.toObject, includeInstance),
    privateDataMap: (f = msg.getPrivateDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.FileOutput}
 */
proto.presets.api.v1.FileOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.FileOutput;
  return proto.presets.api.v1.FileOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.FileOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.FileOutput}
 */
proto.presets.api.v1.FileOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormatName(value);
      break;
    case 2:
      var value = new scheduler_models_pb.VideoAudioStreamMapping;
      reader.readMessage(value,scheduler_models_pb.VideoAudioStreamMapping.deserializeBinaryFromReader);
      msg.addVideoAudioMap(value);
      break;
    case 99:
      var value = msg.getPrivateDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.FileOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.FileOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.FileOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.FileOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormatName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVideoAudioMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      scheduler_models_pb.VideoAudioStreamMapping.serializeBinaryToWriter
    );
  }
  f = message.getPrivateDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string format_name = 1;
 * @return {string}
 */
proto.presets.api.v1.FileOutput.prototype.getFormatName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.FileOutput} returns this
 */
proto.presets.api.v1.FileOutput.prototype.setFormatName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated scheduler.api.v1.VideoAudioStreamMapping video_audio_map = 2;
 * @return {!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>}
 */
proto.presets.api.v1.FileOutput.prototype.getVideoAudioMapList = function() {
  return /** @type{!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, scheduler_models_pb.VideoAudioStreamMapping, 2));
};


/**
 * @param {!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>} value
 * @return {!proto.presets.api.v1.FileOutput} returns this
*/
proto.presets.api.v1.FileOutput.prototype.setVideoAudioMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.scheduler.api.v1.VideoAudioStreamMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scheduler.api.v1.VideoAudioStreamMapping}
 */
proto.presets.api.v1.FileOutput.prototype.addVideoAudioMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.scheduler.api.v1.VideoAudioStreamMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.api.v1.FileOutput} returns this
 */
proto.presets.api.v1.FileOutput.prototype.clearVideoAudioMapList = function() {
  return this.setVideoAudioMapList([]);
};


/**
 * map<string, string> private_data = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.presets.api.v1.FileOutput.prototype.getPrivateDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.FileOutput} returns this
 */
proto.presets.api.v1.FileOutput.prototype.clearPrivateDataMap = function() {
  this.getPrivateDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.AudioOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.AudioOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.AudioOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.AudioOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    encoderName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bitRate: (f = msg.getBitRate()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    channelLayout: (f = msg.getChannelLayout()) && proto.presets.api.v1.ChannelLayoutProperty.toObject(includeInstance, f),
    sampleFormat: (f = msg.getSampleFormat()) && proto.presets.api.v1.SampleFormatProperty.toObject(includeInstance, f),
    channels: (f = msg.getChannels()) && proto.presets.api.v1.UintProperty.toObject(includeInstance, f),
    sampleRate: (f = msg.getSampleRate()) && proto.presets.api.v1.UintProperty.toObject(includeInstance, f),
    timeBase: (f = msg.getTimeBase()) && proto.presets.api.v1.RationalProperty.toObject(includeInstance, f),
    privateDataMap: (f = msg.getPrivateDataMap()) ? f.toObject(includeInstance, proto.presets.api.v1.StringProperty.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.AudioOutput}
 */
proto.presets.api.v1.AudioOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.AudioOutput;
  return proto.presets.api.v1.AudioOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.AudioOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.AudioOutput}
 */
proto.presets.api.v1.AudioOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncoderName(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setBitRate(value);
      break;
    case 3:
      var value = new proto.presets.api.v1.ChannelLayoutProperty;
      reader.readMessage(value,proto.presets.api.v1.ChannelLayoutProperty.deserializeBinaryFromReader);
      msg.setChannelLayout(value);
      break;
    case 4:
      var value = new proto.presets.api.v1.SampleFormatProperty;
      reader.readMessage(value,proto.presets.api.v1.SampleFormatProperty.deserializeBinaryFromReader);
      msg.setSampleFormat(value);
      break;
    case 5:
      var value = new proto.presets.api.v1.UintProperty;
      reader.readMessage(value,proto.presets.api.v1.UintProperty.deserializeBinaryFromReader);
      msg.setChannels(value);
      break;
    case 6:
      var value = new proto.presets.api.v1.UintProperty;
      reader.readMessage(value,proto.presets.api.v1.UintProperty.deserializeBinaryFromReader);
      msg.setSampleRate(value);
      break;
    case 7:
      var value = new proto.presets.api.v1.RationalProperty;
      reader.readMessage(value,proto.presets.api.v1.RationalProperty.deserializeBinaryFromReader);
      msg.setTimeBase(value);
      break;
    case 99:
      var value = msg.getPrivateDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.StringProperty.deserializeBinaryFromReader, "", new proto.presets.api.v1.StringProperty());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.AudioOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.AudioOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.AudioOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.AudioOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEncoderName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBitRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getChannelLayout();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.presets.api.v1.ChannelLayoutProperty.serializeBinaryToWriter
    );
  }
  f = message.getSampleFormat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.presets.api.v1.SampleFormatProperty.serializeBinaryToWriter
    );
  }
  f = message.getChannels();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.presets.api.v1.UintProperty.serializeBinaryToWriter
    );
  }
  f = message.getSampleRate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.presets.api.v1.UintProperty.serializeBinaryToWriter
    );
  }
  f = message.getTimeBase();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.presets.api.v1.RationalProperty.serializeBinaryToWriter
    );
  }
  f = message.getPrivateDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.StringProperty.serializeBinaryToWriter);
  }
};


/**
 * optional string encoder_name = 1;
 * @return {string}
 */
proto.presets.api.v1.AudioOutput.prototype.getEncoderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.AudioOutput} returns this
 */
proto.presets.api.v1.AudioOutput.prototype.setEncoderName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IntProperty bit_rate = 2;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.AudioOutput.prototype.getBitRate = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 2));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutput} returns this
*/
proto.presets.api.v1.AudioOutput.prototype.setBitRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutput} returns this
 */
proto.presets.api.v1.AudioOutput.prototype.clearBitRate = function() {
  return this.setBitRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutput.prototype.hasBitRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ChannelLayoutProperty channel_layout = 3;
 * @return {?proto.presets.api.v1.ChannelLayoutProperty}
 */
proto.presets.api.v1.AudioOutput.prototype.getChannelLayout = function() {
  return /** @type{?proto.presets.api.v1.ChannelLayoutProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.ChannelLayoutProperty, 3));
};


/**
 * @param {?proto.presets.api.v1.ChannelLayoutProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutput} returns this
*/
proto.presets.api.v1.AudioOutput.prototype.setChannelLayout = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutput} returns this
 */
proto.presets.api.v1.AudioOutput.prototype.clearChannelLayout = function() {
  return this.setChannelLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutput.prototype.hasChannelLayout = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SampleFormatProperty sample_format = 4;
 * @return {?proto.presets.api.v1.SampleFormatProperty}
 */
proto.presets.api.v1.AudioOutput.prototype.getSampleFormat = function() {
  return /** @type{?proto.presets.api.v1.SampleFormatProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.SampleFormatProperty, 4));
};


/**
 * @param {?proto.presets.api.v1.SampleFormatProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutput} returns this
*/
proto.presets.api.v1.AudioOutput.prototype.setSampleFormat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutput} returns this
 */
proto.presets.api.v1.AudioOutput.prototype.clearSampleFormat = function() {
  return this.setSampleFormat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutput.prototype.hasSampleFormat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UintProperty channels = 5;
 * @return {?proto.presets.api.v1.UintProperty}
 */
proto.presets.api.v1.AudioOutput.prototype.getChannels = function() {
  return /** @type{?proto.presets.api.v1.UintProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.UintProperty, 5));
};


/**
 * @param {?proto.presets.api.v1.UintProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutput} returns this
*/
proto.presets.api.v1.AudioOutput.prototype.setChannels = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutput} returns this
 */
proto.presets.api.v1.AudioOutput.prototype.clearChannels = function() {
  return this.setChannels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutput.prototype.hasChannels = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UintProperty sample_rate = 6;
 * @return {?proto.presets.api.v1.UintProperty}
 */
proto.presets.api.v1.AudioOutput.prototype.getSampleRate = function() {
  return /** @type{?proto.presets.api.v1.UintProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.UintProperty, 6));
};


/**
 * @param {?proto.presets.api.v1.UintProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutput} returns this
*/
proto.presets.api.v1.AudioOutput.prototype.setSampleRate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutput} returns this
 */
proto.presets.api.v1.AudioOutput.prototype.clearSampleRate = function() {
  return this.setSampleRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutput.prototype.hasSampleRate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RationalProperty time_base = 7;
 * @return {?proto.presets.api.v1.RationalProperty}
 */
proto.presets.api.v1.AudioOutput.prototype.getTimeBase = function() {
  return /** @type{?proto.presets.api.v1.RationalProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.RationalProperty, 7));
};


/**
 * @param {?proto.presets.api.v1.RationalProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutput} returns this
*/
proto.presets.api.v1.AudioOutput.prototype.setTimeBase = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutput} returns this
 */
proto.presets.api.v1.AudioOutput.prototype.clearTimeBase = function() {
  return this.setTimeBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutput.prototype.hasTimeBase = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * map<string, StringProperty> private_data = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.StringProperty>}
 */
proto.presets.api.v1.AudioOutput.prototype.getPrivateDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.StringProperty>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      proto.presets.api.v1.StringProperty));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.AudioOutput} returns this
 */
proto.presets.api.v1.AudioOutput.prototype.clearPrivateDataMap = function() {
  this.getPrivateDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.VideoOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.VideoOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.VideoOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.VideoOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
    encoderName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxGop: (f = msg.getMaxGop()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    width: (f = msg.getWidth()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    height: (f = msg.getHeight()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    bitRate: (f = msg.getBitRate()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    refs: (f = msg.getRefs()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    pixelFormat: (f = msg.getPixelFormat()) && proto.presets.api.v1.PixelFormatProperty.toObject(includeInstance, f),
    frameRate: (f = msg.getFrameRate()) && proto.presets.api.v1.RationalProperty.toObject(includeInstance, f),
    timeBase: (f = msg.getTimeBase()) && proto.presets.api.v1.RationalProperty.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.presets.api.v1.StringProperty.toObject(includeInstance, f),
    level: (f = msg.getLevel()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    colorSpace: (f = msg.getColorSpace()) && proto.presets.api.v1.ColorSpaceProperty.toObject(includeInstance, f),
    passMode: (f = msg.getPassMode()) && proto.presets.api.v1.PassModeProperty.toObject(includeInstance, f),
    rcMaxBitrate: (f = msg.getRcMaxBitrate()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    rcMinBitrate: (f = msg.getRcMinBitrate()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    resources: (f = msg.getResources()) && proto.presets.api.v1.ResourcesProperty.toObject(includeInstance, f),
    bufSize: (f = msg.getBufSize()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    maxBFrames: (f = msg.getMaxBFrames()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    keyIntMin: (f = msg.getKeyIntMin()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    privateDataMap: (f = msg.getPrivateDataMap()) ? f.toObject(includeInstance, proto.presets.api.v1.StringProperty.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.VideoOutput}
 */
proto.presets.api.v1.VideoOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.VideoOutput;
  return proto.presets.api.v1.VideoOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.VideoOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.VideoOutput}
 */
proto.presets.api.v1.VideoOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncoderName(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setMaxGop(value);
      break;
    case 3:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setWidth(value);
      break;
    case 4:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setHeight(value);
      break;
    case 5:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setBitRate(value);
      break;
    case 6:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setRefs(value);
      break;
    case 7:
      var value = new proto.presets.api.v1.PixelFormatProperty;
      reader.readMessage(value,proto.presets.api.v1.PixelFormatProperty.deserializeBinaryFromReader);
      msg.setPixelFormat(value);
      break;
    case 8:
      var value = new proto.presets.api.v1.RationalProperty;
      reader.readMessage(value,proto.presets.api.v1.RationalProperty.deserializeBinaryFromReader);
      msg.setFrameRate(value);
      break;
    case 9:
      var value = new proto.presets.api.v1.RationalProperty;
      reader.readMessage(value,proto.presets.api.v1.RationalProperty.deserializeBinaryFromReader);
      msg.setTimeBase(value);
      break;
    case 10:
      var value = new proto.presets.api.v1.StringProperty;
      reader.readMessage(value,proto.presets.api.v1.StringProperty.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 11:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    case 12:
      var value = new proto.presets.api.v1.ColorSpaceProperty;
      reader.readMessage(value,proto.presets.api.v1.ColorSpaceProperty.deserializeBinaryFromReader);
      msg.setColorSpace(value);
      break;
    case 13:
      var value = new proto.presets.api.v1.PassModeProperty;
      reader.readMessage(value,proto.presets.api.v1.PassModeProperty.deserializeBinaryFromReader);
      msg.setPassMode(value);
      break;
    case 14:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setRcMaxBitrate(value);
      break;
    case 15:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setRcMinBitrate(value);
      break;
    case 16:
      var value = new proto.presets.api.v1.ResourcesProperty;
      reader.readMessage(value,proto.presets.api.v1.ResourcesProperty.deserializeBinaryFromReader);
      msg.setResources(value);
      break;
    case 17:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setBufSize(value);
      break;
    case 18:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setMaxBFrames(value);
      break;
    case 19:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setKeyIntMin(value);
      break;
    case 99:
      var value = msg.getPrivateDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.StringProperty.deserializeBinaryFromReader, "", new proto.presets.api.v1.StringProperty());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.VideoOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.VideoOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.VideoOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.VideoOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEncoderName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaxGop();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getHeight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getBitRate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getRefs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getPixelFormat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.presets.api.v1.PixelFormatProperty.serializeBinaryToWriter
    );
  }
  f = message.getFrameRate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.presets.api.v1.RationalProperty.serializeBinaryToWriter
    );
  }
  f = message.getTimeBase();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.presets.api.v1.RationalProperty.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.presets.api.v1.StringProperty.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getColorSpace();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.presets.api.v1.ColorSpaceProperty.serializeBinaryToWriter
    );
  }
  f = message.getPassMode();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.presets.api.v1.PassModeProperty.serializeBinaryToWriter
    );
  }
  f = message.getRcMaxBitrate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getRcMinBitrate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getResources();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.presets.api.v1.ResourcesProperty.serializeBinaryToWriter
    );
  }
  f = message.getBufSize();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getMaxBFrames();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getKeyIntMin();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getPrivateDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.StringProperty.serializeBinaryToWriter);
  }
};


/**
 * optional string encoder_name = 1;
 * @return {string}
 */
proto.presets.api.v1.VideoOutput.prototype.getEncoderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.setEncoderName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IntProperty max_gop = 2;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getMaxGop = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 2));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setMaxGop = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearMaxGop = function() {
  return this.setMaxGop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasMaxGop = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IntProperty width = 3;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getWidth = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 3));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setWidth = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearWidth = function() {
  return this.setWidth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasWidth = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IntProperty height = 4;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getHeight = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 4));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setHeight = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearHeight = function() {
  return this.setHeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional IntProperty bit_rate = 5;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getBitRate = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 5));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setBitRate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearBitRate = function() {
  return this.setBitRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasBitRate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional IntProperty refs = 6;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getRefs = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 6));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setRefs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearRefs = function() {
  return this.setRefs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasRefs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PixelFormatProperty pixel_format = 7;
 * @return {?proto.presets.api.v1.PixelFormatProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getPixelFormat = function() {
  return /** @type{?proto.presets.api.v1.PixelFormatProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PixelFormatProperty, 7));
};


/**
 * @param {?proto.presets.api.v1.PixelFormatProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setPixelFormat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearPixelFormat = function() {
  return this.setPixelFormat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasPixelFormat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RationalProperty frame_rate = 8;
 * @return {?proto.presets.api.v1.RationalProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getFrameRate = function() {
  return /** @type{?proto.presets.api.v1.RationalProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.RationalProperty, 8));
};


/**
 * @param {?proto.presets.api.v1.RationalProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setFrameRate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearFrameRate = function() {
  return this.setFrameRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasFrameRate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional RationalProperty time_base = 9;
 * @return {?proto.presets.api.v1.RationalProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getTimeBase = function() {
  return /** @type{?proto.presets.api.v1.RationalProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.RationalProperty, 9));
};


/**
 * @param {?proto.presets.api.v1.RationalProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setTimeBase = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearTimeBase = function() {
  return this.setTimeBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasTimeBase = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional StringProperty filter = 10;
 * @return {?proto.presets.api.v1.StringProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getFilter = function() {
  return /** @type{?proto.presets.api.v1.StringProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.StringProperty, 10));
};


/**
 * @param {?proto.presets.api.v1.StringProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional IntProperty level = 11;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getLevel = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 11));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ColorSpaceProperty color_space = 12;
 * @return {?proto.presets.api.v1.ColorSpaceProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getColorSpace = function() {
  return /** @type{?proto.presets.api.v1.ColorSpaceProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.ColorSpaceProperty, 12));
};


/**
 * @param {?proto.presets.api.v1.ColorSpaceProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setColorSpace = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearColorSpace = function() {
  return this.setColorSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasColorSpace = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PassModeProperty pass_mode = 13;
 * @return {?proto.presets.api.v1.PassModeProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getPassMode = function() {
  return /** @type{?proto.presets.api.v1.PassModeProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PassModeProperty, 13));
};


/**
 * @param {?proto.presets.api.v1.PassModeProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setPassMode = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearPassMode = function() {
  return this.setPassMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasPassMode = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional IntProperty rc_max_bitrate = 14;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getRcMaxBitrate = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 14));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setRcMaxBitrate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearRcMaxBitrate = function() {
  return this.setRcMaxBitrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasRcMaxBitrate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional IntProperty rc_min_bitrate = 15;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getRcMinBitrate = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 15));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setRcMinBitrate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearRcMinBitrate = function() {
  return this.setRcMinBitrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasRcMinBitrate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ResourcesProperty resources = 16;
 * @return {?proto.presets.api.v1.ResourcesProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getResources = function() {
  return /** @type{?proto.presets.api.v1.ResourcesProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.ResourcesProperty, 16));
};


/**
 * @param {?proto.presets.api.v1.ResourcesProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setResources = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearResources = function() {
  return this.setResources(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasResources = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional IntProperty buf_size = 17;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getBufSize = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 17));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setBufSize = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearBufSize = function() {
  return this.setBufSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasBufSize = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional IntProperty max_b_frames = 18;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getMaxBFrames = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 18));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setMaxBFrames = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearMaxBFrames = function() {
  return this.setMaxBFrames(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasMaxBFrames = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional IntProperty key_int_min = 19;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutput.prototype.getKeyIntMin = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 19));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutput} returns this
*/
proto.presets.api.v1.VideoOutput.prototype.setKeyIntMin = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearKeyIntMin = function() {
  return this.setKeyIntMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutput.prototype.hasKeyIntMin = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * map<string, StringProperty> private_data = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.StringProperty>}
 */
proto.presets.api.v1.VideoOutput.prototype.getPrivateDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.StringProperty>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      proto.presets.api.v1.StringProperty));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.VideoOutput} returns this
 */
proto.presets.api.v1.VideoOutput.prototype.clearPrivateDataMap = function() {
  this.getPrivateDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.PropertyDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.PropertyDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.PropertyDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.PropertyDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    required: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    inputType: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.PropertyDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.PropertyDetails;
  return proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.PropertyDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.PropertyDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.PropertyDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getInputType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool required = 1;
 * @return {boolean}
 */
proto.presets.api.v1.PropertyDetails.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.presets.api.v1.PropertyDetails} returns this
 */
proto.presets.api.v1.PropertyDetails.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool editable = 2;
 * @return {boolean}
 */
proto.presets.api.v1.PropertyDetails.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.presets.api.v1.PropertyDetails} returns this
 */
proto.presets.api.v1.PropertyDetails.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool hidden = 3;
 * @return {boolean}
 */
proto.presets.api.v1.PropertyDetails.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.presets.api.v1.PropertyDetails} returns this
 */
proto.presets.api.v1.PropertyDetails.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string input_type = 4;
 * @return {string}
 */
proto.presets.api.v1.PropertyDetails.prototype.getInputType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.PropertyDetails} returns this
 */
proto.presets.api.v1.PropertyDetails.prototype.setInputType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.IntProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.IntProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.IntProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.IntProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.IntProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.IntProperty;
  return proto.presets.api.v1.IntProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.IntProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.IntProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.IntProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.IntProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.IntProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.IntProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.presets.api.v1.IntProperty.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.presets.api.v1.IntProperty} returns this
 */
proto.presets.api.v1.IntProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.IntProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.IntProperty} returns this
*/
proto.presets.api.v1.IntProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.IntProperty} returns this
 */
proto.presets.api.v1.IntProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.IntProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.UintProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.UintProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.UintProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.UintProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.UintProperty}
 */
proto.presets.api.v1.UintProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.UintProperty;
  return proto.presets.api.v1.UintProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.UintProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.UintProperty}
 */
proto.presets.api.v1.UintProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.UintProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.UintProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.UintProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.UintProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 value = 1;
 * @return {number}
 */
proto.presets.api.v1.UintProperty.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.presets.api.v1.UintProperty} returns this
 */
proto.presets.api.v1.UintProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.UintProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.UintProperty} returns this
*/
proto.presets.api.v1.UintProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.UintProperty} returns this
 */
proto.presets.api.v1.UintProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.UintProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.StringProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.StringProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.StringProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.StringProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.StringProperty}
 */
proto.presets.api.v1.StringProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.StringProperty;
  return proto.presets.api.v1.StringProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.StringProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.StringProperty}
 */
proto.presets.api.v1.StringProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.StringProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.StringProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.StringProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.StringProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.presets.api.v1.StringProperty.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.StringProperty} returns this
 */
proto.presets.api.v1.StringProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.StringProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.StringProperty} returns this
*/
proto.presets.api.v1.StringProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.StringProperty} returns this
 */
proto.presets.api.v1.StringProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.StringProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.PixelFormatProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.PixelFormatProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.PixelFormatProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.PixelFormatProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.PixelFormatProperty}
 */
proto.presets.api.v1.PixelFormatProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.PixelFormatProperty;
  return proto.presets.api.v1.PixelFormatProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.PixelFormatProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.PixelFormatProperty}
 */
proto.presets.api.v1.PixelFormatProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.scheduler.api.v1.PixelFormat} */ (reader.readEnum());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.PixelFormatProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.PixelFormatProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.PixelFormatProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.PixelFormatProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional scheduler.api.v1.PixelFormat value = 1;
 * @return {!proto.scheduler.api.v1.PixelFormat}
 */
proto.presets.api.v1.PixelFormatProperty.prototype.getValue = function() {
  return /** @type {!proto.scheduler.api.v1.PixelFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.scheduler.api.v1.PixelFormat} value
 * @return {!proto.presets.api.v1.PixelFormatProperty} returns this
 */
proto.presets.api.v1.PixelFormatProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.PixelFormatProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.PixelFormatProperty} returns this
*/
proto.presets.api.v1.PixelFormatProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.PixelFormatProperty} returns this
 */
proto.presets.api.v1.PixelFormatProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.PixelFormatProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.RationalProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.RationalProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.RationalProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.RationalProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && scheduler_models_pb.Rational.toObject(includeInstance, f),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.RationalProperty}
 */
proto.presets.api.v1.RationalProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.RationalProperty;
  return proto.presets.api.v1.RationalProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.RationalProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.RationalProperty}
 */
proto.presets.api.v1.RationalProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scheduler_models_pb.Rational;
      reader.readMessage(value,scheduler_models_pb.Rational.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.RationalProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.RationalProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.RationalProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.RationalProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scheduler_models_pb.Rational.serializeBinaryToWriter
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional scheduler.api.v1.Rational value = 1;
 * @return {?proto.scheduler.api.v1.Rational}
 */
proto.presets.api.v1.RationalProperty.prototype.getValue = function() {
  return /** @type{?proto.scheduler.api.v1.Rational} */ (
    jspb.Message.getWrapperField(this, scheduler_models_pb.Rational, 1));
};


/**
 * @param {?proto.scheduler.api.v1.Rational|undefined} value
 * @return {!proto.presets.api.v1.RationalProperty} returns this
*/
proto.presets.api.v1.RationalProperty.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.RationalProperty} returns this
 */
proto.presets.api.v1.RationalProperty.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.RationalProperty.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.RationalProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.RationalProperty} returns this
*/
proto.presets.api.v1.RationalProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.RationalProperty} returns this
 */
proto.presets.api.v1.RationalProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.RationalProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.ColorSpaceProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.ColorSpaceProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.ColorSpaceProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.ColorSpaceProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.ColorSpaceProperty}
 */
proto.presets.api.v1.ColorSpaceProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.ColorSpaceProperty;
  return proto.presets.api.v1.ColorSpaceProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.ColorSpaceProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.ColorSpaceProperty}
 */
proto.presets.api.v1.ColorSpaceProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.scheduler.api.v1.ColorSpace} */ (reader.readEnum());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.ColorSpaceProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.ColorSpaceProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.ColorSpaceProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.ColorSpaceProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional scheduler.api.v1.ColorSpace value = 1;
 * @return {!proto.scheduler.api.v1.ColorSpace}
 */
proto.presets.api.v1.ColorSpaceProperty.prototype.getValue = function() {
  return /** @type {!proto.scheduler.api.v1.ColorSpace} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.scheduler.api.v1.ColorSpace} value
 * @return {!proto.presets.api.v1.ColorSpaceProperty} returns this
 */
proto.presets.api.v1.ColorSpaceProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.ColorSpaceProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.ColorSpaceProperty} returns this
*/
proto.presets.api.v1.ColorSpaceProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.ColorSpaceProperty} returns this
 */
proto.presets.api.v1.ColorSpaceProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.ColorSpaceProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.PassModeProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.PassModeProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.PassModeProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.PassModeProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.PassModeProperty}
 */
proto.presets.api.v1.PassModeProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.PassModeProperty;
  return proto.presets.api.v1.PassModeProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.PassModeProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.PassModeProperty}
 */
proto.presets.api.v1.PassModeProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.scheduler.api.v1.PassMode} */ (reader.readEnum());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.PassModeProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.PassModeProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.PassModeProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.PassModeProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional scheduler.api.v1.PassMode value = 1;
 * @return {!proto.scheduler.api.v1.PassMode}
 */
proto.presets.api.v1.PassModeProperty.prototype.getValue = function() {
  return /** @type {!proto.scheduler.api.v1.PassMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.scheduler.api.v1.PassMode} value
 * @return {!proto.presets.api.v1.PassModeProperty} returns this
 */
proto.presets.api.v1.PassModeProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.PassModeProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.PassModeProperty} returns this
*/
proto.presets.api.v1.PassModeProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.PassModeProperty} returns this
 */
proto.presets.api.v1.PassModeProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.PassModeProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.ResourcesProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.ResourcesProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.ResourcesProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.ResourcesProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && scheduler_models_pb.Resources.toObject(includeInstance, f),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.ResourcesProperty}
 */
proto.presets.api.v1.ResourcesProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.ResourcesProperty;
  return proto.presets.api.v1.ResourcesProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.ResourcesProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.ResourcesProperty}
 */
proto.presets.api.v1.ResourcesProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scheduler_models_pb.Resources;
      reader.readMessage(value,scheduler_models_pb.Resources.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.ResourcesProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.ResourcesProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.ResourcesProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.ResourcesProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scheduler_models_pb.Resources.serializeBinaryToWriter
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional scheduler.api.v1.Resources value = 1;
 * @return {?proto.scheduler.api.v1.Resources}
 */
proto.presets.api.v1.ResourcesProperty.prototype.getValue = function() {
  return /** @type{?proto.scheduler.api.v1.Resources} */ (
    jspb.Message.getWrapperField(this, scheduler_models_pb.Resources, 1));
};


/**
 * @param {?proto.scheduler.api.v1.Resources|undefined} value
 * @return {!proto.presets.api.v1.ResourcesProperty} returns this
*/
proto.presets.api.v1.ResourcesProperty.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.ResourcesProperty} returns this
 */
proto.presets.api.v1.ResourcesProperty.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.ResourcesProperty.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.ResourcesProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.ResourcesProperty} returns this
*/
proto.presets.api.v1.ResourcesProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.ResourcesProperty} returns this
 */
proto.presets.api.v1.ResourcesProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.ResourcesProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.ChannelLayoutProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.ChannelLayoutProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.ChannelLayoutProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.ChannelLayoutProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.ChannelLayoutProperty}
 */
proto.presets.api.v1.ChannelLayoutProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.ChannelLayoutProperty;
  return proto.presets.api.v1.ChannelLayoutProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.ChannelLayoutProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.ChannelLayoutProperty}
 */
proto.presets.api.v1.ChannelLayoutProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.scheduler.api.v1.ChannelLayout} */ (reader.readEnum());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.ChannelLayoutProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.ChannelLayoutProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.ChannelLayoutProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.ChannelLayoutProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional scheduler.api.v1.ChannelLayout value = 1;
 * @return {!proto.scheduler.api.v1.ChannelLayout}
 */
proto.presets.api.v1.ChannelLayoutProperty.prototype.getValue = function() {
  return /** @type {!proto.scheduler.api.v1.ChannelLayout} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.scheduler.api.v1.ChannelLayout} value
 * @return {!proto.presets.api.v1.ChannelLayoutProperty} returns this
 */
proto.presets.api.v1.ChannelLayoutProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.ChannelLayoutProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.ChannelLayoutProperty} returns this
*/
proto.presets.api.v1.ChannelLayoutProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.ChannelLayoutProperty} returns this
 */
proto.presets.api.v1.ChannelLayoutProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.ChannelLayoutProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.SampleFormatProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.SampleFormatProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.SampleFormatProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.SampleFormatProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    details: (f = msg.getDetails()) && proto.presets.api.v1.PropertyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.SampleFormatProperty}
 */
proto.presets.api.v1.SampleFormatProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.SampleFormatProperty;
  return proto.presets.api.v1.SampleFormatProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.SampleFormatProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.SampleFormatProperty}
 */
proto.presets.api.v1.SampleFormatProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.scheduler.api.v1.SampleFormat} */ (reader.readEnum());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.PropertyDetails;
      reader.readMessage(value,proto.presets.api.v1.PropertyDetails.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.SampleFormatProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.SampleFormatProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.SampleFormatProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.SampleFormatProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.PropertyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional scheduler.api.v1.SampleFormat value = 1;
 * @return {!proto.scheduler.api.v1.SampleFormat}
 */
proto.presets.api.v1.SampleFormatProperty.prototype.getValue = function() {
  return /** @type {!proto.scheduler.api.v1.SampleFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.scheduler.api.v1.SampleFormat} value
 * @return {!proto.presets.api.v1.SampleFormatProperty} returns this
 */
proto.presets.api.v1.SampleFormatProperty.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PropertyDetails details = 2;
 * @return {?proto.presets.api.v1.PropertyDetails}
 */
proto.presets.api.v1.SampleFormatProperty.prototype.getDetails = function() {
  return /** @type{?proto.presets.api.v1.PropertyDetails} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PropertyDetails, 2));
};


/**
 * @param {?proto.presets.api.v1.PropertyDetails|undefined} value
 * @return {!proto.presets.api.v1.SampleFormatProperty} returns this
*/
proto.presets.api.v1.SampleFormatProperty.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.SampleFormatProperty} returns this
 */
proto.presets.api.v1.SampleFormatProperty.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.SampleFormatProperty.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.BlueprintOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.BlueprintOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.BlueprintOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.BlueprintOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileOutputsMap: (f = msg.getFileOutputsMap()) ? f.toObject(includeInstance, proto.presets.api.v1.FileOutputOverride.toObject) : [],
    audioOutputsMap: (f = msg.getAudioOutputsMap()) ? f.toObject(includeInstance, proto.presets.api.v1.AudioOutputOverride.toObject) : [],
    videoOutputsMap: (f = msg.getVideoOutputsMap()) ? f.toObject(includeInstance, proto.presets.api.v1.VideoOutputOverride.toObject) : [],
    disabledMap: (f = msg.getDisabledMap()) ? f.toObject(includeInstance, proto.presets.api.v1.DisabledMappings.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.BlueprintOverride}
 */
proto.presets.api.v1.BlueprintOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.BlueprintOverride;
  return proto.presets.api.v1.BlueprintOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.BlueprintOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.BlueprintOverride}
 */
proto.presets.api.v1.BlueprintOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getFileOutputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.FileOutputOverride.deserializeBinaryFromReader, "", new proto.presets.api.v1.FileOutputOverride());
         });
      break;
    case 2:
      var value = msg.getAudioOutputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.AudioOutputOverride.deserializeBinaryFromReader, "", new proto.presets.api.v1.AudioOutputOverride());
         });
      break;
    case 3:
      var value = msg.getVideoOutputsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.VideoOutputOverride.deserializeBinaryFromReader, "", new proto.presets.api.v1.VideoOutputOverride());
         });
      break;
    case 4:
      var value = msg.getDisabledMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.DisabledMappings.deserializeBinaryFromReader, "", new proto.presets.api.v1.DisabledMappings());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.BlueprintOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.BlueprintOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.BlueprintOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.BlueprintOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileOutputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.FileOutputOverride.serializeBinaryToWriter);
  }
  f = message.getAudioOutputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.AudioOutputOverride.serializeBinaryToWriter);
  }
  f = message.getVideoOutputsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.VideoOutputOverride.serializeBinaryToWriter);
  }
  f = message.getDisabledMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.DisabledMappings.serializeBinaryToWriter);
  }
};


/**
 * map<string, FileOutputOverride> file_outputs = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.FileOutputOverride>}
 */
proto.presets.api.v1.BlueprintOverride.prototype.getFileOutputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.FileOutputOverride>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.presets.api.v1.FileOutputOverride));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.BlueprintOverride} returns this
 */
proto.presets.api.v1.BlueprintOverride.prototype.clearFileOutputsMap = function() {
  this.getFileOutputsMap().clear();
  return this;};


/**
 * map<string, AudioOutputOverride> audio_outputs = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.AudioOutputOverride>}
 */
proto.presets.api.v1.BlueprintOverride.prototype.getAudioOutputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.AudioOutputOverride>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.presets.api.v1.AudioOutputOverride));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.BlueprintOverride} returns this
 */
proto.presets.api.v1.BlueprintOverride.prototype.clearAudioOutputsMap = function() {
  this.getAudioOutputsMap().clear();
  return this;};


/**
 * map<string, VideoOutputOverride> video_outputs = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.VideoOutputOverride>}
 */
proto.presets.api.v1.BlueprintOverride.prototype.getVideoOutputsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.VideoOutputOverride>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.presets.api.v1.VideoOutputOverride));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.BlueprintOverride} returns this
 */
proto.presets.api.v1.BlueprintOverride.prototype.clearVideoOutputsMap = function() {
  this.getVideoOutputsMap().clear();
  return this;};


/**
 * map<string, DisabledMappings> disabled = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.DisabledMappings>}
 */
proto.presets.api.v1.BlueprintOverride.prototype.getDisabledMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.DisabledMappings>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.presets.api.v1.DisabledMappings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.BlueprintOverride} returns this
 */
proto.presets.api.v1.BlueprintOverride.prototype.clearDisabledMap = function() {
  this.getDisabledMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.presets.api.v1.DisabledMappings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.DisabledMappings.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.DisabledMappings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.DisabledMappings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.DisabledMappings.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    scheduler_models_pb.VideoAudioStreamMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.DisabledMappings}
 */
proto.presets.api.v1.DisabledMappings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.DisabledMappings;
  return proto.presets.api.v1.DisabledMappings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.DisabledMappings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.DisabledMappings}
 */
proto.presets.api.v1.DisabledMappings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scheduler_models_pb.VideoAudioStreamMapping;
      reader.readMessage(value,scheduler_models_pb.VideoAudioStreamMapping.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.DisabledMappings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.DisabledMappings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.DisabledMappings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.DisabledMappings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      scheduler_models_pb.VideoAudioStreamMapping.serializeBinaryToWriter
    );
  }
};


/**
 * repeated scheduler.api.v1.VideoAudioStreamMapping values = 1;
 * @return {!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>}
 */
proto.presets.api.v1.DisabledMappings.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, scheduler_models_pb.VideoAudioStreamMapping, 1));
};


/**
 * @param {!Array<!proto.scheduler.api.v1.VideoAudioStreamMapping>} value
 * @return {!proto.presets.api.v1.DisabledMappings} returns this
*/
proto.presets.api.v1.DisabledMappings.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scheduler.api.v1.VideoAudioStreamMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scheduler.api.v1.VideoAudioStreamMapping}
 */
proto.presets.api.v1.DisabledMappings.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scheduler.api.v1.VideoAudioStreamMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.api.v1.DisabledMappings} returns this
 */
proto.presets.api.v1.DisabledMappings.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.BlueprintOverrideValue.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.BlueprintOverrideValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.BlueprintOverrideValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.BlueprintOverrideValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    override: (f = msg.getOverride()) && proto.presets.api.v1.BlueprintOverride.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.BlueprintOverrideValue}
 */
proto.presets.api.v1.BlueprintOverrideValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.BlueprintOverrideValue;
  return proto.presets.api.v1.BlueprintOverrideValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.BlueprintOverrideValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.BlueprintOverrideValue}
 */
proto.presets.api.v1.BlueprintOverrideValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.presets.api.v1.BlueprintOverride;
      reader.readMessage(value,proto.presets.api.v1.BlueprintOverride.deserializeBinaryFromReader);
      msg.setOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.BlueprintOverrideValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.BlueprintOverrideValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.BlueprintOverrideValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.BlueprintOverrideValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverride();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.presets.api.v1.BlueprintOverride.serializeBinaryToWriter
    );
  }
};


/**
 * optional BlueprintOverride override = 1;
 * @return {?proto.presets.api.v1.BlueprintOverride}
 */
proto.presets.api.v1.BlueprintOverrideValue.prototype.getOverride = function() {
  return /** @type{?proto.presets.api.v1.BlueprintOverride} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.BlueprintOverride, 1));
};


/**
 * @param {?proto.presets.api.v1.BlueprintOverride|undefined} value
 * @return {!proto.presets.api.v1.BlueprintOverrideValue} returns this
*/
proto.presets.api.v1.BlueprintOverrideValue.prototype.setOverride = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.BlueprintOverrideValue} returns this
 */
proto.presets.api.v1.BlueprintOverrideValue.prototype.clearOverride = function() {
  return this.setOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.BlueprintOverrideValue.prototype.hasOverride = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.FileOutputOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.FileOutputOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.FileOutputOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.FileOutputOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    privateDataMap: (f = msg.getPrivateDataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.FileOutputOverride}
 */
proto.presets.api.v1.FileOutputOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.FileOutputOverride;
  return proto.presets.api.v1.FileOutputOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.FileOutputOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.FileOutputOverride}
 */
proto.presets.api.v1.FileOutputOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 99:
      var value = msg.getPrivateDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.FileOutputOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.FileOutputOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.FileOutputOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.FileOutputOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrivateDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional bool disabled = 1;
 * @return {boolean}
 */
proto.presets.api.v1.FileOutputOverride.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.presets.api.v1.FileOutputOverride} returns this
 */
proto.presets.api.v1.FileOutputOverride.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.presets.api.v1.FileOutputOverride.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.api.v1.FileOutputOverride} returns this
 */
proto.presets.api.v1.FileOutputOverride.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> private_data = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.presets.api.v1.FileOutputOverride.prototype.getPrivateDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.FileOutputOverride} returns this
 */
proto.presets.api.v1.FileOutputOverride.prototype.clearPrivateDataMap = function() {
  this.getPrivateDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.AudioOutputOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.AudioOutputOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.AudioOutputOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    bitRate: (f = msg.getBitRate()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    channelLayout: (f = msg.getChannelLayout()) && proto.presets.api.v1.ChannelLayoutProperty.toObject(includeInstance, f),
    sampleFormat: (f = msg.getSampleFormat()) && proto.presets.api.v1.SampleFormatProperty.toObject(includeInstance, f),
    channels: (f = msg.getChannels()) && proto.presets.api.v1.UintProperty.toObject(includeInstance, f),
    sampleRate: (f = msg.getSampleRate()) && proto.presets.api.v1.UintProperty.toObject(includeInstance, f),
    timeBase: (f = msg.getTimeBase()) && proto.presets.api.v1.RationalProperty.toObject(includeInstance, f),
    privateDataMap: (f = msg.getPrivateDataMap()) ? f.toObject(includeInstance, proto.presets.api.v1.StringProperty.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.AudioOutputOverride}
 */
proto.presets.api.v1.AudioOutputOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.AudioOutputOverride;
  return proto.presets.api.v1.AudioOutputOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.AudioOutputOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.AudioOutputOverride}
 */
proto.presets.api.v1.AudioOutputOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setBitRate(value);
      break;
    case 3:
      var value = new proto.presets.api.v1.ChannelLayoutProperty;
      reader.readMessage(value,proto.presets.api.v1.ChannelLayoutProperty.deserializeBinaryFromReader);
      msg.setChannelLayout(value);
      break;
    case 4:
      var value = new proto.presets.api.v1.SampleFormatProperty;
      reader.readMessage(value,proto.presets.api.v1.SampleFormatProperty.deserializeBinaryFromReader);
      msg.setSampleFormat(value);
      break;
    case 5:
      var value = new proto.presets.api.v1.UintProperty;
      reader.readMessage(value,proto.presets.api.v1.UintProperty.deserializeBinaryFromReader);
      msg.setChannels(value);
      break;
    case 6:
      var value = new proto.presets.api.v1.UintProperty;
      reader.readMessage(value,proto.presets.api.v1.UintProperty.deserializeBinaryFromReader);
      msg.setSampleRate(value);
      break;
    case 7:
      var value = new proto.presets.api.v1.RationalProperty;
      reader.readMessage(value,proto.presets.api.v1.RationalProperty.deserializeBinaryFromReader);
      msg.setTimeBase(value);
      break;
    case 99:
      var value = msg.getPrivateDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.StringProperty.deserializeBinaryFromReader, "", new proto.presets.api.v1.StringProperty());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.AudioOutputOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.AudioOutputOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.AudioOutputOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBitRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getChannelLayout();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.presets.api.v1.ChannelLayoutProperty.serializeBinaryToWriter
    );
  }
  f = message.getSampleFormat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.presets.api.v1.SampleFormatProperty.serializeBinaryToWriter
    );
  }
  f = message.getChannels();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.presets.api.v1.UintProperty.serializeBinaryToWriter
    );
  }
  f = message.getSampleRate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.presets.api.v1.UintProperty.serializeBinaryToWriter
    );
  }
  f = message.getTimeBase();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.presets.api.v1.RationalProperty.serializeBinaryToWriter
    );
  }
  f = message.getPrivateDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.StringProperty.serializeBinaryToWriter);
  }
};


/**
 * optional bool disabled = 1;
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
 */
proto.presets.api.v1.AudioOutputOverride.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional IntProperty bit_rate = 2;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.getBitRate = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 2));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
*/
proto.presets.api.v1.AudioOutputOverride.prototype.setBitRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
 */
proto.presets.api.v1.AudioOutputOverride.prototype.clearBitRate = function() {
  return this.setBitRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.hasBitRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ChannelLayoutProperty channel_layout = 3;
 * @return {?proto.presets.api.v1.ChannelLayoutProperty}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.getChannelLayout = function() {
  return /** @type{?proto.presets.api.v1.ChannelLayoutProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.ChannelLayoutProperty, 3));
};


/**
 * @param {?proto.presets.api.v1.ChannelLayoutProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
*/
proto.presets.api.v1.AudioOutputOverride.prototype.setChannelLayout = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
 */
proto.presets.api.v1.AudioOutputOverride.prototype.clearChannelLayout = function() {
  return this.setChannelLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.hasChannelLayout = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SampleFormatProperty sample_format = 4;
 * @return {?proto.presets.api.v1.SampleFormatProperty}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.getSampleFormat = function() {
  return /** @type{?proto.presets.api.v1.SampleFormatProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.SampleFormatProperty, 4));
};


/**
 * @param {?proto.presets.api.v1.SampleFormatProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
*/
proto.presets.api.v1.AudioOutputOverride.prototype.setSampleFormat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
 */
proto.presets.api.v1.AudioOutputOverride.prototype.clearSampleFormat = function() {
  return this.setSampleFormat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.hasSampleFormat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UintProperty channels = 5;
 * @return {?proto.presets.api.v1.UintProperty}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.getChannels = function() {
  return /** @type{?proto.presets.api.v1.UintProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.UintProperty, 5));
};


/**
 * @param {?proto.presets.api.v1.UintProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
*/
proto.presets.api.v1.AudioOutputOverride.prototype.setChannels = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
 */
proto.presets.api.v1.AudioOutputOverride.prototype.clearChannels = function() {
  return this.setChannels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.hasChannels = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UintProperty sample_rate = 6;
 * @return {?proto.presets.api.v1.UintProperty}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.getSampleRate = function() {
  return /** @type{?proto.presets.api.v1.UintProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.UintProperty, 6));
};


/**
 * @param {?proto.presets.api.v1.UintProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
*/
proto.presets.api.v1.AudioOutputOverride.prototype.setSampleRate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
 */
proto.presets.api.v1.AudioOutputOverride.prototype.clearSampleRate = function() {
  return this.setSampleRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.hasSampleRate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RationalProperty time_base = 7;
 * @return {?proto.presets.api.v1.RationalProperty}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.getTimeBase = function() {
  return /** @type{?proto.presets.api.v1.RationalProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.RationalProperty, 7));
};


/**
 * @param {?proto.presets.api.v1.RationalProperty|undefined} value
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
*/
proto.presets.api.v1.AudioOutputOverride.prototype.setTimeBase = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
 */
proto.presets.api.v1.AudioOutputOverride.prototype.clearTimeBase = function() {
  return this.setTimeBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.hasTimeBase = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * map<string, StringProperty> private_data = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.StringProperty>}
 */
proto.presets.api.v1.AudioOutputOverride.prototype.getPrivateDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.StringProperty>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      proto.presets.api.v1.StringProperty));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.AudioOutputOverride} returns this
 */
proto.presets.api.v1.AudioOutputOverride.prototype.clearPrivateDataMap = function() {
  this.getPrivateDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.api.v1.VideoOutputOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.api.v1.VideoOutputOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.VideoOutputOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    maxGop: (f = msg.getMaxGop()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    width: (f = msg.getWidth()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    height: (f = msg.getHeight()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    bitRate: (f = msg.getBitRate()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    refs: (f = msg.getRefs()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    pixelFormat: (f = msg.getPixelFormat()) && proto.presets.api.v1.PixelFormatProperty.toObject(includeInstance, f),
    frameRate: (f = msg.getFrameRate()) && proto.presets.api.v1.RationalProperty.toObject(includeInstance, f),
    timeBase: (f = msg.getTimeBase()) && proto.presets.api.v1.RationalProperty.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.presets.api.v1.StringProperty.toObject(includeInstance, f),
    level: (f = msg.getLevel()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    colorSpace: (f = msg.getColorSpace()) && proto.presets.api.v1.ColorSpaceProperty.toObject(includeInstance, f),
    passMode: (f = msg.getPassMode()) && proto.presets.api.v1.PassModeProperty.toObject(includeInstance, f),
    rcMaxBitrate: (f = msg.getRcMaxBitrate()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    rcMinBitrate: (f = msg.getRcMinBitrate()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    bufSize: (f = msg.getBufSize()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    maxBFrames: (f = msg.getMaxBFrames()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    keyIntMin: (f = msg.getKeyIntMin()) && proto.presets.api.v1.IntProperty.toObject(includeInstance, f),
    privateDataMap: (f = msg.getPrivateDataMap()) ? f.toObject(includeInstance, proto.presets.api.v1.StringProperty.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.api.v1.VideoOutputOverride}
 */
proto.presets.api.v1.VideoOutputOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.api.v1.VideoOutputOverride;
  return proto.presets.api.v1.VideoOutputOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.api.v1.VideoOutputOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.api.v1.VideoOutputOverride}
 */
proto.presets.api.v1.VideoOutputOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 2:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setMaxGop(value);
      break;
    case 3:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setWidth(value);
      break;
    case 4:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setHeight(value);
      break;
    case 5:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setBitRate(value);
      break;
    case 6:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setRefs(value);
      break;
    case 7:
      var value = new proto.presets.api.v1.PixelFormatProperty;
      reader.readMessage(value,proto.presets.api.v1.PixelFormatProperty.deserializeBinaryFromReader);
      msg.setPixelFormat(value);
      break;
    case 8:
      var value = new proto.presets.api.v1.RationalProperty;
      reader.readMessage(value,proto.presets.api.v1.RationalProperty.deserializeBinaryFromReader);
      msg.setFrameRate(value);
      break;
    case 9:
      var value = new proto.presets.api.v1.RationalProperty;
      reader.readMessage(value,proto.presets.api.v1.RationalProperty.deserializeBinaryFromReader);
      msg.setTimeBase(value);
      break;
    case 10:
      var value = new proto.presets.api.v1.StringProperty;
      reader.readMessage(value,proto.presets.api.v1.StringProperty.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 11:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    case 12:
      var value = new proto.presets.api.v1.ColorSpaceProperty;
      reader.readMessage(value,proto.presets.api.v1.ColorSpaceProperty.deserializeBinaryFromReader);
      msg.setColorSpace(value);
      break;
    case 13:
      var value = new proto.presets.api.v1.PassModeProperty;
      reader.readMessage(value,proto.presets.api.v1.PassModeProperty.deserializeBinaryFromReader);
      msg.setPassMode(value);
      break;
    case 14:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setRcMaxBitrate(value);
      break;
    case 15:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setRcMinBitrate(value);
      break;
    case 16:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setBufSize(value);
      break;
    case 17:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setMaxBFrames(value);
      break;
    case 18:
      var value = new proto.presets.api.v1.IntProperty;
      reader.readMessage(value,proto.presets.api.v1.IntProperty.deserializeBinaryFromReader);
      msg.setKeyIntMin(value);
      break;
    case 99:
      var value = msg.getPrivateDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.presets.api.v1.StringProperty.deserializeBinaryFromReader, "", new proto.presets.api.v1.StringProperty());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.api.v1.VideoOutputOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.api.v1.VideoOutputOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.api.v1.VideoOutputOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMaxGop();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getHeight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getBitRate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getRefs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getPixelFormat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.presets.api.v1.PixelFormatProperty.serializeBinaryToWriter
    );
  }
  f = message.getFrameRate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.presets.api.v1.RationalProperty.serializeBinaryToWriter
    );
  }
  f = message.getTimeBase();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.presets.api.v1.RationalProperty.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.presets.api.v1.StringProperty.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getColorSpace();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.presets.api.v1.ColorSpaceProperty.serializeBinaryToWriter
    );
  }
  f = message.getPassMode();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.presets.api.v1.PassModeProperty.serializeBinaryToWriter
    );
  }
  f = message.getRcMaxBitrate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getRcMinBitrate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getBufSize();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getMaxBFrames();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getKeyIntMin();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.presets.api.v1.IntProperty.serializeBinaryToWriter
    );
  }
  f = message.getPrivateDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.presets.api.v1.StringProperty.serializeBinaryToWriter);
  }
};


/**
 * optional bool disabled = 1;
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional IntProperty max_gop = 2;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getMaxGop = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 2));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setMaxGop = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearMaxGop = function() {
  return this.setMaxGop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasMaxGop = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IntProperty width = 3;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getWidth = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 3));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setWidth = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearWidth = function() {
  return this.setWidth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasWidth = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IntProperty height = 4;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getHeight = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 4));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setHeight = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearHeight = function() {
  return this.setHeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional IntProperty bit_rate = 5;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getBitRate = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 5));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setBitRate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearBitRate = function() {
  return this.setBitRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasBitRate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional IntProperty refs = 6;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getRefs = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 6));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setRefs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearRefs = function() {
  return this.setRefs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasRefs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PixelFormatProperty pixel_format = 7;
 * @return {?proto.presets.api.v1.PixelFormatProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getPixelFormat = function() {
  return /** @type{?proto.presets.api.v1.PixelFormatProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PixelFormatProperty, 7));
};


/**
 * @param {?proto.presets.api.v1.PixelFormatProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setPixelFormat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearPixelFormat = function() {
  return this.setPixelFormat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasPixelFormat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RationalProperty frame_rate = 8;
 * @return {?proto.presets.api.v1.RationalProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getFrameRate = function() {
  return /** @type{?proto.presets.api.v1.RationalProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.RationalProperty, 8));
};


/**
 * @param {?proto.presets.api.v1.RationalProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setFrameRate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearFrameRate = function() {
  return this.setFrameRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasFrameRate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional RationalProperty time_base = 9;
 * @return {?proto.presets.api.v1.RationalProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getTimeBase = function() {
  return /** @type{?proto.presets.api.v1.RationalProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.RationalProperty, 9));
};


/**
 * @param {?proto.presets.api.v1.RationalProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setTimeBase = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearTimeBase = function() {
  return this.setTimeBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasTimeBase = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional StringProperty filter = 10;
 * @return {?proto.presets.api.v1.StringProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getFilter = function() {
  return /** @type{?proto.presets.api.v1.StringProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.StringProperty, 10));
};


/**
 * @param {?proto.presets.api.v1.StringProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional IntProperty level = 11;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getLevel = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 11));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ColorSpaceProperty color_space = 12;
 * @return {?proto.presets.api.v1.ColorSpaceProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getColorSpace = function() {
  return /** @type{?proto.presets.api.v1.ColorSpaceProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.ColorSpaceProperty, 12));
};


/**
 * @param {?proto.presets.api.v1.ColorSpaceProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setColorSpace = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearColorSpace = function() {
  return this.setColorSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasColorSpace = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PassModeProperty pass_mode = 13;
 * @return {?proto.presets.api.v1.PassModeProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getPassMode = function() {
  return /** @type{?proto.presets.api.v1.PassModeProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.PassModeProperty, 13));
};


/**
 * @param {?proto.presets.api.v1.PassModeProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setPassMode = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearPassMode = function() {
  return this.setPassMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasPassMode = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional IntProperty rc_max_bitrate = 14;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getRcMaxBitrate = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 14));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setRcMaxBitrate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearRcMaxBitrate = function() {
  return this.setRcMaxBitrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasRcMaxBitrate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional IntProperty rc_min_bitrate = 15;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getRcMinBitrate = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 15));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setRcMinBitrate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearRcMinBitrate = function() {
  return this.setRcMinBitrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasRcMinBitrate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional IntProperty buf_size = 16;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getBufSize = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 16));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setBufSize = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearBufSize = function() {
  return this.setBufSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasBufSize = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional IntProperty max_b_frames = 17;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getMaxBFrames = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 17));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setMaxBFrames = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearMaxBFrames = function() {
  return this.setMaxBFrames(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasMaxBFrames = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional IntProperty key_int_min = 18;
 * @return {?proto.presets.api.v1.IntProperty}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getKeyIntMin = function() {
  return /** @type{?proto.presets.api.v1.IntProperty} */ (
    jspb.Message.getWrapperField(this, proto.presets.api.v1.IntProperty, 18));
};


/**
 * @param {?proto.presets.api.v1.IntProperty|undefined} value
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
*/
proto.presets.api.v1.VideoOutputOverride.prototype.setKeyIntMin = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearKeyIntMin = function() {
  return this.setKeyIntMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.hasKeyIntMin = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * map<string, StringProperty> private_data = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.presets.api.v1.StringProperty>}
 */
proto.presets.api.v1.VideoOutputOverride.prototype.getPrivateDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.presets.api.v1.StringProperty>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      proto.presets.api.v1.StringProperty));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.presets.api.v1.VideoOutputOverride} returns this
 */
proto.presets.api.v1.VideoOutputOverride.prototype.clearPrivateDataMap = function() {
  this.getPrivateDataMap().clear();
  return this;};


goog.object.extend(exports, proto.presets.api.v1);
