import {envVars} from '../../../config/envs/env';
import {AuthService} from '../../auth/api/service';
import {JobsServiceClient} from '../../../autogen/gRPC/arranger/jobs/Service_jobsServiceClientPb';
import {
	CancelRequest,
	CreateRequest,
	CreateRequests,
	DeleteRequest,
	ListRequest
} from '../../../autogen/gRPC/arranger/jobs/service_jobs_pb';
import {InputInfo, OutputInfo} from '../../../autogen/gRPC/scheduler/models_pb';
import {BlueprintOverride} from '../../../autogen/gRPC/arranger/presets/models_pb';
import {GoogleStorageConfig} from '../../../autogen/gRPC/cloud/models_pb';

const service = new JobsServiceClient(envVars.apiArrangerUrl);

const prepareHeaders = async () => {
	const token = await AuthService.getInstance().currentToken();
	return {'Authorization': 'Bearer ' + token.token};
};

export const callListJobs = async (ids?: string[], offset?: number, limit?: number) => {
	const request = new ListRequest();

	ids && request.setIdsList(ids);
	limit && request.setLimit(limit);
	offset && request.setOffset(offset);
	request.setOrder('DESC');

	const response = await service.list(request, await prepareHeaders());
	return response.toObject();
};

export const callCreateJob = async (name: string, baseId: string, fileName: string, override: BlueprintOverride,
	inputInfo?: InputInfo, outputInfo?: OutputInfo) => {
	const requests = new CreateRequests();

	const request = new CreateRequest();
	request.setName(name);
	request.setBasePresetId(baseId);
	request.setOverride(override);
	requests.setRequestsList([request]);
	outputInfo && request.setOutputInfo(outputInfo);

	if (inputInfo) {
		request.setInputInfo(inputInfo);
	} else {
		request.setInputInfo(new InputInfo().setGcs(new GoogleStorageConfig()).setPath(fileName));
	}

	const response = await service.create(requests, await prepareHeaders());
	return response.toObject();
};

export const callCancelJob = async (id: string) => {
	const request = new CancelRequest();

	request.setIdsList([id]);

	const response = await service.cancel(request, await prepareHeaders());
	return response.toObject();
};

export const callDeleteJob = async (id: string) => {
	const request = new DeleteRequest();

	request.setIdsList([id]);

	const response = await service.delete(request, await prepareHeaders());
	return response.toObject();
};
