import styled from 'styled-components';
import {GREY_1, LIGHT_3} from '../../../../utils/colors';

export const ManageFilesS = styled.div`
	display: flex;
	justify-content: center;
	height: 80vh;
	width: 100%;

	a {
		width: 10px;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: ${LIGHT_3};
		text-decoration: underline;
	}
`;

export const ManageFilesS_Dropzone = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${GREY_1};
	height: 66vh;
`;
