import React, {FC, useEffect, useState} from 'react';
import {Page} from '../../../../components/Page/Page';
import {Button, Checkbox, Divider, Form, Input, Row, Select, Spin, Typography} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {Notification, notify} from '../../../../utils/notify';
import * as CheckType from 'check-types';
import {Preset} from '../../../../autogen/gRPC/arranger/presets/models_pb';
import {CreatePresetS, CreatePresetS_Container, CreatePresetS_Divider} from '../CreatePreset/CreatePresetS';
import {validateRequired} from '../../domain/validators';
import {categories, categoryToString} from '../../domain/categoryConverter';
import {AudioOutput} from '../../../presets/components/AudioOutput/AudioOutput';
import {VideoOutput} from '../../../presets/components/VideoOutput/VideoOutput';
import {FileOutput} from '../../../presets/components/FileOutput/FileOutput';
import {channelLayoutToString} from '../../domain/channelLayoutConverter';
import {sampleFormatToString} from '../../domain/sampleFormatConverter';
import {pixelFormatToString} from '../../domain/pixelFormatConverter';
import {colorSpaceToString} from '../../domain/colorSpaceConverter';
import {passModeToString} from '../../domain/passModeConverter';
import {convertFormStateToFormValues} from '../../domain/formConverter';
import {callUpdatePreset} from '../../api/presets_requests';
import {CreatePresetFormValues} from '../CreatePreset/CreatePreset';
import {convertEditPresetDataToServiceArgs} from '../../domain/serviceArgs';

const {Option} = Select;

export interface EditPresetFormValues extends CreatePresetFormValues {
}

let originalEditPresetFormValues: EditPresetFormValues;

export const EditPreset: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [preset, setPreset] = useState<Preset.AsObject | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);
	const [override, setOverride] = useState(true);

	const [audioNameIdxMap, setAudioNameIdxMap] = useState<Map<number, string>>(new Map<number, string>());
	const [videoNameIdxMap, setVideoNameIdxMap] = useState<Map<number, string>>(new Map<number, string>());
	const [initialMappings, setInitialMappings] = useState<number[][] | undefined>(undefined);
	const [initialValues, setInitialValues] = useState<any>(undefined);
	const [formatIdxMap, setFormatIdxMap] = useState<Map<number, string> | undefined>(undefined);

	const [privateDataFileInitial, setPrivateDataFileInitial] = useState<number[][] | undefined>(undefined);
	const [privateDataAudioInitial, setPrivateDataAudioInitial] = useState<number[][] | undefined>(undefined);
	const [privateDataVideoInitial, setPrivateDataVideoInitial] = useState<number[][] | undefined>(undefined);

	useEffect(() => {
		if (CheckType.not.assigned(location.state && (location.state as any).preset)) {
			navigate('/');
		} else {
			const state = {...(location.state as any)};
			setPreset(state.preset);
			delete location.state;
		}
	}, []);

	useEffect(() => {
		if (preset) {
			const initialValues: any = {};
			const initialMappers: number[][] = [];
			const initialFormatMap = new Map<number, string>();

			const initialFilePrivateIndexes: number[][] = [];
			const initialAudioPrivateIndexes: number[][] = [];
			const initialVideoPrivateIndexes: number[][] = [];

			initialValues[`presetName`] = preset.name;
			initialValues[`nodePoolName`] = preset.nodePoolName;
			initialValues[`presetCategory`] = categoryToString(preset.category);

			preset?.blueprint?.fileOutputsMap.forEach(([name, fOutput], idx) => {
				initialValues[`fileOutputName${idx}`] = name;
				initialValues[`fileFormatName${idx}`] = fOutput.formatName;
				initialFormatMap.set(idx, fOutput.formatName);

				const initialMapper: number[] = [];
				fOutput.videoAudioMapList.forEach((entry, index) => {
					initialMapper.push(index);
					initialValues[`audioOutput|${idx}|${index}`] = entry.audio;
					initialValues[`videoOutput|${idx}|${index}`] = entry.video;
				});
				initialMappers.push(initialMapper);

				const initialIndexes: number[] = [];
				fOutput.privateDataMap.forEach(([prop, value], index) => {
					initialIndexes.push(index);
					initialValues[`customProperty|file|${idx}|${index}`] = prop;
					initialValues[`customValue|file|${idx}|${index}`] = value;
				});
				initialFilePrivateIndexes.push(initialIndexes);
			});

			setFormatIdxMap(initialFormatMap);
			setInitialMappings(initialMappers);
			setPrivateDataFileInitial(initialFilePrivateIndexes);

			const copyAudio = new Map(audioNameIdxMap);
			preset?.blueprint?.audioOutputsMap.forEach(([name, aOutput], idx) => {
				copyAudio.set(idx, name);

				initialValues[`audioOutputName${idx}`] = name;
				initialValues[`audioEncoderName${idx}`] = aOutput.encoderName;

				aOutput.bitRate?.value && aOutput.bitRate?.value > 0 && (initialValues[`audioBitrate${idx}`] = aOutput.bitRate?.value);
				initialValues[`requiredAudioBitrate${idx}`] = aOutput.bitRate?.details?.required;
				initialValues[`editableAudioBitrate${idx}`] = aOutput.bitRate?.details?.editable;
				initialValues[`hiddenAudioBitrate${idx}`] = aOutput.bitRate?.details?.hidden;

				initialValues[`audioChannelLayout${idx}`] = aOutput.channelLayout ? channelLayoutToString(aOutput.channelLayout.value) : '';
				initialValues[`requiredAudioChannelLayout${idx}`] = aOutput.channelLayout?.details?.required;
				initialValues[`editableAudioChannelLayout${idx}`] = aOutput.channelLayout?.details?.editable;
				initialValues[`hiddenAudioChannelLayout${idx}`] = aOutput.channelLayout?.details?.hidden;

				initialValues[`audioSampleFormat${idx}`] = aOutput.sampleFormat ? sampleFormatToString(aOutput.sampleFormat.value) : '';
				initialValues[`requiredAudioSampleFormat${idx}`] = aOutput.sampleFormat?.details?.required;
				initialValues[`editableAudioSampleFormat${idx}`] = aOutput.sampleFormat?.details?.editable;
				initialValues[`hiddenAudioSampleFormat${idx}`] = aOutput.sampleFormat?.details?.hidden;

				aOutput.channels?.value && aOutput.channels?.value > 0 && (initialValues[`audioChannels${idx}`] = aOutput.channels?.value);
				initialValues[`requiredAudioChannels${idx}`] = aOutput.channels?.details?.required;
				initialValues[`editableAudioChannels${idx}`] = aOutput.channels?.details?.editable;
				initialValues[`hiddenAudioChannels${idx}`] = aOutput.channels?.details?.hidden;

				aOutput.sampleRate?.value && aOutput.sampleRate?.value > 0 && (initialValues[`audioSampleRate${idx}`] = aOutput.sampleRate?.value);
				initialValues[`requiredAudioSampleRate${idx}`] = aOutput.sampleRate?.details?.required;
				initialValues[`editableAudioSampleRate${idx}`] = aOutput.sampleRate?.details?.editable;
				initialValues[`hiddenAudioSampleRate${idx}`] = aOutput.sampleRate?.details?.hidden;

				aOutput.timeBase?.value?.num && aOutput.timeBase?.value?.num > 0 && (initialValues[`audioTimeBaseNom${idx}`] = aOutput.timeBase?.value?.num);
				aOutput.timeBase?.value?.den && aOutput.timeBase?.value?.den > 0 && (initialValues[`audioTimeBaseDen${idx}`] = aOutput.timeBase?.value?.den);
				initialValues[`requiredAudioTimeBase${idx}`] = aOutput.timeBase?.details?.required;
				initialValues[`editableAudioTimeBase${idx}`] = aOutput.timeBase?.details?.editable;
				initialValues[`hiddenAudioTimeBase${idx}`] = aOutput.timeBase?.details?.hidden;

				const initialIndexes: number[] = [];
				aOutput.privateDataMap.forEach(([prop, value], index) => {
					initialIndexes.push(index);
					initialValues[`customProperty|audio|${idx}|${index}`] = prop;
					initialValues[`customValue|audio|${idx}|${index}`] = value.value;
					initialValues[`requiredCustomDetails|audio|${idx}|${index}${idx}`] = value.details?.required;
					initialValues[`editableCustomDetails|audio|${idx}|${index}${idx}`] = value?.details?.editable;
					initialValues[`hiddenCustomDetails|audio|${idx}|${index}${idx}`] = value?.details?.hidden;
				});
				initialAudioPrivateIndexes.push(initialIndexes);
			});
			setAudioNameIdxMap && setAudioNameIdxMap(copyAudio);
			setPrivateDataAudioInitial(initialAudioPrivateIndexes);

			const copyVideo = new Map(videoNameIdxMap);
			preset?.blueprint?.videoOutputsMap.forEach(([name, vOutput], idx) => {
				copyVideo.set(idx, name);

				initialValues[`videoOutputName${idx}`] = name;
				initialValues[`videoEncoderName${idx}`] = vOutput.encoderName;

				vOutput.resources?.value?.requestsCpu && vOutput.resources?.value?.requestsCpu > 0 && (initialValues[`videoResourcesCpu${idx}`] = vOutput.resources?.value?.requestsCpu);
				vOutput.resources?.value?.requestsGpu && vOutput.resources?.value?.requestsGpu > 0 && (initialValues[`videoResourcesGpu${idx}`] = vOutput.resources?.value?.requestsGpu);

				vOutput.maxGop?.value && vOutput.maxGop?.value > 0 && (initialValues[`videoMaxGop${idx}`] = vOutput.maxGop?.value);
				initialValues[`requiredVideoMaxGop${idx}`] = vOutput.maxGop?.details?.required;
				initialValues[`editableVideoMaxGop${idx}`] = vOutput.maxGop?.details?.editable;
				initialValues[`hiddenVideoMaxGop${idx}`] = vOutput.maxGop?.details?.hidden;

				vOutput.width?.value && vOutput.width?.value > 0 && (initialValues[`videoWidth${idx}`] = vOutput.width?.value);
				initialValues[`requiredVideoWidth${idx}`] = vOutput.width?.details?.required;
				initialValues[`editableVideoWidth${idx}`] = vOutput.width?.details?.editable;
				initialValues[`hiddenVideoWidth${idx}`] = vOutput.width?.details?.hidden;

				vOutput.height?.value && vOutput.height?.value > 0 && (initialValues[`videoHeight${idx}`] = vOutput.height?.value);
				initialValues[`requiredVideoHeight${idx}`] = vOutput.height?.details?.required;
				initialValues[`editableVideoHeight${idx}`] = vOutput.height?.details?.editable;
				initialValues[`hiddenVideoHeight${idx}`] = vOutput.height?.details?.hidden;

				vOutput.refs?.value && vOutput.refs?.value > 0 && (initialValues[`videoRefs${idx}`] = vOutput.refs?.value);
				initialValues[`requiredVideoRefs${idx}`] = vOutput.refs?.details?.required;
				initialValues[`editableVideoRefs${idx}`] = vOutput.refs?.details?.editable;
				initialValues[`hiddenVideoRefs${idx}`] = vOutput.refs?.details?.hidden;

				initialValues[`videoPixelFormat${idx}`] = vOutput.pixelFormat ? pixelFormatToString(vOutput.pixelFormat.value) : '';
				initialValues[`requiredVideoPixelFormat${idx}`] = vOutput.pixelFormat?.details?.required;
				initialValues[`editableVideoPixelFormat${idx}`] = vOutput.pixelFormat?.details?.editable;
				initialValues[`hiddenVideoPixelFormat${idx}`] = vOutput.pixelFormat?.details?.hidden;

				vOutput.bitRate?.value && vOutput.bitRate?.value > 0 && (initialValues[`videoBitrate${idx}`] = vOutput.bitRate?.value);
				initialValues[`requiredVideoBitrate${idx}`] = vOutput.bitRate?.details?.required;
				initialValues[`editableVideoBitrate${idx}`] = vOutput.bitRate?.details?.editable;
				initialValues[`hiddenVideoBitrate${idx}`] = vOutput.bitRate?.details?.hidden;

				vOutput.frameRate?.value?.num && vOutput.frameRate?.value?.num > 0 && (initialValues[`videoFrameRateNom${idx}`] = vOutput.frameRate?.value?.num);
				vOutput.frameRate?.value?.den && vOutput.frameRate?.value?.den > 0 && (initialValues[`videoFrameRateDen${idx}`] = vOutput.frameRate?.value?.den);
				initialValues[`requiredVideoFrameRate${idx}`] = vOutput.frameRate?.details?.required;
				initialValues[`editableVideoFrameRate${idx}`] = vOutput.frameRate?.details?.editable;
				initialValues[`hiddenVideoFrameRate${idx}`] = vOutput.frameRate?.details?.hidden;

				vOutput.timeBase?.value?.num && vOutput.timeBase?.value?.num > 0 && (initialValues[`videoTimeBaseNom${idx}`] = vOutput.timeBase?.value?.num);
				vOutput.timeBase?.value?.den && vOutput.timeBase?.value?.den > 0 && (initialValues[`videoTimeBaseDen${idx}`] = vOutput.timeBase?.value?.den);
				initialValues[`requiredVideoTimeBase${idx}`] = vOutput.timeBase?.details?.required;
				initialValues[`editableVideoTimeBase${idx}`] = vOutput.timeBase?.details?.editable;
				initialValues[`hiddenVideoTimeBase${idx}`] = vOutput.timeBase?.details?.hidden;

				initialValues[`videoFilter${idx}`] = vOutput.filter?.value;
				initialValues[`requiredVideoFilter${idx}`] = vOutput.filter?.details?.required;
				initialValues[`editableVideoFilter${idx}`] = vOutput.filter?.details?.editable;
				initialValues[`hiddenVideoFilter${idx}`] = vOutput.filter?.details?.hidden;

				vOutput.level?.value && vOutput.level?.value > 0 && (initialValues[`videoLevel${idx}`] = vOutput.level?.value);
				initialValues[`requiredVideoLevel${idx}`] = vOutput.level?.details?.required;
				initialValues[`editableVideoLevel${idx}`] = vOutput.level?.details?.editable;
				initialValues[`hiddenVideoLevel${idx}`] = vOutput.level?.details?.hidden;

				initialValues[`videoColorSpace${idx}`] = vOutput.colorSpace ? colorSpaceToString(vOutput.colorSpace.value) : '';
				initialValues[`requiredVideoColorSpace${idx}`] = vOutput.colorSpace?.details?.required;
				initialValues[`editableVideoColorSpace${idx}`] = vOutput.colorSpace?.details?.editable;
				initialValues[`hiddenVideoColorSpace${idx}`] = vOutput.colorSpace?.details?.hidden;

				initialValues[`videoPassMode${idx}`] = vOutput.passMode ? passModeToString(vOutput.passMode.value) : '';
				initialValues[`requiredVideoPassMode${idx}`] = vOutput.passMode?.details?.required;
				initialValues[`editableVideoPassMode${idx}`] = vOutput.passMode?.details?.editable;
				initialValues[`hiddenVideoPassMode${idx}`] = vOutput.passMode?.details?.hidden;

				vOutput.rcMaxBitrate?.value && vOutput.rcMaxBitrate?.value > 0 && (initialValues[`videoRcMaxBitrate${idx}`] = vOutput.rcMaxBitrate?.value);
				initialValues[`requiredVideoRcMaxBitrate${idx}`] = vOutput.rcMaxBitrate?.details?.required;
				initialValues[`editableVideoRcMaxBitrate${idx}`] = vOutput.rcMaxBitrate?.details?.editable;
				initialValues[`hiddenVideoRcMaxBitrate${idx}`] = vOutput.rcMaxBitrate?.details?.hidden;

				vOutput.rcMinBitrate?.value && vOutput.rcMinBitrate?.value > 0 && (initialValues[`videoRcMinBitrate${idx}`] = vOutput.rcMinBitrate?.value);
				initialValues[`requiredVideoRcMinBitrate${idx}`] = vOutput.rcMinBitrate?.details?.required;
				initialValues[`editableVideoRcMinBitrate${idx}`] = vOutput.rcMinBitrate?.details?.editable;
				initialValues[`hiddenVideoRcMinBitrate${idx}`] = vOutput.rcMinBitrate?.details?.hidden;

				vOutput.bufSize?.value && vOutput.bufSize?.value > 0 && (initialValues[`videoBufSize${idx}`] = vOutput.bufSize?.value);
				initialValues[`requiredVideoBufSize${idx}`] = vOutput.bufSize?.details?.required;
				initialValues[`editableVideoBufSize${idx}`] = vOutput.bufSize?.details?.editable;
				initialValues[`hiddenVideoBufSize${idx}`] = vOutput.bufSize?.details?.hidden;

				vOutput.maxBFrames?.value && vOutput.maxBFrames?.value > 0 && (initialValues[`videoMaxBFrames${idx}`] = vOutput.maxBFrames?.value);
				initialValues[`requiredVideoMaxBFrames${idx}`] = vOutput.maxBFrames?.details?.required;
				initialValues[`editableVideoMaxBFrames${idx}`] = vOutput.maxBFrames?.details?.editable;
				initialValues[`hiddenVideoMaxBFrames${idx}`] = vOutput.maxBFrames?.details?.hidden;

				vOutput.keyIntMin?.value && vOutput.keyIntMin?.value > 0 && (initialValues[`videoKeyIntMin${idx}`] = vOutput.keyIntMin?.value);
				initialValues[`requiredVideoKeyIntMin${idx}`] = vOutput.keyIntMin?.details?.required;
				initialValues[`editableVideoKeyIntMin${idx}`] = vOutput.keyIntMin?.details?.editable;
				initialValues[`hiddenVideoKeyIntMin${idx}`] = vOutput.keyIntMin?.details?.hidden;

				const initialIndexes: number[] = [];
				vOutput.privateDataMap.forEach(([prop, value], index) => {
					initialIndexes.push(index);
					initialValues[`customProperty|video|${idx}|${index}`] = prop;
					initialValues[`customValue|video|${idx}|${index}`] = value.value;
					initialValues[`requiredCustomDetails|video|${idx}|${index}${idx}`] = value.details?.required;
					initialValues[`editableCustomDetails|video|${idx}|${index}${idx}`] = value?.details?.editable;
					initialValues[`hiddenCustomDetails|video|${idx}|${index}${idx}`] = value?.details?.hidden;
				});
				initialVideoPrivateIndexes.push(initialIndexes);

			});
			setVideoNameIdxMap && setVideoNameIdxMap(copyVideo);
			setPrivateDataVideoInitial(initialVideoPrivateIndexes);

			setInitialValues(initialValues);
			originalEditPresetFormValues = {...initialValues};
		}
	}, [preset]);

	if (!initialValues || !preset || !initialMappings || !formatIdxMap) {
		return <div style={{width: '100%', height: '100%'}}>
			<Row justify="center" align="middle">
				<Spin/>
			</Row>
		</div>;
	}

	const onSubmit = async (values: EditPresetFormValues) => {
		const original = convertFormStateToFormValues(originalEditPresetFormValues);
		const result = convertFormStateToFormValues(values);
		setLoading(true);

		try {
			const serviceArgs = convertEditPresetDataToServiceArgs(original, result);
			serviceArgs.override = override;
			await callUpdatePreset(preset.id, serviceArgs);
			onSuccess();
		} catch (error: any) {
			setError(error.message);
			console.log(error);
			notify(Notification.ERROR, 'Operation Failed', `Failed to create a preset. Please try again`);
		} finally {
			setLoading(false);
		}
	};

	const onSuccess = () => {
		setLoading(false);
		navigate('/admin-tools');
		notify(Notification.SUCCESS, 'Operation Completed', 'Preset has been updated successfully');
	};

	return (
		<Page tab="none">
			<CreatePresetS>
				<Form
					form={form}
					layout="vertical"
					autoComplete="Off"
					onFinish={onSubmit}
					initialValues={initialValues}
				>
					<Typography.Title>Creating New Preset</Typography.Title>
					<Divider style={{marginBottom: '50px'}}/>

					<CreatePresetS_Container>
						<Form.Item
							label={<Typography.Text>Preset Name</Typography.Text>}
							name="presetName"
							tooltip="Enter preset name"
							rules={validateRequired()}
							initialValue={preset.name}
						>
							<Input placeholder="input preset name"/>
						</Form.Item>
						<Form.Item style={{margin: 0}}
						           label={<Typography.Text>Preset Category</Typography.Text>}
						           name="presetCategory"
						           tooltip="Select category"
						           rules={validateRequired()}
						           initialValue={categoryToString(preset.category)}
						>
							<Select placeholder="select preset category">
								{categories.map(categoryToString).map((option) =>
									<Option value={option.toLowerCase()}>{option}</Option>
								)}
							</Select>
						</Form.Item>
						<Form.Item
							label={<Typography.Text>Node Pool Name</Typography.Text>}
							name="nodePoolName"
							tooltip="Enter node pool name"
							initialValue={preset.nodePoolName}
						>
							<Input placeholder="input node pool name"/>
						</Form.Item>
						<Form.Item
							label={<Typography.Text>Override</Typography.Text>}
							name="override"
							tooltip="When checked, all custom presets based on this preset will reflect these changes."
							valuePropName="checked"
							initialValue={true}
						>
							<Checkbox defaultChecked value={override} onChange={() => setOverride(!override)}>Override Custom Presets</Checkbox>
						</Form.Item>
					</CreatePresetS_Container>

					<AudioOutput deletable={true} withHeader={true} nameIdxMap={audioNameIdxMap} setNameIdxMap={setAudioNameIdxMap}
					             initialIndexes={[...Array(preset.blueprint?.audioOutputsMap.length).keys()]}
					             privateDataAudioInitial={privateDataAudioInitial}
					/>
					<CreatePresetS_Divider/>
					<VideoOutput deletable={true} withHeader={true} nameIdxMap={videoNameIdxMap} setNameIdxMap={setVideoNameIdxMap}
					             initialIndexes={[...Array(preset.blueprint?.videoOutputsMap.length).keys()]}
					             privateDataVideoInitial={privateDataVideoInitial}
					/>
					<CreatePresetS_Divider/>
					<FileOutput deletable={true} videoNameIdxMap={videoNameIdxMap} audioNameIdxMap={audioNameIdxMap}
					            initialIndexes={[...Array(preset.blueprint?.fileOutputsMap.length).keys()]}
					            initialMappings={initialMappings} initialFormatIdxMap={formatIdxMap}
					            privateDataFileInitial={privateDataFileInitial}
					/>

					<Row justify="space-between" style={{marginTop: '20px'}}>
						<Form.Item>
							<Button type="default" onClick={() => navigate(-1)}>Cancel</Button>
						</Form.Item>
						<Form.Item>
							<Button loading={loading} type="primary" htmlType="submit">Submit</Button>
						</Form.Item>
					</Row>
				</Form>
			</CreatePresetS>
		</Page>
	);
};
