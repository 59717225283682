import React, {FC, useEffect, useState} from 'react';
import {Page} from '../../../../components/Page/Page';
import {useNavigate} from 'react-router-dom';
import {Button, Row, Space, Steps} from 'antd';
import {CreateJobS, StepContentS, StepsWrapperS} from './CreateJobS';
import {SelectSource} from './SelectSource/SelectSource';
import {SelectCodec} from './SelectCodec/SelectCodec';
import {CreateCustomPresetFormValues, SelectPreset} from './SelectPreset/SelectPreset';
import {Preset} from '../../../../autogen/gRPC/arranger/presets/models_pb';
import {OutputFormat} from './OutputFormat/OutputFormat';
import {Category} from '../../../../autogen/gRPC/arranger/presets/enums_pb';
import {callCreateJob} from '../../api/requests';
import {convertBlueprintOverrideFormValuesToProto} from '../../domain/blueptintOverrideConverter';
import {Notification, notify} from '../../../../utils/notify';
import {InputInfo, OutputInfo} from '../../../../autogen/gRPC/scheduler/models_pb';
import {FileEntry} from '../../../../stores/slices/files';
import {Int64Value} from 'google-protobuf/google/protobuf/wrappers_pb';
import {ApiSample} from '../../../../components/ApiSample/ApiSample';
import {CreateRequest, CreateRequests} from '../../../../autogen/gRPC/arranger/jobs/service_jobs_pb';
import {GoogleStorageConfig} from '../../../../autogen/gRPC/cloud/models_pb';
import {CreateJobInfo} from '../../../../components/ApiSample/infos';
import {ErrorLabel} from '../../../../components/ErrorLabel/ErrorLabel';

const {Step} = Steps;

export const CreateJob: FC = () => {
	const navigate = useNavigate();

	const [step, setStep] = useState(0);

	const [inputInfo, setInputInfo] = useState<InputInfo | undefined>(undefined);
	const [outputInfo, setOutputInfo] = useState<OutputInfo | undefined>(undefined);
	const [selectedFile, setSelectedFile] = useState<FileEntry | undefined>(undefined);
	const [selectedCodec, setSelectedCodec] = useState<Category | undefined>(undefined);
	const [selectedPreset, setSelectedPreset] = useState<Preset.AsObject | undefined>(undefined);
	const [selectedFormats, setSelectedFormats] = useState<string[]>([]);
	const [selectedOverride, setSelectedOverride] = useState<CreateCustomPresetFormValues | undefined>(undefined);
	const [selectedVideoIdx, setSelectedVideoIdx] = useState<number | undefined>(undefined);
	const [selectedAudioIdx, setSelectedAudioIdx] = useState<number | undefined>(undefined);

	const [error, setError] = useState<string | undefined>(undefined)

	useEffect(() => {
		if (inputInfo) {
			const info = new OutputInfo();
			const path = inputInfo?.getPath();
			path && info.setPath(path);

			const gcsConfig = inputInfo?.getGcs();
			gcsConfig && info.setGcs(gcsConfig);
			const azureConfig = inputInfo?.getAzure();
			azureConfig && info.setAzure(azureConfig);
			const s3Config = inputInfo?.getS3();
			s3Config && info.setS3(s3Config);
			setOutputInfo(info);
		}
	}, [inputInfo]);

	const next = () => {
		setStep(step + 1);
	};

	const prev = () => {
		setStep(step - 1);
	};

	const steps = [
		{
			title: 'Select Source',
			content: <SelectSource selectedSource={selectedFile} setSelectedSource={setSelectedFile} setInputInfo={setInputInfo}
			                       setSelectedVideoIdx={setSelectedVideoIdx} setSelectedAudioIdx={setSelectedAudioIdx}
			/>
		},
		{
			title: 'Select Codec',
			content: <SelectCodec setSelectedCodec={setSelectedCodec}/>
		},
		{
			title: 'Select Preset',
			content: <SelectPreset codec={selectedCodec!} selectedOverride={selectedOverride} setSelectedOverride={setSelectedOverride}
			                       setSelectedPreset={setSelectedPreset}
			/>
		},
		{
			title: 'Output Format',
			content: <OutputFormat selectedPreset={selectedPreset!} selectedOverride={selectedOverride!}
			                       setSelectedOverride={setSelectedOverride} setSelectedFormats={setSelectedFormats}
			                       outputInfo={outputInfo} setOutputInfo={setOutputInfo} selectedFile={selectedFile}
			/>
		}
	];

	const prepareBody = () => {
		const blueprint = convertBlueprintOverrideFormValuesToProto(selectedOverride!.blueprint);
		if (selectedPreset && selectedFile?.details?.format) {

			if (selectedAudioIdx !== undefined) {
				inputInfo?.setAudioStream(new Int64Value().setValue(selectedAudioIdx));
			}
			if (selectedVideoIdx !== undefined) {
				inputInfo?.setVideoStream(new Int64Value().setValue(selectedVideoIdx));
			}

			let fileName = selectedFile.name;
			const parts = fileName.split('/');
			fileName = parts[parts.length - 1].substring(0, 26);

			const baseId = selectedPreset.baseId !== '' ? selectedPreset.baseId : selectedPreset.id;

			const requests = new CreateRequests();
			const request = new CreateRequest();
			request.setName(`File ${fileName}`);
			request.setBasePresetId(baseId);
			request.setOverride(blueprint);
			outputInfo && request.setOutputInfo(outputInfo);

			if (inputInfo) {
				request.setInputInfo(inputInfo);
			} else {
				request.setInputInfo(new InputInfo().setGcs(new GoogleStorageConfig()).setPath(selectedFile.name));
			}

			requests.setRequestsList([request]);

			return JSON.stringify(requests.toObject()).replace('requestsList', 'requests')
		}
	}

	const onSubmit = async () => {
		const blueprint = convertBlueprintOverrideFormValuesToProto(selectedOverride!.blueprint);
		if (selectedPreset && selectedFile?.details?.format) {

			if (selectedAudioIdx !== undefined) {
				inputInfo?.setAudioStream(new Int64Value().setValue(selectedAudioIdx));
			}
			if (selectedVideoIdx !== undefined) {
				inputInfo?.setVideoStream(new Int64Value().setValue(selectedVideoIdx));
			}

			let fileName = selectedFile.name;
			const parts = fileName.split('/');
			fileName = parts[parts.length - 1].substring(0, 26);

			try {
				const baseId = selectedPreset.baseId !== '' ? selectedPreset.baseId : selectedPreset.id;
				const response = await callCreateJob(`File ${fileName}`, baseId, selectedFile.name, blueprint, inputInfo, outputInfo);
				console.log(response);
				navigate('/recent-jobs');
				notify(Notification.SUCCESS, 'Operation Completed', 'Job has been created successfully');
			} catch (error: any) {
				setError(`Failed to create a job. Please verify your data. ${error.message}`);
				console.log(error);
			}
		}
	};

	const isDisabled = () => {
		if (step === 0) {
			return !selectedFile;
		} else if (step === 1) {
			return !selectedCodec;
		} else if (step === 2) {
			return !selectedOverride;
		} else {
			return true;
		}
	};

	return (
		<Page tab="create-job">
			<CreateJobS>
				<Row style={{width: '100%'}}>
					<StepsWrapperS>
						<Space direction="vertical" size={20}>
							<div>
								<Steps current={step}>
									{steps.map(item => (
										<Step key={item.title} title={item.title}/>
									))}
								</Steps>
							</div>
							<StepContentS className="steps-content">{steps[step].content}</StepContentS>
							<Row justify="space-between">
								{step > 0 ? (
									<Button style={{margin: '0 8px'}} onClick={() => prev()}>
										Previous
									</Button>
								) : <div/>
								}
								<Row id='err' justify={'center'} align={'middle'} style={{textAlign: 'center'}}>
									{error && <ErrorLabel error={error}/>}
								</Row>
								{step < steps.length - 1 && (
									<Button disabled={isDisabled()} type="primary" onClick={() => next()}>
										Next
									</Button>
								)}
								{step === steps.length - 1 && (
									<Row>
										<Space>
											<ApiSample info={CreateJobInfo} body={prepareBody()}/>
											<Button disabled={!selectedFormats.length} type="primary" onClick={onSubmit}>
												Submit
											</Button>
										</Space>
									</Row>
								)}
							</Row>
						</Space>
					</StepsWrapperS>
				</Row>
			</CreateJobS>
		</Page>
	);
};
