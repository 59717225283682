import {PixelFormat} from '../../../autogen/gRPC/scheduler/enums_pb';

///
/// PixelFormats List
///

export const pixelFormats: PixelFormat[] = [
	PixelFormat.PIXELFORMAT_COPY,
	PixelFormat.PIXELFORMAT_BGR24,
	PixelFormat.PIXELFORMAT_RGB24,
	PixelFormat.PIXELFORMAT_RGBA,
	PixelFormat.PIXELFORMAT_YUV420P,
	PixelFormat.PIXELFORMAT_YUVJ420P,
	PixelFormat.PIXELFORMAT_YUYV422,
	PixelFormat.PIXELFORMAT_YUV422P,
	PixelFormat.PIXELFORMAT_YUV444P
];

///
/// PixelFormat to String
///

export const pixelFormatToString = (pixelFormat: PixelFormat) => {
	switch (pixelFormat) {
		case PixelFormat.PIXELFORMAT_COPY: {
			return 'COPY';
		}
		case PixelFormat.PIXELFORMAT_BGR24: {
			return 'BGR24';
		}
		case PixelFormat.PIXELFORMAT_RGB24: {
			return 'RGB24';
		}
		case PixelFormat.PIXELFORMAT_RGBA: {
			return 'RGBA';
		}
		case PixelFormat.PIXELFORMAT_YUV420P: {
			return 'YUV420P';
		}
		case PixelFormat.PIXELFORMAT_YUVJ420P: {
			return 'YUVJ420P';
		}
		case PixelFormat.PIXELFORMAT_YUYV422: {
			return 'YUYV422';
		}
		case PixelFormat.PIXELFORMAT_YUV422P: {
			return 'YUV422P';
		}
		case PixelFormat.PIXELFORMAT_YUV444P: {
			return 'YUV444P';
		}
		case PixelFormat.PIXELFORMAT_UNKNOWN: {
			return 'UNKNOWN';
		}
		default: {
			return 'UNKNOWN';
		}
	}
};

///
/// Categories String List Type
///

export type PixelFormatName = ReturnType<typeof pixelFormatToString>

///
/// PixelFormat string to eventAccess
///

export const pixelFormatStringToEnum = (pixelFormat: PixelFormatName) => {
	switch (pixelFormat.toUpperCase()) {
		case 'COPY': {
			return PixelFormat.PIXELFORMAT_COPY;
		}
		case 'BGR24': {
			return PixelFormat.PIXELFORMAT_BGR24;
		}
		case 'RGB24': {
			return PixelFormat.PIXELFORMAT_RGB24;
		}
		case 'RGBA': {
			return PixelFormat.PIXELFORMAT_RGBA;
		}
		case 'YUV420P': {
			return PixelFormat.PIXELFORMAT_YUV420P;
		}
		case 'YUV422P': {
			return PixelFormat.PIXELFORMAT_YUVJ420P;
		}
		case 'YUVJ420P': {
			return PixelFormat.PIXELFORMAT_YUYV422;
		}
		case 'YUYV422': {
			return PixelFormat.PIXELFORMAT_YUV422P;
		}
		case 'YUV444P': {
			return PixelFormat.PIXELFORMAT_YUV444P;
		}
		case 'UNKNOWN': {
			return PixelFormat.PIXELFORMAT_UNKNOWN;
		}
		default: {
			return PixelFormat.PIXELFORMAT_UNKNOWN;
		}
	}
};
