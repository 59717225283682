import React, {FC, useCallback, useEffect, useState} from 'react';
import {Page} from '../../../../components/Page/Page';
import {ManageFilesS, ManageFilesS_Dropzone} from './ManageFilesS';
import {Button, Modal, Result, Row, Space, Table, Tabs, Typography} from 'antd';
import {TableRowSelection} from 'antd/es/table/interface';
import {store} from '../../../../stores/store';
import {useAppDispatch, useAppSelector} from '../../../../stores/hooks';
import {DateUtils} from '../../../../utils/dates';
import {callDeleteUploadedFiles} from '../../api/requests';
import {filesActions, selectFile, selectFiles} from '../../../../stores/slices/files';
import {File as GrpcFile} from '../../../../autogen/gRPC/arranger/files/models_pb';
import {FileInput} from '../../../../components/FileInput/FileInput';
import Dropzone from 'react-dropzone';
import {UploadOutlined} from '@ant-design/icons';
import {handleFiles, onFileSelect, setupFilesManagement} from '../../utils/files';

const {TabPane} = Tabs;

interface FileProperties {
	key: string,
	name: string,
	size: string,
	type: string,
	created: string,

	url: string,
}

export const ManageFiles: FC = () => {
	const files = useAppSelector(selectFiles);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const [dragging, setDragging] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedRows, setSelectedRows] = useState<FileProperties[]>([]);
	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
		(async function () {
			await setupFilesManagement();
		})();

	}, []);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		await onDeleteClick();
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const prepareData = () => {
		return files.map((file) => convertProtoFile(file.file!));
	};

	const convertProtoFile = (file: GrpcFile.AsObject) => {
		const entry: FileProperties = {
			key: file.name,
			name: file.name,
			size: `${(Math.round((file.size / 1000000) * 100) / 100).toFixed(2)} MB`,
			type: file.type,
			created: DateUtils.convertProtoTimestamp(file.createdAt)[0],

			url: file.url
		};
		return entry;
	};

	const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: FileProperties[]) => {
		setSelectedRows(selectedRows);
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection: TableRowSelection<FileProperties> = {
		selectedRowKeys,
		onChange: onSelectChange
	};
	const hasSelected = selectedRowKeys.length > 0;

	const onDeleteClick = async () => {
		setLoading(true);
		const names = selectedRows.map((row) => row.name);
		try {
			await callDeleteUploadedFiles(names);
			dispatch(filesActions.removeFiles(names));
			setSelectedRows([]);
			setSelectedRowKeys([]);
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	const onDragEnter = () => {
		setDragging(true);
	};

	const onDragLeave = () => {
		setDragging(false);
	};

	const onDrop = useCallback(acceptedFiles => {
		handleFiles(acceptedFiles);
		setDragging(false);
	}, []);

	return (
		<Page tab="manage-files">
			<ManageFilesS>
				<Tabs style={{width: '100%'}} centered defaultActiveKey="1">
					<TabPane tab="Uploaded Files" key="1">
						<Row justify="space-between">
							<FileInput onChange={onFileSelect}/>
							<Space direction="horizontal" size={20}>
								<Button type="default" onClick={() => showModal()} disabled={!hasSelected}>Delete</Button>
							</Space>
						</Row>
						<span style={{margin: 8}}>
							{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
						</span>
						<Dropzone onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
							{({getRootProps}) => (
								<div {...getRootProps({
									onClick: event => event.stopPropagation()
								})}>
									{
										dragging ?
											<ManageFilesS_Dropzone>
												<Result
													icon={<UploadOutlined/>}
													title="Drop files here to upload them"
												/>
											</ManageFilesS_Dropzone>
											:
											<Table style={{height: '66vh'}} rowSelection={rowSelection as TableRowSelection<any>}
											       columns={TableColumns}
											       dataSource={prepareData()}
											       pagination={false}
											/>
									}
								</div>
							)}
						</Dropzone>
					</TabPane>
				</Tabs>
				<Modal title="Warning" centered visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} confirmLoading={loading}>
					<p>Are you sure you want to remove this file(s)?</p>
				</Modal>
			</ManageFilesS>
		</Page>
	);
};

const TableColumns = [
	{
		title: 'Name',
		dataIndex: 'name',
		width: '35%',
		render: (text: string) => <Typography.Text ellipsis={true} style={{maxWidth: '25vw'}}>
			<a href={selectFile(store.getState(), text)?.file?.url} target="_blank">
				{text}
			</a>
		</Typography.Text>
	},
	{
		title: 'Size',
		dataIndex: 'size',
		width: '20%'
	},
	{
		title: 'Type',
		dataIndex: 'type',
		width: '20%'
	},
	{
		title: 'Created',
		dataIndex: 'created',
		width: '25%'
	}
];
