import React, {FC, useEffect, useState} from 'react';
import {Page} from '../../../../components/Page/Page';
import {InfiniteList, ListItemProps} from '../../../../components/InfiniteList/InfiniteList';
import {useNavigate} from 'react-router-dom';
import {Button, Col, Divider, Progress, Row, Space, Spin, Tabs, Typography} from 'antd';
import {RecentJobsS, RecentJobsS_JobDetails} from './RecentJobsS';
import {callCancelJob, callDeleteJob, callListJobs} from '../../api/requests';
import {FileOutlined, RocketOutlined} from '@ant-design/icons';
import {jobStatusToString} from '../../domain/jobStatusConverter';
import {Job} from '../../../../autogen/gRPC/arranger/jobs/models_pb';
import {Status} from '../../../../autogen/gRPC/arranger/jobs/enums_pb';
import {PRIMARY} from '../../../../utils/colors';
import {remm} from '../../../../utils/remm';
import {Notification, notify} from '../../../../utils/notify';
import {useAppDispatch, useAppSelector} from '../../../../stores/hooks';
import {jobsActions, selectJobsAsc} from '../../../../stores/slices/jobs';

const {TabPane} = Tabs;

export const RecentJobs: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const jobs = useAppSelector(selectJobsAsc);

	const ItemsToLoad = 10;
	const [loadedJobs, setLoadedJobs] = useState<number>(0);
	const [totalJobs, setTotalJobs] = useState<number>(0);

	useEffect(() => {
		const ongoingJobs = jobs.filter((job) => !isDone(job));
		const finishedJobs = jobs.filter((job) => isDone(job));

		if (ongoingJobs.length) {
			const interval = setInterval(async () => {
				const jobs = await callListJobs(ongoingJobs.map((job) => job.id));
				const newJobs = [...jobs.jobsList, ...finishedJobs].sort((a, b) => a.createdAt <= b.createdAt ? 1 : -1);
				dispatch(jobsActions.setJobs(newJobs));
				if (!jobs.jobsList.filter((job) => !isDone(job)).length) {
					clearInterval(interval);
				}
				console.log('loaded');
			}, 5000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [jobs.length]);

	const loadJobs = async () => {
		const response = await callListJobs(undefined, loadedJobs, loadedJobs + ItemsToLoad);
		const newJobs = [...jobs, ...response.jobsList].sort((a, b) => a.createdAt <= b.createdAt ? 1 : -1);
		dispatch(jobsActions.setJobs(newJobs));
		setLoadedJobs(loadedJobs + response.jobsList.length);
		setTotalJobs(response.total);
		return convertJobs(newJobs);
	};

	const convertJobs = (jobs: Job.AsObject[]) => {
		return jobs.map((job) => {
			const itemProps: ListItemProps = {
				id: job.id,
				image: <RocketOutlined style={{fontSize: '70px'}}/>,
				title: job.name,
				description: prepareJobDetails(job),
				actions: [prepareJobActions(job)],
				collapsedContent: prepareContent(job)
			};
			return itemProps;
		});
	};

	const prepareContent = (job: Job.AsObject) => {

		if (job.status !== Status.STATUS_FINISHED) {
			return <div key={`job-content-${job.id}`}>
				<Typography.Text>Once job is finished successfully, you will be able to download result files.</Typography.Text>
			</div>;
		}

		const elements = job.resultInfosList.map((info) => {
			const files = info.outputPathsList.map((path) => {
				let name = '';
				const parts = path.split('?');
				if (parts.length) {
					const innerParts = parts[0].split('/');
					if (innerParts.length) {
						name = innerParts[innerParts.length - 1];
					}
				}
				return (
					<div key={`job-content-file${job.id}`}>
						<Row justify="space-between" align="middle">
							<Typography.Text>
								<FileOutlined style={{marginRight: '10px'}}/>
								{name}
							</Typography.Text>
							{/*<Divider type="vertical"/>*/}
							<Typography.Link href={path}>download</Typography.Link>
						</Row>
						<hr/>
					</div>
				);
			});
			return <div key={`job-content-output${job.id}`} style={{marginTop: '12px'}}>
				<Typography style={{marginBottom: '2px'}}>Output {info.output}:</Typography>
				<hr/>
				{files}
			</div>;
		});

		return (
			<div>
				<Typography.Text style={{fontSize: remm('17px')}}>Transcoded files:</Typography.Text>
				{elements}
			</div>
		);
	};

	const resolveProgressStatus = (job: Job.AsObject) => {
		if (job.status === Status.STATUS_FINISHED) {
			return 'success';
		} else if (job.status === Status.STATUS_CANCELLED || job.status === Status.STATUS_FAILED) {
			return 'exception';
		} else {
			return 'normal';
		}
	};

	const prepareJobDetails = (job: Job.AsObject) => {
		return (
			<RecentJobsS_JobDetails key={`job-details-${job.id}`}>
				<Col>
					<Row align="middle">
						{!isDone(job) &&
							<>
								<Spin style={{marginRight: '10px'}}/>
								<Typography.Text>Processing, please wait...</Typography.Text>
								<Divider type="vertical" style={{margin: '0 10px'}}/>
							</>
						}
						<Typography.Text>Status: {job.status ? jobStatusToString(job.status) : 'UNKNOWN'}</Typography.Text>
					</Row>
					<Progress status={resolveProgressStatus(job)}
					          style={{marginTop: '5px', marginBottom: '10px'}}
					          strokeColor={PRIMARY}
					          percent={Math.ceil(job.progress)}
					/>
				</Col>
			</RecentJobsS_JobDetails>
		);
	};

	const prepareJobActions = (job: Job.AsObject) => {
		return (
			<>
				<a style={{marginRight: '20px'}} key="list-jobs-delete">results</a>
				{
					isDone(job) ?
						<a key={`list-jobs-delete${job.id}`} onClick={async (e) => {
							try {
								e.stopPropagation();
								await callDeleteJob(job.id);
								setTotalJobs(totalJobs - 1);
								setLoadedJobs(loadedJobs - 1);
								dispatch(jobsActions.removeJob(job.id));
								notify(Notification.SUCCESS, 'Operation Completed', 'Job has been deleted successfully');
							} catch (e) {
								console.error(e);
								notify(Notification.ERROR, 'Operation Failed', 'Failed to delete job, please try again');
							}
						}}>delete</a>
						:
						<a key={`list-jobs-cancel${job.id}`} onClick={async (e) => {
							try {
								e.stopPropagation();
								await callCancelJob(job.id);
								dispatch(jobsActions.updateJob({...job, status: Status.STATUS_CANCELLED}));
								notify(Notification.SUCCESS, 'Operation Completed', 'Job has been canceled successfully');
							} catch (e) {
								console.error(e);
								notify(Notification.ERROR, 'Operation Failed', 'Failed to cancel job, please try again');
							}
						}}>cancel</a>
				}
			</>
		);
	};

	const isDone = (job: Job.AsObject) => {
		return job.status === Status.STATUS_FINISHED || job.status === Status.STATUS_CANCELLED || job.status === Status.STATUS_FAILED;
	};

	return (
		<Page tab="recent-jobs">
			<RecentJobsS>
				<Tabs centered defaultActiveKey="1">
					<TabPane tab="Jobs Management" key="1">
						<Space style={{width: '70vw'}} direction="vertical" size={22}>
							<Button type="primary" onClick={() => navigate('/create-job')}>Create Job</Button>
							<InfiniteList height="65vh" header="Recent Jobs" total={totalJobs} loader={loadJobs} overrideItems={convertJobs(jobs)}/>
						</Space>
					</TabPane>
				</Tabs>
			</RecentJobsS>
		</Page>
	);
};
