import {Checkbox, Form, Input, Modal, Select, Typography} from 'antd';
import React, {FC, useEffect, useState} from 'react';
import {validateRequired} from '../../../../../settings/domain/validators';
import {ErrorLabel} from '../../../../../../components/ErrorLabel/ErrorLabel';
import TextArea from 'antd/es/input/TextArea';

const {Option} = Select;

export interface CloudModalProps {
	visible: boolean;
	setVisible: (arg: boolean) => void;
	onSubmit: (values: CloudModalFormValues) => void;
	initialValues?: CloudModalFormValues;
}

export interface CloudModalFormValues {
	provider?: string;
	path?: string;
	details?: GcsDetails | AzureDetails | S3Details;
}

export interface GcsDetails {
	serviceAccount: Map<string, string>;
	bucketPolicyOnly: boolean;
}

export interface AzureDetails {
	account: string;
	key: string;
}

export interface S3Details {
	accessKeyId: string;
	secretAccessKey: string;
	endpoint: string;
	provider: string;
	region: string;
}

type CloudType = 'gcs' | 'azure' | 's3'

const providers = ['Google Cloud Storage', 'Azure', 'AWS', 'Scaleway', 'TencentCOS', 'Minio', 'Alibaba', 'Wasabi', 'Other (S3)'];

const retrieveCloudType = (provider: string) => {
	switch (provider) {
		case 'Google Cloud Storage':
			return 'gcs';
		case 'Azure':
			return 'azure';
		case 'AWS':
		case 'Scaleway':
		case 'Netease':
		case 'TencentCOS':
		case 'Minio':
		case 'Alibaba':
		case 'Wasabi':
		case 'Other (S3)':
			return 's3';
		default:
			return 'gcs';
	}
};

export const CloudModal: FC<CloudModalProps> = (props) => {
	const {visible, setVisible, onSubmit, initialValues} = props;

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState<CloudModalFormValues | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);
	const [cloudType, setCloudType] = useState<CloudType | undefined>('gcs');
	const [cloudProvider, setCloudProvider] = useState<string | undefined>(undefined);
	const [initial, setInitial] = useState<any>({});

	useEffect(() => {
		if (initialValues) {
			const values: any = {};
			values.cloudFilePath = initialValues.path;
			values.cloudProvider = initialValues.provider;

			if (initialValues.details && gcsConfigGuard(initialValues.details)) {
				setCloudType('gcs')
				values.cloudProvider = 'Google Cloud Storage';
				const details = initialValues.details as GcsDetails;
				const obj = Object.fromEntries(details.serviceAccount);
				values.gcsServiceAccount = JSON.stringify(obj, null, 2);
				values.bucketPolicyOnly = details.bucketPolicyOnly;
			}

			if (initialValues.details && azureConfigGuard(initialValues.details)) {
				setCloudType('azure')
				values.cloudProvider = 'Azure';
				const details = initialValues.details as AzureDetails;
				values.azureAccount = details.account;
				values.azureKey = details.key;
			}

			if (initialValues.details && s3ConfigGuard(initialValues.details)) {
				setCloudType('s3')
				const details = initialValues.details as S3Details;
				let provider = details.provider;
				if (provider) {
					provider = provider[0].toUpperCase() + provider.slice(1);
					setCloudProvider(provider)
				}
				values.cloudProvider = provider;
				values.s3AccessKeyId = details.accessKeyId;
				values.s3SecretAccessKey = details.secretAccessKey;
				values.s3Region = details.region;
				values.s3Endpoint = details.endpoint;
			}
			setInitial(values);
			onValuesChange();
		}
	}, [initialValues]);

	const handleOk = async () => {
		if (!values) return;

		setLoading(true);
		try {
			await onSubmit(values);
			setVisible(false);
		} catch (error: any) {
			setError(`Unable to check file. Please verify your data. ${error.message}`);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const prepareGcs = () => {
		return (
			<>
				<Form.Item label={<Typography.Text>Service Account</Typography.Text>}
				           name={`gcsServiceAccount`}
				           tooltip="Input service account credentials"
				           rules={validateRequired()}
				>
					<TextArea style={{height: '280px'}} placeholder={serviceAccountPlaceholder}/>
				</Form.Item>
				<Form.Item name={`gcsBucketPolicyOnly`}
				           tooltip="Check bucket policy only"
				           valuePropName="checked"
				           initialValue={true}
				>
					<Checkbox defaultChecked>Bucket Policy Only</Checkbox>
				</Form.Item>
			</>
		);
	};

	const prepareAzure = () => {
		return (
			<>
				<Form.Item label={<Typography.Text>Account</Typography.Text>}
				           name={`azureAccount`}
				           tooltip="Input account"
				           rules={validateRequired()}
				>
					<Input placeholder={'input account'}/>
				</Form.Item>
				<Form.Item label={<Typography.Text>Key</Typography.Text>}
				           name={`azureKey`}
				           tooltip="Input key"
				           rules={validateRequired()}
				>
					<Input placeholder={'input key'}/>
				</Form.Item>
			</>
		);
	};

	const prepareS3 = () => {
		return (
			<>
				<Form.Item label={<Typography.Text>Access Key Id</Typography.Text>}
				           name={`s3AccessKeyId`}
				           tooltip="Input access key id"
				           rules={validateRequired()}
				>
					<Input placeholder={retrievePlaceholders().accessKeyId}/>
				</Form.Item>
				<Form.Item label={<Typography.Text>Secret Access Key</Typography.Text>}
				           name={`s3SecretAccessKey`}
				           tooltip="Input secret access key"
				           rules={validateRequired()}
				>
					<Input placeholder={retrievePlaceholders().secretAccessKey}/>
				</Form.Item>
				<Form.Item label={<Typography.Text>Endpoint</Typography.Text>}
				           name={`s3Endpoint`}
				           tooltip="Input endpoint"
				           rules={cloudProvider === 'AWS' ? [] : validateRequired()}
				>
					<Input placeholder={retrievePlaceholders().endpoint}/>
				</Form.Item>
				<Form.Item label={<Typography.Text>Region</Typography.Text>}
				           name={`s3Region`}
				           tooltip="Input region"
				           rules={cloudProvider === 'AWS' ? [] : validateRequired()}
				>
					<Input placeholder={retrievePlaceholders().region}/>
				</Form.Item>
			</>
		);
	};

	const onValuesChange = () => {
		const formState = form.getFieldsValue();
		const result: CloudModalFormValues = {
			path: formState.cloudFilePath
		};

		const cloudType = retrieveCloudType(formState.cloudProvider);
		switch (cloudType) {
			case 'gcs':
				let json = {};
				try {
					json = JSON.parse(formState.gcsServiceAccount);
				} catch (e) {
				}
				const serviceAccount = new Map<string, string>(Object.entries(json));
				result.details = {
					serviceAccount: serviceAccount,
					bucketPolicyOnly: formState.gcsBucketPolicyOnly
				};
				break;
			case 'azure':
				result.details = {
					account: formState.azureAccount,
					key: formState.azureKey
				};
				break;
			case 's3':
				result.details = {
					accessKeyId: formState.s3AccessKeyId,
					secretAccessKey: formState.s3SecretAccessKey,
					endpoint: formState.s3Endpoint,
					provider: formState.cloudProvider,
					region: formState.s3Region
				};
				break;
		}

		setValues(result);
	};

	const retrievePlaceholders = () => {
		switch (cloudProvider) {
			case "AWS":
				return awsPlaceholders
			case "Scaleway":
				return scalewayPlaceholders
			case "Wasabi":
				return wasabiPlaceholders
			case "Alibaba":
				return alibabaPlaceholders
			case "TencentCOS":
				return tencentPlaceholders
			default:
				return {
					accessKeyId: "input access key id",
					secretAccessKey: "input secret access key",
					endpoint: "input endpoint",
					region: "input region",
				}
		}
	}

	return (
		<Modal title="File from cloud" centered visible={visible} onOk={handleOk} onCancel={handleCancel}
		       okButtonProps={{htmlType: 'submit'}} confirmLoading={loading}>
			<Form
				form={form}
				layout="vertical"
				autoComplete="Off"
				initialValues={initial}
				onValuesChange={onValuesChange}
			>
				<Form.Item label={<Typography.Text>Cloud Provider</Typography.Text>}
				           name={`cloudProvider`}
				           tooltip="Select cloud provider"
				           rules={validateRequired()}
				           initialValue={providers[0]}
				>
					<Select onChange={(e) => {
						const cloudType = retrieveCloudType(e);
						setCloudProvider(e)
						setCloudType(cloudType);
					}} placeholder="select provider">
						{providers.map((provider) => <Option key={provider}>{provider}</Option>)}
					</Select>
				</Form.Item>

				<Form.Item label={<Typography.Text>File Path</Typography.Text>}
				           name={`cloudFilePath`}
				           tooltip="Input file path in cloud storage"
				           rules={validateRequired()}
				>
					<Input placeholder="my-storage/entities/sample.mp4"/>
				</Form.Item>

				{cloudType === 'gcs' && prepareGcs()}
				{cloudType === 'azure' && prepareAzure()}
				{cloudType === 's3' && prepareS3()}

				<ErrorLabel error={error}/>
			</Form>
		</Modal>
	);
};

const serviceAccountPlaceholder = '{\n' +
	'  "type": "service_account",\n' +
	'  "project_id": "...",\n' +
	'  "private_key_id": "...",\n' +
	'  "private_key": "...",\n' +
	'  "client_email": "...",\n' +
	'  "client_id": "...",\n' +
	'  "auth_uri": "...",\n' +
	'  "token_uri": "...",\n' +
	'  "auth_provider_x509_cert_url": "...",\n' +
	'  "client_x509_cert_url": "..."\n' +
	'}\n';

const awsPlaceholders = {
	accessKeyId: "YOURACCESSKEY",
	secretAccessKey: "YOURSECRETACCESSKEY",
	endpoint: "leave blank to find automatically",
	region: "leave blank to find automatically",
}


const scalewayPlaceholders = {
	accessKeyId: "SCWXXXXXXXXXXXXXX",
	secretAccessKey: "1111111-2222-3333-44444-55555555555555",
	endpoint: "s3.nl-ams.scw.cloud",
	region: "nl-ams",
}

const wasabiPlaceholders = {
	accessKeyId: "YOURACCESSKEY",
	secretAccessKey: "YOURSECRETACCESSKEY",
	endpoint: "s3.eu-central-1.wasabisys.com",
	region: "eu-central-1 ",
}

const alibabaPlaceholders = {
	accessKeyId: "YOURACCESSKEY",
	secretAccessKey: "YOURSECRETACCESSKEY",
	endpoint: "oss-eu-central-1.aliyuncs.com",
	region: "eu-central-1 ",
}

const tencentPlaceholders = {
	accessKeyId: "YOURACCESSKEY",
	secretAccessKey: "YOURSECRETACCESSKEY",
	endpoint: "cos.ap-guangzhou.myqcloud.com",
	region: "eu-central-1 ",
}

export const gcsConfigGuard = (config: GcsDetails | AzureDetails | S3Details) => {
	if ('serviceAccount' in (config as any)) {
		return true;
	}
};

export const azureConfigGuard = (config: GcsDetails | AzureDetails | S3Details) => {
	if ('account' in (config as any)) {
		return true;
	}
};

export const s3ConfigGuard = (config: GcsDetails | AzureDetails | S3Details) => {
	if ('accessKeyId' in (config as any)) {
		return true;
	}
};
