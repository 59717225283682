import {SampleFormat} from '../../../autogen/gRPC/scheduler/enums_pb';

///
/// SampleFormats List
///

export const sampleFormats: SampleFormat[] = [
	SampleFormat.SAMPLEFORMAT_U8,
	SampleFormat.SAMPLEFORMAT_S16,
	SampleFormat.SAMPLEFORMAT_S32,
	SampleFormat.SAMPLEFORMAT_FLT,
	SampleFormat.SAMPLEFORMAT_U8P,
	SampleFormat.SAMPLEFORMAT_S16P,
	SampleFormat.SAMPLEFORMAT_S32P,
	SampleFormat.SAMPLEFORMAT_FLTP,
	SampleFormat.SAMPLEFORMAT_NONE
];

///
/// SampleFormat to String
///

export const sampleFormatToString = (sampleFormat: SampleFormat) => {
	switch (sampleFormat) {
		case SampleFormat.SAMPLEFORMAT_U8: {
			return 'U8';
		}
		case SampleFormat.SAMPLEFORMAT_S16: {
			return 'S16';
		}
		case SampleFormat.SAMPLEFORMAT_S32: {
			return 'S32';
		}
		case SampleFormat.SAMPLEFORMAT_FLT: {
			return 'FLT';
		}
		case SampleFormat.SAMPLEFORMAT_U8P: {
			return 'U8P';
		}
		case SampleFormat.SAMPLEFORMAT_S16P: {
			return 'S16P';
		}
		case SampleFormat.SAMPLEFORMAT_S32P: {
			return 'S32P';
		}
		case SampleFormat.SAMPLEFORMAT_FLTP: {
			return 'FLTP';
		}
		case SampleFormat.SAMPLEFORMAT_NONE: {
			return 'NONE';
		}
		case SampleFormat.SAMPLEFORMAT_UNKNOWN: {
			return 'UNKNOWN';
		}
		default: {
			return 'UNKNOWN';
		}
	}
};

///
/// Categories String List Type
///

export type SampleFormatName = ReturnType<typeof sampleFormatToString>

///
/// SampleFormat string to eventAccess
///

export const sampleFormatStringToEnum = (sampleFormat: SampleFormatName) => {
	switch (sampleFormat.toUpperCase()) {
		case 'U8': {
			return SampleFormat.SAMPLEFORMAT_U8;
		}
		case 'S16': {
			return SampleFormat.SAMPLEFORMAT_S16;
		}
		case 'S32': {
			return SampleFormat.SAMPLEFORMAT_S32;
		}
		case 'FLT': {
			return SampleFormat.SAMPLEFORMAT_FLT;
		}
		case 'U8P': {
			return SampleFormat.SAMPLEFORMAT_U8P;
		}
		case 'S16P': {
			return SampleFormat.SAMPLEFORMAT_S16P;
		}
		case 'S32P': {
			return SampleFormat.SAMPLEFORMAT_S32P;
		}
		case 'FLTP': {
			return SampleFormat.SAMPLEFORMAT_FLTP;
		}
		case 'UNKNOWN': {
			return SampleFormat.SAMPLEFORMAT_UNKNOWN;
		}
		default: {
			return SampleFormat.SAMPLEFORMAT_UNKNOWN;
		}
	}
};
