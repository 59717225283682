import React, {FC, useState} from 'react';
import {Button, Col, Divider, Form, Input, Row, Typography} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import {preparePropertyDetails} from '../../util/propertyDetails';
import {PrivateDataSS_LongRow} from './PrivateDataS';

export interface PrivateDataProps {
	idx: number,
	name: string
	header?: boolean
	details?: boolean
	initialIndexes?: number[],
}

export const PrivateData: FC<PrivateDataProps> = ({
	idx,
	name,
	header,
	details,
	initialIndexes
}) => {
	const [indexes, setIndexes] = useState(initialIndexes ? initialIndexes : [0]);

	return (
		<div style={{width: '100%'}}>
			{header &&
				<Row justify="center" style={{width: '100%'}}>
					<Typography.Text style={{fontSize: '20px'}}>Custom Settings</Typography.Text>
					<Divider/>
				</Row>
			}
			{indexes.map((index) => (
				<Col style={{width: '100%'}}>
					<Row justify="center">
						<PrivateDataSS_LongRow style={{gap: 30, marginLeft: index === 0 ? '30px' : '0'}}>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text>Property</Typography.Text>}
							           name={`customProperty|${name}|${idx}|${index}`}
							           tooltip="Set custom key value pair settings"
							>
								<Input placeholder="optional"/>
							</Form.Item>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text>Value</Typography.Text>}
							           name={`customValue|${name}|${idx}|${index}`}
							>
								<Input placeholder="optional"/>
							</Form.Item>
							{index === 0 &&
								<div style={{marginLeft: '-30px'}}>
									<Button icon={<PlusCircleOutlined/>} shape="circle" type="text" onClick={() => {
										setIndexes([...indexes, indexes.length]);
									}}/>
								</div>
							}
						</PrivateDataSS_LongRow>
					</Row>
					{details &&
						<Row justify="center">
							<div style={{marginLeft: '40px'}}>
								{preparePropertyDetails(idx, `CustomDetails|${name}|${idx}|${index}`)}
							</div>
						</Row>
					}
				</Col>
			))}
		</div>
	);
};
