import React, {FC, useState} from 'react';
import {GenericOutput} from '../GenericOutput/GenericOutput';
import {Button, Col, Divider, Form, Input, Row, Select, Typography} from 'antd';
import {validateRequired} from '../../../settings/domain/validators';
import {PlusCircleFilled} from '@ant-design/icons';
import {VideoAudioMapping} from '../VideoAudioMapping/VideoAudioMapping';
import {GenericOutputS_BaseOptionsWrapper} from '../GenericOutput/GenericOutputS';
import {outputFormats} from '../../../settings/components/CreatePreset/CreatePreset';
import {PrivateData} from '../PrivateData/PrivateData';

const {Option} = Select;

export interface FileOutputProps {
	audioNameIdxMap: Map<number, string>;
	videoNameIdxMap: Map<number, string>;
	deletable?: boolean;
	initialIndexes?: number[];
	initialMappings?: number[][];
	initialFormatIdxMap?: Map<number, string>;
	privateDataFileInitial?: number[][];
}

export const FileOutput: FC<FileOutputProps> = (props) => {
	const {audioNameIdxMap, videoNameIdxMap, deletable, initialIndexes, initialMappings, initialFormatIdxMap, privateDataFileInitial} = props;

	const [indexes, setIndexes] = useState(initialIndexes ? initialIndexes : [0]);
	const [formatIdxMap, setFormatIdxMap] = useState<Map<number, string>>(initialFormatIdxMap ? initialFormatIdxMap : new Map<number, string>());

	const prepareHeader = (title: string, onClick: () => void) => (
		<Row align="middle" justify="space-between">
			<Typography.Title>{title}</Typography.Title>
			<div style={{paddingBottom: '20px'}}>
				<Button style={{width: '50px', height: '50px'}} shape="circle" icon={<PlusCircleFilled style={{fontSize: '40px'}}/>} type="text"
				        onClick={() => onClick()}/>
			</div>
		</Row>
	);

	const onDelete = (idx: number) => setIndexes(indexes.filter((_, index) => index !== idx));

	const prepareTopContent = () => {
		return (
			<Row justify="center">
				<Typography.Text style={{fontSize: '20px'}}>Base Settings</Typography.Text>
				<Divider/>
			</Row>
		);
	};

	const prepareBottomContent = (idx: number) => (
		<div>
			<Col>
				<GenericOutputS_BaseOptionsWrapper>
					<div>
						<Form.Item
							label={<Typography.Text>File Output Name</Typography.Text>}
							name={`fileOutputName${idx}`}
							tooltip="Enter file output name (any)"
							rules={validateRequired()}
						>
							<Input placeholder="input file output name"/>
						</Form.Item>
					</div>
					<div>
						<Form.Item
							label={<Typography.Text>Format Name</Typography.Text>}
							name={`fileFormatName${idx}`}
							tooltip="Enter format name for this file output"
							rules={validateRequired()}
						>
							<Select onChange={(value) => {
								const copy = new Map(formatIdxMap);
								copy.set(idx, value);
								setFormatIdxMap && setFormatIdxMap(copy);
							}} placeholder="select output format">
								{outputFormats().map((option) =>
									<Option value={option.toLowerCase()}>{option}</Option>
								)}
							</Select>
						</Form.Item>
					</div>
				</GenericOutputS_BaseOptionsWrapper>
				<GenericOutputS_BaseOptionsWrapper>
					<VideoAudioMapping idx={idx} formatName={formatIdxMap.get(idx)!} audioOutputs={Array.from(audioNameIdxMap.values())}
					                   videoOutputs={Array.from(videoNameIdxMap.values())}
					                   initialIndexes={initialMappings ? initialMappings[idx] : [0]}/>
				</GenericOutputS_BaseOptionsWrapper>
			</Col>
			<GenericOutputS_BaseOptionsWrapper style={{marginTop: '30px'}}>
				<PrivateData idx={idx} name={'file'} header={true} initialIndexes={privateDataFileInitial?.at(idx)}/>
			</GenericOutputS_BaseOptionsWrapper>
		</div>
	);

	const prepareOutput = (idx: number) => (
		<GenericOutput idx={idx} title={`File Output #${idx + 1}`} onDelete={deletable && indexes.length > 1 ? onDelete : undefined}
		               topContent={prepareTopContent()} bottomContent={prepareBottomContent(idx)}
		>
		</GenericOutput>
	);

	const prepareOutputs = () => (
		<Col>
			{prepareHeader('File Outputs', () => setIndexes([...indexes, indexes.length]))}
			{indexes.map((idx) => prepareOutput(idx))}
		</Col>
	);

	return prepareOutputs();
};
