import React, {FC, useState} from 'react';
import {GenericOutput} from '../GenericOutput/GenericOutput';
import {Button, Col, Divider, Form, Input, InputNumber, Row, Select, Typography} from 'antd';
import {validateRequired} from '../../../settings/domain/validators';
import {preparePropertyDetails} from '../../util/propertyDetails';
import {PlusCircleFilled} from '@ant-design/icons';
import {channelLayouts, channelLayoutToString} from '../../../settings/domain/channelLayoutConverter';
import {sampleFormats, sampleFormatToString} from '../../../settings/domain/sampleFormatConverter';
import {GenericOutputS_BaseOptionsWrapper, GenericOutputS_MultiField, GenericOutputS_Separator} from '../GenericOutput/GenericOutputS';
import {PrivateData} from '../PrivateData/PrivateData';

const {Option} = Select;

export interface AudioOutputProps {
	deletable?: boolean;
	withHeader?: boolean;
	nameIdxMap?: Map<number, string>;
	setNameIdxMap?: (arg: Map<number, string>) => void;
	initialIndexes?: number[];
	privateDataAudioInitial?: number[][];
}

export const AudioOutput: FC<AudioOutputProps> = (props) => {
	const {deletable, withHeader, nameIdxMap, setNameIdxMap, initialIndexes, privateDataAudioInitial} = props;

	const [indexes, setIndexes] = useState(initialIndexes ? initialIndexes : [0]);

	const prepareHeader = (title: string, onClick: () => void) => (
		<Row align="middle" justify="space-between">
			<Typography.Title>{title}</Typography.Title>
			<div style={{paddingBottom: '20px'}}>
				<Button style={{width: '50px', height: '50px'}} shape="circle" icon={<PlusCircleFilled style={{fontSize: '40px'}}/>} type="text"
				        onClick={() => onClick()}/>
			</div>
		</Row>
	);

	const onDelete = (idx: number) => setIndexes(indexes.filter((_, index) => index !== idx));

	const prepareTopContent = () => {
		return (
			<Row justify="center">
				<Typography.Text style={{fontSize: '20px'}}>Base Settings</Typography.Text>
				<Divider/>
			</Row>
		);
	};

	const prepareBottomContent = (idx: number) => (
		<GenericOutputS_BaseOptionsWrapper>
			<PrivateData idx={idx} name={'audio'} header={true} details={true} initialIndexes={privateDataAudioInitial?.at(idx)}/>
		</GenericOutputS_BaseOptionsWrapper>
	);

	const prepareOutput = (idx: number) => (
		<GenericOutput idx={idx} title={`Audio Output #${idx + 1}`} onDelete={deletable && indexes.length > 1 ? onDelete : undefined}
		               topContent={prepareTopContent()} bottomContent={prepareBottomContent(idx)}
		>
			<div>
				<Form.Item
					label={<Typography.Text>Audio Output Name</Typography.Text>}
					name={`audioOutputName${idx}`}
					tooltip="Enter audio output name (any)"
					rules={validateRequired()}
				>
					<Input onChange={(e) => {
						const copy = new Map(nameIdxMap);
						copy.set(idx, e.target.value);
						setNameIdxMap && setNameIdxMap(copy);
					}} placeholder="input audio output name"/>
				</Form.Item>
			</div>
			<div>
				<Form.Item
					label={<Typography.Text>Encoder Name</Typography.Text>}
					name={`audioEncoderName${idx}`}
					tooltip="Enter encoder name for this audio output"
					rules={validateRequired()}
				>
					<Input placeholder="input encoder name"/>
				</Form.Item>
			</div>
			<GenericOutputS_Separator/>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Bitrate</Typography.Text>}
				           name={`audioBitrate${idx}`}
				           tooltip="Enter encoder name for this audio output"
				           rules={validateRequired()}
				>
					<InputNumber min={0} placeholder="input encoder name"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'AudioBitrate')}
			</div>

			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Channel Layout</Typography.Text>}
				           name={`audioChannelLayout${idx}`}
				           tooltip="Select channel layout for this audio output"
				           rules={validateRequired()}
				>
					<Select placeholder="select channel layout">
						{channelLayouts.map(channelLayoutToString).map((option) =>
							<Option value={option.toLowerCase()}>{option}</Option>
						)}
					</Select>
				</Form.Item>
				{preparePropertyDetails(idx, 'AudioChannelLayout')}
			</div>

			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Sample Format</Typography.Text>}
				           name={`audioSampleFormat${idx}`}
				           tooltip="Select sample format for this audio output"
				           rules={validateRequired()}
				>
					<Select placeholder="select sample format">
						{sampleFormats.map(sampleFormatToString).map((option) =>
							<Option value={option.toLowerCase()}>{option}</Option>
						)}
					</Select>
				</Form.Item>
				{preparePropertyDetails(idx, 'AudioSampleFormat')}
			</div>

			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Channels Number</Typography.Text>}
				           name={`audioChannels${idx}`}
				           tooltip="Enter channels number for this audio output"

				>
					<InputNumber min={0} placeholder="input channels number"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'AudioChannels')}
			</div>

			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Sample Rate</Typography.Text>}
				           name={`audioSampleRate${idx}`}
				           tooltip="Enter sample rate for this audio output"
				           rules={validateRequired()}
				>
					<InputNumber min={0} placeholder="input sample rate"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'AudioSampleRate')}
			</div>

			<div>
				<GenericOutputS_MultiField>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Time Base</Typography.Text>}
					           name={`audioTimeBaseNom${idx}`}
					           tooltip="Enter time base for this audio output"

					>
						<InputNumber min={0} placeholder="input nominator"/>
					</Form.Item>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text></Typography.Text>}
					           name={`audioTimeBaseDen${idx}`}

					>
						<InputNumber min={0} placeholder="input denominator"/>
					</Form.Item>
				</GenericOutputS_MultiField>
				{preparePropertyDetails(idx, 'AudioTimeBase')}
			</div>
		</GenericOutput>
	);

	const prepareOutputs = () => (
		<Col>
			{withHeader && prepareHeader('Audio Outputs', () => setIndexes([...indexes, indexes.length]))}
			{indexes.map((idx) => prepareOutput(idx))}
		</Col>
	);

	return prepareOutputs();
};
