import styled from 'styled-components';
import {GREY_1} from '../../../../../utils/colors';
import {ListSelectS_Container} from '../../../../../components/ListSelect/ListSelectS';

export const SelectSourceS = styled.div<{ selected: boolean }>`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 40px 4vw;
	overflow: auto;

	svg {
		color: white;
	}

	${ListSelectS_Container} {
		min-height: ${({selected}) => selected ? '22vh' : '45vh'};
	}
`;

export const SelectSourceS_Buttons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;

	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

export const SelectSourceS_Dropzone = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${GREY_1};
	min-height: 45vh;
	width: 49%;
`;
