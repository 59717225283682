import {CreatePresetFormValues} from '../components/CreatePreset/CreatePreset';
import {AudioOutput, FileOutput, newAudioOutput, newFileOutput, newVideoOutput, VideoAudioMapper, VideoOutput} from './blueprint';
import {ChannelLayoutName} from './channelLayoutConverter';
import {SampleFormatName} from './sampleFormatConverter';
import {PassModeName} from './passModeConverter';
import {ColorSpaceName} from './colorSpaceConverter';
import {PixelFormatName} from './pixelFormatConverter';

export const convertFormStateToFormValues = (formState: any) => {
	const result: CreatePresetFormValues = {
		name: formState.presetName,
		category: formState.presetCategory,
		nodePoolName: formState.nodePoolName,
		blueprint: {
			audioOutputs: new Map<string, AudioOutput>(),
			fileOutputs: new Map<string, FileOutput>(),
			videoOutputs: new Map<string, VideoOutput>()
		}
	};

	/*
	 ** Audio
	 */

	const audioInputIdxMap = new Map<number, string>();
	console.log(formState, 'test form');
	for (const [field, value] of Object.entries(formState)) {

		if (field.startsWith('audioOutputName')) {
			const idx = parseInt(field.charAt(field.length - 1));
			result.blueprint.audioOutputs.set(value as string, newAudioOutput());
			audioInputIdxMap.set(idx, value as string);
		}

		if (field.startsWith('audioEncoderName')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.encoderName = value as string;
				}
			}
		}

		/// Bitrate

		if (field.startsWith('audioBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.bitrate.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredAudioBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.bitrate.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableAudioBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.bitrate.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenAudioBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.bitrate.details.hidden = value as boolean;
				}
			}
		}

		/// ChannelLayout

		if (field.startsWith('audioChannelLayout')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.channelLayout.value = value as ChannelLayoutName;
				}
			}
		}
		if (field.startsWith('requiredAudioChannelLayout')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.channelLayout.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableAudioChannelLayout')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.channelLayout.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenAudioChannelLayout')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.channelLayout.details.hidden = value as boolean;
				}
			}
		}

		/// SampleFormat

		if (field.startsWith('audioSampleFormat')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.sampleFormat.value = value as SampleFormatName;
				}
			}
		}
		if (field.startsWith('requiredAudioSampleFormat')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.sampleFormat.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableAudioSampleFormat')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.sampleFormat.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenAudioSampleFormat')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.sampleFormat.details.hidden = value as boolean;
				}
			}
		}

		/// Channels

		if (field.startsWith('audioChannels')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput) {
					audioOutput.channels.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredAudioChannels')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.channels) {
					audioOutput.channels.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableAudioChannels')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.channels) {
					audioOutput.channels.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenAudioChannels')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.channels) {
					audioOutput.channels.details.hidden = value as boolean;
				}
			}
		}

		/// SampleRate

		if (field.startsWith('audioSampleRate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.sampleRate) {
					audioOutput.sampleRate.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredAudioSampleRate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.sampleRate) {
					audioOutput.sampleRate.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableAudioSampleRate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.sampleRate) {
					audioOutput.sampleRate.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenAudioSampleRate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.sampleRate) {
					audioOutput.sampleRate.details.hidden = value as boolean;
				}
			}
		}

		/// TimeBase

		if (field.startsWith('audioTimeBaseNom')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.timeBase) {
					audioOutput.timeBase.value.nom = value as number;
				}
			}
		}
		if (field.startsWith('audioTimeBaseDen')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.timeBase) {
					audioOutput.timeBase.value.den = value as number;
				}
			}
		}
		if (field.startsWith('requiredAudioTimeBase')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.timeBase) {
					audioOutput.timeBase.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableAudioTimeBase')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.timeBase) {
					audioOutput.timeBase.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenAudioTimeBase')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const audioOutputName = audioInputIdxMap.get(idx);
			if (audioOutputName) {
				const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
				if (audioOutput?.timeBase) {
					audioOutput.timeBase.details.hidden = value as boolean;
				}
			}
		}
	}

	/*
	 ** Video
	 */

	const videoInputIdxMap = new Map<number, string>();
	for (const [field, value] of Object.entries(formState)) {

		if (field.startsWith('videoOutputName')) {
			const idx = parseInt(field.charAt(field.length - 1));
			result.blueprint.videoOutputs.set(value as string, newVideoOutput());
			videoInputIdxMap.set(idx, value as string);
		}

		if (field.startsWith('videoEncoderName')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.encoderName = value as string;
				}
			}
		}

		/// MaxGop

		if (field.startsWith('videoMaxGop')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.maxGop.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoMaxGop')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.maxGop.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoMaxGop')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.maxGop.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoMaxGop')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.maxGop.details.hidden = value as boolean;
				}
			}
		}

		/// Width

		if (field.startsWith('videoWidth')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.width.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoWidth')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.width.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoWidth')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.width.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoWidth')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.width.details.hidden = value as boolean;
				}
			}
		}

		/// Height

		if (field.startsWith('videoHeight')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.height.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoHeight')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.height.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoHeight')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.height.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoHeight')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.height.details.hidden = value as boolean;
				}
			}
		}

		/// Bitrate

		if (field.startsWith('videoBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.bitrate.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.bitrate.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.bitrate.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.bitrate.details.hidden = value as boolean;
				}
			}
		}

		/// Refs

		if (field.startsWith('videoRefs')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.refs) {
					videoOutput.refs.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoRefs')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.refs) {
					videoOutput.refs.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoRefs')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.refs) {
					videoOutput.refs.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoRefs')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.refs) {
					videoOutput.refs.details.hidden = value as boolean;
				}
			}
		}

		/// PixelFormat

		if (field.startsWith('videoPixelFormat')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.pixelFormat.value = value as PixelFormatName;
				}
			}
		}
		if (field.startsWith('requiredVideoPixelFormat')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.pixelFormat.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoPixelFormat')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.pixelFormat.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoPixelFormat')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.pixelFormat.details.hidden = value as boolean;
				}
			}
		}

		/// FrameRate

		if (field.startsWith('videoFrameRateNom')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.frameRate) {
					videoOutput.frameRate.value.nom = value as number;
				}
			}
		}
		if (field.startsWith('videoFrameRateDen')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.frameRate) {
					videoOutput.frameRate.value.den = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoFrameRate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.frameRate) {
					videoOutput.frameRate.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoFrameRate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.frameRate) {
					videoOutput.frameRate.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoFrameRate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.frameRate) {
					videoOutput.frameRate.details.hidden = value as boolean;
				}
			}
		}

		/// TimeBase

		if (field.startsWith('videoTimeBaseNom')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.timeBase) {
					videoOutput.timeBase.value.nom = value as number;
				}
			}
		}
		if (field.startsWith('videoTimeBaseDen')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.timeBase) {
					videoOutput.timeBase.value.den = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoTimeBase')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.timeBase) {
					videoOutput.timeBase.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoTimeBase')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.timeBase) {
					videoOutput.timeBase.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoTimeBase')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.timeBase) {
					videoOutput.timeBase.details.hidden = value as boolean;
				}
			}
		}

		/// Filter

		if (field.startsWith('videoFilter')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.filter.value = value as string;
				}
			}
		}
		if (field.startsWith('requiredVideoFilter')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.filter.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoFilter')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.filter.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoFilter')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.filter.details.hidden = value as boolean;
				}
			}
		}

		/// Level

		if (field.startsWith('videoLevel')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.level) {
					videoOutput.level.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoLevel')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.level) {
					videoOutput.level.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoLevel')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.level) {
					videoOutput.level.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoLevel')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.level) {
					videoOutput.level.details.hidden = value as boolean;
				}
			}
		}

		/// ColorSpace

		if (field.startsWith('videoColorSpace')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.colorSpace.value = value as ColorSpaceName;
				}
			}
		}
		if (field.startsWith('requiredVideoColorSpace')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.colorSpace.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoColorSpace')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.colorSpace.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoColorSpace')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput) {
					videoOutput.colorSpace.details.hidden = value as boolean;
				}
			}
		}

		/// PassMode

		if (field.startsWith('videoPassMode')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.passMode) {
					videoOutput.passMode.value = value as PassModeName;
				}
			}
		}
		if (field.startsWith('requiredVideoPassMode')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.passMode) {
					videoOutput.passMode.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoPassMode')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.passMode) {
					videoOutput.passMode.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoPassMode')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.passMode) {
					videoOutput.passMode.details.hidden = value as boolean;
				}
			}
		}

		/// RcMaxBitrate

		if (field.startsWith('videoRcMaxBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.rcMaxBitrate) {
					videoOutput.rcMaxBitrate.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoRcMaxBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.rcMaxBitrate) {
					videoOutput.rcMaxBitrate.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoRcMaxBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.rcMaxBitrate) {
					videoOutput.rcMaxBitrate.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoRcMaxBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.rcMaxBitrate) {
					videoOutput.rcMaxBitrate.details.hidden = value as boolean;
				}
			}
		}

		/// RcMinBitrate

		if (field.startsWith('videoRcMinBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.rcMinBitrate) {
					videoOutput.rcMinBitrate.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoRcMinBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.rcMinBitrate) {
					videoOutput.rcMinBitrate.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoRcMinBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.rcMinBitrate) {
					videoOutput.rcMinBitrate.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoRcMinBitrate')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.rcMinBitrate) {
					videoOutput.rcMinBitrate.details.hidden = value as boolean;
				}
			}
		}

		/// Resources

		if (field.startsWith('videoResourcesCpu')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.resources) {
					videoOutput.resources.value.cpu = value as number;
				}
			}
		}
		if (field.startsWith('videoResourcesGpu')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.resources) {
					videoOutput.resources.value.gpu = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoResources')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.resources) {
					videoOutput.resources.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoResources')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.resources) {
					videoOutput.resources.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoResources')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.resources) {
					videoOutput.resources.details.hidden = value as boolean;
				}
			}
		}

		/// BufSize

		if (field.startsWith('videoBufSize')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.bufSize) {
					videoOutput.bufSize.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoBufSize')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.bufSize) {
					videoOutput.bufSize.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoBufSize')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.bufSize) {
					videoOutput.bufSize.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoBufSize')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.bufSize) {
					videoOutput.bufSize.details.hidden = value as boolean;
				}
			}
		}

		/// MaxBFrames

		if (field.startsWith('videoMaxBFrames')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.maxBFrames) {
					videoOutput.maxBFrames.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoMaxBFrames')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.maxBFrames) {
					videoOutput.maxBFrames.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoMaxBFrames')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.maxBFrames) {
					videoOutput.maxBFrames.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoMaxBFrames')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.maxBFrames) {
					videoOutput.maxBFrames.details.hidden = value as boolean;
				}
			}
		}

		/// KeyIntMin

		if (field.startsWith('videoKeyIntMin')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.keyIntMin) {
					videoOutput.keyIntMin.value = value as number;
				}
			}
		}
		if (field.startsWith('requiredVideoKeyIntMin')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.keyIntMin) {
					videoOutput.keyIntMin.details.required = value as boolean;
				}
			}
		}
		if (field.startsWith('editableVideoKeyIntMin')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.keyIntMin) {
					videoOutput.keyIntMin.details.editable = value as boolean;
				}
			}
		}
		if (field.startsWith('hiddenVideoKeyIntMin')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const videoOutputName = videoInputIdxMap.get(idx);
			if (videoOutputName) {
				const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
				if (videoOutput?.keyIntMin) {
					videoOutput.keyIntMin.details.hidden = value as boolean;
				}
			}
		}
	}

	/*
	 ** File
	 */

	const fileInputIdxMap = new Map<number, string>();
	for (const [field, value] of Object.entries(formState)) {

		if (field.startsWith('fileOutputName')) {
			const idx = parseInt(field.charAt(field.length - 1));
			result.blueprint.fileOutputs.set(value as string, newFileOutput());
			fileInputIdxMap.set(idx, value as string);
		}

		if (field.startsWith('fileFormatName')) {
			const idx = parseInt(field.charAt(field.length - 1));
			const fileOutputName = fileInputIdxMap.get(idx);
			if (fileOutputName) {
				const fileOutput = result.blueprint.fileOutputs.get(fileOutputName);
				if (fileOutput) {
					fileOutput.formatName = value as string;
				}
			}
		}

		if (field.startsWith('audioOutput')) {
			const elems = field.split('|');
			if (elems.length === 3) {
				const fileOutputIdx = parseInt(elems[1]);
				const videoAudioIdx = parseInt(elems[2]);

				const fileOutputName = fileInputIdxMap.get(fileOutputIdx);
				if (fileOutputName) {
					const fileOutput = result.blueprint.fileOutputs.get(fileOutputName);
					if (fileOutput) {
						const result = fileOutput.videoAudioMap.find((_, index) => index === videoAudioIdx);
						if (result) {
							result.audio = value as string;
						} else {
							const mapper: VideoAudioMapper = {
								idx: videoAudioIdx,
								audio: value as string,
								video: ''
							};
							fileOutput.videoAudioMap.push(mapper);
						}
					}
				}
			}
		}

		if (field.startsWith('videoOutput')) {
			const elems = field.split('|');
			if (elems.length === 3) {
				const fileOutputIdx = parseInt(elems[1]);
				const videoAudioIdx = parseInt(elems[2]);

				const fileOutputName = fileInputIdxMap.get(fileOutputIdx);
				if (fileOutputName) {
					const fileOutput = result.blueprint.fileOutputs.get(fileOutputName);
					if (fileOutput) {
						const result = fileOutput.videoAudioMap.find((_, index) => index === videoAudioIdx);
						if (result) {
							result.video = value as string;
						} else {
							const mapper: VideoAudioMapper = {
								idx: videoAudioIdx,
								audio: '',
								video: value as string
							};
							fileOutput.videoAudioMap.push(mapper);
						}
					}
				}
			}
		}
	}

	/*
   * Custom Properties
   */

	interface CustomSetting {
		property?: string;
		value?: string;

		required?: boolean;
		editable?: boolean;
		hidden?: boolean;
	}

	const customSettings = new Map<string, CustomSetting>();
	for (const [field, value] of Object.entries(formState)) {
		if (field.startsWith('customProperty')) {
			const elems = field.split('|');
			if (elems.length === 4) {
				const type = elems[1];
				const firstIdx = parseInt(elems[2]);
				const secondIdx = parseInt(elems[3]);

				const id = `${type}|${firstIdx}|${secondIdx}`;
				const current = customSettings.get(id);

				if (current) {
					current.property = value as string;
					customSettings.set(id, current);
				} else {
					customSettings.set(id, {
						property: value as string
					});
				}
			}
		}

		if (field.startsWith('customValue')) {
			const elems = field.split('|');
			if (elems.length === 4) {
				const type = elems[1];
				const firstIdx = parseInt(elems[2]);
				const secondIdx = parseInt(elems[3]);

				const id = `${type}|${firstIdx}|${secondIdx}`;
				const current = customSettings.get(id);

				if (current) {
					current.value = value as string;
					customSettings.set(id, current);
				} else {
					customSettings.set(id, {
						value: value as string
					});
				}
			}
		}

		if (field.startsWith('requiredCustomDetails')) {
			const elems = field.split('|');
			if (elems.length === 4) {
				const type = elems[1];
				const firstIdx = parseInt(elems[2]);
				const secondIdx = parseInt(elems[3][0]);

				const id = `${type}|${firstIdx}|${secondIdx}`;
				const current = customSettings.get(id);

				if (current) {
					current.required = value as boolean;
					customSettings.set(id, current);
				} else {
					customSettings.set(id, {
						required: value as boolean
					});
				}
			}
		}

		if (field.startsWith('editableCustomDetails')) {
			const elems = field.split('|');
			if (elems.length === 4) {
				const type = elems[1];
				const firstIdx = parseInt(elems[2]);
				const secondIdx = parseInt(elems[3][0]);

				const id = `${type}|${firstIdx}|${secondIdx}`;
				const current = customSettings.get(id);

				if (current) {
					current.editable = value as boolean;
					customSettings.set(id, current);
				} else {
					customSettings.set(id, {
						editable: value as boolean
					});
				}
			}
		}

		if (field.startsWith('hiddenCustomDetails')) {
			const elems = field.split('|');
			if (elems.length === 4) {
				const type = elems[1];
				const firstIdx = parseInt(elems[2]);
				const secondIdx = parseInt(elems[3][0]);

				const id = `${type}|${firstIdx}|${secondIdx}`;
				const current = customSettings.get(id);

				if (current) {
					current.hidden = value as boolean;
					customSettings.set(id, current);
				} else {
					customSettings.set(id, {
						hidden: value as boolean
					});
				}
			}
		}
	}

	customSettings.forEach((setting, type) => {
		const elems = type.split('|');
		if (elems.length === 3) {
			const type = elems[0];
			const firstIdx = parseInt(elems[1]);

			if (type === 'audio') {
				const audioOutputName = audioInputIdxMap.get(firstIdx);
				if (audioOutputName) {
					const audioOutput = result.blueprint.audioOutputs.get(audioOutputName);
					if (audioOutput && setting.property && setting.value) {
						audioOutput.privateData.set(setting.property, {
							value: setting.value,
							details: {
								required: setting.required,
								editable: setting.editable,
								hidden: setting.hidden
							}
						});
					}
				}
			}

			if (type === 'video') {
				const videoOutputName = videoInputIdxMap.get(firstIdx);
				if (videoOutputName) {
					const videoOutput = result.blueprint.videoOutputs.get(videoOutputName);
					if (videoOutput && setting.property && setting.value) {
						videoOutput.privateData.set(setting.property, {
							value: setting.value,
							details: {
								required: setting.required,
								editable: setting.editable,
								hidden: setting.hidden
							}
						});
					}
				}
			}

			if (type === 'file') {
				const fileOutputName = fileInputIdxMap.get(firstIdx);
				if (fileOutputName) {
					const fileOutput = result.blueprint.fileOutputs.get(fileOutputName);
					if (fileOutput && setting.property && setting.value) {
						fileOutput.privateData.set(setting.property, setting.value);
					}
				}
			}

		}
	});

	return result;
};
