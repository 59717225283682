import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {callDeletePreset, callListBasePresets} from '../../../api/presets_requests';
import {Button, Modal, Space} from 'antd';
import {InfiniteList, ListItemProps} from '../../../../../components/InfiniteList/InfiniteList';
import {Notification, notify} from '../../../../../utils/notify';
import {BorderlessTableOutlined} from '@ant-design/icons';
import {categoryToString} from '../../../domain/categoryConverter';

const WarningText = 'Are you sure you want to remove this preset?';

export const PresetsManagement: FC = () => {
	const navigate = useNavigate();

	const ItemsToLoad = 10;
	const [loadedPresets, setLoadedPresets] = useState<number>(0);
	const [totalPresets, setTotalPresets] = useState<number>(0);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalText, setModalText] = useState('');
	const [currentPresetId, setCurrentPresetId] = useState<string>('');
	const [idToRemove, setIdToRemove] = useState('');

	const loadPresets = async () => {
		const presets = await callListBasePresets(loadedPresets, loadedPresets + ItemsToLoad);
		setLoadedPresets(loadedPresets + presets.presetsList.length);
		setTotalPresets(presets.total);
		return presets.presetsList.map((preset) => {
			const itemProps: ListItemProps = {
				id: preset.id,
				image: <BorderlessTableOutlined style={{fontSize: '50px'}}/>,
				title: <div style={{cursor: 'pointer'}} onClick={() => {
					setModalText(JSON.stringify(preset.blueprint, null, 4));
					setIsModalVisible(true);
				}
				}>{preset.name}</div>,
				description: `Codec: ${categoryToString(preset.category)}`,
				actions: [
					<a key="list-presets-edit" onClick={() => {
						navigate('/admin-tools/edit-preset', {
							state: {preset: preset}
						});
					}}>edit</a>,
					<a key="list-presets-delete" onClick={() => {
						setModalText(WarningText);
						showModal(preset.id);
					}}>delete</a>
				]
			};
			return itemProps;
		});
	};

	const showModal = (id: string) => {
		setCurrentPresetId(id);
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		setIsModalVisible(false);
		try {
			await callDeletePreset(currentPresetId);
			setIdToRemove(currentPresetId);
			setTotalPresets(totalPresets - 1);
			notify(Notification.SUCCESS, 'Operation Completed', 'Preset has been deleted successfully');
		} catch (e) {
			console.log(e);
			notify(Notification.ERROR, 'Operation Failed', 'Failed to delete preset, please try again');
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<>
			<Space style={{width: '70vw'}} direction="vertical" size={22}>
				<Button type="primary" onClick={() => {
					navigate('/admin-tools/create-preset');
				}}>Create Preset</Button>
				<InfiniteList height="65vh" header="Existing Presets" total={totalPresets} loader={loadPresets} idToRemove={idToRemove}/>
			</Space>
			<Modal title="Warning" centered visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				<p style={{whiteSpace: 'pre'}}>{modalText}</p>
			</Modal>
		</>
	);
};
