import React, {FC, useEffect, useState} from 'react';
import {Col, Divider, Empty, Row, Space, Typography} from 'antd';
import {ToolOutlined} from '@ant-design/icons';
import {setupFilesManagement} from '../../../../files/utils/files';
import {SelectCodecS} from './SelectCodecS';
import {categories, CategoryName, categoryStringToEnum, categoryToString} from '../../../../settings/domain/categoryConverter';
import {resolveCodecInfo} from '../../../util/codecs';
import {ListSelect, ListSelectEntry} from '../../../../../components/ListSelect/ListSelect';
import {Category} from '../../../../../autogen/gRPC/arranger/presets/enums_pb';

interface CodecEntry {
	key: string,
	name: string | JSX.Element,
}

export interface SelectCodecProps {
	setSelectedCodec: (arg: Category) => void;
}

export const SelectCodec: FC<SelectCodecProps> = (props) => {
	const {setSelectedCodec} = props;

	const codecs = categories.map(categoryToString);
	const [selectedRow, setSelectedRow] = useState<ListSelectEntry | undefined>(undefined);

	useEffect(() => {
		(async function () {
			await setupFilesManagement();
		})();
	}, []);

	useEffect(() => {
		if (selectedRow) {
			const currentCodec = categoryStringToEnum(selectedRow.key as CategoryName);
			setSelectedCodec(currentCodec);
		}
	}, [selectedRow]);

	const prepareData = () => {
		return codecs.map((codec) => {
			const result: CodecEntry = {
				key: codec,
				name: <><ToolOutlined style={{marginRight: '10px'}}/>{codec}</>
			};
			return result;
		});
	};

	const codecInfo = () => {
		if (selectedRow) {
			return resolveCodecInfo(selectedRow.key as CategoryName);
		} else {
			return {
				url: '',
				summary: ''
			};
		}
	};

	const prepareInfo = () => (
		<>
			{selectedRow ?
				<Col style={{padding: '15px 50px'}}>
					<Row style={{marginBottom: '20px'}}>
						<Typography.Text>Your codec has been selected properly. You can go to the next step or select different codec.</Typography.Text>
					</Row>
					<Row>
						<ToolOutlined style={{fontSize: '42px'}}/>
						<div style={{marginLeft: '10px'}}>
							<Row>
								<Typography.Text>Selected Codec:</Typography.Text>
							</Row>
							<Row>
								<Typography.Text>Name: &nbsp;&nbsp; {selectedRow.key}</Typography.Text>
							</Row>
						</div>
					</Row>
					<Divider style={{marginTop: '20px'}}/>
					<Col style={{marginTop: '20px'}}>
						<div>
							<Typography.Text>{codecInfo()?.summary}</Typography.Text>
						</div>
						<Row justify="end">
							<Typography.Link style={{marginRight: '20px'}} href={codecInfo()?.url} target="_blank">More info...</Typography.Link>
						</Row>
					</Col>
				</Col>
				:
				<Col>
					<Row style={{padding: '15px 50px'}}>
						<Typography.Text>Please select codec from the table at the left.</Typography.Text>
					</Row>
					<Row justify="center" align="middle" style={{height: '60%'}}>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
					</Row>
				</Col>
			}
		</>
	);

	return (
		<SelectCodecS>
			<Col>
				<Row justify="center">
					<Typography.Title>Step 2: select target codec</Typography.Title>
				</Row>
				<Space direction="vertical" size={30}>
					<Typography.Text>
						Now it is time to specify codec of result file. Depending on which codec you choose, your file will differ in parameters such
						as the type of compression and the file size. You can select on of them from the list and see more information about it or
						move to the next step.
					</Typography.Text>
					<ListSelect leftTitle="Available Codecs" rightTitle="Selected Codec" selectedContent={prepareInfo()}
					            entries={prepareData()} setSelectedRow={setSelectedRow}
					/>
				</Space>
			</Col>
		</SelectCodecS>
	);
};
