import styled from 'styled-components';
import {GREY_2, LIGHT_3} from '../../../../utils/colors';

export const RecentJobsS = styled.div`
	display: flex;
	justify-content: center;
	height: 80vh;
	width: 100%;
	
	hr {
		height: 1px;
		border: 0; 
		border-top: 1px solid ${GREY_2};
	}
`;

export const RecentJobsS_JobDetails = styled.div`
	.ant-spin-dot-item {
		background: ${LIGHT_3};
	}
`;
