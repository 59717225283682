import styled from 'styled-components';
import {GREY_4, GREY_5, LIGHT_3} from '../../../../utils/colors';

export const UploadPanelS = styled.div<{ visible?: boolean }>`
	position: fixed;
	bottom: 30px;
	left: 30px;
	width: 635px;
	max-height: 385px;
	box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
	border-radius: 4px;
	background: ${GREY_4};
	visibility: ${({visible}) => visible ? 'unset' : 'hidden'};

	.ant-divider {
		margin-top: 15px;
		margin-bottom: 5px;
		border-color: ${GREY_5};
	}
`;

export const UploadPanelS_Minimized = styled.div<{ visible?: boolean }>`
	position: fixed;
	bottom: 120px;
	left: 30px;
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
	visibility: ${({visible}) => visible ? 'unset' : 'hidden'};
`;

export const UploadPanelS_Toolbar = styled.div`
	border-radius: 4px 4px 0 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 40px;
	padding: 10px;
	background: ${GREY_5};

	svg {
		cursor: pointer;
	}
`;

export const UploadPanelS_Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 10px 10px;
	width: 100%;
	max-height: 285px;
	overflow-y: scroll;
	overflow-x: hidden;
`;

export const UploadPanelS_Divider = styled.div`
	.ant-divider {
		margin-bottom: 10px;
		border-color: ${LIGHT_3};
	}
`;
