import {EnvVars} from './env';

export const prodEnv: EnvVars = {
	pageUrl: 'https://transcoder.evemeta.com',
	apiArrangerUrl: 'https://api.transcoder.evemeta.com',
	firebaseConfig: {
		apiKey: 'AIzaSyAR0-WQZXXWRJO-E4biHDKf_u4JQlp7i2I',
		authDomain: 'em-transcoder.firebaseapp.com',
		projectId: 'em-transcoder',
		storageBucket: 'em-transcoder.appspot.com',
		messagingSenderId: '134239569711',
		appId: '1:134239569711:web:a488f7dddab9f8d91d83da'
	}
};
