import React, {FC} from 'react';
import {AzureStorageConfig, GoogleStorageConfig, S3StorageConfig} from '../../../../../../autogen/gRPC/cloud/models_pb';
import {
	azureConfigGuard,
	AzureDetails,
	CloudModal,
	CloudModalFormValues,
	gcsConfigGuard,
	GcsDetails,
	s3ConfigGuard,
	S3Details
} from '../../common/CloudModal/CloudModal';
import {OutputInfo} from '../../../../../../autogen/gRPC/scheduler/models_pb';

export interface UploadToCloudModalProps {
	visible: boolean;
	setVisible: (arg: boolean) => void;
	outputInfo?: OutputInfo;
	setOutputInfo: (arg: OutputInfo) => void;
}

export const UploadToCloudModal: FC<UploadToCloudModalProps> = (props) => {
	const {visible, setVisible, outputInfo, setOutputInfo} = props;

	const onSubmit = async (values: CloudModalFormValues) => {
		const outputInfo = new OutputInfo();
		values?.path && outputInfo.setPath(values.path);

		const gcsConfig = new GoogleStorageConfig();
		if (values?.details && gcsConfigGuard(values?.details)) {
			const details = values.details as GcsDetails;
			gcsConfig.setBucketPolicyOnly(details.bucketPolicyOnly);
			const map = gcsConfig.getServiceAccountCredentialsMap();
			details.serviceAccount.forEach((value, key) => {
				map.set(key, value);
			});
			outputInfo.setGcs(gcsConfig);
		}

		const azureConfig = new AzureStorageConfig();
		if (values?.details && azureConfigGuard(values?.details)) {
			const details = values.details as AzureDetails;
			azureConfig.setAccount(details.account);
			azureConfig.setKey(details.key);
			outputInfo.setAzure(azureConfig);
		}

		const s3Config = new S3StorageConfig();
		if (values?.details && s3ConfigGuard(values?.details)) {
			const details = values.details as S3Details;
			s3Config.setAccessKeyId(details.accessKeyId);
			s3Config.setSecretAccessKey(details.secretAccessKey);
			s3Config.setEndpoint(details.endpoint);
			s3Config.setProvider(details.provider);

			if (details.provider !== 'Alibaba' && details.provider !== 'TencentCOS') {
				s3Config.setRegion(details.region);
			}

			if (details.provider === 'Other (S3)') {
				s3Config.setProvider('Other')
			}
			outputInfo.setS3(s3Config);
		}

		setOutputInfo(outputInfo);
	};

	const prepareInitialValues = () => {
		const result: CloudModalFormValues = {};
		result.path = outputInfo?.getPath();
		if (result.path) {
			result.path = result.path.substring(0, result.path.lastIndexOf('/'));
		}

		const gcs = outputInfo?.getGcs();
		if (gcs) {
			const serviceAccount = new Map<string, string>();
			const grpcMap = gcs.getServiceAccountCredentialsMap().toObject();
			grpcMap.forEach(([key, value]) => {
				serviceAccount.set(key, value);
			});

			result.details = {
				serviceAccount: serviceAccount,
				bucketPolicyOnly: gcs.getBucketPolicyOnly()
			};
		}

		const azure = outputInfo?.getAzure();
		if (azure) {
			result.details = {
				account: azure.getAccount(),
				key: azure.getKey()
			};
		}

		const s3 = outputInfo?.getS3();
		if (s3) {
			result.details = {
				accessKeyId: s3.getAccessKeyId(),
				secretAccessKey: s3.getSecretAccessKey(),
				endpoint: s3.getEndpoint(),
				provider: s3.getProvider(),
				region: s3.getRegion()
			};
		}
		return result;
	};

	return (
		<CloudModal visible={visible} setVisible={setVisible} onSubmit={onSubmit} initialValues={prepareInitialValues()}/>
	);
};
