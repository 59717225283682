import {ChannelLayout} from '../../../autogen/gRPC/scheduler/enums_pb';

///
/// ChannelLayouts List
///

export const channelLayouts: ChannelLayout[] = [
	ChannelLayout.CHANNELLAYOUT_2_1,
	ChannelLayout.CHANNELLAYOUT_3_1,
	ChannelLayout.CHANNELLAYOUT_5_1,
	ChannelLayout.CHANNELLAYOUT_7_1,
	ChannelLayout.CHANNELLAYOUT_MONO,
	ChannelLayout.CHANNELLAYOUT_STEREO
];

///
/// ChannelLayout to String
///

export const channelLayoutToString = (channelLayout: ChannelLayout) => {
	switch (channelLayout) {
		case ChannelLayout.CHANNELLAYOUT_2_1: {
			return '2.1';
		}
		case ChannelLayout.CHANNELLAYOUT_3_1: {
			return '3.1';
		}
		case ChannelLayout.CHANNELLAYOUT_5_1: {
			return '5.1';
		}
		case ChannelLayout.CHANNELLAYOUT_7_1: {
			return '7.1';
		}
		case ChannelLayout.CHANNELLAYOUT_MONO: {
			return 'MONO';
		}
		case ChannelLayout.CHANNELLAYOUT_STEREO: {
			return 'STEREO';
		}
		case ChannelLayout.CHANNELLAYOUT_UNKNOWN: {
			return 'UNKNOWN';
		}
		default: {
			return 'UNKNOWN';
		}
	}
};

///
/// Categories String List Type
///

export type ChannelLayoutName = ReturnType<typeof channelLayoutToString>

///
/// ChannelLayout string to eventAccess
///

export const channelLayoutStringToEnum = (channelLayout: ChannelLayoutName) => {
	switch (channelLayout.toUpperCase()) {
		case '2.1': {
			return ChannelLayout.CHANNELLAYOUT_2_1;
		}
		case '3.1': {
			return ChannelLayout.CHANNELLAYOUT_3_1;
		}
		case '5.1': {
			return ChannelLayout.CHANNELLAYOUT_5_1;
		}
		case '7.1': {
			return ChannelLayout.CHANNELLAYOUT_7_1;
		}
		case 'MONO': {
			return ChannelLayout.CHANNELLAYOUT_MONO;
		}
		case 'STEREO': {
			return ChannelLayout.CHANNELLAYOUT_STEREO;
		}
		case 'UNKNOWN': {
			return ChannelLayout.CHANNELLAYOUT_UNKNOWN;
		}
		default: {
			return ChannelLayout.CHANNELLAYOUT_UNKNOWN;
		}
	}
};
