import {createEntityAdapter, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {Preset} from '../../autogen/gRPC/arranger/presets/models_pb';
import {Category} from '../../autogen/gRPC/arranger/presets/enums_pb';

const customPresetsAdapter = createEntityAdapter<Preset.AsObject>({
	selectId: (customPreset) => customPreset.name
});

export const customPresetsSlice = createSlice({
	name: 'customPresets',
	initialState: customPresetsAdapter.getInitialState(),
	reducers: {
		addCustomPresets: customPresetsAdapter.addMany,
		addCustomPreset: customPresetsAdapter.addOne,
		setCustomPresets: customPresetsAdapter.setMany,
		removeCustomPreset: customPresetsAdapter.removeOne,
		removeCustomPresets: customPresetsAdapter.removeMany,
		updateCustomPreset: (state, action: PayloadAction<Preset.AsObject>) => {
			state.entities[action.payload.name] = action.payload;
		}
	}
});

export const customPresetsActions = customPresetsSlice.actions;
export const selectCustomPresets = customPresetsAdapter.getSelectors<RootState>((state) => state.customPresets).selectAll;
export const selectCustomPreset = customPresetsAdapter.getSelectors<RootState>((state) => state.customPresets).selectById;

export const selectCustomPresetByCategory = (category: Category) => createSelector(
	selectCustomPresets,
	customPreset => customPreset.filter(customPreset => customPreset.category === category)
);
