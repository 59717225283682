import React, {FC, useEffect, useRef, useState} from 'react';
import {UploadPanelS, UploadPanelS_Container, UploadPanelS_Divider, UploadPanelS_Minimized, UploadPanelS_Toolbar} from './UploadPanelS';
import {Button, Divider, Progress, Row, Space, Typography} from 'antd';
import {PRIMARY} from '../../../../utils/colors';
import {selectUpload, selectUploads, uploadsActions} from '../../../../stores/slices/uploads';
import {useAppDispatch, useAppSelector} from '../../../../stores/hooks';
import Uploader from '../../domain/uploader';
import {store} from '../../../../stores/store';
import {CloseCircleFilled, CloseOutlined, CloudUploadOutlined, PauseCircleFilled, PlayCircleFilled} from '@ant-design/icons';
import {v4 as uuidv4} from 'uuid';

type UploadStatus = 'pending' | 'paused' | 'completed' | 'failed'

export interface UploadEntry {
	name: string
	progress: number
	speed: string,
	size: string,
	resumed: boolean,
	status: UploadStatus
}

export const UploadPanel: FC = () => {
	const uploads = useAppSelector(selectUploads);
	const dispatch = useAppDispatch();

	const [minimized, setMinimized] = useState(false);
	const upload = useRef<Uploader | null>(null);
	const container = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		upload.current = Uploader.getInstance();

		window.addEventListener('online', onOnline);
		window.addEventListener('offline', onOffline);

		return () => {
			window.removeEventListener('online', onOnline);
			window.removeEventListener('offline', onOffline);
		};
	}, []);

	useEffect(() => {
		setMinimized(false);
		container.current?.scrollTo(0, 1000000);
	}, [uploads.length]);

	const onOffline = () => {
		upload.current?.pause();
		console.log('Became offline');
	};

	const onOnline = () => {
		upload.current?.start();
		console.log('Became online');
	};

	const onPause = () => {
		upload.current?.pause();
		const currentFile = upload.current?.currentUpload();
		if (currentFile) {
			const currentUpload = selectUpload(store.getState(), currentFile.name);
			if (currentUpload) {
				dispatch(uploadsActions.updateUpload({id: currentUpload.name, changes: {speed: 'paused', status: 'paused'}}));
			}
		}
	};

	const onUnpause = () => {
		upload.current?.start();
		const currentFile = upload.current?.currentUpload();
		if (currentFile) {
			const currentUpload = selectUpload(store.getState(), currentFile.name);
			if (currentUpload) {
				dispatch(uploadsActions.updateUpload({id: currentUpload.name, changes: {status: 'pending'}}));
			}
		}
	};

	const onCancel = (name: string) => {
		upload.current?.cancel(name);
		dispatch(uploadsActions.removeUpload(name));
	};

	const prepareUploads = () => uploads.map((entry) => <div style={{width: '100%'}} key={uuidv4()}>
			<Row align="middle" justify="space-between">
				<Row align="middle" justify="center">
					<Row>
						<Space direction={'horizontal'}>
							<CloudUploadOutlined style={{fontSize: '24px'}}/>
							<Typography.Text style={{maxWidth: '300px'}} ellipsis={{rows: 1, expandable: false} as Omit<any, any>}>
								{entry.name}
							</Typography.Text>
						</Space>
					</Row>
					<UploadPanelS_Divider>
						<Divider type="vertical"/>
					</UploadPanelS_Divider>
					<div>{entry.size}</div>
				</Row>
				{prepareContent(entry)}
			</Row>
			<Progress status={resolveProgressStatus(entry)}
			          style={{marginTop: '5px'}}
			          strokeColor={PRIMARY}
			          percent={entry.status === 'completed' ? 100 : entry.progress}
			/>
			<Divider/>
		</div>
	);

	const resolveProgressStatus = (entry: UploadEntry) => {
		switch (entry.status) {
			case 'completed':
				return 'success';
			case 'failed':
				return 'exception';
			case 'pending':
			case 'paused':
				return 'normal';
		}
	};

	const prepareContent = (entry: UploadEntry) => {
		switch (entry.status) {
			case 'completed':
				return (
					<div>completed</div>
				);
			case 'failed':
				return (
					<div>failed</div>
				);
			default:
				return (
					<Space direction="horizontal" size={1}>
						<Typography.Text style={{marginRight: '10px'}}>{entry.speed}</Typography.Text>
						{entry.status === 'paused' ?
							<Button size="small" type="text" shape="circle" icon={<PlayCircleFilled style={{fontSize: '20px'}}/>} onClick={onUnpause}/>
							:
							<Button size="small" type="text" shape="circle" icon={<PauseCircleFilled style={{fontSize: '20px'}}/>} onClick={onPause}/>
						}
						<Button size="small" type="text" shape="circle" icon={<CloseCircleFilled style={{fontSize: '20px'}}/>}
						        onClick={() => onCancel(entry.name)}/>
					</Space>
				);
		}
	};

	return (
		minimized ?
			<UploadPanelS_Minimized visible={uploads.length > 0} onClick={() => setMinimized(false)}>
				<Button style={{width: '70px', height: '70px'}} shape="circle" icon={<CloudUploadOutlined style={{fontSize: '34px'}}/>}/>
			</UploadPanelS_Minimized>
			:
			<UploadPanelS visible={uploads.length > 0}>
				<UploadPanelS_Toolbar>
					<p>Files Upload</p>
					<CloseOutlined style={{fontSize: '18px'}} onClick={() => setMinimized(true)}/>
				</UploadPanelS_Toolbar>
				<UploadPanelS_Container ref={container}>
					{prepareUploads()}
				</UploadPanelS_Container>
			</UploadPanelS>
	);
};
