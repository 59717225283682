/* eslint-disable */
// source: prober/models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var cloud_models_pb = require('../cloud/models_pb.js');
goog.object.extend(proto, cloud_models_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.prober.api.v1.FileInfo', null, global);
goog.exportSymbol('proto.prober.api.v1.FormatInfo', null, global);
goog.exportSymbol('proto.prober.api.v1.InputInfo', null, global);
goog.exportSymbol('proto.prober.api.v1.InputInfo.ConfigCase', null, global);
goog.exportSymbol('proto.prober.api.v1.StreamInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.prober.api.v1.FileInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.prober.api.v1.FileInfo.repeatedFields_, null);
};
goog.inherits(proto.prober.api.v1.FileInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.prober.api.v1.FileInfo.displayName = 'proto.prober.api.v1.FileInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.prober.api.v1.StreamInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.prober.api.v1.StreamInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.prober.api.v1.StreamInfo.displayName = 'proto.prober.api.v1.StreamInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.prober.api.v1.FormatInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.prober.api.v1.FormatInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.prober.api.v1.FormatInfo.displayName = 'proto.prober.api.v1.FormatInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.prober.api.v1.InputInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.prober.api.v1.InputInfo.oneofGroups_);
};
goog.inherits(proto.prober.api.v1.InputInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.prober.api.v1.InputInfo.displayName = 'proto.prober.api.v1.InputInfo';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.prober.api.v1.FileInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.prober.api.v1.FileInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.prober.api.v1.FileInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.prober.api.v1.FileInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.prober.api.v1.FileInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    format: (f = msg.getFormat()) && proto.prober.api.v1.FormatInfo.toObject(includeInstance, f),
    streamsList: jspb.Message.toObjectList(msg.getStreamsList(),
    proto.prober.api.v1.StreamInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.prober.api.v1.FileInfo}
 */
proto.prober.api.v1.FileInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.prober.api.v1.FileInfo;
  return proto.prober.api.v1.FileInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.prober.api.v1.FileInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.prober.api.v1.FileInfo}
 */
proto.prober.api.v1.FileInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.prober.api.v1.FormatInfo;
      reader.readMessage(value,proto.prober.api.v1.FormatInfo.deserializeBinaryFromReader);
      msg.setFormat(value);
      break;
    case 2:
      var value = new proto.prober.api.v1.StreamInfo;
      reader.readMessage(value,proto.prober.api.v1.StreamInfo.deserializeBinaryFromReader);
      msg.addStreams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.prober.api.v1.FileInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.prober.api.v1.FileInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.prober.api.v1.FileInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.prober.api.v1.FileInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.prober.api.v1.FormatInfo.serializeBinaryToWriter
    );
  }
  f = message.getStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.prober.api.v1.StreamInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional FormatInfo format = 1;
 * @return {?proto.prober.api.v1.FormatInfo}
 */
proto.prober.api.v1.FileInfo.prototype.getFormat = function() {
  return /** @type{?proto.prober.api.v1.FormatInfo} */ (
    jspb.Message.getWrapperField(this, proto.prober.api.v1.FormatInfo, 1));
};


/**
 * @param {?proto.prober.api.v1.FormatInfo|undefined} value
 * @return {!proto.prober.api.v1.FileInfo} returns this
*/
proto.prober.api.v1.FileInfo.prototype.setFormat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.prober.api.v1.FileInfo} returns this
 */
proto.prober.api.v1.FileInfo.prototype.clearFormat = function() {
  return this.setFormat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.prober.api.v1.FileInfo.prototype.hasFormat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated StreamInfo streams = 2;
 * @return {!Array<!proto.prober.api.v1.StreamInfo>}
 */
proto.prober.api.v1.FileInfo.prototype.getStreamsList = function() {
  return /** @type{!Array<!proto.prober.api.v1.StreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.prober.api.v1.StreamInfo, 2));
};


/**
 * @param {!Array<!proto.prober.api.v1.StreamInfo>} value
 * @return {!proto.prober.api.v1.FileInfo} returns this
*/
proto.prober.api.v1.FileInfo.prototype.setStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.prober.api.v1.StreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.prober.api.v1.StreamInfo}
 */
proto.prober.api.v1.FileInfo.prototype.addStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.prober.api.v1.StreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.prober.api.v1.FileInfo} returns this
 */
proto.prober.api.v1.FileInfo.prototype.clearStreamsList = function() {
  return this.setStreamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.prober.api.v1.StreamInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.prober.api.v1.StreamInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.prober.api.v1.StreamInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.prober.api.v1.StreamInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    codecName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    codecLongName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profile: jspb.Message.getFieldWithDefault(msg, 4, ""),
    codecType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    codecTimeBase: jspb.Message.getFieldWithDefault(msg, 40, ""),
    codecTagString: jspb.Message.getFieldWithDefault(msg, 6, ""),
    codecTag: jspb.Message.getFieldWithDefault(msg, 41, ""),
    width: jspb.Message.getFieldWithDefault(msg, 7, 0),
    height: jspb.Message.getFieldWithDefault(msg, 8, 0),
    codedWidth: jspb.Message.getFieldWithDefault(msg, 9, 0),
    codedHeight: jspb.Message.getFieldWithDefault(msg, 10, 0),
    closedCaptions: jspb.Message.getFieldWithDefault(msg, 11, 0),
    hasBFrames: jspb.Message.getFieldWithDefault(msg, 12, 0),
    sampleAspectRatio: jspb.Message.getFieldWithDefault(msg, 13, ""),
    displayAspectRatio: jspb.Message.getFieldWithDefault(msg, 14, ""),
    pixFmt: jspb.Message.getFieldWithDefault(msg, 15, ""),
    level: jspb.Message.getFieldWithDefault(msg, 16, 0),
    colorRange: jspb.Message.getFieldWithDefault(msg, 17, ""),
    colorSpace: jspb.Message.getFieldWithDefault(msg, 18, ""),
    colorTransfer: jspb.Message.getFieldWithDefault(msg, 19, ""),
    colorPrimaries: jspb.Message.getFieldWithDefault(msg, 20, ""),
    chromaLocation: jspb.Message.getFieldWithDefault(msg, 21, ""),
    refs: jspb.Message.getFieldWithDefault(msg, 22, 0),
    isAvc: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    nalLengthSize: jspb.Message.getFieldWithDefault(msg, 24, 0),
    rFrameRate: jspb.Message.getFieldWithDefault(msg, 25, ""),
    avgFrameRate: jspb.Message.getFieldWithDefault(msg, 26, ""),
    timeBase: jspb.Message.getFieldWithDefault(msg, 27, ""),
    startPts: jspb.Message.getFieldWithDefault(msg, 28, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 29, ""),
    durationTs: jspb.Message.getFieldWithDefault(msg, 30, 0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    bitRate: jspb.Message.getFieldWithDefault(msg, 32, 0),
    maxBitRate: jspb.Message.getFieldWithDefault(msg, 43, 0),
    bitsPerRawSample: jspb.Message.getFieldWithDefault(msg, 33, 0),
    nbFrames: jspb.Message.getFieldWithDefault(msg, 34, 0),
    sampleFmt: jspb.Message.getFieldWithDefault(msg, 35, ""),
    sampleRate: jspb.Message.getFieldWithDefault(msg, 36, 0),
    channels: jspb.Message.getFieldWithDefault(msg, 37, 0),
    bitsPerSample: jspb.Message.getFieldWithDefault(msg, 38, 0),
    channelLayout: jspb.Message.getFieldWithDefault(msg, 39, ""),
    id: jspb.Message.getFieldWithDefault(msg, 42, 0),
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.prober.api.v1.StreamInfo}
 */
proto.prober.api.v1.StreamInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.prober.api.v1.StreamInfo;
  return proto.prober.api.v1.StreamInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.prober.api.v1.StreamInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.prober.api.v1.StreamInfo}
 */
proto.prober.api.v1.StreamInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodecName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodecLongName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfile(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodecType(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodecTimeBase(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodecTagString(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodecTag(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeight(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCodedWidth(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCodedHeight(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClosedCaptions(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHasBFrames(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSampleAspectRatio(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayAspectRatio(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPixFmt(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLevel(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorRange(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorSpace(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorTransfer(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorPrimaries(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setChromaLocation(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRefs(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAvc(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNalLengthSize(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setRFrameRate(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvgFrameRate(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeBase(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartPts(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationTs(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBitRate(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxBitRate(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBitsPerRawSample(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNbFrames(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setSampleFmt(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSampleRate(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChannels(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBitsPerSample(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelLayout(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 99:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.prober.api.v1.StreamInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.prober.api.v1.StreamInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.prober.api.v1.StreamInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.prober.api.v1.StreamInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCodecName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCodecLongName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfile();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCodecType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCodecTimeBase();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getCodecTagString();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCodecTag();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCodedWidth();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCodedHeight();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getClosedCaptions();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getHasBFrames();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getSampleAspectRatio();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDisplayAspectRatio();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPixFmt();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getColorRange();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getColorSpace();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getColorTransfer();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getColorPrimaries();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getChromaLocation();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getRefs();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getIsAvc();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getNalLengthSize();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getRFrameRate();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getAvgFrameRate();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getTimeBase();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getStartPts();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getDurationTs();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      31,
      f
    );
  }
  f = message.getBitRate();
  if (f !== 0) {
    writer.writeInt64(
      32,
      f
    );
  }
  f = message.getMaxBitRate();
  if (f !== 0) {
    writer.writeInt64(
      43,
      f
    );
  }
  f = message.getBitsPerRawSample();
  if (f !== 0) {
    writer.writeInt64(
      33,
      f
    );
  }
  f = message.getNbFrames();
  if (f !== 0) {
    writer.writeInt64(
      34,
      f
    );
  }
  f = message.getSampleFmt();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getSampleRate();
  if (f !== 0) {
    writer.writeInt64(
      36,
      f
    );
  }
  f = message.getChannels();
  if (f !== 0) {
    writer.writeInt64(
      37,
      f
    );
  }
  f = message.getBitsPerSample();
  if (f !== 0) {
    writer.writeInt64(
      38,
      f
    );
  }
  f = message.getChannelLayout();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      42,
      f
    );
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional int64 index = 1;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string codec_name = 2;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getCodecName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setCodecName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string codec_long_name = 3;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getCodecLongName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setCodecLongName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile = 4;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string codec_type = 5;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getCodecType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setCodecType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string codec_time_base = 40;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getCodecTimeBase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setCodecTimeBase = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string codec_tag_string = 6;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getCodecTagString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setCodecTagString = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string codec_tag = 41;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getCodecTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setCodecTag = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional int64 width = 7;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 height = 8;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 coded_width = 9;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getCodedWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setCodedWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 coded_height = 10;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getCodedHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setCodedHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 closed_captions = 11;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getClosedCaptions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setClosedCaptions = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 has_b_frames = 12;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getHasBFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setHasBFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string sample_aspect_ratio = 13;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getSampleAspectRatio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setSampleAspectRatio = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string display_aspect_ratio = 14;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getDisplayAspectRatio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setDisplayAspectRatio = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string pix_fmt = 15;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getPixFmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setPixFmt = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 level = 16;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string color_range = 17;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getColorRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setColorRange = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string color_space = 18;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getColorSpace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setColorSpace = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string color_transfer = 19;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getColorTransfer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setColorTransfer = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string color_primaries = 20;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getColorPrimaries = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setColorPrimaries = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string chroma_location = 21;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getChromaLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setChromaLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int64 refs = 22;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getRefs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setRefs = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bool is_avc = 23;
 * @return {boolean}
 */
proto.prober.api.v1.StreamInfo.prototype.getIsAvc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setIsAvc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional int64 nal_length_size = 24;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getNalLengthSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setNalLengthSize = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string r_frame_rate = 25;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getRFrameRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setRFrameRate = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string avg_frame_rate = 26;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getAvgFrameRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setAvgFrameRate = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string time_base = 27;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getTimeBase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setTimeBase = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional int64 start_pts = 28;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getStartPts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setStartPts = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional string start_time = 29;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional int64 duration_ts = 30;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getDurationTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setDurationTs = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional float duration = 31;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional int64 bit_rate = 32;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getBitRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setBitRate = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int64 max_bit_rate = 43;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getMaxBitRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setMaxBitRate = function(value) {
  return jspb.Message.setProto3IntField(this, 43, value);
};


/**
 * optional int64 bits_per_raw_sample = 33;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getBitsPerRawSample = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setBitsPerRawSample = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional int64 nb_frames = 34;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getNbFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setNbFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional string sample_fmt = 35;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getSampleFmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setSampleFmt = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional int64 sample_rate = 36;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getSampleRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setSampleRate = function(value) {
  return jspb.Message.setProto3IntField(this, 36, value);
};


/**
 * optional int64 channels = 37;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setChannels = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional int64 bits_per_sample = 38;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getBitsPerSample = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setBitsPerSample = function(value) {
  return jspb.Message.setProto3IntField(this, 38, value);
};


/**
 * optional string channel_layout = 39;
 * @return {string}
 */
proto.prober.api.v1.StreamInfo.prototype.getChannelLayout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setChannelLayout = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional int32 id = 42;
 * @return {number}
 */
proto.prober.api.v1.StreamInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * map<string, string> tags = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.prober.api.v1.StreamInfo.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.prober.api.v1.StreamInfo} returns this
 */
proto.prober.api.v1.StreamInfo.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.prober.api.v1.FormatInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.prober.api.v1.FormatInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.prober.api.v1.FormatInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.prober.api.v1.FormatInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    nbStreams: jspb.Message.getFieldWithDefault(msg, 1, 0),
    formatName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    formatLongName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    durationTs: jspb.Message.getFieldWithDefault(msg, 9, 0),
    size: jspb.Message.getFieldWithDefault(msg, 6, 0),
    bitRate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    probeScore: jspb.Message.getFieldWithDefault(msg, 10, 0),
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.prober.api.v1.FormatInfo}
 */
proto.prober.api.v1.FormatInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.prober.api.v1.FormatInfo;
  return proto.prober.api.v1.FormatInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.prober.api.v1.FormatInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.prober.api.v1.FormatInfo}
 */
proto.prober.api.v1.FormatInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNbStreams(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormatName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormatLongName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationTs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBitRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProbeScore(value);
      break;
    case 99:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.prober.api.v1.FormatInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.prober.api.v1.FormatInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.prober.api.v1.FormatInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.prober.api.v1.FormatInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNbStreams();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFormatName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFormatLongName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getDurationTs();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getBitRate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getProbeScore();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(99, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string file_name = 8;
 * @return {string}
 */
proto.prober.api.v1.FormatInfo.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 nb_streams = 1;
 * @return {number}
 */
proto.prober.api.v1.FormatInfo.prototype.getNbStreams = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setNbStreams = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string format_name = 2;
 * @return {string}
 */
proto.prober.api.v1.FormatInfo.prototype.getFormatName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setFormatName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string format_long_name = 3;
 * @return {string}
 */
proto.prober.api.v1.FormatInfo.prototype.getFormatLongName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setFormatLongName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float start_time = 4;
 * @return {number}
 */
proto.prober.api.v1.FormatInfo.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float duration = 5;
 * @return {number}
 */
proto.prober.api.v1.FormatInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 duration_ts = 9;
 * @return {number}
 */
proto.prober.api.v1.FormatInfo.prototype.getDurationTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setDurationTs = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 size = 6;
 * @return {number}
 */
proto.prober.api.v1.FormatInfo.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 bit_rate = 7;
 * @return {number}
 */
proto.prober.api.v1.FormatInfo.prototype.getBitRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setBitRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 probe_score = 10;
 * @return {number}
 */
proto.prober.api.v1.FormatInfo.prototype.getProbeScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.setProbeScore = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * map<string, string> tags = 99;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.prober.api.v1.FormatInfo.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 99, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.prober.api.v1.FormatInfo} returns this
 */
proto.prober.api.v1.FormatInfo.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
  return this;};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.prober.api.v1.InputInfo.oneofGroups_ = [[20,21,22,23]];

/**
 * @enum {number}
 */
proto.prober.api.v1.InputInfo.ConfigCase = {
  CONFIG_NOT_SET: 0,
  GCS: 20,
  AZURE: 21,
  S3: 22,
  HTTP: 23
};

/**
 * @return {proto.prober.api.v1.InputInfo.ConfigCase}
 */
proto.prober.api.v1.InputInfo.prototype.getConfigCase = function() {
  return /** @type {proto.prober.api.v1.InputInfo.ConfigCase} */(jspb.Message.computeOneofCase(this, proto.prober.api.v1.InputInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.prober.api.v1.InputInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.prober.api.v1.InputInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.prober.api.v1.InputInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.prober.api.v1.InputInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gcs: (f = msg.getGcs()) && cloud_models_pb.GoogleStorageConfig.toObject(includeInstance, f),
    azure: (f = msg.getAzure()) && cloud_models_pb.AzureStorageConfig.toObject(includeInstance, f),
    s3: (f = msg.getS3()) && cloud_models_pb.S3StorageConfig.toObject(includeInstance, f),
    http: (f = msg.getHttp()) && cloud_models_pb.HttpStorageConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.prober.api.v1.InputInfo}
 */
proto.prober.api.v1.InputInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.prober.api.v1.InputInfo;
  return proto.prober.api.v1.InputInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.prober.api.v1.InputInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.prober.api.v1.InputInfo}
 */
proto.prober.api.v1.InputInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 20:
      var value = new cloud_models_pb.GoogleStorageConfig;
      reader.readMessage(value,cloud_models_pb.GoogleStorageConfig.deserializeBinaryFromReader);
      msg.setGcs(value);
      break;
    case 21:
      var value = new cloud_models_pb.AzureStorageConfig;
      reader.readMessage(value,cloud_models_pb.AzureStorageConfig.deserializeBinaryFromReader);
      msg.setAzure(value);
      break;
    case 22:
      var value = new cloud_models_pb.S3StorageConfig;
      reader.readMessage(value,cloud_models_pb.S3StorageConfig.deserializeBinaryFromReader);
      msg.setS3(value);
      break;
    case 23:
      var value = new cloud_models_pb.HttpStorageConfig;
      reader.readMessage(value,cloud_models_pb.HttpStorageConfig.deserializeBinaryFromReader);
      msg.setHttp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.prober.api.v1.InputInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.prober.api.v1.InputInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.prober.api.v1.InputInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.prober.api.v1.InputInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGcs();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      cloud_models_pb.GoogleStorageConfig.serializeBinaryToWriter
    );
  }
  f = message.getAzure();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      cloud_models_pb.AzureStorageConfig.serializeBinaryToWriter
    );
  }
  f = message.getS3();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      cloud_models_pb.S3StorageConfig.serializeBinaryToWriter
    );
  }
  f = message.getHttp();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      cloud_models_pb.HttpStorageConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.prober.api.v1.InputInfo.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.prober.api.v1.InputInfo} returns this
 */
proto.prober.api.v1.InputInfo.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional cloud.api.v1.GoogleStorageConfig gcs = 20;
 * @return {?proto.cloud.api.v1.GoogleStorageConfig}
 */
proto.prober.api.v1.InputInfo.prototype.getGcs = function() {
  return /** @type{?proto.cloud.api.v1.GoogleStorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.GoogleStorageConfig, 20));
};


/**
 * @param {?proto.cloud.api.v1.GoogleStorageConfig|undefined} value
 * @return {!proto.prober.api.v1.InputInfo} returns this
*/
proto.prober.api.v1.InputInfo.prototype.setGcs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.prober.api.v1.InputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.prober.api.v1.InputInfo} returns this
 */
proto.prober.api.v1.InputInfo.prototype.clearGcs = function() {
  return this.setGcs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.prober.api.v1.InputInfo.prototype.hasGcs = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional cloud.api.v1.AzureStorageConfig azure = 21;
 * @return {?proto.cloud.api.v1.AzureStorageConfig}
 */
proto.prober.api.v1.InputInfo.prototype.getAzure = function() {
  return /** @type{?proto.cloud.api.v1.AzureStorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.AzureStorageConfig, 21));
};


/**
 * @param {?proto.cloud.api.v1.AzureStorageConfig|undefined} value
 * @return {!proto.prober.api.v1.InputInfo} returns this
*/
proto.prober.api.v1.InputInfo.prototype.setAzure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.prober.api.v1.InputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.prober.api.v1.InputInfo} returns this
 */
proto.prober.api.v1.InputInfo.prototype.clearAzure = function() {
  return this.setAzure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.prober.api.v1.InputInfo.prototype.hasAzure = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional cloud.api.v1.S3StorageConfig s3 = 22;
 * @return {?proto.cloud.api.v1.S3StorageConfig}
 */
proto.prober.api.v1.InputInfo.prototype.getS3 = function() {
  return /** @type{?proto.cloud.api.v1.S3StorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.S3StorageConfig, 22));
};


/**
 * @param {?proto.cloud.api.v1.S3StorageConfig|undefined} value
 * @return {!proto.prober.api.v1.InputInfo} returns this
*/
proto.prober.api.v1.InputInfo.prototype.setS3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.prober.api.v1.InputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.prober.api.v1.InputInfo} returns this
 */
proto.prober.api.v1.InputInfo.prototype.clearS3 = function() {
  return this.setS3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.prober.api.v1.InputInfo.prototype.hasS3 = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional cloud.api.v1.HttpStorageConfig http = 23;
 * @return {?proto.cloud.api.v1.HttpStorageConfig}
 */
proto.prober.api.v1.InputInfo.prototype.getHttp = function() {
  return /** @type{?proto.cloud.api.v1.HttpStorageConfig} */ (
    jspb.Message.getWrapperField(this, cloud_models_pb.HttpStorageConfig, 23));
};


/**
 * @param {?proto.cloud.api.v1.HttpStorageConfig|undefined} value
 * @return {!proto.prober.api.v1.InputInfo} returns this
*/
proto.prober.api.v1.InputInfo.prototype.setHttp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.prober.api.v1.InputInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.prober.api.v1.InputInfo} returns this
 */
proto.prober.api.v1.InputInfo.prototype.clearHttp = function() {
  return this.setHttp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.prober.api.v1.InputInfo.prototype.hasHttp = function() {
  return jspb.Message.getField(this, 23) != null;
};


goog.object.extend(exports, proto.prober.api.v1);
