import {CategoryName} from '../../settings/domain/categoryConverter';

export interface CodecInfo {
	url: string;
	summary: string;
}

export const resolveCodecInfo = (codec: CategoryName) => {
	let result: CodecInfo;

	switch (codec) {
		case 'H.264':
			result = {
				url: 'https://en.wikipedia.org/wiki/Advanced_Video_Coding',
				summary: 'Advanced Video Coding (AVC), also referred to as H.264 or MPEG-4 Part 10, is a video compression standard based on ' +
					'block-oriented, motion-compensated coding. It is by far the most commonly used format for the recording, compression, and ' +
					'distribution of video content, used by 91% of video industry developers as of September 2019. It supports resolutions ' +
					'up to and including 8K UHD.'
			};
			return result;
		case 'H.265':
			result = {
				url: 'https://en.wikipedia.org/wiki/High_Efficiency_Video_Coding',
				summary: 'High Efficiency Video Coding (HEVC), also known as H.265 and MPEG-H Part 2, is a video compression standard designed as ' +
					'part of the MPEG-H project as a successor to the widely used Advanced Video Coding (AVC, H.264, or MPEG-4 Part 10). In ' +
					'comparison to AVC, HEVC offers from 25% to 50% better data compression at the same level of video quality, or substantially ' +
					'improved video quality at the same bit rate. It supports resolutions up to 8192×4320, including 8K UHD, and unlike the primarily ' +
					'8-bit AVC, HEVC\'s higher fidelity Main 10 profile has been incorporated into nearly all supporting hardware.'
			};
			return result;
		case 'VP8':
			result = {
				url: 'https://en.wikipedia.org/wiki/VP8',
				summary: 'VP8 is an open and royalty-free video compression format released by On2 Technologies in 2008 Initially released as a ' +
					'proprietary successor to On2\'s previous VP7 format, VP8 was released as an open and royalty-free format in May 2010 after ' +
					'Google acquired On2 Technologies. Google provided an irrevocable patent promise on its patents for implementing the VP8 format, ' +
					'and released a specification of the format under the Creative Commons Attribution 3.0 license. That same year, Google also ' +
					'released libvpx, the reference implementation of VP8, under the revised BSD license.'
			};
			return result;
		case 'VP9':
			result = {
				url: 'https://en.wikipedia.org/wiki/VP9',
				summary: 'VP9 is an open and royalty-free video coding format developed by Google. VP9 is the successor to VP8 and competes ' +
					'mainly with MPEG\'s High Efficiency Video Coding (HEVC/H.265). At first, VP9 was mainly used on Google\'s video platform ' +
					'YouTube. The emergence of the Alliance for Open Media, and its support for the ongoing development of the successor AV1, of ' +
					'which Google is a part, led to growing interest in the format.'
			};
			return result;
		case 'AV1':
			result = {
				url: 'https://en.wikipedia.org/wiki/AV1',
				summary: 'AOMedia Video 1 (AV1) is an open, royalty-free video coding format initially designed for video transmissions over the ' +
					'Internet. It was developed as a successor to VP9 by the Alliance for Open Media (AOMedia), a consortium founded in 2015 that ' +
					'includes semiconductor firms, video on demand providers, video content producers, software development companies and web browser ' +
					'vendors. The AV1 bitstream specification includes a reference video codec. In 2018, Facebook conducted testing that approximated ' +
					'real world conditions, and the AV1 reference encoder achieved 34%, 46.2% and 50.3% higher data compression than libvpx-vp9, x264 High ' +
					'profile, and x264 Main profile respectively.'
			};
			return result;
		default:
			result = {
				url: '',
				summary: ''
			};
	}
};
