import React, {FC, useState} from 'react';
import {GenericOutput} from '../GenericOutput/GenericOutput';
import {Button, Col, Divider, Form, Input, InputNumber, Row, Select, Typography} from 'antd';
import {validateRequired} from '../../../settings/domain/validators';
import {pixelFormats, pixelFormatToString} from '../../../settings/domain/pixelFormatConverter';
import {colorSpaces, colorSpaceToString} from '../../../settings/domain/colorSpaceConverter';
import {passModes, passModeToString} from '../../../settings/domain/passModeConverter';
import {preparePropertyDetails} from '../../util/propertyDetails';
import {PlusCircleFilled} from '@ant-design/icons';
import {GenericOutputS_BaseOptionsWrapper, GenericOutputS_MultiField} from '../GenericOutput/GenericOutputS';
import {PrivateData} from '../PrivateData/PrivateData';

const {Option} = Select;

export interface VideoOutputProps {
	deletable?: boolean;
	withHeader?: boolean;
	nameIdxMap?: Map<number, string>;
	setNameIdxMap?: (arg: Map<number, string>) => void;
	initialIndexes?: number[];
	privateDataVideoInitial?: number[][];
}

export const VideoOutput: FC<VideoOutputProps> = (props) => {
	const {deletable, withHeader, nameIdxMap, setNameIdxMap, initialIndexes, privateDataVideoInitial} = props;

	const [indexes, setIndexes] = useState(initialIndexes ? initialIndexes : [0]);

	const prepareHeader = (title: string, onClick: () => void) => (
		<Row align="middle" justify="space-between">
			<Typography.Title>{title}</Typography.Title>
			<div style={{paddingBottom: '20px'}}>
				<Button style={{width: '30px', height: '30px'}} shape="circle" icon={<PlusCircleFilled style={{fontSize: '40px'}}/>} type="text"
				        onClick={() => onClick()}/>
			</div>
		</Row>
	);

	const onDelete = (idx: number) => setIndexes(indexes.filter((_, index) => index !== idx));

	const prepareTopContent = () => {
		return (
			<Row justify="center">
				<Typography.Text style={{fontSize: '20px'}}>Base Settings</Typography.Text>
				<Divider/>
			</Row>
		);
	};

	const prepareBottomContent = (idx: number) => (
		<GenericOutputS_BaseOptionsWrapper>
			<PrivateData idx={idx} name={'video'} header={true} details={true} initialIndexes={privateDataVideoInitial?.at(idx)}/>
		</GenericOutputS_BaseOptionsWrapper>
	);

	const prepareOutput = (idx: number) => (
		<GenericOutput idx={idx} title={`Video Output #${idx + 1}`} onDelete={deletable && indexes.length > 1 ? onDelete : undefined}
		               topContent={prepareTopContent()} bottomContent={prepareBottomContent(idx)}
		>
			<div>
				<Form.Item
					label={<Typography.Text>Video Output Name</Typography.Text>}
					name={`videoOutputName${idx}`}
					tooltip="Enter video output name (any)"
					rules={validateRequired()}
				>
					<Input placeholder="input video output name" onChange={(e) => {
						const copy = new Map(nameIdxMap);
						copy.set(idx, e.target.value);
						setNameIdxMap && setNameIdxMap(copy);
					}}/>
				</Form.Item>
			</div>
			<div>
				<Form.Item
					label={<Typography.Text>Encoder Name</Typography.Text>}
					name={`videoEncoderName${idx}`}
					tooltip="Enter encoder name for this video output"
					rules={validateRequired()}
				>
					<Input placeholder="input encoder name"/>
				</Form.Item>
			</div>
			<div>
				<GenericOutputS_MultiField>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Resources</Typography.Text>}
					           name={`videoResourcesCpu${idx}`}
					           tooltip="Enter time base for this video output"
					           rules={validateRequired()}
					>
						<InputNumber min={0} placeholder="cpu cores num."/>
					</Form.Item>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text></Typography.Text>}
					           name={`videoResourcesGpu${idx}`}

					>
						<InputNumber min={0} placeholder="gpu cores num."/>
					</Form.Item>
				</GenericOutputS_MultiField>
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Max GOP</Typography.Text>}
				           name={`videoMaxGop${idx}`}
				           tooltip="Enter Max GOP for this video output"
				>
					<InputNumber min={0} placeholder="input Max GOP"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoMaxGop')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Width</Typography.Text>}
				           name={`videoWidth${idx}`}
				           tooltip="Enter width for this video output"
				           rules={validateRequired()}
				>
					<InputNumber min={0} placeholder="input width"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoWidth')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Height</Typography.Text>}
				           name={`videoHeight${idx}`}
				           tooltip="Enter height for this video output"
				           rules={validateRequired()}
				>
					<InputNumber min={0} placeholder="input height"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoHeight')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Bitrate</Typography.Text>}
				           name={`videoBitrate${idx}`}
				           tooltip="Enter bitrate for this video output"
				           rules={validateRequired()}
				>
					<InputNumber min={0} placeholder="input bitrate"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoBitrate')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Refs</Typography.Text>}
				           name={`videoRefs${idx}`}
				           tooltip="Enter refs for this video output"

				>
					<InputNumber min={0} placeholder="input refs"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoRefs')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Pixel Format</Typography.Text>}
				           name={`videoPixelFormat${idx}`}
				           tooltip="Select pixel format for this video output"
				>
					<Select placeholder="select pixel format">
						{pixelFormats.map(pixelFormatToString).map((option) =>
							<Option value={option.toLowerCase()}>{option}</Option>
						)}
					</Select>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoPixelFormat')}
			</div>
			<div>
				<GenericOutputS_MultiField>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Frame Rate</Typography.Text>}
					           name={`videoFrameRateNom${idx}`}
					           tooltip="Enter frame rate for this video output"

					>
						<InputNumber min={0} placeholder="input nominator"/>
					</Form.Item>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text></Typography.Text>}
					           name={`videoFrameRateDen${idx}`}

					>
						<InputNumber min={0} placeholder="input denominator"/>
					</Form.Item>
				</GenericOutputS_MultiField>
				{preparePropertyDetails(idx, 'VideoFrameRate')}
			</div>
			<div>
				<GenericOutputS_MultiField>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text>Time Base</Typography.Text>}
					           name={`videoTimeBaseNom${idx}`}
					           tooltip="Enter time base for this video output"

					>
						<InputNumber min={0} placeholder="input nominator"/>
					</Form.Item>
					<Form.Item style={{margin: 0}}
					           label={<Typography.Text></Typography.Text>}
					           name={`videoTimeBaseDen${idx}`}

					>
						<InputNumber min={0} placeholder="input denominator"/>
					</Form.Item>
				</GenericOutputS_MultiField>
				{preparePropertyDetails(idx, 'VideoTimeBase')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Filter</Typography.Text>}
				           name={`videoFilter${idx}`}
				           tooltip="Input filter for this video output"
				           rules={validateRequired()}
				>
					<Input placeholder="input filter"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoFilter')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Level</Typography.Text>}
				           name={`videoLevel${idx}`}
				           tooltip="Input level for this video output"
				>
					<InputNumber min={0} placeholder="input level"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoLevel')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Color Space</Typography.Text>}
				           name={`videoColorSpace${idx}`}
				           tooltip="Select color space for this video output"
				>
					<Select placeholder="select color space">
						{colorSpaces.map(colorSpaceToString).map((option) =>
							<Option value={option.toLowerCase()}>{option}</Option>
						)}
					</Select>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoColorSpace')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Pass Mode</Typography.Text>}
				           name={`videoPassMode${idx}`}
				           tooltip="Select pass mode for this video output"

				>
					<Select placeholder="select pass mode">
						{passModes.map(passModeToString).map((option) =>
							<Option value={option.toLowerCase()}>{option}</Option>
						)}
					</Select>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoPassMode')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>RC Max Bitrate</Typography.Text>}
				           name={`videoRcMaxBitrate${idx}`}
				           tooltip="Input rc max bitrate for this video output"

				>
					<InputNumber min={0} placeholder="input rc max bitrate"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoRcMaxBitrate')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Rc Min Bitrate</Typography.Text>}
				           name={`videoRcMinBitrate${idx}`}
				           tooltip="Input rc min bitrate for this video output"

				>
					<InputNumber min={0} placeholder="input RC min. bitrate"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoRcMinBitrate')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Buf Size</Typography.Text>}
				           name={`videoBufSize${idx}`}
				           tooltip="Input buf size for this video output"

				>
					<InputNumber min={0} placeholder="input buf size"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoBufSize')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Max B Frames</Typography.Text>}
				           name={`videoMaxBFrames${idx}`}
				           tooltip="Input max b frames for this video output"

				>
					<InputNumber min={0} placeholder="input max b frames"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoMaxBFrames')}
			</div>
			<div>
				<Form.Item style={{margin: 0}}
				           label={<Typography.Text>Key Int Min</Typography.Text>}
				           name={`videoKeyIntMin${idx}`}
				           tooltip="Input key int min for this video output"

				>
					<InputNumber min={0} placeholder="input key int min"/>
				</Form.Item>
				{preparePropertyDetails(idx, 'VideoKeyIntMin')}
			</div>
		</GenericOutput>
	);

	const prepareOutputs = () => (
		<Col>
			{withHeader && prepareHeader('Video Outputs', () => setIndexes([...indexes, indexes.length]))}
			{indexes.map((idx) => prepareOutput(idx))}
		</Col>
	);

	return prepareOutputs();
};
