import React, {FC, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Col, Divider, Empty, Form, Input, InputNumber, Row, Select, Space, Table, Typography} from 'antd';
import {ArrowRightOutlined, CloudUploadOutlined, DesktopOutlined, SettingOutlined} from '@ant-design/icons';
import {ListSelectEntry} from '../../../../../components/ListSelect/ListSelect';
import {OutputFormatS, OutputFormatS_Container, OutputFormatS_Wrapper} from './OutputFormatS';
import {TableRowSelection} from 'antd/lib/table/interface';
import {AudioOutput, FileOutput, Preset} from '../../../../../autogen/gRPC/arranger/presets/models_pb';
import {GenericOutput} from '../../../../presets/components/GenericOutput/GenericOutput';
import {validateRequired} from '../../../../settings/domain/validators';
import {GenericOutputS_MultiField} from '../../../../presets/components/GenericOutput/GenericOutputS';
import {ListSelectS_Header, ListSelectS_Info} from '../../../../../components/ListSelect/ListSelectS';
import {channelLayouts, channelLayoutToString} from '../../../../settings/domain/channelLayoutConverter';
import {sampleFormats, sampleFormatToString} from '../../../../settings/domain/sampleFormatConverter';
import {remm} from '../../../../../utils/remm';
import {resolveFormatInfo} from '../../../util/formats';
import {CreateCustomPresetFormValues} from '../SelectPreset/SelectPreset';
import {convertFormStateToFormValues} from '../../../domain/formConverter';
import {VideoAudioMappingS_LongRow} from '../../../../presets/components/VideoAudioMapping/VideoAudioMappingS';
import {UploadToCloudModal} from './UploadToCloudModal/UploadToCloudModal';
import {OutputInfo} from '../../../../../autogen/gRPC/scheduler/models_pb';
import {FileEntry} from '../../../../../stores/slices/files';

const {Option} = Select;

export interface OutputFormatProps {
	selectedPreset: Preset.AsObject;
	selectedOverride: CreateCustomPresetFormValues;
	setSelectedOverride: (arg: CreateCustomPresetFormValues) => void;
	setSelectedFormats: (arg: string[]) => void;
	outputInfo?: OutputInfo;
	setOutputInfo: (arg: OutputInfo) => void;
	selectedFile?: FileEntry;
}

export const OutputFormat: FC<OutputFormatProps> = (props) => {
	const {selectedPreset, selectedOverride, setSelectedOverride, setSelectedFormats, outputInfo, setOutputInfo, selectedFile} = props;

	const [form] = Form.useForm();
	const [selectedRows, setSelectedRows] = useState<ListSelectEntry[]>([]);
	const [selectedFormat, setSelectedFormat] = useState<string>('');
	const [cloudModalVisible, setCloudModalVisible] = useState(false);

	const formatFileOutputMap = useRef<Map<string, FileOutput.AsObject[]>>(new Map<string, FileOutput.AsObject[]>());

	useEffect(() => {
		form.resetFields();
	}, [selectedFormat]);

	useEffect(() => {
		clearUnusedOutputs();
		setSelectedFormats(selectedRows.map((row) => row.key));
	}, [selectedRows]);

	const clearUnusedOutputs = () => {
		const selectedFormats = selectedRows.map((row) => row.key);
		selectedPreset.blueprint?.fileOutputsMap.forEach(([name, fOutput]) => {
			const copy = {...selectedOverride};
			const fileOutput = copy.blueprint.fileOutputs.get(name);
			if (!selectedFormats.includes(fOutput.formatName)) {
				if (fileOutput) {
					fileOutput.disabled = true;
					copy.blueprint.fileOutputs.set(name, fileOutput);
					setSelectedOverride(copy);
				}
			} else {
				if (fileOutput) {
					fileOutput.disabled = false;
					copy.blueprint.fileOutputs.set(name, fileOutput);
					setSelectedOverride(copy);
				}
			}
		});
	};

	const prepareData = () => {
		selectedPreset.blueprint?.fileOutputsMap.forEach(([name, fOutput]) => {
			const format = fOutput.formatName;
			const currentFiles = formatFileOutputMap.current.get(format);
			if (currentFiles) {
				formatFileOutputMap.current.set(format, [...currentFiles, fOutput]);
			} else {
				formatFileOutputMap.current.set(format, [fOutput]);
			}
		});

		return Array.from(formatFileOutputMap.current.keys()).map((name) => {

			const result: ListSelectEntry = {
				key: name,
				name:
					<Row justify="space-between" align="middle">
						<div>
							<DesktopOutlined style={{marginRight: '10px'}}/>{name}
						</div>
						<Button size="small" type="text" shape="circle" icon={<SettingOutlined/>} onClick={() => {
							setSelectedFormat(name);
						}}/>
					</Row>
			};
			return result;
		});
	};

	const prepareColumns = (title: string) => [
		{
			title: <Typography.Text style={{fontWeight: 600}}>{title}</Typography.Text>,
			dataIndex: 'name',
			render: (text: string) => <Typography.Text>{text}</Typography.Text>
		}
	];

	const rowSelection: TableRowSelection<ListSelectEntry> = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows);
		}
	};

	const prepareOutput = (idx: number, aOutputName: string, vOutputName: string, fOutputName: string, aOutput: AudioOutput.AsObject) => {

		const privateDataFields = aOutput.privateDataMap.map(([prop, val]) => {
			return (
				<>
					{!val?.details?.hidden &&
						<div>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text>{prop}</Typography.Text>}
							           name={`audioCustomProperty${aOutputName}|${idx}|${prop}`}
							           tooltip={`Enter ${prop} for this audio output`}
							           rules={val?.details?.required ? validateRequired() : []}
							           initialValue={val?.value && val?.value != '' ? val?.value : undefined}
							>
								<Input disabled={!val?.details?.editable} min={0} placeholder={`input ${prop}`}/>
							</Form.Item>
						</div>
					}
				</>
			);
		});

		return (
			<div key={idx} style={{marginBottom: '40px'}}>
				<Typography.Title style={{fontSize: remm('25px')}}>Output {vOutputName}</Typography.Title>
				<Divider/>
				<Row justify="center" style={{marginLeft: '-55px'}}>
					<Form.Item
						label={<Typography.Text>File Name</Typography.Text>}
						name={`fileName${fOutputName}|${idx}`}
						tooltip="Enter file name"
						rules={validateRequired()}
						initialValue={fOutputName}
					>
						<Input style={{width: '300px'}} placeholder="input file name"/>
					</Form.Item>
				</Row>
				{selectedFile?.details?.streamsList.filter((elem) => elem.codecType === 'audio').length ?
					<div>
						<Typography.Title style={{fontSize: remm('20px'), marginBottom: '20px'}}>Audio Settings</Typography.Title>
						<GenericOutput idx={idx} title={aOutputName}>
							<div>
								<Form.Item style={{margin: 0}}
								           label={<Typography.Text>Bitrate</Typography.Text>}
								           name={`audioBitrate${aOutputName}|${idx}`}
								           tooltip="Enter encoder name for this audio output"
								           rules={validateRequired()}
								           initialValue={aOutput.bitRate?.value}
								>
									<InputNumber min={0} placeholder="input encoder name"/>
								</Form.Item>
							</div>

							<div>
								<Form.Item style={{margin: 0}}
								           label={<Typography.Text>Channel Layout</Typography.Text>}
								           name={`audioChannelLayout${aOutputName}|${idx}`}
								           tooltip="Select channel layout for this audio output"
								           rules={validateRequired()}
								           initialValue={aOutput.channelLayout ? channelLayoutToString(aOutput.channelLayout.value) : undefined}
								>
									<Select placeholder="select channel layout">
										{channelLayouts.map(channelLayoutToString).map((option) =>
											<Option key={option} value={option.toLowerCase()}>{option}</Option>
										)}
									</Select>
								</Form.Item>
							</div>

							<div>
								<Form.Item style={{margin: 0}}
								           label={<Typography.Text>Sample Format</Typography.Text>}
								           name={`audioSampleFormat${aOutputName}|${idx}`}
								           tooltip="Select sample format for this audio output"
								           rules={validateRequired()}
								           initialValue={aOutput.sampleFormat ? sampleFormatToString(aOutput.sampleFormat.value) : undefined}
								>
									<Select placeholder="select sample format">
										{sampleFormats.map(sampleFormatToString).map((option) =>
											<Option key={option} value={option.toLowerCase()}>{option}</Option>
										)}
									</Select>
								</Form.Item>
							</div>

							<div>
								<Form.Item style={{margin: 0}}
								           label={<Typography.Text>Channels Number</Typography.Text>}
								           name={`audioChannels${aOutputName}|${idx}`}
								           tooltip="Enter channels number for this audio output"
								           initialValue={aOutput.channels?.value}
								>
									<InputNumber min={0} placeholder="input channels number"/>
								</Form.Item>
							</div>

							<div>
								<Form.Item style={{margin: 0}}
								           label={<Typography.Text>Sample Rate</Typography.Text>}
								           name={`audioSampleRate${aOutputName}|${idx}`}
								           tooltip="Enter sample rate for this audio output"
								           rules={validateRequired()}
								           initialValue={aOutput.sampleRate?.value}
								>
									<InputNumber min={0} placeholder="input sample rate"/>
								</Form.Item>
							</div>

							<div>
								<GenericOutputS_MultiField>
									<Form.Item style={{margin: 0}}
									           label={<Typography.Text>Time Base</Typography.Text>}
									           name={`audioTimeBaseNom${aOutputName}|${idx}`}
									           tooltip="Enter time base for this audio output"
									           initialValue={aOutput.timeBase?.value?.num}
									>
										<InputNumber min={0} placeholder="input nominator"/>
									</Form.Item>
									<Form.Item style={{margin: 0}}
									           label={<Typography.Text></Typography.Text>}
									           name={`audioTimeBaseDen${aOutputName}|${idx}`}
									           initialValue={aOutput.timeBase?.value?.den}
									>
										<InputNumber min={0} placeholder="input denominator"/>
									</Form.Item>
								</GenericOutputS_MultiField>
							</div>
							{privateDataFields}
						</GenericOutput>
					</div>
					:
					null
				}
			</div>
		);
	};

	const prepareOutputs = () => {
		const entries = selectedPreset.blueprint?.fileOutputsMap.filter(([_, fOutput]) => {
			return fOutput.formatName === selectedFormat;
		});
		if (entries) {
			const outputs: JSX.Element[] = [];
			entries.forEach(([fName, fOutput]) => {
				outputs.push(prepareMappings(fName, fOutput));
				fOutput.videoAudioMapList.map((elem, idx) => {
					const audioOutput = selectedPreset.blueprint?.audioOutputsMap.find(([name]) => name === elem.audio);
					if (audioOutput) {
						const [aName, aOutput] = audioOutput;
						outputs.push(prepareOutput(idx, aName, elem.video, fName, aOutput));
					}
				});
			});
			return outputs;
		}
		return null;
	};

	const prepareMappings = (fName: string, fOutput: FileOutput.AsObject) => {

		if (fOutput.formatName === 'hls') {
			return <div style={{width: '100%'}}>
				<Typography.Title style={{fontSize: remm('25px')}}>Selected Outputs</Typography.Title>
				<Divider/>
				<Row justify="center" align="middle" style={{width: '100%', marginBottom: '30px'}}>
					{
						fOutput.videoAudioMapList.map((videoAudio, idx) => {
							return (
								<VideoAudioMappingS_LongRow>
									<Form.Item style={{margin: 0}}
									           name={`selectedOutput|${videoAudio.audio}|${videoAudio.video}|${fName}`}
									           valuePropName="checked"
									           initialValue={true}
									>
										<Checkbox defaultChecked/>
									</Form.Item>

									<Form.Item style={{margin: 0}}
									           label={<Typography.Text>Output Mapping #{idx}</Typography.Text>}
									           name={`audioOutput|${idx}`}
									           initialValue={videoAudio.audio}

									>
										<Input disabled/>
									</Form.Item>
									<ArrowRightOutlined/>
									<Form.Item style={{margin: 0}}
									           label={<Typography.Text></Typography.Text>}
									           name={`videoOutput|${idx}`}
									           initialValue={videoAudio.video}
									>
										<Input disabled/>
									</Form.Item>
								</VideoAudioMappingS_LongRow>
							);
						})
					}
				</Row>
			</div>;
		}

		if (fOutput.formatName === 'dash') {
			const audioOutputs = [...new Set(fOutput.videoAudioMapList.map((entry) => entry.audio))];
			const videoOutputs = [...new Set(fOutput.videoAudioMapList.map((entry) => entry.video))];

			return <div style={{width: '100%'}}>
				<Typography.Title style={{fontSize: remm('25px')}}>Selected Outputs</Typography.Title>
				<Divider/>
				<Row justify="center" style={{width: '100%', marginBottom: '30px'}}>
					<Col style={{marginRight: '30px'}}>
						<Row justify="center" style={{marginBottom: '10px'}}>
							<Typography.Text>Video outputs</Typography.Text>
						</Row>
						{
							videoOutputs.map((video, idx) => {
								return (
									<Row style={{marginBottom: '10px'}}>
										<Form.Item style={{margin: 0}}
										           name={`selectedVideoOutput|${video}|${fName}`}
										           valuePropName="checked"
										           initialValue={true}
										>
											<Checkbox style={{marginRight: '15px'}} defaultChecked/>
										</Form.Item>

										<Form.Item style={{margin: 0}}
										>
											<Input disabled value={video}/>
										</Form.Item>
									</Row>
								);
							})
						}
					</Col>
					<Col>
						<Row justify="center" style={{marginBottom: '10px'}}>
							<Typography.Text>Audio outputs</Typography.Text>
						</Row>
						{
							audioOutputs.map((audio, idx) => {
								return (
									<Row style={{marginBottom: '10px'}}>
										<Form.Item style={{margin: 0}}
										           name={`selectedAudioOutput|${audio}|${fName}`}
										           valuePropName="checked"
										           initialValue={true}
										>
											<Checkbox style={{marginRight: '15px'}} defaultChecked/>
										</Form.Item>

										<Form.Item style={{margin: 0}}
										           initialValue={audio}
										>
											<Input disabled value={audio}/>
										</Form.Item>
									</Row>
								);
							})
						}
					</Col>
				</Row>
			</div>;
		}
		return <></>;
	};

	const preparePresetPreview = () => {
		return (
			<div style={{minWidth: '45vw'}}>
				{selectedFormat ?
					<Col>
						{prepareOutputs()}
					</Col>
					:
					<ListSelectS_Info>
						<Col>
							<ListSelectS_Header>
								<Typography.Text>File Parameters</Typography.Text>
							</ListSelectS_Header>
							<Col>
								<Row style={{padding: '15px 50px'}}>
									<Typography.Text>
										Please select target file formats in a table at the left. If you want to adjust parameters of specific output, please
										click the gear icon.
									</Typography.Text>
								</Row>
								<Row justify="center" align="middle" style={{height: '60%'}}>
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
								</Row>
							</Col>
						</Col>
					</ListSelectS_Info>
				}
			</div>
		);
	};

	const formatInfo = () => {
		if (selectedFormat) {
			return resolveFormatInfo(selectedFormat as any);
		} else {
			return {
				url: '',
				summary: ''
			};
		}
	};

	const excludeSelectedOutputsHsl = (formState: any, values: CreateCustomPresetFormValues) => {
		const result = {...values};

		interface Combination {
			file: string;
			video: string;
			audio: string;
		}

		const excludedOutputs: Combination[] = [];
		for (const [field, value] of Object.entries(formState)) {
			if (field.startsWith(`selectedOutput`)) {
				const parts = field.split('|');

				if (parts.length == 4 && !(value as boolean)) {
					const audio = parts[1];
					const video = parts[2];
					const file = parts[3];

					excludedOutputs.push({
						file: file,
						audio: audio,
						video: video
					});
				}
			}
		}

		excludedOutputs.forEach((output) => {
			const current = result.blueprint.disabled.get(output.file);
			const newVal = {idx: -1, audio: output.audio, video: output.video};
			if (current) {
				result.blueprint.disabled.set(output.file, [...current, newVal]);
			} else {
				result.blueprint.disabled.set(output.file, [newVal]);
			}
		});

		return result;
	};

	const excludeSelectedOutputsDash = (formState: any, values: CreateCustomPresetFormValues) => {
		const result = {...values};

		interface AudioCombination {
			file: string;
			audio: string;
		}

		interface VideoCombination {
			file: string;
			video: string;
		}

		const excludedAudioOutputs: AudioCombination[] = [];
		const excludedVideoOutputs: VideoCombination[] = [];
		for (const [field, value] of Object.entries(formState)) {

			if (field.startsWith(`selectedAudioOutput`)) {
				const parts = field.split('|');
				if (parts.length == 3 && !(value as boolean)) {
					const name = parts[1];
					const file = parts[2];
					excludedAudioOutputs.push({audio: name, file: file});
				}
			}

			if (field.startsWith(`selectedVideoOutput`)) {
				const parts = field.split('|');
				if (parts.length == 3 && !(value as boolean)) {
					const name = parts[1];
					const file = parts[2];
					excludedVideoOutputs.push({video: name, file: file});
				}
			}
		}

		interface Combination {
			file: string;
			video: string;
			audio: string;
		}

		const excludedAudioVideo: Combination[] = [];
		selectedPreset.blueprint?.fileOutputsMap.forEach(([name, fOutput]) => {
			fOutput.videoAudioMapList.forEach((audioVideo) => {

				excludedAudioOutputs.forEach((aOutput) => {
					if (name === aOutput.file && audioVideo.audio == aOutput.audio) {
						excludedAudioVideo.push({file: aOutput.file, audio: audioVideo.audio, video: audioVideo.video});
					}
				});
				excludedVideoOutputs.forEach((vOutput) => {
					if (name === vOutput.file && audioVideo.video == vOutput.video) {
						excludedAudioVideo.push({file: vOutput.file, audio: audioVideo.audio, video: audioVideo.video});
					}
				});

			});
		});

		excludedAudioVideo.forEach((audioVideo) => {
			const current = result.blueprint.disabled.get(audioVideo.file);
			if (current) {
				result.blueprint.disabled.set(audioVideo.file, [...current, {idx: -1, audio: audioVideo.audio, video: audioVideo.video}]);
			} else {
				result.blueprint.disabled.set(audioVideo.file, [{idx: -1, audio: audioVideo.audio, video: audioVideo.video}]);
			}
		});

		return result;
	};

	return (
		<OutputFormatS>
			<Col>
				<Row justify="center">
					<Typography.Title>Step 4: select output file format</Typography.Title>
				</Row>
				<Space direction="vertical" size={30}>
					<Typography.Text>
						At this stage you can select output file format for your video. Depending on the application, you can choose one of the
						predefined file types from the table on the left. After selecting the file type, you can also adjust the audio parameters of
						your file.
					</Typography.Text>
					<Button icon={<CloudUploadOutlined/>} type="primary" onClick={() => setCloudModalVisible(true)}>Upload to cloud</Button>
					<OutputFormatS_Container>
						<Col style={{width: '100%', marginTop: '-14px'}}>
							<Space style={{width: '100%'}} direction="vertical" size={30}>
								<Table
									rowSelection={{
										type: 'checkbox',
										...rowSelection
									}}
									columns={prepareColumns('Available Output Formats')}
									pagination={false}
									dataSource={prepareData()}
								/>
								<ListSelectS_Info style={{width: '15vw', minHeight: '23.5vh'}}>
									<Col style={{width: '15vw'}}>
										<ListSelectS_Header style={{width: '15vw'}}>
											<Typography.Text>Format info</Typography.Text>
										</ListSelectS_Header>
										{
											formatInfo()?.url !== '' ?
												<Col>
													<Row style={{padding: '15px 50px'}}>
														<Col style={{marginTop: '20px'}}>
															<div>
																<Typography.Text>{formatInfo()?.summary}</Typography.Text>
															</div>
															<Row justify="end">
																<Typography.Link style={{marginRight: '20px'}} href={formatInfo()?.url} target="_blank">More
																	info...</Typography.Link>
															</Row>
														</Col>
													</Row>
												</Col>
												:
												<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
										}
									</Col>
								</ListSelectS_Info>
							</Space>
						</Col>
						<OutputFormatS_Wrapper wrapper={selectedFormat !== ''}>
							<Form
								form={form}
								layout="vertical"
								autoComplete="Off"
								onValuesChange={() => {
									const formState = form.getFieldsValue();
									let result = convertFormStateToFormValues(formState, selectedPreset, selectedOverride);
									result = excludeSelectedOutputsHsl(formState, result);
									result = excludeSelectedOutputsDash(formState, result);
									setSelectedOverride(result);
								}}
							>
								{preparePresetPreview()}
							</Form>
						</OutputFormatS_Wrapper>
					</OutputFormatS_Container>
				</Space>
			</Col>
			<UploadToCloudModal visible={cloudModalVisible} setVisible={setCloudModalVisible} outputInfo={outputInfo}
			                    setOutputInfo={setOutputInfo}/>
		</OutputFormatS>
	);
};
