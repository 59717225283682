import {createEntityAdapter, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {Job} from '../../autogen/gRPC/arranger/jobs/models_pb';

const jobsAdapter = createEntityAdapter<Job.AsObject>({
	selectId: (job) => job.id
});

export const jobsSlice = createSlice({
	name: 'jobs',
	initialState: jobsAdapter.getInitialState(),
	reducers: {
		addJobs: jobsAdapter.addMany,
		addJob: jobsAdapter.addOne,
		setJobs: jobsAdapter.setMany,
		removeJob: jobsAdapter.removeOne,
		removeJobs: jobsAdapter.removeMany,
		updateJob: (state, action: PayloadAction<Job.AsObject>) => {
			state.entities[action.payload.id] = action.payload;
		}
	}
});

export const jobsActions = jobsSlice.actions;
export const selectJobs = jobsAdapter.getSelectors<RootState>((state) => state.jobs).selectAll;
export const selectJob = jobsAdapter.getSelectors<RootState>((state) => state.jobs).selectById;

export const selectJobsAsc = createSelector(
	selectJobs,
	job => job.sort((a, b) => a.createdAt <= b.createdAt ? 1 : -1)
);
