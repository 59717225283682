import {Status} from '../../../autogen/gRPC/arranger/jobs/enums_pb';

///
/// Statuses List
///

export const jobStatuses: Status[] = [
	Status.STATUS_PENDING,
	Status.STATUS_DOWNLOADING,
	Status.STATUS_ANALYSING,
	Status.STATUS_ANALYZED,
	Status.STATUS_TRANSCODING,
	Status.STATUS_TRANSCODED,
	Status.STATUS_MUXING,
	Status.STATUS_FINISHED,
	Status.STATUS_CANCELLED,
	Status.STATUS_FAILED
];

///
/// Status to String
///

export const jobStatusToString = (jobStatus: Status) => {
	switch (jobStatus) {
		case Status.STATUS_PENDING: {
			return 'PENDING';
		}
		case Status.STATUS_DOWNLOADING: {
			return 'DOWNLOADING';
		}
		case Status.STATUS_ANALYSING: {
			return 'ANALYSING';
		}
		case Status.STATUS_ANALYZED: {
			return 'ANALYZED';
		}
		case Status.STATUS_TRANSCODING: {
			return 'TRANSCODING';
		}
		case Status.STATUS_TRANSCODED: {
			return 'TRANSCODED';
		}
		case Status.STATUS_MUXING: {
			return 'MUXING';
		}
		case Status.STATUS_FINISHED: {
			return 'FINISHED';
		}
		case Status.STATUS_CANCELLED: {
			return 'CANCELLED';
		}
		case Status.STATUS_FAILED: {
			return 'FAILED';
		}
		case Status.STATUS_UNKNOWN: {
			return 'UNKNOWN';
		}
		default: {
			return 'UNKNOWN';
		}
	}
};

///
/// Categories String List Type
///

export type StatusName = ReturnType<typeof jobStatusToString>

///
/// Status string to eventAccess
///

export const jobStatusStringToEnum = (jobStatus: StatusName) => {
	switch (jobStatus.toUpperCase()) {
		case 'PENDING': {
			return Status.STATUS_PENDING;
		}
		case 'DOWNLOADING': {
			return Status.STATUS_DOWNLOADING;
		}
		case 'ANALYSING': {
			return Status.STATUS_ANALYSING;
		}
		case 'ANALYZED': {
			return Status.STATUS_ANALYZED;
		}
		case 'TRANSCODING': {
			return Status.STATUS_TRANSCODING;
		}
		case 'TRANSCODED': {
			return Status.STATUS_TRANSCODED;
		}
		case 'MUXING': {
			return Status.STATUS_MUXING;
		}
		case 'FINISHED': {
			return Status.STATUS_FINISHED;
		}
		case 'CANCELLED': {
			return Status.STATUS_CANCELLED;
		}
		case 'FAILED': {
			return Status.STATUS_FAILED;
		}
		case 'UNKNOWN': {
			return Status.STATUS_UNKNOWN;
		}
		default: {
			return Status.STATUS_UNKNOWN;
		}
	}
};
