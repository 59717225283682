import {createEntityAdapter, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {File} from '../../autogen/gRPC/arranger/files/models_pb';
import {FileInfo} from '../../autogen/gRPC/prober/models_pb';

export interface FileEntry {
	name: string;
	file?: File.AsObject;
	details?: FileInfo.AsObject;
}

const filesAdapter = createEntityAdapter<FileEntry>({
	selectId: (file) => file.name
});

export const filesSlice = createSlice({
	name: 'files',
	initialState: filesAdapter.getInitialState(),
	reducers: {
		addFiles: filesAdapter.addMany,
		addFile: filesAdapter.addOne,
		setFile: filesAdapter.setOne,
		removeFile: filesAdapter.removeOne,
		removeFiles: filesAdapter.removeMany,
		updateFile: (state, action: PayloadAction<FileEntry>) => {
			state.entities[action.payload.name] = action.payload;
		}
	}
});

export const filesActions = filesSlice.actions;
export const selectFiles = filesAdapter.getSelectors<RootState>((state) => state.files).selectAll;
export const selectFile = filesAdapter.getSelectors<RootState>((state) => state.files).selectById;
