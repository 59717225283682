import styled from 'styled-components';

export const GenericOutputS = styled.div<{ deletable?: boolean }>`
	margin-bottom: 20px;
	
	.ant-input {
		width: 300px;
	}

	.ant-input-number {
		width: 300px;
	}

	.ant-select {
		width: 300px;
	}

	.ant-collapse-arrow {
		margin-top: ${({deletable}) => deletable ? '10px' : 'unset'};
	}
`;

export const GenericOutputS_Container = styled.div`
	padding-top: 30px;
	max-width: 1200px;
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	margin-bottom: 20px;
	justify-items: center;
`;

export const GenericOutputS_Separator = styled.div`
	width: 300px;
	
	@media (max-width: 1480px) {
		display: none;
	}
`;

export const GenericOutputS_MultiField = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 300px;
	
	.ant-input {
		width: 140px;
	}

	.ant-input-number {
		width: 140px;
	}
`;

export const GenericOutputS_BaseOptionsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 30px;
	width: 100%;

	@media (max-width: 1000px) {
		flex-direction: column;
		gap: unset;
	}
`;
