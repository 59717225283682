import {CreateCustomPresetFormValues} from '../components/CreateJob/SelectPreset/SelectPreset';
import {AudioOutputOverride, FileOutputOverride, VideoOutputOverride} from './blueprintOverride';
import {Preset} from '../../../autogen/gRPC/arranger/presets/models_pb';
import {ChannelLayoutName} from '../../settings/domain/channelLayoutConverter';
import {SampleFormatName} from '../../settings/domain/sampleFormatConverter';
import {PixelFormatName, pixelFormatToString} from '../../settings/domain/pixelFormatConverter';
import {ColorSpaceName, colorSpaceToString} from '../../settings/domain/colorSpaceConverter';
import {PassModeName, passModeToString} from '../../settings/domain/passModeConverter';
import {Property, VideoAudioMapper} from '../../settings/domain/blueprint';

export const convertFormStateToFormValues = (formState: any, basePreset: Preset.AsObject, initialValue?: CreateCustomPresetFormValues) => {
	let result: CreateCustomPresetFormValues = {
		blueprint: {
			disabled: new Map<string, VideoAudioMapper[]>(),
			audioOutputs: new Map<string, AudioOutputOverride>(),
			fileOutputs: new Map<string, FileOutputOverride>(),
			videoOutputs: new Map<string, VideoOutputOverride>()
		}
	};

	if (initialValue) {
		result = {...initialValue};
	}

	for (const [field, value] of Object.entries(formState)) {

		/*
		 * File
		 */

		basePreset.blueprint?.fileOutputsMap.forEach(([name, fOutput], idx) => {
			if (!result.blueprint.fileOutputs.get(name)) {
				result.blueprint.fileOutputs.set(name, {});
			}
			const fileOutput = result.blueprint.fileOutputs.get(name);
			if (fileOutput) {
				fileOutput.formatName = fOutput.formatName;

			}

			if (field.startsWith(`fileName${name}|${idx}`) && value !== name) {
				if (!result.blueprint.fileOutputs.get(name)) {
					result.blueprint.fileOutputs.set(name, {});
				}
				const fileOutput = result.blueprint.fileOutputs.get(name);
				if (fileOutput) {
					fileOutput.fileName = value as string;
				}
			}
		});

		/*
		 * Audio
		 */

		basePreset.blueprint?.audioOutputsMap.forEach(([name, aOutput], idx) => {

			/// Bitrate

			if (field.startsWith(`audioBitrate${name}|${idx}`) && value !== aOutput.bitRate?.value) {
				if (!result.blueprint.audioOutputs.get(name)) {
					result.blueprint.audioOutputs.set(name, {});
				}
				const audioOutput = result.blueprint.audioOutputs.get(name);
				if (audioOutput) {
					audioOutput.bitrate = {value: value as number, details: {}};
				}
			}

			/// ChannelLayout

			if (field.startsWith(`audioChannelLayout${name}|${idx}`) && value !== aOutput.channelLayout?.value) {
				if (!result.blueprint.audioOutputs.get(name)) {
					result.blueprint.audioOutputs.set(name, {});
				}
				const audioOutput = result.blueprint.audioOutputs.get(name);
				if (audioOutput) {
					audioOutput.channelLayout = {value: value as ChannelLayoutName, details: {}};
				}
			}

			/// SampleFormat

			if (field.startsWith(`audioSampleFormat${name}|${idx}`) && value !== aOutput.sampleFormat?.value) {
				if (!result.blueprint.audioOutputs.get(name)) {
					result.blueprint.audioOutputs.set(name, {});
				}
				const audioOutput = result.blueprint.audioOutputs.get(name);
				if (audioOutput) {
					audioOutput.sampleFormat = {value: value as SampleFormatName, details: {}};
				}
			}

			/// Channels

			if (field.startsWith(`audioChannels${name}|${idx}`) && value !== aOutput.channels?.value) {
				if (!result.blueprint.audioOutputs.get(name)) {
					result.blueprint.audioOutputs.set(name, {});
				}
				const audioOutput = result.blueprint.audioOutputs.get(name);
				if (audioOutput) {
					audioOutput.channels = {value: value as number, details: {}};
				}
			}

			/// SampleRate

			if (field.startsWith(`audioSampleRate${name}|${idx}`) && value !== aOutput.sampleRate?.value) {
				if (!result.blueprint.audioOutputs.get(name)) {
					result.blueprint.audioOutputs.set(name, {});
				}
				const audioOutput = result.blueprint.audioOutputs.get(name);
				if (audioOutput) {
					audioOutput.sampleRate = {value: value as number, details: {}};
				}
			}

			/// TimeBase

			if (field.startsWith(`audioTimeBaseNom${name}|${idx}`) && value !== aOutput.timeBase?.value?.num) {
				if (!result.blueprint.audioOutputs.get(name)) {
					result.blueprint.audioOutputs.set(name, {});
				}
				const audioOutput = result.blueprint.audioOutputs.get(name);
				if (audioOutput) {
					if (!audioOutput.timeBase) {
						audioOutput.timeBase = {value: {nom: value as number, den: 0}, details: {}};
					}
					audioOutput.timeBase.value = {...audioOutput.timeBase.value, nom: value as number};
				}
			}

			if (field.startsWith(`audioTimeBaseDen${name}|${idx}`) && value !== aOutput.timeBase?.value?.den) {
				if (!result.blueprint.audioOutputs.get(name)) {
					result.blueprint.audioOutputs.set(name, {});
				}
				const audioOutput = result.blueprint.audioOutputs.get(name);
				if (audioOutput) {
					if (!audioOutput.timeBase) {
						audioOutput.timeBase = {value: {nom: 0, den: value as number}, details: {}};
					}
					audioOutput.timeBase.value = {...audioOutput.timeBase.value, den: value as number};
				}
			}

			/// Private Data
			if (field.startsWith(`audioCustomProperty${name}|${idx}`)) {
				const parts = field.split('|');
				if (parts.length === 3) {
					const prop = parts[2];
					aOutput.privateDataMap.forEach(([property, data]) => {
						if (property === prop && data.value !== value as string) {
							const audioOutput = result.blueprint.audioOutputs.get(name);
							if (audioOutput && audioOutput.privateData) {
								audioOutput.privateData?.set(prop, {value: value as string, details: {}});
							} else if (audioOutput) {
								audioOutput.privateData = new Map<string, Property<string>>();
								audioOutput.privateData.set(prop, {value: value as string, details: {}});
							}
						}
					});
				}
			}

		});

		/*
     * Video
     */

		basePreset.blueprint?.videoOutputsMap.forEach(([name, vOutput], idx) => {

			/// MaxGop

			if (field.startsWith(`videoMaxGop${name}|${idx}`) && value !== vOutput.maxGop?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.maxGop = {value: value as number, details: {}};
				}
			}

			/// Width

			if (field.startsWith(`videoWidth${name}|${idx}`) && value !== vOutput.width?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.width = {value: value as number, details: {}};
				}
			}

			/// Height

			if (field.startsWith(`videoHeight${name}|${idx}`) && value !== vOutput.height?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.height = {value: value as number, details: {}};
				}
			}

			/// Bitrate

			if (field.startsWith(`videoBitrate${name}|${idx}`) && value !== vOutput.bitRate?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.bitrate = {value: value as number, details: {}};
				}
			}

			/// Refs

			if (field.startsWith(`videoRefs${name}|${idx}`) && value !== vOutput.refs?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.refs = {value: value as number, details: {}};
				}
			}

			/// PixelFormat

			if (field.startsWith(`videoPixelFormat${name}|${idx}`) && vOutput.pixelFormat?.value && value !== pixelFormatToString(vOutput.pixelFormat.value)) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.pixelFormat = {value: value as PixelFormatName, details: {}};
				}
			}

			/// FrameRate

			if (field.startsWith(`videoFrameRateNom${name}|${idx}`) && value !== vOutput.frameRate?.value?.num) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					if (!videoOutput.frameRate) {
						videoOutput.frameRate = {value: {nom: value as number, den: 0}, details: {}};
					}
					videoOutput.frameRate.value = {...videoOutput.frameRate.value, nom: value as number};
				}
			}

			if (field.startsWith(`videoFrameRateDen${name}|${idx}`) && value !== vOutput.frameRate?.value?.den) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					if (!videoOutput.frameRate) {
						videoOutput.frameRate = {value: {nom: 0, den: value as number}, details: {}};
					}
					videoOutput.frameRate.value = {...videoOutput.frameRate.value, den: value as number};
				}
			}

			/// TimeBase

			if (field.startsWith(`videoTimeBaseNom${name}|${idx}`) && value !== vOutput.timeBase?.value?.num) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					if (!videoOutput.timeBase) {
						videoOutput.timeBase = {value: {nom: value as number, den: 0}, details: {}};
					}
					videoOutput.timeBase.value = {...videoOutput.timeBase.value, nom: value as number};
				}
			}

			if (field.startsWith(`videoTimeBaseDen${name}|${idx}`) && value !== vOutput.timeBase?.value?.den) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					if (!videoOutput.timeBase) {
						videoOutput.timeBase = {value: {nom: 0, den: value as number}, details: {}};
					}
					videoOutput.timeBase.value = {...videoOutput.timeBase.value, den: value as number};
				}
			}

			/// Filter

			if (field.startsWith(`videoFilter${name}|${idx}`) && value !== vOutput.filter?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.filter = {value: value as string, details: {}};
				}
			}

			/// Level

			if (field.startsWith(`videoLevel${name}|${idx}`) && value !== vOutput.level?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.level = {value: value as number, details: {}};
				}
			}

			/// ColorSpace

			if (field.startsWith(`videoColorSpace${name}|${idx}`) && vOutput.colorSpace?.value && value !== colorSpaceToString(vOutput.colorSpace.value)) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.colorSpace = {value: value as ColorSpaceName, details: {}};
				}
			}

			/// PassMode

			if (field.startsWith(`videoPassMode${name}|${idx}`) && vOutput.passMode?.value && value !== passModeToString(vOutput.passMode?.value)) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.passMode = {value: value as PassModeName, details: {}};
				}
			}

			/// RcMaxBitrate

			if (field.startsWith(`videoRcMaxBitrate${name}|${idx}`) && value !== vOutput.rcMaxBitrate?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.rcMaxBitrate = {value: value as number, details: {}};
				}
			}

			/// RcMinBitrate

			if (field.startsWith(`videoRcMinBitrate${name}|${idx}`) && value !== vOutput.rcMinBitrate?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.rcMinBitrate = {value: value as number, details: {}};
				}
			}

			/// BufSize

			if (field.startsWith(`videoBufSize${name}|${idx}`) && value !== vOutput.bufSize?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.bufSize = {value: value as number, details: {}};
				}
			}

			/// MaxBFrames

			if (field.startsWith(`videoMaxBFrames${name}|${idx}`) && value !== vOutput.maxBFrames?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.maxBFrames = {value: value as number, details: {}};
				}
			}

			/// KeyIntMin

			if (field.startsWith(`videoKeyIntMin${name}|${idx}`) && value !== vOutput.keyIntMin?.value) {
				if (!result.blueprint.videoOutputs.get(name)) {
					result.blueprint.videoOutputs.set(name, {});
				}
				const videoOutput = result.blueprint.videoOutputs.get(name);
				if (videoOutput) {
					videoOutput.keyIntMin = {value: value as number, details: {}};
				}
			}

			/// Private Data
			if (field.startsWith(`videoCustomProperty${name}|${idx}`)) {
				const parts = field.split('|');
				if (parts.length === 3) {
					const prop = parts[2];
					vOutput.privateDataMap.forEach(([property, data]) => {
						if (property === prop && data.value !== value as string) {
							const videoOutput = result.blueprint.videoOutputs.get(name);
							if (videoOutput && videoOutput.privateData) {
								videoOutput.privateData?.set(prop, {value: value as string, details: {}});
							} else if (videoOutput) {
								videoOutput.privateData = new Map<string, Property<string>>();
								videoOutput.privateData.set(prop, {value: value as string, details: {}});
							}
						}
					});
				}
			}

		});
	}

	return result;
};
