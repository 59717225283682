import {createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {Preset} from '../../autogen/gRPC/arranger/presets/models_pb';
import {Category} from '../../autogen/gRPC/arranger/presets/enums_pb';

const basePresetsAdapter = createEntityAdapter<Preset.AsObject>({
	selectId: (basePreset) => basePreset.name
});

export const basePresetsSlice = createSlice({
	name: 'basePresets',
	initialState: basePresetsAdapter.getInitialState(),
	reducers: {
		addBasePresets: basePresetsAdapter.addMany,
		addBasePreset: basePresetsAdapter.addOne,
		setBasePresets: basePresetsAdapter.setMany,
		removeBasePreset: basePresetsAdapter.removeOne,
		removeBasePresets: basePresetsAdapter.removeMany,
		updateBasePreset: basePresetsAdapter.updateOne
	}
});

export const basePresetsActions = basePresetsSlice.actions;
export const selectBasePresets = basePresetsAdapter.getSelectors<RootState>((state) => state.basePresets).selectAll;
export const selectBasePreset = basePresetsAdapter.getSelectors<RootState>((state) => state.basePresets).selectById;

export const selectBasePresetById = (id: string) => createSelector(
	selectBasePresets,
	basePreset => basePreset.filter(basePreset => basePreset.id === id).at(0)
);

export const selectBasePresetByCategory = (category: Category) => createSelector(
	selectBasePresets,
	basePreset => basePreset.filter(basePreset => basePreset.category === category)
);
