import {Category} from '../../../autogen/gRPC/arranger/presets/enums_pb';

///
/// Categories List
///

export const categories: Category[] = [
	Category.CATEGORY_H264,
	Category.CATEGORY_H265,
	Category.CATEGORY_VP8,
	Category.CATEGORY_VP9,
	Category.CATEGORY_AV1
];

///
/// Category to String
///

export const categoryToString = (category: Category) => {
	switch (category) {
		case Category.CATEGORY_H264: {
			return 'H.264';
		}
		case Category.CATEGORY_H265: {
			return 'H.265';
		}
		case Category.CATEGORY_VP8: {
			return 'VP8';
		}
		case Category.CATEGORY_VP9: {
			return 'VP9';
		}
		case Category.CATEGORY_AV1: {
			return 'AV1';
		}
		case Category.CATEGORY_UNKNOWN: {
			return 'UNKNOWN';
		}
		default: {
			return 'UNKNOWN';
		}
	}
};

///
/// Categories String List Type
///

export type CategoryName = ReturnType<typeof categoryToString>

///
/// Category string to eventAccess
///

export const categoryStringToEnum = (category: CategoryName) => {
	switch (category.toUpperCase()) {
		case 'H.264': {
			return Category.CATEGORY_H264;
		}
		case 'H.265': {
			return Category.CATEGORY_H265;
		}
		case 'VP8': {
			return Category.CATEGORY_VP8;
		}
		case 'VP9': {
			return Category.CATEGORY_VP9;
		}
		case 'AV1': {
			return Category.CATEGORY_AV1;
		}
		case 'UNKNOWN': {
			return Category.CATEGORY_UNKNOWN;
		}
		default: {
			return Category.CATEGORY_UNKNOWN;
		}
	}
};
