// source: arranger/presets/base/service_presets.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var arranger_presets_enums_pb = require('../../../arranger/presets/enums_pb.js');
goog.object.extend(proto, arranger_presets_enums_pb);
var arranger_presets_models_pb = require('../../../arranger/presets/models_pb.js');
goog.object.extend(proto, arranger_presets_models_pb);
var error_models_pb = require('../../../error/models_pb.js');
goog.object.extend(proto, error_models_pb);
var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var protoc$gen$openapiv2_options_annotations_pb = require('../../../protoc-gen-openapiv2/options/annotations_pb.js');
goog.object.extend(proto, protoc$gen$openapiv2_options_annotations_pb);
goog.exportSymbol('proto.presets.base.api.v1.CountRequest', null, global);
goog.exportSymbol('proto.presets.base.api.v1.CountResponse', null, global);
goog.exportSymbol('proto.presets.base.api.v1.CreateRequest', null, global);
goog.exportSymbol('proto.presets.base.api.v1.CreateResponse', null, global);
goog.exportSymbol('proto.presets.base.api.v1.DeleteRequest', null, global);
goog.exportSymbol('proto.presets.base.api.v1.DeleteResponse', null, global);
goog.exportSymbol('proto.presets.base.api.v1.GetRequest', null, global);
goog.exportSymbol('proto.presets.base.api.v1.GetResponse', null, global);
goog.exportSymbol('proto.presets.base.api.v1.ListRequest', null, global);
goog.exportSymbol('proto.presets.base.api.v1.ListResponse', null, global);
goog.exportSymbol('proto.presets.base.api.v1.UpdateRequest', null, global);
goog.exportSymbol('proto.presets.base.api.v1.UpdateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.GetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.GetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.GetRequest.displayName = 'proto.presets.base.api.v1.GetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.GetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.GetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.GetResponse.displayName = 'proto.presets.base.api.v1.GetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.ListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.presets.base.api.v1.ListRequest.repeatedFields_, null);
};
goog.inherits(proto.presets.base.api.v1.ListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.ListRequest.displayName = 'proto.presets.base.api.v1.ListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.ListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.presets.base.api.v1.ListResponse.repeatedFields_, null);
};
goog.inherits(proto.presets.base.api.v1.ListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.ListResponse.displayName = 'proto.presets.base.api.v1.ListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.CountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.presets.base.api.v1.CountRequest.repeatedFields_, null);
};
goog.inherits(proto.presets.base.api.v1.CountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.CountRequest.displayName = 'proto.presets.base.api.v1.CountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.CountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.CountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.CountResponse.displayName = 'proto.presets.base.api.v1.CountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.CreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.CreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.CreateRequest.displayName = 'proto.presets.base.api.v1.CreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.CreateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.CreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.CreateResponse.displayName = 'proto.presets.base.api.v1.CreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.UpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.UpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.UpdateRequest.displayName = 'proto.presets.base.api.v1.UpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.UpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.UpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.UpdateResponse.displayName = 'proto.presets.base.api.v1.UpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.DeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.DeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.DeleteRequest.displayName = 'proto.presets.base.api.v1.DeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.presets.base.api.v1.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.presets.base.api.v1.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.presets.base.api.v1.DeleteResponse.displayName = 'proto.presets.base.api.v1.DeleteResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.GetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.GetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.GetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.GetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.GetRequest}
 */
proto.presets.base.api.v1.GetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.GetRequest;
  return proto.presets.base.api.v1.GetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.GetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.GetRequest}
 */
proto.presets.base.api.v1.GetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.GetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.GetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.GetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.GetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.presets.base.api.v1.GetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.base.api.v1.GetRequest} returns this
 */
proto.presets.base.api.v1.GetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.GetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.GetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.GetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.GetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    preset: (f = msg.getPreset()) && arranger_presets_models_pb.Preset.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.GetResponse}
 */
proto.presets.base.api.v1.GetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.GetResponse;
  return proto.presets.base.api.v1.GetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.GetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.GetResponse}
 */
proto.presets.base.api.v1.GetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new arranger_presets_models_pb.Preset;
      reader.readMessage(value,arranger_presets_models_pb.Preset.deserializeBinaryFromReader);
      msg.setPreset(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.GetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.GetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.GetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.GetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      arranger_presets_models_pb.Preset.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional presets.api.v1.Preset preset = 1;
 * @return {?proto.presets.api.v1.Preset}
 */
proto.presets.base.api.v1.GetResponse.prototype.getPreset = function() {
  return /** @type{?proto.presets.api.v1.Preset} */ (
    jspb.Message.getWrapperField(this, arranger_presets_models_pb.Preset, 1));
};


/**
 * @param {?proto.presets.api.v1.Preset|undefined} value
 * @return {!proto.presets.base.api.v1.GetResponse} returns this
*/
proto.presets.base.api.v1.GetResponse.prototype.setPreset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.GetResponse} returns this
 */
proto.presets.base.api.v1.GetResponse.prototype.clearPreset = function() {
  return this.setPreset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.GetResponse.prototype.hasPreset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.presets.base.api.v1.GetResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.presets.base.api.v1.GetResponse} returns this
*/
proto.presets.base.api.v1.GetResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.GetResponse} returns this
 */
proto.presets.base.api.v1.GetResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.GetResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.presets.base.api.v1.ListRequest.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.ListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.ListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.ListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.ListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    namesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    offset: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    order: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.ListRequest}
 */
proto.presets.base.api.v1.ListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.ListRequest;
  return proto.presets.base.api.v1.ListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.ListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.ListRequest}
 */
proto.presets.base.api.v1.ListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.presets.api.v1.Category>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategories(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.ListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.ListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.ListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.ListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.presets.base.api.v1.ListRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * repeated string names = 2;
 * @return {!Array<string>}
 */
proto.presets.base.api.v1.ListRequest.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};


/**
 * repeated presets.api.v1.Category categories = 3;
 * @return {!Array<!proto.presets.api.v1.Category>}
 */
proto.presets.base.api.v1.ListRequest.prototype.getCategoriesList = function() {
  return /** @type {!Array<!proto.presets.api.v1.Category>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.presets.api.v1.Category>} value
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.presets.api.v1.Category} value
 * @param {number=} opt_index
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional int64 offset = 4;
 * @return {number}
 */
proto.presets.base.api.v1.ListRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 limit = 5;
 * @return {number}
 */
proto.presets.base.api.v1.ListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string order = 6;
 * @return {string}
 */
proto.presets.base.api.v1.ListRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.base.api.v1.ListRequest} returns this
 */
proto.presets.base.api.v1.ListRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.presets.base.api.v1.ListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.ListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.ListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.ListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.ListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    presetsList: jspb.Message.toObjectList(msg.getPresetsList(),
    arranger_presets_models_pb.Preset.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.ListResponse}
 */
proto.presets.base.api.v1.ListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.ListResponse;
  return proto.presets.base.api.v1.ListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.ListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.ListResponse}
 */
proto.presets.base.api.v1.ListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new arranger_presets_models_pb.Preset;
      reader.readMessage(value,arranger_presets_models_pb.Preset.deserializeBinaryFromReader);
      msg.addPresets(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.ListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.ListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.ListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.ListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPresetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      arranger_presets_models_pb.Preset.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated presets.api.v1.Preset presets = 1;
 * @return {!Array<!proto.presets.api.v1.Preset>}
 */
proto.presets.base.api.v1.ListResponse.prototype.getPresetsList = function() {
  return /** @type{!Array<!proto.presets.api.v1.Preset>} */ (
    jspb.Message.getRepeatedWrapperField(this, arranger_presets_models_pb.Preset, 1));
};


/**
 * @param {!Array<!proto.presets.api.v1.Preset>} value
 * @return {!proto.presets.base.api.v1.ListResponse} returns this
*/
proto.presets.base.api.v1.ListResponse.prototype.setPresetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.presets.api.v1.Preset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.presets.api.v1.Preset}
 */
proto.presets.base.api.v1.ListResponse.prototype.addPresets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.presets.api.v1.Preset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.base.api.v1.ListResponse} returns this
 */
proto.presets.base.api.v1.ListResponse.prototype.clearPresetsList = function() {
  return this.setPresetsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.presets.base.api.v1.ListResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.presets.base.api.v1.ListResponse} returns this
 */
proto.presets.base.api.v1.ListResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.presets.base.api.v1.ListResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.presets.base.api.v1.ListResponse} returns this
*/
proto.presets.base.api.v1.ListResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.ListResponse} returns this
 */
proto.presets.base.api.v1.ListResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.ListResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.presets.base.api.v1.CountRequest.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.CountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.CountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.CountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.CountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    namesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.CountRequest}
 */
proto.presets.base.api.v1.CountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.CountRequest;
  return proto.presets.base.api.v1.CountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.CountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.CountRequest}
 */
proto.presets.base.api.v1.CountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.presets.api.v1.Category>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategories(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.CountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.CountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.CountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.CountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.presets.base.api.v1.CountRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * repeated string names = 2;
 * @return {!Array<string>}
 */
proto.presets.base.api.v1.CountRequest.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};


/**
 * repeated presets.api.v1.Category categories = 3;
 * @return {!Array<!proto.presets.api.v1.Category>}
 */
proto.presets.base.api.v1.CountRequest.prototype.getCategoriesList = function() {
  return /** @type {!Array<!proto.presets.api.v1.Category>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.presets.api.v1.Category>} value
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.presets.api.v1.Category} value
 * @param {number=} opt_index
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.presets.base.api.v1.CountRequest} returns this
 */
proto.presets.base.api.v1.CountRequest.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.CountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.CountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.CountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.CountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.CountResponse}
 */
proto.presets.base.api.v1.CountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.CountResponse;
  return proto.presets.base.api.v1.CountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.CountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.CountResponse}
 */
proto.presets.base.api.v1.CountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.CountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.CountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.CountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.CountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.presets.base.api.v1.CountResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.presets.base.api.v1.CountResponse} returns this
 */
proto.presets.base.api.v1.CountResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.presets.base.api.v1.CountResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.presets.base.api.v1.CountResponse} returns this
*/
proto.presets.base.api.v1.CountResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.CountResponse} returns this
 */
proto.presets.base.api.v1.CountResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.CountResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.CreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.CreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.CreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.CreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodePoolName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    category: jspb.Message.getFieldWithDefault(msg, 3, 0),
    blueprint: (f = msg.getBlueprint()) && arranger_presets_models_pb.Blueprint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.CreateRequest}
 */
proto.presets.base.api.v1.CreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.CreateRequest;
  return proto.presets.base.api.v1.CreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.CreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.CreateRequest}
 */
proto.presets.base.api.v1.CreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodePoolName(value);
      break;
    case 3:
      var value = /** @type {!proto.presets.api.v1.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 4:
      var value = new arranger_presets_models_pb.Blueprint;
      reader.readMessage(value,arranger_presets_models_pb.Blueprint.deserializeBinaryFromReader);
      msg.setBlueprint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.CreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.CreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.CreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.CreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodePoolName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBlueprint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      arranger_presets_models_pb.Blueprint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.presets.base.api.v1.CreateRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.base.api.v1.CreateRequest} returns this
 */
proto.presets.base.api.v1.CreateRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string node_pool_name = 2;
 * @return {string}
 */
proto.presets.base.api.v1.CreateRequest.prototype.getNodePoolName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.base.api.v1.CreateRequest} returns this
 */
proto.presets.base.api.v1.CreateRequest.prototype.setNodePoolName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional presets.api.v1.Category category = 3;
 * @return {!proto.presets.api.v1.Category}
 */
proto.presets.base.api.v1.CreateRequest.prototype.getCategory = function() {
  return /** @type {!proto.presets.api.v1.Category} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.presets.api.v1.Category} value
 * @return {!proto.presets.base.api.v1.CreateRequest} returns this
 */
proto.presets.base.api.v1.CreateRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional presets.api.v1.Blueprint blueprint = 4;
 * @return {?proto.presets.api.v1.Blueprint}
 */
proto.presets.base.api.v1.CreateRequest.prototype.getBlueprint = function() {
  return /** @type{?proto.presets.api.v1.Blueprint} */ (
    jspb.Message.getWrapperField(this, arranger_presets_models_pb.Blueprint, 4));
};


/**
 * @param {?proto.presets.api.v1.Blueprint|undefined} value
 * @return {!proto.presets.base.api.v1.CreateRequest} returns this
*/
proto.presets.base.api.v1.CreateRequest.prototype.setBlueprint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.CreateRequest} returns this
 */
proto.presets.base.api.v1.CreateRequest.prototype.clearBlueprint = function() {
  return this.setBlueprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.CreateRequest.prototype.hasBlueprint = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.CreateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.CreateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.CreateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.CreateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    preset: (f = msg.getPreset()) && arranger_presets_models_pb.Preset.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.CreateResponse}
 */
proto.presets.base.api.v1.CreateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.CreateResponse;
  return proto.presets.base.api.v1.CreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.CreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.CreateResponse}
 */
proto.presets.base.api.v1.CreateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new arranger_presets_models_pb.Preset;
      reader.readMessage(value,arranger_presets_models_pb.Preset.deserializeBinaryFromReader);
      msg.setPreset(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.CreateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.CreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.CreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.CreateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      arranger_presets_models_pb.Preset.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional presets.api.v1.Preset preset = 1;
 * @return {?proto.presets.api.v1.Preset}
 */
proto.presets.base.api.v1.CreateResponse.prototype.getPreset = function() {
  return /** @type{?proto.presets.api.v1.Preset} */ (
    jspb.Message.getWrapperField(this, arranger_presets_models_pb.Preset, 1));
};


/**
 * @param {?proto.presets.api.v1.Preset|undefined} value
 * @return {!proto.presets.base.api.v1.CreateResponse} returns this
*/
proto.presets.base.api.v1.CreateResponse.prototype.setPreset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.CreateResponse} returns this
 */
proto.presets.base.api.v1.CreateResponse.prototype.clearPreset = function() {
  return this.setPreset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.CreateResponse.prototype.hasPreset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.presets.base.api.v1.CreateResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.presets.base.api.v1.CreateResponse} returns this
*/
proto.presets.base.api.v1.CreateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.CreateResponse} returns this
 */
proto.presets.base.api.v1.CreateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.CreateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.UpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.UpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.UpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    override: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    name: (f = msg.getName()) && arranger_presets_models_pb.NameValue.toObject(includeInstance, f),
    nodePoolName: (f = msg.getNodePoolName()) && arranger_presets_models_pb.NodePoolNameValue.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && arranger_presets_models_pb.CategoryValue.toObject(includeInstance, f),
    blueprint: (f = msg.getBlueprint()) && arranger_presets_models_pb.BlueprintValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.UpdateRequest}
 */
proto.presets.base.api.v1.UpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.UpdateRequest;
  return proto.presets.base.api.v1.UpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.UpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.UpdateRequest}
 */
proto.presets.base.api.v1.UpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverride(value);
      break;
    case 3:
      var value = new arranger_presets_models_pb.NameValue;
      reader.readMessage(value,arranger_presets_models_pb.NameValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new arranger_presets_models_pb.NodePoolNameValue;
      reader.readMessage(value,arranger_presets_models_pb.NodePoolNameValue.deserializeBinaryFromReader);
      msg.setNodePoolName(value);
      break;
    case 5:
      var value = new arranger_presets_models_pb.CategoryValue;
      reader.readMessage(value,arranger_presets_models_pb.CategoryValue.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 6:
      var value = new arranger_presets_models_pb.BlueprintValue;
      reader.readMessage(value,arranger_presets_models_pb.BlueprintValue.deserializeBinaryFromReader);
      msg.setBlueprint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.UpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.UpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.UpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOverride();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      arranger_presets_models_pb.NameValue.serializeBinaryToWriter
    );
  }
  f = message.getNodePoolName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      arranger_presets_models_pb.NodePoolNameValue.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      arranger_presets_models_pb.CategoryValue.serializeBinaryToWriter
    );
  }
  f = message.getBlueprint();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      arranger_presets_models_pb.BlueprintValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
 */
proto.presets.base.api.v1.UpdateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool override = 2;
 * @return {boolean}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.getOverride = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
 */
proto.presets.base.api.v1.UpdateRequest.prototype.setOverride = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional presets.api.v1.NameValue name = 3;
 * @return {?proto.presets.api.v1.NameValue}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.getName = function() {
  return /** @type{?proto.presets.api.v1.NameValue} */ (
    jspb.Message.getWrapperField(this, arranger_presets_models_pb.NameValue, 3));
};


/**
 * @param {?proto.presets.api.v1.NameValue|undefined} value
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
*/
proto.presets.base.api.v1.UpdateRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
 */
proto.presets.base.api.v1.UpdateRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional presets.api.v1.NodePoolNameValue node_pool_name = 4;
 * @return {?proto.presets.api.v1.NodePoolNameValue}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.getNodePoolName = function() {
  return /** @type{?proto.presets.api.v1.NodePoolNameValue} */ (
    jspb.Message.getWrapperField(this, arranger_presets_models_pb.NodePoolNameValue, 4));
};


/**
 * @param {?proto.presets.api.v1.NodePoolNameValue|undefined} value
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
*/
proto.presets.base.api.v1.UpdateRequest.prototype.setNodePoolName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
 */
proto.presets.base.api.v1.UpdateRequest.prototype.clearNodePoolName = function() {
  return this.setNodePoolName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.hasNodePoolName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional presets.api.v1.CategoryValue category = 5;
 * @return {?proto.presets.api.v1.CategoryValue}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.getCategory = function() {
  return /** @type{?proto.presets.api.v1.CategoryValue} */ (
    jspb.Message.getWrapperField(this, arranger_presets_models_pb.CategoryValue, 5));
};


/**
 * @param {?proto.presets.api.v1.CategoryValue|undefined} value
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
*/
proto.presets.base.api.v1.UpdateRequest.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
 */
proto.presets.base.api.v1.UpdateRequest.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional presets.api.v1.BlueprintValue blueprint = 6;
 * @return {?proto.presets.api.v1.BlueprintValue}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.getBlueprint = function() {
  return /** @type{?proto.presets.api.v1.BlueprintValue} */ (
    jspb.Message.getWrapperField(this, arranger_presets_models_pb.BlueprintValue, 6));
};


/**
 * @param {?proto.presets.api.v1.BlueprintValue|undefined} value
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
*/
proto.presets.base.api.v1.UpdateRequest.prototype.setBlueprint = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.UpdateRequest} returns this
 */
proto.presets.base.api.v1.UpdateRequest.prototype.clearBlueprint = function() {
  return this.setBlueprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.UpdateRequest.prototype.hasBlueprint = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.UpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.UpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.UpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.UpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    preset: (f = msg.getPreset()) && arranger_presets_models_pb.Preset.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.UpdateResponse}
 */
proto.presets.base.api.v1.UpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.UpdateResponse;
  return proto.presets.base.api.v1.UpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.UpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.UpdateResponse}
 */
proto.presets.base.api.v1.UpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new arranger_presets_models_pb.Preset;
      reader.readMessage(value,arranger_presets_models_pb.Preset.deserializeBinaryFromReader);
      msg.setPreset(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.UpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.UpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.UpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.UpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      arranger_presets_models_pb.Preset.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional presets.api.v1.Preset preset = 1;
 * @return {?proto.presets.api.v1.Preset}
 */
proto.presets.base.api.v1.UpdateResponse.prototype.getPreset = function() {
  return /** @type{?proto.presets.api.v1.Preset} */ (
    jspb.Message.getWrapperField(this, arranger_presets_models_pb.Preset, 1));
};


/**
 * @param {?proto.presets.api.v1.Preset|undefined} value
 * @return {!proto.presets.base.api.v1.UpdateResponse} returns this
*/
proto.presets.base.api.v1.UpdateResponse.prototype.setPreset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.UpdateResponse} returns this
 */
proto.presets.base.api.v1.UpdateResponse.prototype.clearPreset = function() {
  return this.setPreset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.UpdateResponse.prototype.hasPreset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.presets.base.api.v1.UpdateResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.presets.base.api.v1.UpdateResponse} returns this
*/
proto.presets.base.api.v1.UpdateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.UpdateResponse} returns this
 */
proto.presets.base.api.v1.UpdateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.UpdateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.DeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.DeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.DeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.DeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.DeleteRequest}
 */
proto.presets.base.api.v1.DeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.DeleteRequest;
  return proto.presets.base.api.v1.DeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.DeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.DeleteRequest}
 */
proto.presets.base.api.v1.DeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.DeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.DeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.DeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.DeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.presets.base.api.v1.DeleteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.presets.base.api.v1.DeleteRequest} returns this
 */
proto.presets.base.api.v1.DeleteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.presets.base.api.v1.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.presets.base.api.v1.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.presets.base.api.v1.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.presets.base.api.v1.DeleteResponse}
 */
proto.presets.base.api.v1.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.presets.base.api.v1.DeleteResponse;
  return proto.presets.base.api.v1.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.presets.base.api.v1.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.presets.base.api.v1.DeleteResponse}
 */
proto.presets.base.api.v1.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.presets.base.api.v1.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.presets.base.api.v1.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.presets.base.api.v1.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.presets.base.api.v1.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.presets.base.api.v1.DeleteResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.presets.base.api.v1.DeleteResponse} returns this
*/
proto.presets.base.api.v1.DeleteResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.presets.base.api.v1.DeleteResponse} returns this
 */
proto.presets.base.api.v1.DeleteResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.presets.base.api.v1.DeleteResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.presets.base.api.v1);
