import {envVars} from '../../../config/envs/env';
import {FilesServiceClient} from '../../../autogen/gRPC/arranger/files/Service_filesServiceClientPb';
import {
	ArrangeUploadPartRequest,
	CancelUploadRequest,
	DeleteRequest,
	FinalizeUploadRequest,
	InitUploadRequest,
	ListRequest,
	ListUploadPartsRequest,
	ProbeRequest
} from '../../../autogen/gRPC/arranger/files/service_files_pb';
import {AuthService} from '../../auth/api/service';
import {InputInfo} from '../../../autogen/gRPC/prober/models_pb';

const authService = AuthService.getInstance();
const service = new FilesServiceClient(envVars.apiArrangerUrl);

export const prepareHeaders = async () => {
	const token = await authService.currentToken();
	return {'Authorization': 'Bearer ' + token.token};
};

export const callInitUpload = async ({name, type}: { name: string, type: string }) => {
	const request = new InitUploadRequest();
	request.setName(name);
	request.setType(type);

	const response = await service.initUpload(request, await prepareHeaders());
	return response.toObject();
};

export const callListUploadParts = async ({name, uploadId}: { name: string, uploadId: string }) => {
	const request = new ListUploadPartsRequest();
	request.setName(name);
	request.setUploadId(uploadId);

	const response = await service.listUploadParts(request, await prepareHeaders());
	return response.toObject();
};

export const callArrangeUploadPart = async ({
	name,
	uploadId,
	partNumber,
	hash
}: { name: string, uploadId: string, partNumber: number, hash: string }) => {
	const request = new ArrangeUploadPartRequest();
	request.setName(name);
	request.setHash(hash);
	request.setUploadId(uploadId);
	request.setPartNumber(partNumber);

	const response = await service.arrangeUploadPart(request, await prepareHeaders());
	const decodedToken = JSON.parse(atob(response.getToken()).toString()) as { url: string };

	return decodedToken.url;
};

export const callFinalizeUpload = async ({name, uploadId, parts}: { name: string, uploadId: string, parts: Map<number, string> }) => {
	const request = new FinalizeUploadRequest();
	request.setName(name);
	request.setUploadId(uploadId);

	parts.forEach((value, key) => request.getPartsMap().set(key, value));

	const response = await service.finalizeUpload(request, await prepareHeaders());
	return response.toObject();
};

export const callCancelUpload = async ({name, uploadId}: { name: string, uploadId: string }) => {
	const request = new CancelUploadRequest();
	request.setName(name);
	request.setUploadId(uploadId);

	const response = await service.cancelUpload(request, await prepareHeaders());
	return response.toObject();
};

export const callListUploadedFiles = async (names?: string[]) => {
	const request = new ListRequest();

	request.setNamesList(names ?? []);

	const response = await service.list(request, await prepareHeaders());
	return response.toObject();
};

export const callDeleteUploadedFiles = async (names: string[]) => {
	const request = new DeleteRequest();

	request.setNamesList(names);

	const response = await service.delete(request, await prepareHeaders());
	return response.toObject();
};

export const callProbeFile = async (info: InputInfo) => {
	const request = new ProbeRequest();

	request.setInputInfo(info);

	const response = await service.probe(request, await prepareHeaders());
	return response.toObject();
};

