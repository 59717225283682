import React, {FC} from 'react';
import {Button, Collapse, Row, Typography} from 'antd';
import {GenericOutputS, GenericOutputS_Container} from './GenericOutputS';
import {DeleteOutlined} from '@ant-design/icons';

const {Panel} = Collapse;

export interface GenericOutputProps {
	idx: number
	title: string,
	onDelete?: (idx: number) => void
	topContent?: JSX.Element
	bottomContent?: JSX.Element
}

export const GenericOutput: FC<GenericOutputProps> = (props) => {
	const {idx, title, onDelete, topContent, bottomContent, children} = props;

	const preparePanelHeader = (idx: number, title: string, onDelete?: () => void) => (
		<Row align="middle" justify="space-between" style={{width: '100%'}}>
			<Typography>{title}</Typography>
			{onDelete &&
				<div>
					<Button type="text" shape="circle" icon={<DeleteOutlined/>} onClick={(e) => {
						e.stopPropagation();
						onDelete();
					}}/>
				</div>
			}
		</Row>
	);

	const prepareOutput = (idx: number) => (
		<Collapse defaultActiveKey={['1']} onChange={() => {
		}}>
			<Panel key="1" header={preparePanelHeader(
				idx,
				`${title}`,
				onDelete ? () => onDelete(idx) : undefined)}
			>
				{topContent}
				<GenericOutputS_Container>
					{children}
				</GenericOutputS_Container>
				{bottomContent}
			</Panel>
		</Collapse>
	);

	return (
		<GenericOutputS deletable={!!onDelete}>
			{prepareOutput(idx)}
		</GenericOutputS>
	);
};

