import styled from 'styled-components';

export const HeadersS = styled.div`
	margin: 0
`;

export const HeadersS_LongRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	margin: 0 0 10px 0;
	width: 100%;
	
	.ant-btn {
		margin-top: 30px;
	}

	@media (max-width: 1125px) {
		width: unset;
		flex-direction: column;
		gap: unset;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0 0 20px 0;
	}
`;
