import React, {FC, useState} from 'react';
import {Button, Col, Form, Input, Row, Typography} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import {HeadersS, HeadersS_LongRow} from './HeadersS';

export const Headers: FC = () => {
	const [indexes, setIndexes] = useState([0]);

	return (
		<HeadersS style={{width: '100%'}}>
			{indexes.map((index) => (
				<Col style={{width: '100%'}}>
					<Row justify="center">
						<HeadersS_LongRow style={{gap: 30}}>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text>HTTP Headers (optional)</Typography.Text>}
							           name={`customHeader|${index}`}
							           tooltip="Set custom key value pair settings"
							>
								<Input placeholder="header"/>
							</Form.Item>
							<Form.Item style={{margin: 0}}
							           label={<Typography.Text></Typography.Text>}
							           name={`customData|${index}`}
							>
								<Input placeholder="value"/>
							</Form.Item>
							{index === 0 &&
								<div style={{marginLeft: '-30px'}}>
									<Button icon={<PlusCircleOutlined/>} shape="circle" type="text" onClick={() => {
										setIndexes([...indexes, indexes.length]);
									}}/>
								</div>
							}
						</HeadersS_LongRow>
					</Row>
				</Col>
			))}
		</HeadersS>
	);
};
