import {ChangeEvent} from 'react';
import {UploadEntry} from '../components/UploadPanel/UploadPanel';
import {store} from '../../../stores/store';
import {selectUpload, uploadsActions} from '../../../stores/slices/uploads';
import Uploader, {Progress} from '../domain/uploader';
import {Notification, notify} from '../../../utils/notify';
import {callListUploadedFiles} from '../api/requests';
import {FileEntry, filesActions, selectFiles} from '../../../stores/slices/files';

let resumedProgress = 0;

export const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
	e.preventDefault();
	if (e.target.files) {
		const files = e && Array.from(e.target.files);
		handleFiles(files);
	}
};

export const handleFiles = (files: File[]) => {
	if (files) {
		Uploader.getInstance().enqueue(files).then();
		const entries = files.map((file) => {
			const entry: UploadEntry = {
				name: file.name,
				progress: 0,
				speed: 'queued',
				size: `${(Math.round((file.size / 1000000) * 100) / 100).toFixed(2)} MB`,
				status: 'pending',
				resumed: false
			};
			return entry;
		});
		store.dispatch(uploadsActions.setUploads(entries));
	}
};

const onUploadCompleted = async (name: string) => {
	notify(Notification.SUCCESS, 'Operation Completed', `File ${name} has been uploaded successfully`);
	const currentUpload = selectUpload(store.getState(), name);
	if (currentUpload) {
		store.dispatch(uploadsActions.updateUpload({id: name, changes: {status: 'completed'}}));

		try {
			const response = await callListUploadedFiles();
			const files = response.filesList.map((file) => {
				const result: FileEntry = {
					name: file.name,
					file: file
				};
				return result;
			});
			store.dispatch(filesActions.addFiles(files));
		} catch (e) {
			console.error(e);
		}
	}
};

const onUploadFailed = async (name: string) => {
	notify(Notification.ERROR, 'Operation Failed', `File ${name} failed to upload. Please try again`);
	const currentUpload = selectUpload(store.getState(), name);
	if (currentUpload) {
		store.dispatch(uploadsActions.updateUpload({id: name, changes: {status: 'failed'}}));
	}
};

const onUploadResumed = (name: string, bytesNum: number) => {
	const currentUpload = selectUpload(store.getState(), name);
	if (currentUpload?.progress === 0) {
		notify(Notification.INFO, 'Resuming upload', `Detected resumable transfer. Previous progress has been restored`);
		const currentFile = Uploader.getInstance().currentUpload();
		if (currentUpload && currentFile) {
			resumedProgress = Math.ceil((bytesNum / currentFile.size) * 100);
			store.dispatch(uploadsActions.updateUpload({id: name, changes: {resumed: true}}));
		}
	}
};

const calculateProgress = (currentUpload: UploadEntry, progress: Progress) => {
	if (currentUpload.resumed) {
		return resumedProgress + Math.ceil(progress.percent);
	} else {
		return (progress.percent > currentUpload.progress) ? Math.ceil(progress.percent) : currentUpload.progress;
	}
};

const onUploadProgress = (name: string, progress: Progress) => {
	const currentUpload = selectUpload(store.getState(), name);
	if (currentUpload && currentUpload.status === 'pending' && progress) {
		const newProgress = calculateProgress(currentUpload, progress);
		const speed = `${(Math.abs(Math.round(progress.speed * 100) / 100).toFixed(2))} MB/s`;
		store.dispatch(uploadsActions.updateUpload({id: name, changes: {progress: newProgress, speed: speed}}));
	}
};

export const setupFilesManagement = async () => {
	const files = selectFiles(store.getState());

	const uploader = Uploader.getInstance();
	uploader.onUploadCompleted = onUploadCompleted;
	uploader.onUploadProgress = onUploadProgress;
	uploader.onUploadResumed = onUploadResumed;
	uploader.onUploadFailed = onUploadFailed;

	if (!files.length) {
		try {
			const response = await callListUploadedFiles();
			const files = response.filesList.map((file) => {
				const result: FileEntry = {
					name: file.name,
					file: file
				};
				return result;
			});
			store.dispatch(filesActions.addFiles(files));
		} catch (e) {
			console.error(e);
		}
	}
};
