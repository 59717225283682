import styled from 'styled-components';

export const VideoAudioMappingS = styled.div`
`;

export const VideoAudioMappingS_LongRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	margin: 0 0 10px 50px;

	.anticon-arrow-right {
		margin-top: 30px;
	}

	.ant-btn {
		margin-top: 30px;
	}

	.ant-checkbox {
		margin-top: 30px;
	}

	.ant-input {
		min-width: 300px;
	}

	@media (max-width: 1125px) {
		width: unset;
		flex-direction: column;
		gap: unset;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0 0 20px 0;
	}
`;
